import { INews } from '../models/news'

export const news = (state: INews[] = [], action: any) => {
	switch (action.type) {
		case 'News.Load':
			return [
				...state,
				...action.news
			]
		default:
			return state
	}
}