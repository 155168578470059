import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { store } from '..'
import { translate } from '../functions/lang'
import { INews } from '../models/news'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
	news: INews[]
	loadNews: (news: INews[]) => void
}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileHomeComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	render() {

		const lang = this.props.lang
		translate.language = lang

		const ptBr = require('../images/flags/pt-br.svg')
		const enUs = require('../images/flags/en-us.svg')
		const esEs = require('../images/flags/es-es.svg')

		const news = (this.props.news || []).filter(f => f.lang === this.props.lang)

		return (
			<div>
				<div className='homeBanner'>
					<div className='homeBannerTitle'>
						<h2>{translate.text('home_banner_msg_1')}<br />{translate.text('home_banner_msg_2')}</h2>
					</div>
					<Link className='homeBannerBtn' to='/register'>{translate.text('create_free_account')}</Link>
				</div>
				<div className='homeTitle'>
					<h2>{translate.text('whats_new')}</h2>
				</div>
				<div>
					{news.map(m =>
						<div className='homeNews' key={m.title}>
							<a href={m.link} target='_blank' rel='noopener noreferrer'>{m.title}<span>{Intl.DateTimeFormat(undefined, { day: '2-digit', month: 'numeric' }).format(m.date)}</span></a>
						</div>
					)}
					<div className='homeNewsMore'><a href={translate.text('blog_cat_url')} target='_blank' rel='noopener noreferrer'>{translate.text('view_more')}</a></div>
				</div>
				<div className='homeSpacer' />
				<div className='homeTitle'>
					<h2>{translate.text('company')}</h2>
				</div>
				<div className='homeList'>
					<Link to='/aboutUs' className='homeListItem'><span>{translate.text('about_us')}</span></Link>
					<Link to='/fees' className='homeListItem'><span>{translate.text('fees')}</span></Link>
					<Link to='/amlPolicy' className='homeListItem'><span>{translate.text('aml_policy')}</span></Link>
					<Link to='/privacyPolicy' className='homeListItem'><span>{translate.text('privacy_policy')}</span></Link>
					<Link to='/termsOfUse' className='homeListItem'><span>{translate.text('terms_of_use')}</span></Link>
				</div>
				<div className='homeSpacer' />
				<div className='homeTitle'>
					<h2>{translate.text('help')}</h2>
				</div>
				<div className='homeList'>
					<span className='homeListItem'><a href='https://docs.econobit.io/' target='_blank' rel='noopener noreferrer'>{translate.text('api_documentation')}</a></span>
					<span className='homeListItem'><a href={translate.text('support_faq_url')} target='_blank' rel='noopener noreferrer'>{translate.text('faq')}</a></span>
					<span className='homeListItem'><a href='https://stats.uptimerobot.com/49nrKuPnJ' target='_blank' rel='noopener noreferrer'>{translate.text('status_page')}</a></span>
					<span className='homeListItem'><a href={translate.text('support_url')} target='_blank' rel='noopener noreferrer'>{translate.text('support')}</a></span>
				</div>
				<div className='homeSpacer' />
				<div className='homeTitle'>
					<h2>{translate.text('contact_us')}</h2>
				</div>
				<div className='homeList'>
					<span className='homeListItem'><a href='mailto:contact@econobit.io'>contact@econobit.io</a></span>
					<span className='homeListItem'><a href='mailto:support@econobit.io'>support@econobit.io</a></span>
				</div>
				<div className='homeSpacer' />
				<div className='homeTitle'>
					<h2>{translate.text('language')}</h2>
				</div>
				<div className='langSelect'>
					<div className='langSelectItem'>
						<label><img src={enUs} className='langItemIcon' alt='' /><input type='radio' name='lang' value='en-us' onChange={this.changeLang} /> English</label>
					</div>
					<div className='langSelectItem'>
						<label><img src={esEs} className='langItemIcon' alt='' /><input type='radio' name='lang' value='es-es' onChange={this.changeLang} /> Español</label>
					</div>
					<div className='langSelectItem'>
						<label><img src={ptBr} className='langItemIcon' alt='' /><input type='radio' name='lang' value='pt-br' onChange={this.changeLang} /> Português</label>
					</div>
				</div>
				<div className='mobileCopyright'>©‎ 2023 Econobit Ltd.</div>
			</div>

		)
	}
	changeLang(event: React.ChangeEvent<HTMLInputElement>) {
		store.dispatch({ type: 'Lang.Change', lang: event.target.value })
	}
}