import { ActionTypes, Candlesticks, ICandlestickAction, ICandlesticks } from 'exchange-common'

export const candlesticks =
	(state: ICandlesticks = new Candlesticks(), action: ICandlestickAction): ICandlesticks => {
		switch (action.type) {
			case ActionTypes.Candlestick.List:
				return {
					...state,
					...action.candlesticks,
				}
			case ActionTypes.Candlestick.Update:

				return {
					...state,
					list: {
						...state.list,
						[action.market]: [
							...state.list[action.market].filter((f) => new Date(f.date).getTime() !== new Date(action.candlestick.date).getTime()),
							action.candlestick
						]
					}
				}
			case ActionTypes.Candlestick.Period:
				return {
					...state,
					period: action.period,
				}
			default:
				return state
		}
	}