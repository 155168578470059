import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import config from '../config.json'
import { IState } from '../models/state'
interface IProps {
  // include component properties here.

}

interface IMatchProps {
  // include any url parameters here.

}

export class HeaderLogoComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

  componentDidMount() {
    // run after component is called.

  }

  componentWillUnmount() {
    // run before component is unloaded.

  }

  render() {

    return (
      <div className='headerLogo'>
        <Link to='/'>
          <div className='header-logo' title={`Econobit version ${config.version.hash}
Last updated ${config.version.date}`} />
        </Link>
      </div>
    )
  }

}