import { ActionCreators } from 'exchange-common'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IMatchProps {
	market: string
}

export const MarketsComponent: React.FC = () => {
	translate.language = useSelector((state: IState) => state.lang)
	const { current, list: markets } = useSelector((state: IState) => state.markets)
	const tickers = useSelector((state: IState) => state.tickers.list)
	const { market } = useParams<IMatchProps>()
	const dispatch = useDispatch()

	useEffect(() => {
		if (market && market !== current) {
			// ws.send(ActionCreators.Server.Unsubscribe.Orderbook(current))
			// ws.send(ActionCreators.Server.Unsubscribe.LastTrades(current))
			// ws.send(ActionCreators.Server.Unsubscribe.Candlestick(current))
			dispatch(ActionCreators.Market.change(market))
		} else {
			// ws.send(ActionCreators.Server.Subscribe.Orderbook(current))
			// ws.send(ActionCreators.Server.Subscribe.LastTrades(current))
			// ws.send(ActionCreators.Server.Subscribe.Candlestick(current))
		}
	})

	const btcBrlLogo = require('../images/pairs/BTC-BRL.svg')
	const ltcBrlLogo = require('../images/pairs/LTC-BRL.svg')
	const dogeBrlLogo = require('../images/pairs/DOGE-BRL.svg')
	const ethBrlLogo = require('../images/pairs/ETH-BRL.svg')
	const ebitBrlLogo = require('../images/pairs/EBIT-BRL.svg')
	const linkBrlLogo = require('../images/pairs/LINK-BRL.svg')

	const btcUsdcLogo = require('../images/pairs/BTC-USDC.svg')
	const ltcUsdcLogo = require('../images/pairs/LTC-USDC.svg')
	const dogeUsdcLogo = require('../images/pairs/DOGE-USDC.svg')
	const ethUsdcLogo = require('../images/pairs/ETH-USDC.svg')
	const ebitUsdcLogo = require('../images/pairs/EBIT-USDC.svg')
	const linkUsdcLogo = require('../images/pairs/LINK-USDC.svg')

	const ltcBtcLogo = require('../images/pairs/LTC-BTC.svg')
	const dogeBtcLogo = require('../images/pairs/DOGE-BTC.svg')
	const ethBtcLogo = require('../images/pairs/ETH-BTC.svg')
	const ebitBtcLogo = require('../images/pairs/EBIT-BTC.svg')
	const linkBtcLogo = require('../images/pairs/LINK-BTC.svg')


	if (!Object.keys(markets).length || !Object.keys(tickers).length) {
		return <div />
	}

	const logo: any = {
		'BTC-BRL': {
			src: btcBrlLogo,
			alt: 'BTC-BRL',
		},
		'LTC-BRL': {
			src: ltcBrlLogo,
			alt: 'LTC-BRL',
		},
		'ETH-BRL': {
			src: ethBrlLogo,
			alt: 'ETH-BRL',
		},
		'EBIT-BRL': {
			src: ebitBrlLogo,
			alt: 'EBIT-BRL',
		},
		'DOGE-BRL': {
			src: dogeBrlLogo,
			alt: 'DOGE-BRL',
		},
		'LINK-BRL': {
			src: linkBrlLogo,
			alt: 'LINK-BRL',
		},
		'BTC-USDC': {
			src: btcUsdcLogo,
			alt: 'BTC-USDC',
		},
		'LTC-USDC': {
			src: ltcUsdcLogo,
			alt: 'LTC-USDC',
		},
		'DOGE-USDC': {
			src: dogeUsdcLogo,
			alt: 'DOGE-USDC',
		},
		'ETH-USDC': {
			src: ethUsdcLogo,
			alt: 'ETH-USDC',
		},
		'EBIT-USDC': {
			src: ebitUsdcLogo,
			alt: 'EBIT-USDC',
		},
		'LINK-USDC': {
			src: linkUsdcLogo,
			alt: 'LINK-USDC',
		},
		'LTC-BTC': {
			src: ltcBtcLogo,
			alt: 'LTC-BTC',
		},
		'ETH-BTC': {
			src: ethBtcLogo,
			alt: 'ETH-BTC',
		},
		'EBIT-BTC': {
			src: ebitBtcLogo,
			alt: 'EBIT-BTC',
		},
		'DOGE-BTC': {
			src: dogeBtcLogo,
			alt: 'DOGE-BTC',
		},
		'LINK-BTC': {
			src: linkBtcLogo,
			alt: 'LINK-BTC',
		},
	}

	const color = (delta: number) => {
		switch (true) {
			case delta > 0:
				return ' green'
			case delta < 0:
				return ' red'
			default:
				return ' white'
		}
	}

	const format = (delta: number, decimals: number) => {
		return delta < 0 ? delta.toFixed(decimals) : '+' + delta.toFixed(decimals)
	}

	const onMenuClick = () => {
		const x = document.getElementById('marketsDropdown')!
		if (x.style.left !== '0px') {
			x.style.left = '0px'
			x.style.transition = 'left 150ms ease'
		} else {
			x.style.left = '-400px'
		}
		const y = document.getElementById('arrowDown')!
		if (y.style.transform !== 'rotate(-135deg)') {
			y.style.transform = 'rotate(-135deg)'
			y.style.margin = '5px 0 0 0'
			y.style.transition = 'transform 150ms ease'

		} else {
			y.style.transform = 'rotate(45deg)'
			y.style.margin = '0 0 2px 0'
		}
	}

	interface MarketsDropdownRowProps {
		symbol: string
		logo: any
		deltaDecimals?: number
	}

	const MarketsDropdownRow = ({ symbol, logo, deltaDecimals = 2 }: MarketsDropdownRowProps) => {
		if (!markets[symbol].visible) { return null }
		return (
			<Link className='marketsDropdownItem' to={`/exchange/${symbol}/`}>
				<img src={logo} className='marketsDropdownLogo' alt={symbol} />
				<div className='marketsDropdownLabel'>{symbol}</div>
				<div className='marketsDropdownTickerValue'><span>{tickers[symbol].lastPrice.toFixed(markets[symbol].priceDecimals)}</span></div>
				<div className={'marketsDropdownTickerDelta' + color(tickers[symbol].delta24h)}><span>{format(tickers[symbol].delta24h * 100, deltaDecimals)}%</span></div>
			</Link>
		)
	}

	const haveMarkets = (coin: string) => Object.entries(markets).some(([name, market]) => name.endsWith(coin) && market.visible)

	const MarketsDropdownHeader = ({ coin }: { coin: string }) => {
		if (!haveMarkets(coin)) { return null }
		return (
			<div className='marketsDropdownTitle'>
				<div className='marketsTitleLabel'>{translate.text(`${coin.toLowerCase()}_markets`)}</div>
			</div>
		)
	}

	return (
		<div className='markets' onClick={onMenuClick} style={{ userSelect: 'none' }}>
			<img src={logo[current].src} className='marketsLogo' alt={logo[current].alt} />
			<div className='marketsLabel'>{current}</div>
			<div className='marketsSelect'><span className='marketsSelectText'>{translate.text('select_market')}</span><span className='arrowDown' id='arrowDown' /></div>
			<div className='marketsDropdown' id='marketsDropdown'>
				<MarketsDropdownHeader coin='BRL' />
				<MarketsDropdownRow symbol='BTC-BRL' logo={btcBrlLogo} />
				<MarketsDropdownRow symbol='ETH-BRL' logo={ethBrlLogo} />
				<MarketsDropdownRow symbol='EBIT-BRL' logo={ebitBrlLogo} />
				<MarketsDropdownRow symbol='LTC-BRL' logo={ltcBrlLogo} />
				<MarketsDropdownRow symbol='DOGE-BRL' logo={dogeBrlLogo} />
				<MarketsDropdownRow symbol='LINK-BRL' logo={linkBrlLogo} />

				<MarketsDropdownHeader coin='USDC' />
				<MarketsDropdownRow symbol='BTC-USDC' logo={btcUsdcLogo} />
				<MarketsDropdownRow symbol='ETH-USDC' logo={ethUsdcLogo} />
				<MarketsDropdownRow symbol='EBIT-USDC' logo={ebitUsdcLogo} />
				<MarketsDropdownRow symbol='LTC-USDC' logo={ltcUsdcLogo} />
				<MarketsDropdownRow symbol='DOGE-USDC' logo={dogeUsdcLogo} />
				<MarketsDropdownRow symbol='LINK-USDC' logo={linkUsdcLogo} />

				<MarketsDropdownHeader coin='BTC' />
				<MarketsDropdownRow symbol='ETH-BTC' logo={ethBtcLogo} />
				<MarketsDropdownRow symbol='EBIT-BTC' logo={ebitBtcLogo} />
				<MarketsDropdownRow symbol='LTC-BTC' logo={ltcBtcLogo} />
				<MarketsDropdownRow symbol='DOGE-BTC' logo={dogeBtcLogo} />
				<MarketsDropdownRow symbol='LINK-BTC' logo={linkBtcLogo} />
			</div>
		</div >
	)
}