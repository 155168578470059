import { IMarkets, ITickers } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	markets: IMarkets
	tickers: ITickers
	isLogged: boolean
	lang: string
}

interface IMatchProps {
	// include any url parameters here.
	// market: string
}

export class MarketsMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {
	componentWillMount() {
		// const { market } = this.props.match.params
		// const { current } = this.props.markets

		// if (!market) {
		// 	return
		// }
		// if (market !== current) {
		// 	ws.send(ActionCreators.Server.Unsubscribe.Orderbook(current))
		// 	ws.send(ActionCreators.Server.Subscribe.Orderbook(market))
		// 	ws.send(ActionCreators.Server.Unsubscribe.LastTrades(current))
		// 	ws.send(ActionCreators.Server.Subscribe.LastTrades(market))
		// 	ws.send(ActionCreators.Server.Unsubscribe.Candlestick(current))
		// 	ws.send(ActionCreators.Server.Subscribe.Candlestick(market))
		// 	this.props.marketsChange(market)
		// } else {
		// 	ws.send(ActionCreators.Server.Subscribe.Orderbook(current))
		// 	ws.send(ActionCreators.Server.Subscribe.LastTrades(current))
		// 	ws.send(ActionCreators.Server.Subscribe.Candlestick(current))
		// }
	}

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		translate.language = this.props.lang

		const brlLogo = require('../images/coins/BRL.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const usdcLogo = require('../images/coins/USDC.svg')

		const markets = this.props.markets.list
		const tickers = this.props.tickers.list
		if (!Object.keys(markets).length || !Object.keys(tickers).length) {
			return <div />
		}

		const color = (delta: number) => {
			switch (true) {
				case delta > 0:
					return ' green'
				case delta < 0:
					return ' red'
				default:
					return ' grayZero'
			}
		}

		const format = (delta: number, decimals: number) => {
			return delta < 0 ? delta.toFixed(decimals) : '+' + delta.toFixed(decimals)
		}

		interface MarketRowProps {
			symbol: string
			logo: any
			deltaDecimals?: number
			volumeDecimals?: number
		}

		const MarketRow = ({ symbol, logo, deltaDecimals = 2, volumeDecimals = 2 }: MarketRowProps) => {
			if (!markets[symbol].visible) { return null }
			return (
				<Link className='homeMarketsTableBodyRowLink' to={`/exchange/${symbol}/`}>
					<div className='homeMarketsTableBodyRow'>
						<div className='homeMarketsTableBodyRowItem'><img src={logo} className='homeMarketsLogo' alt={symbol.split('-')[0]} /><span>{symbol}</span></div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].lastPrice.toFixed(markets[symbol].priceDecimals)}</div>
						<div className={'homeMarketsTableBodyRowItem' + color(tickers[symbol].delta24h)}><b>{format(tickers[symbol].delta24h * 100, deltaDecimals)}%</b></div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].high24h.toFixed(markets[symbol].priceDecimals)}</div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].low24h.toFixed(markets[symbol].priceDecimals)}</div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].volume24h.toFixed(volumeDecimals)}</div>
					</div>
				</Link>
			)
		}

		const MarketRowHeader = () =>
			<div className='homeMarketsTableHeaderRow'>
				<div className='homeMarketsTableHeaderRowItem pairColumn'>{translate.text('pair')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('last_price')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_price')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_high')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_low')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_volume')}</div>
			</div>

		const haveMarkets = (coin: string) => Object.entries(markets).some(([name, market]) => name.endsWith(coin) && market.visible)

		return (
			<div className='marketsPage'>
				<div className='marketsMain'>
					<span className='pageTitle'>{translate.text('markets')}</span>
					<div className='marketsMainBox'>
						<Tabs>
							<TabList>
								{haveMarkets('BRL') &&
									<Tab><img src={brlLogo} className='homeSelectMarketsLogo' alt='BRL' /> {translate.text('brl_markets')}</Tab>
								}
								{haveMarkets('USDC') &&
									<Tab><img src={usdcLogo} className='homeSelectMarketsLogo' alt='USDC' /> {translate.text('usdc_markets')}</Tab>
								}
								{haveMarkets('BTC') &&
									<Tab><img src={btcLogo} className='homeSelectMarketsLogo' alt='BTC' /> {translate.text('btc_markets')}</Tab>
								}
							</TabList>
							{haveMarkets('BRL') &&
								<TabPanel>
									<div className='homeMarketsTableMain'>
										<MarketRowHeader />
										<MarketRow symbol='BTC-BRL' logo={btcLogo} />
										<MarketRow symbol='ETH-BRL' logo={ethLogo} />
										<MarketRow symbol='EBIT-BRL' logo={ebitLogo} />
										<MarketRow symbol='LTC-BRL' logo={ltcLogo} />
										<MarketRow symbol='DOGE-BRL' logo={dogeLogo} />
										<MarketRow symbol='LINK-BRL' logo={linkLogo} />
									</div>
								</TabPanel>
							}
							{haveMarkets('USDC') &&
								<TabPanel>
									<div className='homeMarketsTableMain'>
										<MarketRowHeader />
										<MarketRow symbol='BTC-USDC' logo={btcLogo} />
										<MarketRow symbol='ETH-USDC' logo={ethLogo} />
										<MarketRow symbol='EBIT-USDC' logo={ebitLogo} />
										<MarketRow symbol='LTC-USDC' logo={ltcLogo} />
										<MarketRow symbol='DOGE-USDC' logo={dogeLogo} />
										<MarketRow symbol='LINK-USDC' logo={linkLogo} />
									</div>
								</TabPanel>
							}
							{haveMarkets('BTC') &&
								<TabPanel>
									<div className='homeMarketsTableMain'>
										<MarketRowHeader />
										<MarketRow symbol='ETH-BTC' logo={ethLogo} />
										<MarketRow symbol='EBIT-BTC' logo={ebitLogo} />
										<MarketRow symbol='LTC-BTC' logo={ltcLogo} />
										<MarketRow symbol='DOGE-BTC' logo={dogeLogo} />
										<MarketRow symbol='LINK-BTC' logo={linkLogo} />
									</div>
								</TabPanel>
							}
						</Tabs>
					</div>
				</div>
				<HomeFooterContainer />
			</div >
		)
	}

}