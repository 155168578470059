import { ICurrencies, ITickers } from 'exchange-common'
import { multipliedBy } from 'libs-tools'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	lang: string
	tickers: ITickers
	currencies: ICurrencies
}

interface IMatchProps {
	// include any url parameters here.

}

export class FeesComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang
		const { tickers, currencies } = this.props
		if (!tickers.list['BTC-BRL'] || !currencies.list[1]) {
			return <div />
		}
		const { makerFee, takerFee } = tickers.list['BTC-BRL']
		const btc = currencies.list[1]
		const brl = currencies.list[2]
		const eth = currencies.list[3]
		const ltc = currencies.list[4]
		const link = currencies.list[7]
		const usdc = currencies.list[8]
		const doge = currencies.list[9]
		const ebit = currencies.list[10]
		
		const linkLogo = require('../images/coins/LINK.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const usdcLogo = require('../images/coins/USDC.svg')
		const brlLogo = require('../images/coins/BRL.svg')

		return (
			<div className='fees'>
				<div className='feesMain'>
					<span className='pageTitle'>{translate.text('fees')}</span>
					<div className='feesBox'>
						<h4>{translate.text('account')}</h4>
						<table>
							<tbody>
								<tr className='feesRow' >
									<td><b>{translate.text('account_opening')}</b></td>
									<td>{translate.text('free')}</td>
								</tr>
								<tr className='feesRow' >
									<td><b>{translate.text('custody')}</b></td>
									<td>{translate.text('free')}</td>
								</tr>
							</tbody>
						</table>
						<br />
						<h4>{translate.text('trading')}</h4>
						<table>
							<tbody>
								<tr>
									<td><b>{translate.text('maker_cap')}</b></td>
									<td>{makerFee > 0 ? makerFee * 100 + '%' : translate.text('free')}</td>
								</tr>
								<tr>
									<td><b>{translate.text('taker_cap')}</b></td>
									<td>{takerFee * 100}%</td>
								</tr>
							</tbody>
						</table>
						<br />
						<h4>{translate.text('deposits')} & {translate.text('withdrawals')}</h4>
						<table>
							<tbody>
								<tr>
									<th>{translate.text('coin')}</th>
									<th />
									<th>{translate.text('name')}</th>
									<th>{translate.text('deposit_fee')}</th>
									<th>{translate.text('withdrawal_fee')}</th>
									<th>{translate.text('minimum_withdrawal')}</th>
								</tr>
								<tr>
									<td><img src={brlLogo} className='feesCoinLogo' alt='BRL' /> BRL</td>
									<td><span className='coinProtocol'>FIAT</span></td>
									<td>Real Brasileiro</td>
									<td>{translate.text('free')}</td>
									<td>{translate.text('free')}</td>
									<td>{brl.minWithdraw} BRL</td>
								</tr>
								<tr>
									<td><img src={btcLogo} className='feesCoinLogo' alt='BTC' /> BTC</td>
									<td />
									<td>Bitcoin</td>
									<td>{translate.text('free')}</td>
									<td>{btc.feePct ? multipliedBy(btc.feePct, 100) + '%' : ''} {btc.feePct && btc.fee ? '+' : ''} {btc.fee ? btc.fee + ' BTC' : ''}</td>
									<td>{btc.minWithdraw} BTC</td>
								</tr>
								<tr>
									<td><img src={ethLogo} className='feesCoinLogo' alt='ETH' /> ETH</td>
									<td />
									<td>Ethereum</td>
									<td>{translate.text('free')}</td>
									<td>{eth.feePct ? multipliedBy(eth.feePct, 100) + '%' : ''} {eth.feePct && eth.fee ? '+' : ''} {eth.fee ? eth.fee + ' ETH' : ''}</td>
									<td>{eth.minWithdraw} ETH</td>
								</tr>
								<tr>
									<td><img src={ebitLogo} className='feesCoinLogo' alt='EBIT' /> EBIT</td>
									<td />
									<td>Econobit</td>
									<td>{translate.text('free')}</td>
									<td>{ebit.feePct ? multipliedBy(ebit.feePct, 100) + '%' : ''} {ebit.feePct && ebit.fee ? '+' : ''} {ebit.fee ? ebit.fee + ' EBIT' : ''}</td>
									<td>{ebit.minWithdraw} EBIT</td>
								</tr>
								<tr>
									<td><img src={ltcLogo} className='feesCoinLogo' alt='LTC' /> LTC</td>
									<td />
									<td>Litecoin</td>
									<td>{translate.text('free')}</td>
									<td>{ltc.feePct ? multipliedBy(ltc.feePct, 100) + '%' : ''} {ltc.feePct && ltc.fee ? '+' : ''} {ltc.fee ? ltc.fee + ' LTC' : ''}</td>
									<td>{ltc.minWithdraw} LTC</td>
								</tr>
								<tr>
									<td><img src={dogeLogo} className='feesCoinLogo' alt='DOGE' /> DOGE</td>
									<td />
									<td>Dogecoin</td>
									<td>{translate.text('free')}</td>
									<td>{doge.feePct ? multipliedBy(doge.feePct, 100) + '%' : ''} {doge.feePct && doge.fee ? '+' : ''} {doge.fee ? doge.fee + ' DOGE' : ''}</td>
									<td>{doge.minWithdraw} DOGE</td>
								</tr>
								<tr>
									<td><img src={linkLogo} className='feesCoinLogo' alt='LINK' /> LINK</td>
									<td><span className='coinProtocol'>ERC-20</span></td>
									<td>Chainlink</td>
									<td>{translate.text('free')}</td>
									<td>{link.feePct ? multipliedBy(link.feePct, 100) + '%' : ''} {link.feePct && link.fee ? '+' : ''} {link.fee ? link.fee + ' LINK' : ''}</td>
									<td>{link.minWithdraw} LINK</td>
								</tr>
								<tr>
									<td><img src={usdcLogo} className='feesCoinLogo' alt='USDC' /> USDC</td>
									<td><span className='coinProtocol'>ERC-20</span></td>
									<td>USD Coin</td>
									<td>{translate.text('free')}</td>
									<td>{usdc.feePct ? multipliedBy(usdc.feePct, 100) + '%' : ''} {usdc.feePct && usdc.fee ? '+' : ''} {usdc.fee ? usdc.fee + ' USDC' : ''}</td>
									<td>{usdc.minWithdraw} USDC</td>
								</tr>
							</tbody>
						</table>
						<br />
						<h4>{translate.text('fee_rounding')}</h4>
						<p>{translate.text('fee_rounding_msg')}</p>
						<br />
						<h4>{translate.text('minimum_trade_amount')}</h4>
						<table>
							<tbody>
								<tr>
									<th>{translate.text('coin')}</th>
									<th>{translate.text('minimum_trade_amount')}</th>
								</tr>
								<tr>
									<td><img src={brlLogo} className='feesCoinLogo' alt='BRL' /> Real Brasileiro</td>
									<td>0.01 BRL</td>
								</tr>
								<tr>
									<td><img src={btcLogo} className='feesCoinLogo' alt='BTC' /> Bitcoin</td>
									<td>0.0001 BTC</td>
								</tr>
								<tr>
									<td><img src={ethLogo} className='feesCoinLogo' alt='ETH' /> Ethereum</td>
									<td>0.0001 ETH</td>
								</tr>
								<tr>
									<td><img src={ebitLogo} className='feesCoinLogo' alt='EBIT' /> Econobit</td>
									<td>0.0001 EBIT</td>
								</tr>
								<tr>
									<td><img src={ltcLogo} className='feesCoinLogo' alt='LTC' /> Litecoin</td>
									<td>0.0001 LTC</td>
								</tr>
								<tr>
									<td><img src={dogeLogo} className='feesCoinLogo' alt='LTC' /> Dogecoin</td>
									<td>1 DOGE</td>
								</tr>
								<tr>
									<td><img src={linkLogo} className='feesCoinLogo' alt='LINK' /> Chainlink</td>
									<td>0.0001 LINK</td>
								</tr>
								<tr>
									<td><img src={usdcLogo} className='feesCoinLogo' alt='USDC' /> USD Coin</td>
									<td>0.01 USDC</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<br />
					<br />
				</div>
				<HomeFooterContainer />
			</div>

		)
	}

}