import { IMarkets, ITickers, IWalletChange, IWallets } from 'exchange-common'
import { dividedBy, multipliedBy } from 'libs-tools'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { LedgerMainContainer } from '../containers/ledgerMain'
import { translate } from '../functions/lang'
import { walletAmount, walletBalance, walletInOrders } from '../functions/wallet'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	wallets: IWallets
	walletChange: (current: string) => IWalletChange
	tickers: ITickers
	markets: IMarkets
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class WalletsMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const brlLogo = require('../images/coins/BRL.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const usdcLogo = require('../images/coins/USDC.svg')

		const list = require('../images/icons/list.svg')
		const crypto = require('../images/icons/crypto.svg')
		// const fiat = require('../images/icons/fiat.svg')
		// const stablecoin = require('../images/icons/stablecoin.svg')

		const wallets = this.props.wallets.list

		const { list: tickers } = this.props.tickers

		const estValue = (wallets.BTC.amount || 0)
			+ (tickers['BTC-BRL'].lastPrice ? dividedBy(wallets.BRL.amount, tickers['BTC-BRL'].lastPrice) : 0)
			+ multipliedBy(wallets.ETH.amount, tickers['ETH-BTC'].lastPrice)
			+ multipliedBy(wallets.EBIT.amount, tickers['EBIT-BTC'].lastPrice)
			+ multipliedBy(wallets.LTC.amount, tickers['LTC-BTC'].lastPrice)
			+ multipliedBy(wallets.DOGE.amount, tickers['DOGE-BTC'].lastPrice)
			+ multipliedBy(wallets.LINK.amount, tickers['LINK-BTC'].lastPrice)
			+ (tickers['BTC-USDC'].lastPrice ? dividedBy(wallets.USDC.amount, tickers['BTC-USDC'].lastPrice) : 0)

		const estValueBRL = multipliedBy(estValue, tickers['BTC-BRL'].lastPrice)

		return (
			<div className='walletsMain'>
				<span className='pageTitle'>{translate.text('wallets')}</span>
				<div className='walletsValueBox'>
					<div className='walletsValueBoxRow'><span className='walletsValueBoxRowGray'>{translate.text('estimated_value')} ≈ </span>{estValue.toFixed(8)} BTC <span className='walletsValueBoxRowGray'>({estValueBRL.toFixed(2)} BRL)</span></div>
				</div>
				<Tabs>
					<TabList>
						<Tab><img className='iconsOrders' src={crypto} alt='' />{translate.text('balances')}</Tab>
						<Tab><img className='iconsOrders' src={list} alt='' />{translate.text('ledger')}</Tab>
					</TabList>
					<TabPanel>
						<div>
							<table className='walletsTableMain'>
								<thead>
									<tr className='walletsTableHeaderRow'>
										<td className='walletsTableHeaderRowItem coinColumn'>{translate.text('coin')}</td>
										<td className='walletsTableHeaderRowItem coinNameColumn'>{translate.text('name')}</td>
										<td className='walletsTableHeaderRowItem balanceColumn'>{translate.text('total')}</td>
										<td className='walletsTableHeaderRowItem balanceColumn'>{translate.text('available')}</td>
										<td className='walletsTableHeaderRowItem balanceColumn'>{translate.text('in_orders')}</td>
										<td className='walletsTableHeaderRowItem' />
										<td className='walletsTableHeaderRowItem' />
									</tr>
								</thead>
								<tbody>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={brlLogo} className='walletsLogo' alt='BRL' /> BRL</td>
										<td className='walletsTableBodyRowItem'>Real Brasileiro</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.BRL)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.BRL)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.BRL)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('BRL')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('BRL')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={btcLogo} className='walletsLogo' alt='BTC' /> BTC</td>
										<td className='walletsTableBodyRowItem'>Bitcoin</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.BTC)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.BTC)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.BTC)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('BTC')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('BTC')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={ethLogo} className='walletsLogo' alt='ETH' /> ETH</td>
										<td className='walletsTableBodyRowItem'>Ethereum</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.ETH)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.ETH)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.ETH)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('ETH')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('ETH')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={ebitLogo} className='walletsLogo' alt='EBIT' /> EBIT</td>
										<td className='walletsTableBodyRowItem'>Econobit</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.EBIT)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.EBIT)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.EBIT)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('EBIT')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('EBIT')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={ltcLogo} className='walletsLogo' alt='LTC' /> LTC</td>
										<td className='walletsTableBodyRowItem'>Litecoin</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.LTC)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.LTC)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.LTC)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('LTC')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('LTC')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={dogeLogo} className='walletsLogo' alt='DOGE' /> DOGE</td>
										<td className='walletsTableBodyRowItem'>Dogecoin</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.DOGE)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.DOGE)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.DOGE)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('DOGE')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('DOGE')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={usdcLogo} className='walletsLogo' alt='USDC' /> USDC</td>
										<td className='walletsTableBodyRowItem'>USD Coin</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.USDC)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.USDC)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.USDC)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('USDC')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('USDC')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={linkLogo} className='walletsLogo' alt='LINK' /> LINK</td>
										<td className='walletsTableBodyRowItem'>Chainlink</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.LINK)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.LINK)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.LINK)}</td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('LINK')} to='/deposit'>{translate.text('deposit')}</Link></td>
										<td className='walletsTableBodyRowItem alignColumn'><Link className='walletsTableButton' onClick={e => this.onSelectedCoin('LINK')} to='/withdraw'>{translate.text('withdraw')}</Link></td>
									</tr>
								</tbody>
							</table>
						</div>
					</TabPanel>
					<TabPanel>
						<LedgerMainContainer />
					</TabPanel>
				</Tabs>
			</div>
		)
	}
	onSelectedCoin(coin: string) {
		this.props.walletChange(coin)
	}
}