import { ActionCreators, IApiKey } from 'exchange-common'
import * as React from 'react'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { RouteComponentProps } from 'react-router'
import { Field, reduxForm, reset } from 'redux-form'
import { store } from '..'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { maxLength10, renderField } from './renderField'

interface IProps {
	// include component properties here.
	apiKeys: IApiKey[]
	lang: string
	clearSecret: (key: string) => void
}

interface IMatchProps {
	// include any url parameters here.

}

let ApiKeyForm = (props: any) => {
	const { handleSubmit } = props

	return (
		<form onSubmit={handleSubmit}>
			<div className='keysCheckbox' >
				<span><b>{translate.text('permissions')}: </b></span>
				<input type='checkbox' defaultChecked={true} disabled={true} />
				<span> {translate.text('read')}</span>
				<Field type='checkbox' component={renderField} name='canTrade' id='canTrade' value='true' />
				<span><label htmlFor='canTrade'> {translate.text('trade')}</label></span>
				<input type='checkbox' defaultChecked={false} name='canWithdraw' disabled={true} />
				{/* <Field type='checkbox' component={renderField} name='canWithdraw' value='true' /> */}
				<span> {translate.text('withdraw')}</span>
			</div>
			<div className='authEnableForm'>
				<div className='authEnableFormBox'>
					<label htmlFor='ip'>{translate.text('limit_by_ip')}</label>
					<Field name='ip' component={renderField} type='text' />
				</div>
				<div className='authEnableFormBox'>
					<label htmlFor='tag'>{translate.text('custom_tag')}</label>
					<Field name='tag' component={renderField} type='text' validate={[maxLength10]} />
				</div>
			</div>
			<div className='authEnableForm'>
				<div className='authEnableFormBox'>
					<label htmlFor='auth'>{translate.text('2fa_token_if_enabled')}</label>
					<Field name='auth' component={renderField} type='text' />
				</div>
				<button className='keysSubmitButton' type='submit'>{translate.text('create')}</button>
			</div>
		</form>
	)
}

ApiKeyForm = reduxForm({
	form: 'ApiKeyForm'
})(ApiKeyForm) as any

export class KeysMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { openModal: boolean }> {

	componentDidMount() {
		// run after component is called.
		if (!this.props.apiKeys.length) {
			ws.send(ActionCreators.ApiKey.getList())
		}
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const apiKeys = this.props.apiKeys
		const newApiKey = apiKeys.find(f => f.secret)
		const openModal = newApiKey ? true : false
		translate.language = this.props.lang

		return (
			<div className='keysMain'>
				<Modal
					open={openModal}
					onClose={this.onCloseModal}
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal',
					}}
					center={true}
				>
					<h2>{translate.text('api_key_created')}</h2>
					<div className='modalForm'>
						<div className='modalFormRow'>
							<div className='modalFormText'><span>{translate.text('api_key_caution')}</span></div>
						</div>
						<br />
						<div className='modalFormRow'>
							<label className='modalFormLabel'>{translate.text('api_key')}</label>
							<div className='modalFormText'><span>{newApiKey?.key}</span></div>
						</div>
						<div className='modalFormRow'>
							<label className='modalFormLabel'>{translate.text('api_secret')}</label>
							<div className='modalFormText'><span>{newApiKey?.secret}</span></div>
						</div>
					</div>
					<div className='modalFormConfirm'>
						<button onClick={() => { this.onCloseModal() }}>{translate.text('confirm')}</button>
					</div>
				</Modal>
				<span className='pageTitle'>{translate.text('api_keys')}</span>
				<div className='keysInfoText'>
					<span>{translate.text('connect_their_projects')}</span><br /><br />
					<span>{translate.text('choose_permissions')}</span><br /><br />
				</div>
				<ApiKeyForm onSubmit={(values: IApiKey) => this.onSubmit(values)} />
				<span><b className='red'>{translate.text('warning')}:</b> {translate.text('never_disclose')}</span>
				<div>
					<table className='keysTableMain'>
						<thead>
							<tr className='keysTableHeaderRow'>
								<td className='keysTableHeaderRowItem dateColumn'>{translate.text('created')}</td>
								<td className='keysTableHeaderRowItem'>{translate.text('tag')}</td>
								<td className='keysTableHeaderRowItem'>{translate.text('key')}</td>
								<td className='keysTableHeaderRowItem'>{translate.text('ip')}</td>
								<td className='keysTableHeaderRowItem center'>{translate.text('read')}</td>
								<td className='keysTableHeaderRowItem center'>{translate.text('trade')}</td>
								<td className='keysTableHeaderRowItem center'>{translate.text('withdraw')}</td>
								<td className='keysTableHeaderRowItem center' />
							</tr>
						</thead>
						<tbody>
							{apiKeys.map(m =>
								<tr className='keysTableBodyRow' key={m.key}>
									<td className='keysTableBodyRowItem'>{new Date(m.createdAt).toLocaleString('en-GB')}</td>
									<td className='keysTableBodyRowItem'>{m.tag}</td>
									<td className='keysTableBodyRowItem'>{m.key}</td>
									<td className='keysTableBodyRowItem'>{m.ip}</td>
									<td className='keysTableBodyRowItem center green'><b>{translate.text('yes')}</b></td>
									<td className={`keysTableBodyRowItem center ${m.canTrade ? `green` : `red`}`}><b>{m.canTrade ? translate.text('yes') : translate.text('no')}</b></td>
									<td className={`keysTableBodyRowItem center ${m.canWithdraw ? `green` : `red`}`}><b>{m.canWithdraw ? translate.text('yes') : translate.text('no')}</b></td>
									<td className='keysTableBodyRowItem center'><button className='BankTableRemoveButton' onClick={e => this.onDelete(m.key)}>{translate.text('revoke')}</button></td>
								</tr>
							)}

						</tbody>
					</table>
				</div>
			</div>
		)
	}

	onCloseModal = () => {
		const newApiKey = this.props.apiKeys.find(f => f.secret)
		this.props.clearSecret(newApiKey!.key)
	}

	onSubmit(apiKey: IApiKey) {
		console.log('onSubmit', { apiKey })
		// apiKey.canTrade = apiKey.canTrade ? true : false
		// apiKey.canWithdraw = apiKey.canWithdraw ? true : false

		const action = ActionCreators.ApiKey.insert(apiKey)
		ws.send(action)
		store.dispatch(reset('ApiKeyForm'))
	}

	onDelete(key: string) {
		console.log('onDelete', { key })
		const action = ActionCreators.ApiKey.delete(key)
		ws.send(action)
	}
}