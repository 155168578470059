import { ActionTypes, IWalletAction, IWallets, Wallets } from 'exchange-common'

export const wallets =
	(state: IWallets = new Wallets(), action: IWalletAction): IWallets => {
		switch (action.type) {
			case ActionTypes.Wallet.Change:
				return {
					...state,
					current: action.current,
				}
			case ActionTypes.Wallet.List:
				return {
					...state,
					list: action.wallets.list,
				}
			case ActionTypes.Wallet.Update:
				return action.wallet.coin ? {
					...state,
					list: {
						...state.list,
						[action.wallet.coin]: {
							...state.list[action.wallet.coin],
							...action.wallet,
						},
					},
				} : state
			case ActionTypes.Wallet.Unload:
				return new Wallets()
			default:
				return state
		}
	}