import { ActionTypes, IUser, IUserAction, User } from 'exchange-common'
import { toast } from 'react-toastify'
import { translate } from '../functions/lang'

export const user =
	(state: IUser = new User(), action: IUserAction): IUser => {
		switch (action.type) {
			case ActionTypes.User.Load:
				// toast.success(translate.text('login_successful'))
				return {
					...state,
					...action.user,
				}
			case ActionTypes.User.Unload:
				// toast.success('Logout successful')
				return {
					...new User(),
				}
			case ActionTypes.User.UpdateAuth:
				return {
					...state,
					authSecret: action.secret,
				}
			case ActionTypes.User.Registered:
				return {
					...state,
					registered: true,
				}
			case ActionTypes.User.AuthEnabled:
				if (action.authEnabled) {
					toast.success(translate.text('2fa_enabled'))
				} else {
					toast.success(translate.text('2fa_disabled'))
				}
				return {
					...state,
					authEnabled: action.authEnabled,
				}
			case ActionTypes.User.UpdateProfile:
				return {
					...state,
					...action.user
				}
			default:
				return state
		}
	}