import { ActionCreators, IKyc } from 'exchange-common'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Field, reduxForm, reset } from 'redux-form'
import { store } from '..'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { maxLength100, maxLength14, renderField, renderSelect, required } from './renderField'

interface IProps {
	// include component properties here.
	kyc: IKyc
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}
const SelfieDemo = require('../images/icons/selfie.svg')

const resizeImg = async (imgDataUrl: string, maxWidth: number = 1280, maxHeight: number = 1280, quality: number = 0.7, imgType: string = 'jpeg') => {
	return new Promise<string>((resolve, reject) => {
		const canvas = document.createElement('canvas')
		const img = new Image() // document.createElement('img') as HTMLImageElement
		img.src = imgDataUrl
		img.onload = () => {
			let width = img.width
			let height = img.height
			// calculate the width and height, constraining the proportions
			if (width / maxWidth > height / maxHeight) {
				height = Math.round(height * maxWidth / width)
				width = maxWidth
			} else {
				width = Math.round(width * maxHeight / height)
				height = maxHeight
			}
			// resize the canvas and draw the image data into it
			canvas.width = width
			canvas.height = height
			const ctx = canvas.getContext('2d')
			if (ctx) { ctx.drawImage(img, 0, 0, width, height) }
			resolve(canvas.toDataURL(`image/${imgType}`, quality)) // get the data from canvas as 70% JPG (can be also PNG, etc.)
		}
	})
}

let KycForm = (props: any) => {
	const { handleSubmit } = props

	return (
		<div>
			<form className='kycForm' onSubmit={handleSubmit} encType='multipart/form-data'>
				<div className='pageColumn3'>
					<h2 className='pageBoxTitle'>{translate.text('personal_data')}</h2>
					<div className='kycFormRow'>
						<label htmlFor='fullName' className='kycFormLabel'>{translate.text('full_name')}</label>
						<Field name='fullName' component={renderField} type='text' validate={[required, maxLength100]} />
					</div>
					<div className='kycFormRow'>
						<label htmlFor='birthdate' className='kycFormLabel'>{translate.text('birth_date')}</label>
						<Field name='birthdate' component={renderField} type='date' validate={[required]} />
					</div>
					<div className='kycFormRow'>
						<label className='kycFormLabel'>{translate.text('document_id_type')}</label>
						<Field name='docTypeId' component={renderSelect} validate={[required]} values={['personal', 'corporate']} />
					</div>
					<div className='kycFormRow'>
						<label htmlFor='docIdNumber' className='kycFormLabel'>{translate.text('document_id_number')}</label>
						<Field name='docIdNumber' component={renderField} type='text' validate={[required, maxLength14]} />
					</div>
				</div>
				<div className='pageColumn3'>
					<h2 className='pageBoxTitle'>{translate.text('address_info')}</h2>
					<div className='kycFormRow'>
						<label htmlFor='streetAddress' className='kycFormLabel'>{translate.text('street_address')}</label>
						<Field name='streetAddress' component={renderField} type='text' validate={[required, maxLength100]} />
					</div>
					<div className='kycFormRow'>
						<label htmlFor='city' className='kycFormLabel'>{translate.text('city')}</label>
						<Field name='city' component={renderField} type='text' validate={[required, maxLength100]} />
					</div>
					<div className='kycFormRow'>
						<label htmlFor='state' className='kycFormLabel'>{translate.text('country_state')}</label>
						<Field name='state' component={renderField} type='text' validate={[required, maxLength100]} />
					</div>
					<div className='kycFormRow'>
						<label htmlFor='postcode' className='kycFormLabel'>{translate.text('post_code')}</label>
						<Field name='postcode' component={renderField} type='text' validate={[required, maxLength100]} />
					</div>
					<div className='kycFormRow'>
						<label className='kycFormLabel'>{translate.text('country')}</label>
						<Field name='country' component={renderSelect} validate={[required]} values={['BR', 'ES', 'US']} />
					</div>
				</div>
				<div className='pageColumn3'>
					<h2 className='pageBoxTitle'>{translate.text('uploads')}</h2>
					<div className='kycFormRow'>
						<label className='kycFormLabel'>{translate.text('document_id_photo')}</label>
						<input type='file' name='document_id_photo' required={true} id='document_id_pic' accept='image/*' />
						<label className='kycFormInfo'>{translate.text('document_id_photo_msg')}</label>
					</div>
					<div className='kycFormRow'>
						<label className='kycFormLabel'>{translate.text('selfie_holding_id_photo')}</label>
						<input type='file' name='selfie_holding_id_photo' required={true} id='selfie_holding_id_pic' accept='image/*' />
						<label className='kycFormInfo'>{translate.text('selfie_holding_id_photo_msg')}<img src={SelfieDemo} className='SelfieDemo' alt='SelfieDemo' /></label>
					</div>
					<div className='kycFormRow'>
						<label className='kycFormLabel'>{translate.text('proof_of_residence')}</label>
						<input type='file' name='proof_of_residence' required={true} id='proof_of_residence_pic' accept='image/*' />
						<label className='kycFormInfo'>{translate.text('proof_of_residence_msg')}</label>
					</div>
				</div>
				<div className='kycSubmitButton'>
					<button type='submit'>{translate.text('verify')}</button>
				</div>
			</form>
		</div>
	)
}

KycForm = reduxForm({
	form: 'kycForm',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
})(KycForm) as any

KycForm = connect((state: IState) => ({
	initialValues: {
		fullName: state.kyc.fullName,
		birthdate: (state.kyc.birthdate || '').toString().split('T')[0],
		docTypeId: state.kyc.docTypeId,
		docIdNumber: state.kyc.docIdNumber,
		streetAddress: state.kyc.streetAddress,
		city: state.kyc.city,
		state: state.kyc.state,
		country: state.kyc.country,
		postcode: state.kyc.postcode
	}
}))(KycForm) as any

export class MobileKycMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const unverifiedIcon = require('../images/icons/unverified.svg')
		const verifiedIcon = require('../images/icons/verified.svg')
		const inReviewIcon = require('../images/icons/inreview.svg')

		if (this.props.kyc.verified === undefined) {
			return (
				<div className='kycMain'>
					<span className='pageTitle'>{translate.text('id_verification')}</span>
					<div className='kycStatusDiv'>
						<span>{translate.text('status')}:<img src={unverifiedIcon} className='kycStatusIcon' alt='' /><b>{translate.text('unverified')}</b></span>
						<span>{translate.text('unverified_msg')}</span>
					</div>
					<div>
						<KycForm onSubmit={(values: any) => this.onKycSubmit(values)} kyc={this.props.kyc} />
					</div>
				</div>
			)
		}
		if (this.props.kyc.verified === false) {
			return (
				<div className='kycMain'>
					<span className='pageTitle'>{translate.text('id_verification')}</span>
					<div className='kycStatusDiv'>
						<span>{translate.text('status')}:<img src={inReviewIcon} className='kycStatusIcon' alt='' /><b>{translate.text('in_review')}</b></span>
						<span>{translate.text('in_review_msg')}</span>
					</div>
				</div>
			)
		} else {
			return (
				<div className='kycMain'>
					<span className='pageTitle'>{translate.text('id_verification')}</span>
					<div className='kycStatusDiv'>
						<span>{translate.text('status')}:<img src={verifiedIcon} className='kycStatusIcon' alt='' /><b>{translate.text('verified')}</b></span>
						<span>{translate.text('verified_msg')}</span>
					</div>
				</div>
			)
		}
	}

	async onKycSubmit(kyc: IKyc) {
		try {
			const docIdPic = document.getElementById('document_id_pic') as HTMLInputElement
			kyc.docIdPic = docIdPic.files ? await this.readFile(docIdPic.files[0]) : ''

			const selfiePic = document.getElementById('selfie_holding_id_pic') as HTMLInputElement
			kyc.selfiePic = selfiePic.files ? await this.readFile(selfiePic.files[0]) : ''

			const proofResPic = document.getElementById('proof_of_residence_pic') as HTMLInputElement
			kyc.proofResPic = proofResPic.files ? await this.readFile(proofResPic.files[0]) : ''

			ws.send(ActionCreators.Kyc.insert(kyc))
			store.dispatch(reset('KycForm'))
		} catch (err) {
			console.error(err)
		}
	}

	async readFile(file: Blob): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const fr = new FileReader()
			fr.readAsDataURL(file)
			fr.onloadend = () => {
				try {
					if (!file.type.startsWith('image')) {
						resolve(fr.result as string)
					}
					resolve(resizeImg(fr.result as string))
				} catch (err) {
					reject(err)
				}
			}
			fr.onerror = (error) => reject(error)
		})
	}

}