import * as React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { IState } from '../models/state'
import { ExchangeContainer } from '../containers/exchange'
import { WalletsContainer } from '../containers/wallets'
import { OrdersContainer } from '../containers/orders'
import { DepositContainer } from '../containers/deposit'
import { WithdrawContainer } from '../containers/withdraw'
import { ProfileContainer } from '../containers/profile'
import { SecurityContainer } from '../containers/security'
import { RegisterContainer } from '../containers/register'
import { LoginContainer } from '../containers/login'
import { HomeContainer } from '../containers/home'
import { PrivateRoute } from '../containers/privateRoute'
import { LostPasswordContainer } from '../containers/lostPassword'
import { SentPasswordContainer } from '../containers/sentPassword'
import { ResetPasswordContainer } from '../containers/resetPassword'
import { LogoutContainer } from '../containers/logout'
import { FeesContainer } from '../containers/fees'
import { AboutUsContainer } from '../containers/aboutUs'
import { TermsOfUseContainer } from '../containers/termsOfUse'
import { AmlPolicyContainer } from '../containers/amlPolicy'
import { PrivacyPolicyContainer } from '../containers/privacyPolicy'
import { VerifyEmailContainer } from '../containers/verifyEmail'
import { KycContainer } from '../containers/kyc'
import { MarketsMainContainer } from '../containers/marketsMain'
import { ConfirmWithdrawContainer } from '../containers/confirmWithdraw'
import { BankAccountsContainer } from '../containers/bankAccounts'
import { KeysContainer } from '../containers/keys'

interface IProps {
	// include component properties here.

}

interface IMatchProps {
	// include any url parameters here.

}

export class MainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		return (
			<div className='main'>
				<Switch>
					<Route path='/exchange/:market?' component={ExchangeContainer} />

					<PrivateRoute path='/wallets' component={WalletsContainer} />
					<PrivateRoute path='/orders' component={OrdersContainer} />
					<PrivateRoute path='/deposit' component={DepositContainer} />
					<PrivateRoute path='/withdraw' component={WithdrawContainer} />
					<Route path='/confirmWithdraw/:hash' component={ConfirmWithdrawContainer} />

					<PrivateRoute path='/profile' component={ProfileContainer} />
					<PrivateRoute path='/security' component={SecurityContainer} />
					<PrivateRoute path='/kyc' component={KycContainer} />
					<PrivateRoute path='/bankAccounts' component={BankAccountsContainer} />
					<PrivateRoute path='/keys' component={KeysContainer} />

					<Route path='/markets' component={MarketsMainContainer} />

					<Route path='/aboutUs' component={AboutUsContainer} />
					<Route path='/fees' component={FeesContainer} />
					<Route path='/termsOfUse' component={TermsOfUseContainer} />
					<Route path='/privacyPolicy' component={PrivacyPolicyContainer} />
					<Route path='/amlPolicy' component={AmlPolicyContainer} />

					<Route path='/verifyEmail/:email/:hash' component={VerifyEmailContainer} />

					<Route path='/register' component={RegisterContainer} />
					<Route path='/login' component={LoginContainer} />
					<Route path='/lostPassword' component={LostPasswordContainer} />
					<Route path='/sentPassword' component={SentPasswordContainer} />
					<Route path='/resetPassword/:reset' component={ResetPasswordContainer} />
					<Route path='/logout' component={LogoutContainer} />
					<Route path='/' exact={true} component={HomeContainer} />
					<Redirect from='*' to='/' />
				</Switch>
			</div>
		)
	}

}