import { ActionCreators, IOrder, IOrderAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { ExchangeMenuComponent } from '../components/exchangeMenu'
import { IState } from '../models/state'
// import { orderFormSide, orderFormType, IOrderFormAction, orderFormAmount, orderFormPrice } from '../actions/orderForm'

function mapStateToProps(state: IState) {
	return {
		order: state.order,
		symbol: state.markets.current,
		markets: state.markets,
		isLogged: state.user.isLogged,
		tickers: state.tickers,
		lang: state.lang,
		verified: state.kyc.verified,
		orderbooks: state.orderbooks,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IOrderAction>) {
	return {
		changeOrder: (order: Partial<IOrder>) => dispatch(ActionCreators.Order.change(order))
	}
}

export const ExchangeMenuContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExchangeMenuComponent))