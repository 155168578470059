import { ActionTypes, Banks, IBankAction, IBanks } from 'exchange-common'

export const banks = (state: IBanks = new Banks(), action: IBankAction): IBanks => {
	switch (action.type) {
		case ActionTypes.Bank.List:
			return {
				...state,
				list: action.banks.list,
				isLoaded: action.banks.isLoaded,
			}
		default:
			return state
	}
}