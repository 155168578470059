import { ActionCreators, IWalletAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { WithdrawMainComponent } from '../components/withdrawMain'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		current: state.wallets.current,
		wallets: state.wallets,
		withdrawals: state.withdrawals,
		currencies: state.currencies,
		bankAccounts: state.bankAccounts,
		banks: state.banks,
		lang: state.lang,
		verified: state.kyc.verified,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IWalletAction>) {
	return {
		walletChange: (current: string) => dispatch(ActionCreators.Wallet.change(current))
	}
}

export const WithdrawMainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(WithdrawMainComponent))