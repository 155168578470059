import { ActionTypes, ILastTradesAction, ITrades, Trades } from 'exchange-common'

export const lastTrades =
	(state: ITrades = new Trades(), action: ILastTradesAction): ITrades => {
		switch (action.type) {
			case ActionTypes.LastTrades.Load:
				return {
					...action.lastTrades,
				}

			case ActionTypes.LastTrades.Add:
				return {
					...state,
					list: {
						...state.list,
						[action.trade.market]: [
							action.trade,
							...state.list[action.trade.market].slice(0, 99),
						]
					}
				}

			default:
				return state
		}
	}