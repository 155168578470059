export default {
	'invalid_login': 'Invalid e-mail or password',
	'login_successful': 'Login successful',
	'2fa_enabled': '2FA Enabled',
	'2fa_disabled': '2FA Disabled',
	'2fa_invalid': 'Invalid 2FA token',
	'2fa_already_used': '2FA token already used',
	'email_not_verified': 'E-mail not verified',
	'email_already_exists': 'E-mail already exists',
	'order_book': 'Order Book',
	'price_charts': 'Price Charts',
	'trade_history': 'Trade History',
	'market_size': 'Market Size',
	'price': 'Price',
	'total': 'Total',
	'mid_market_price': 'Mid Market Price',
	'trade_size': 'Trade Size',
	'time': 'Time',
	'last_price': 'Last price',
	'24h_price': '24h price',
	'24h_high': '24h high',
	'24h_low': '24h low',
	'24h_volume': '24h volume',
	'open_orders': 'Open Orders',
	'side': 'Side',
	'size_abr': 'Size',
	'filled_abr': 'Filled',
	'size': 'Size',
	'filled': 'Filled',
	'status': 'Status',
	'actions': 'Actions',
	'cancel': 'Cancel',
	'confirm': 'Confirm',
	'buy': 'Buy',
	'sell': 'Sell',
	'partial': 'Partial',
	'open': 'Open',
	'cancel_your_order': 'Cancel your order',
	'confirm_your_order': 'Confirm your order',
	'select_market': 'Select market',
	'usdc_markets': 'USDC Markets',
	'btc_markets': 'BTC Markets',
	'brl_markets': 'BRL Markets',
	'order_form': 'Order Form',
	'market_order': 'Market',
	'limit_order': 'Limit',
	'amount': 'Amount',
	'price_limit': 'Price Limit',
	'fee': 'Fee',
	'net': 'Net',
	'place_buy_order': 'Place Buy Order',
	'place_sell_order': 'Place Sell Order',
	'user_not_logged_in': 'User not logged in',
	'user_not_verified': 'User not verified.',
	'amount_invalid': 'Amount invalid',
	'price_invalid': 'Price invalid',
	'market': 'Market',
	'confirm_order': 'Confirm Order',
	'login': 'Login',
	'register': 'Register',
	'connected': 'Connected',
	'disconnected': 'Disconnected',
	'theme': 'Theme',
	'default': 'Default',
	'dark': 'Dark',
	'light': 'Light',
	'cvd': 'CVD',
	'language': 'Language',
	'orders': 'Orders',
	'wallets': 'Wallets',
	'profile': 'Profile',
	'id_verification': 'ID Verification',
	'security': 'Security',
	'logout': 'Logout',
	'available_balance': 'Available Balance',
	'coin': 'Coin',
	'deposit': 'Deposit',
	'withdraw': 'Withdraw',
	'exchange': 'Exchange',
	'beware_phishing': 'Beware Phishing',
	'always': 'Always',
	'phishing_msg': `ensure you're on the correct website`,
	'email': 'E-mail',
	'welcome_back': 'Welcome back',
	'password': 'Password',
	'2fa_token_if_enabled': '2FA token (if enabled)',
	'lost_your_password': 'Lost your password?',
	'email_us': 'e-mail us',
	'lost_2FA_box_msg_1': 'If you have lost your 2FA token, please',
	'lost_2FA_box_msg_2': `with the subject "Lost 2FA Device".`,
	'lost_2FA_box_msg_3': 'Our support will then do an ID verification for your security.',
	'request_password_reset': 'Request Password Reset',
	'insert_email_msg': 'Insert your e-mail to receive the instructions to reset your password.',
	'reset_password': 'Reset Password',
	'reset_password_sent_msg': 'Check your e-mail inbox or spam folder to follow the instructions to reset your password.',
	'go_back': 'Go back',
	'password_reset': 'Password Reset',
	'confirm_password': 'Confirm password',
	'user_not_found': 'User not found',
	'password_reset_expired': 'Password reset time expired',
	'error_contact_support': 'Error, contact support',
	'password_reseted': 'Password reseted',
	'create_free_account': 'Create a free account',
	'register_msg_1': 'I have read and accepted the',
	'terms_of_use': 'Terms of Use',
	'privacy_policy': 'Privacy Policy',
	'and': 'and',
	'aml_policy': 'AML Policy',
	'register_successful': 'Register successful',
	'please_verify_your_email': 'Please verify your e-mail',
	'home_banner_msg_1': `Welcome to Brazil's best`,
	'home_banner_msg_2': 'Cryptocurrency exchange',
	'view_more': 'View more',
	'pair': 'Pair',
	'experience_the_best_technology': 'Experience the best technology',
	'fast': 'Fast',
	'easy': 'Easy',
	'secure': 'Secure',
	'block1_msg_1': 'Econobit offers unwavering performance with our world-class matching engine, supporting up to 1,000,000 orders per second. We ensure fast and reliable crypto trading, even at peak times.',
	'block1_msg_2': 'Crypto trading with Econobit is easy. Get started in minutes with your crypto trading on Econobit.',
	'block1_msg_3': 'We protect your assets with a multi-tier & multi-cluster system architecture. And with our 100% solvency, you can rest assured that your funds are secure with Econobit.',
	'trade_anywhere': 'Trade. Anywhere.',
	'block2_msg': 'Stay connected with the latest charts and markets on your desktop using our specialised web trading platform.',
	'register_now': 'Register Now',
	'view_exchange': 'View Exchange',
	'integration_with_ease': 'Integration with ease',
	'block3_msg': 'The Econobit API is designed to provide an easy and efficient way to integrate your trading application into our platform.',
	'api_documentation': 'API Documentation',
	'block4_title': 'Get in touch. Stay in touch.',
	'247_support': '24 / 7 Support',
	'econobit_blog': 'Econobit Blog',
	'community': 'Community',
	'careers': 'Careers',
	'got_a_problem': 'Got a problem? Just get in touch. Our support team is available 24/7.',
	'news_and_updates': 'News and updates from the Brazil’s best cryptocurrency exchange.',
	'econobit_is_global': 'Econobit is global. Join the discussion in our worldwide communities.',
	'help_build_future': 'Help build the future of technology. Start your new career at Econobit.',
	'start_trading_now': 'Start trading now',
	'trade_many_cryptos': 'Trade Bitcoin, Ethereum, and many other cryptocurrencies with ease.',
	'address': 'Address',
	'company': 'Company',
	'help': 'Help',
	'contact_us': 'Contact Us',
	'about_us': 'About Us',
	'fees': 'Fees',
	'support': 'Support',
	'faq': 'FAQ',
	'status_page': 'Status',
	'deposits': 'Deposits',
	'deposit_funds': 'Deposit Funds',
	'history': 'History',
	'available': 'Available',
	'in_orders': 'In Orders',
	'your_btc_address': 'Your Bitcoin wallet address',
	'your_ltc_address': 'Your Litecoin wallet address',
	'your_doge_address': 'Your Dogecoin wallet address',
	'your_eth_address': 'Your Ethereum wallet address',
	'your_link_address': 'Your Chainlink wallet address',
	'your_usdc_address': 'Your USD Coin wallet address',
	'your_ebit_address': 'Your Econobit wallet address',
	'our_brl_bank_account': 'Our Real Brasileiro bank account',
	'instructions': 'Instructions',
	'brl_deposit_msg1': 'This is the only bank account you can use to deposit Real Brasileiro on Econobit, and after the deposit transaction you will need to upload the deposit receipt.',
	'brl_deposit_msg2': 'This bank account is not allowed to receive other currencies other than Real Brasileiro, and any other currencies deposit will not be recovered.',
	'brl_deposit_msg3': 'The deposit transaction will be automatic, and the Real Brasileiro transfer will need to be confirmed by our bank, and your Real Brasileiro will be automatically deposited to your account after the confirmation.',
	'btc_deposit_msg1': 'The address is not allowed to deposit other assets other than Bitcoin, and any other asset deposit will not be recovered.',
	'btc_deposit_msg2': 'The deposit transaction will be automatic, and the Bitcoin transfer will need to be confirmed by the entire Bitcoin network, and your Bitcoin will be automatically deposited to your account after 1 confirmation.',
	'btc_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'ltc_deposit_msg1': 'The address is not allowed to deposit other assets other than Litecoin, and any other asset deposit will not be recovered.',
	'ltc_deposit_msg2': 'The deposit transaction will be automatic, and the Litecoin transfer will need to be confirmed by the entire Litecoin network, and your Litecoin will be automatically deposited to your account after 4 confirmations.',
	'ltc_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'doge_deposit_msg1': 'The address is not allowed to deposit other assets other than Dogecoin, and any other asset deposit will not be recovered.',
	'doge_deposit_msg2': 'The deposit transaction will be automatic, and the Dogecoin transfer will need to be confirmed by the entire Dogecoin network, and your Dogecoin will be automatically deposited to your account after 10 confirmations.',
	'doge_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'eth_deposit_msg1': 'The address is not allowed to deposit other assets other than Ethereum, and any other asset deposit will not be recovered.',
	'eth_deposit_msg2': 'The deposit transaction will be automatic, and the Ethereum transfer will need to be confirmed by the entire Ethereum network, and your Ethereum will be automatically deposited to your account after 12 confirmations.',
	'eth_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'link_deposit_msg1': 'The address is not allowed to deposit other assets other than Chainlink, and any other asset deposit will not be recovered.',
	'link_deposit_msg2': 'The deposit transaction will be automatic, and the Chainlink transfer will need to be confirmed by the entire Ethereum network, and your Chainlink will be automatically deposited to your account after 12 confirmations.',
	'link_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'usdc_deposit_msg1': 'The address is not allowed to deposit other assets other than USD Coin, and any other asset deposit will not be recovered.',
	'usdc_deposit_msg2': 'The deposit transaction will be automatic, and the USD Coin transfer will need to be confirmed by the entire Ethereum network, and your USD Coin will be automatically deposited to your account after 12 confirmations.',
	'usdc_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'ebit_deposit_msg1': 'The address is not allowed to deposit other assets other than Econobit, and any other asset deposit will not be recovered.',
	'ebit_deposit_msg2': 'The deposit transaction will be automatic, and the Econobit transfer will need to be confirmed by the entire Econobit network, and your Econobit will be automatically deposited to your account after 1 confirmation.',
	'ebit_deposit_msg3': 'This address is the only one you can use on your own, and you can do multiple deposits at the same time.',
	'deposit_id': 'Deposit ID',
	'date': 'Date',
	'record': 'Record',
	'confirmed': 'Confirmed',
	'unconfirmed': 'Unconfirmed',
	'link': 'Link',
	'no_results_found': 'No results found.',
	'address_copied': 'Address copied.',
	'insufficient_balance': 'Insufficient balance.',
	'withdraw_all_available_balance': 'Withdraw all available balance.',
	'withdraw_id': 'Withdraw ID',
	'withdrawals': 'Withdrawals',
	'withdraw_funds': 'Withdraw Funds',
	'withdraw_fee': 'Withdraw fee',
	'will_receive': 'Will receive',
	'confirm_your_withdraw': 'Confirm your withdraw',
	'withdraw_msg_1': 'To enhance the security of your assets, you must approve the withdraw on the link sent to your e-mail inbox.',
	'withdraw_msg_2': 'We will process your withdrawal instantly, but it depends on the blockchain when assets would finally be received on your external wallet.',
	'withdraw_msg_3': 'Please avoid to withdraw to ICO address directly, in case ICO refund does NOT come to your Econobit wallet.',
	'withdraw_msg_fiat': 'We will process your withdrawal in up to 1 business day, it depends on the banking network when funds would finally be received on your bank account.',
	'sent': 'Sent',
	'expired': 'Expired',
	'pending': 'Pending',
	'confirm_withdraw': 'Confirm Withdraw',
	'order_id': 'Order ID',
	'type': 'Type',
	'remain': 'Remain',
	'open_tab': 'Open',
	'executed': 'Executed',
	'cancelled': 'Cancelled',
	'balances': 'Balances',
	'ledger': 'Ledger',
	'estimated_value': 'Estimated Value',
	'name': 'Name',
	'description': 'Description',
	'value': 'Value',
	'balance_after': 'Balance after',
	'internal': 'Internal',
	'deposit_from_external_wallet': 'Deposit from external wallet',
	'deposit_from_internal_wallet': 'Deposit from internal wallet',
	'withdraw_to_external_wallet': 'Withdraw to external wallet',
	'withdraw_to_internal_wallet': 'Withdraw to internal wallet',
	'deposit_from_bank_account': 'Deposit from bank account',
	'withdraw_to_bank_account': 'Withdraw to bank account',
	'save_changes': 'Save Changes',
	'verified': 'Verified',
	'yes': 'Yes',
	'no': 'No',
	'go_to_id_verification': 'Go to ID Verification',
	'nickname': 'Nickname',
	'mobile_phone': 'Mobile Phone',
	'personal_data': 'Personal Data',
	'full_name': 'Full Name',
	'birth_date': 'Birth Date',
	'document_id_type': 'Account Type',
	'personal': 'Personal',
	'corporate': 'Corporate',
	'document_id_number': 'Tax ID Number',
	'address_info': 'Address Info',
	'street_address': 'Full Street Address',
	'city': 'City',
	'country_state': 'State',
	'post_code': 'Post Code',
	'country': 'Country',
	'BR': 'Brazil',
	'ES': 'Spain',
	'US': 'United States',
	'verify': 'Verify',
	'uploads': 'Uploads',
	'upload': 'Upload',
	'document_id_photo': 'Document ID Photo',
	'document_id_photo_msg': 'Color photo of front and back of ID document showing ID photo, full legal name, birth date and ID number. All documents must be within the expire date.',
	'selfie_holding_id_photo': 'Selfie holding ID Photo',
	'selfie_holding_id_photo_msg': 'A photo of you holding your ID document visibly. The selfies need to be high resolution so that your face along with the document data are readable.',
	'proof_of_residence': 'Proof of Residence',
	'proof_of_residence_msg': 'Photo of utility bill service showing full legal name and full address, can be electricity, water, gas, telephone, and bank statement, with up to 3 months of issue.',
	'change_password': 'Change Password',
	'two_factor_auth': 'Two Factor Authentication',
	'enter_6_digit': 'Enter the 6 digit validation code from your device',
	'secret': 'Secret',
	'manual_activation': 'For manual activation use the key below',
	'enable': 'Enable',
	'disable': 'Disable',
	'save': 'Save',
	'2fa_token': '2FA Token',
	'old_password': 'Old Password',
	'new_password': 'New Password',
	'confirm_new_password': 'Confirm New Password',
	'activate_2fa_msg1': 'Install Google Authenticator on your device',
	'activate_2fa_msg2': 'Scan the code with your device',
	'trade_fee': 'Trade fee',
	'maker': 'maker',
	'taker': 'taker',
	'market_ledger': 'market',
	'limit_ledger': 'limit',
	'latency': 'Latency',
	'blog_posts_url': 'https://support.econobit.io/wp-json/wp/v2/posts?per_page=3&_fields=title,link,date_gmt&categories=27',
	'blog_cat_url': 'https://support.econobit.io/category/announcements/',
	'support_url': 'https://support.econobit.io/',
	'support_faq_url': 'https://support.econobit.io/category/faq/',
	'not_enough_buy_orders': 'Not enough buy orders',
	'not_enough_sell_orders': 'Not enough sell orders',
	'server_error': 'Server error. Try again later.',
	'invalid_password': 'Invalid password',
	'invalid_order': 'Invalid order',
	'order_not_found': 'Order not found',
	'invalid_market': 'Invalid market',
	'market_not_found': 'Market not found',
	'withdraw_msg_confirmed': 'Withdraw of {amount} {coin} confirmed',
	'deposit_msg_confirmed': 'Deposit of {amount} {coin} confirmed',
	'deposit_msg_cancelled': 'Deposit of {amount} {coin} cancelled',
	'deposit_msg_received': 'Deposit of {amount} {coin} received (unconfirmed)',
	'order': 'Order',
	'changes_saved': 'Changes saved',
	'password_changed': 'Password changed',
	'field_email_empty': `Field 'E-mail' can not be empty`,
	'field_email_invalid': `Field 'E-mail' must be a valid e-mail`,
	'field_password_empty': `Field 'Password' can not be empty`,
	'field_password_invalid': `Field 'Password' must be at least 6 chars long`,
	'withdraw_success': 'Withdrawal successfully',
	'field_address_empty': `Field 'Address' can not be empty`,
	'field_amount_empty': `Field 'Amount' can not be empty`,
	'field_coin_empty': `Field 'Coin' can not be empty`,
	'field_amount_greater': `Field 'Amount' must be greater than {minAmount}`,
	'bank_account_added': 'Bank account added',
	'bank_account_removed': 'Bank account removed',
	'2fa_not_enabled': '2FA is not enabled',
	'order_cancelled': 'Order cancelled',
	'order_submitted': 'Order submitted',
	'field_market_empty': `Field 'Market' can not be empty`,
	'field_type_empty': `Field 'Type' can not be empty`,
	'field_side_empty': `Field 'Side' can not be empty`,
	'invalid_amount_value': `Invalid 'Amount' value`,
	'invalid_price_value': `Invalid 'Price' value`,
	'field_old_password_empty': `Field 'Old Password' can not be empty`,
	'field_old_password_invalid': `Field 'Old Password' must be at least 6 chars long`,
	'field_new_password_empty': `Field 'New Password' can not be empty`,
	'field_new_password_invalid': `Field 'New Password' must be at least 6 chars long`,
	'required': 'Required',
	'same_old_password': 'Same as old password',
	'passwords_dont_match': `Passwords don't match`,
	'invalid_email_address': `Invalid E-mail address`,
	'must_be': 'Must be',
	'characters_or_less': 'characters or less',
	'characters_or_more': 'characters or more',
	'exec_trade_msg': '{side} order {amount} {otherCoin} executed @ {price} {baseCoin}',
	'the_company': 'The Company',
	'the_company_msg_1': 'Econobit begun as a small project back in 2013, started operating its exchange by 2017, and now is one of the oldest and most reliable crypto exchange in the Brazilian market. Our platform offers advanced tools for professional traders and developers to transact their crypto currencies, such as Bitcoin, Litecoin, Ethereum, and ERC-20 tokens. We continually improve the code to deliver the best experience for our customers.',
	'the_company_msg_2': `Bitcoin is the world's most widely used crypto currency with a total market cap of over US$1 trillion. The bitcoin network is made up of thousands of computers run by individuals all over the world.`,
	'stats': 'Stats',
	'users': 'Users',
	'traded': 'Traded',
	'investors_and_partners': 'Investors & Partners',
	'follow_us_social': 'Follow us on social media',
	'no_positions_open': 'No positions currently open.',
	'future_positions': 'Future positions will be posted here.',
	'account': 'Account',
	'account_opening': 'Account opening',
	'free': 'Free',
	'custody': 'Custody',
	'trading': 'Trading',
	'deposit_fee': 'Deposit fee',
	'withdrawal_fee': 'Withdrawal fee',
	'minimum_withdrawal': 'Minimum withdrawal',
	'maker_cap': 'Maker',
	'taker_cap': 'Taker',
	'fee_rounding': 'Fee rounding',
	'minimum_trade_amount': 'Minimum trade amount',
	'fee_rounding_msg': `We kindly ask our users to take note on Econobit's policy regarding fee calculation. As our fees are calculated to two decimal places, all fees which might exceed this limitation are rounded up. The rounding up is executed in such a way that the second decimal digit is always one digit value higher than it was before the rounding up. For example; a fee of 0.111 will be charged as 0.12.`,
	'markets': 'Markets',
	'whats_new': `What's New`,
	'withdraw_created': 'Withdraw requested. Confirm in your e-mail.',
	'withdraw_confirmed': 'Withdraw request confirmed.',
	'try_simulated_trading': 'Want to try simulated trading?',
	'try_live_trading': 'Want to try live trading?',
	'unverified': 'Unverified',
	'unverified_msg': 'Please complete your ID verification.',
	'in_review': 'In review',
	'in_review_msg': 'Please wait while we verify your ID.',
	'verified_msg': 'Thank you for verifying your account.',
	'login_history': 'Login History',
	'ip': 'IP',
	'browser': 'Browser',
	'device': 'Device',
	'bank_accounts': 'Accounts',
	'bank_account': 'Bank Account',
	'receipt': 'Receipt',
	'deposit_receipt': 'Deposit Receipt',
	'go_to_bank_accounts': 'Go to Bank Accounts to add an account',
	'deposit_receipt_msg': 'Photo of Deposit Receipt showing your full legal name, bank account info, and correct amount.',
	'confirm_your_deposit': 'Confirm your deposit',
	'confirm_deposit': 'Confirm deposit',
	'add': 'Add',
	'remove': 'Remove',
	'sending_do_not_leave': 'Sending... Do not leave page.',
	'no_bank_accounts_found': 'No bank accounts found.',
	'my_bank_accounts': 'My Bank Accounts',
	'bank_code': 'Bank Code',
	'branch': 'Branch',
	'branch_number': 'Branch Number',
	'branch_digit': 'Branch Digit',
	'account_number': 'Account Number',
	'account_digit': 'Account Digit',
	'add_new_bank_account': 'Add new Bank Account',
	'withdraw_3rd_start': 'Please note that withdraw to third party bank accounts is ',
	'prohibited': 'Prohibited',
	'withdraw_3rd_end': ', add only bank accounts in your own name.',
	'social_name': 'Social name',
	'api_keys': 'API Keys',
	'permissions': 'Permissions',
	'api_key_created': 'API Key Created',
	'api_key_caution': 'Please make sure you write down your API Secret as it will not be show again.',
	'api_key': 'API Key',
	'api_secret': 'API Secret',
	'api_key_revoked': 'API Key Revoked',
	'revoke': 'Revoke',
	'field_invalid_address': 'Invalid Address for selected coin',
	'field_bankAccountId_empty': 'Field Bank Account empty.',
	'cost': 'Cost',
	'read': 'Read',
	'trade': 'Trade',
	'limit_by_ip': 'Limit by IP',
	'custom_tag': 'Custom Tag',
	'tag': 'Tag',
	'key': 'Key',
	'create': 'Create',
	'created': 'Created',
	'warning': 'Warning',
	'never_disclose': 'Never disclose the keys to anyone. Only use this if you know what you are doing.',
	'choose_permissions': 'Choose below which permissions you want to enable.',
	'connect_their_projects': 'Users can create API keys to connect their projects with Econobit and automate their strategies.',
	'cancel_all_orders': 'Cancel All Orders',
	'all_open_orders': 'All Open Orders',
	'cancel_warning': 'This will cancel all your open orders. Are you sure?',
	'create_wallet_error': 'Address unavailable at the moment',
	'market_trade_disabled': 'Trade disabled on this market',
	'cookie_consent_text': `We use cookies to improve user experience and analyze website traffic as described in our`,
	'cookie_accept_all': 'Accept All',
	'cookie_reject_all': 'Reject',
	'last_aml_updated_date': 'Last updated: 07 Jun 2022',
	'last_privacy_updated_date': 'Last updated: 07 Jun 2022',
	'last_terms_updated_date': 'Last updated: 07 Jun 2022',
	'aml_title_1': `ECONOBIT ANTI MONEY LAUNDERING ("AML")`,
	'aml_title_2': `AND COUNTER TERRORIST FINANCING ("CTF") POLICY`,
	'aml_text_1': `Money laundering is defined as the process where the identity of the proceeds of crime is so disguised that it gives an impression of legitimate income. Criminals specifically target financial services firms through which they attempt to launder criminal proceeds without the firms’ knowledge or suspicion.`,
	'aml_text_2': `In response to the scale and effect of money laundering, the European Union has passed Directives designed to combat money laundering and terrorism. These Directives, together with regulations, rules and industry guidance, form the cornerstone of our AML/CTF obligations and outline the offenses and penalties for failing to comply.`,
	'aml_text_3': `Whilst Econobit Ltd is currently unregulated and does not fall within the scope of the AML/CTF obligations, the senior management have implemented systems and procedures that meet the standards set forth by the European Union. This decision reflects the senior management’s desire to prevent money laundering and not be used by criminals to launder proceeds of crime.`,
	'aml_title_3': `ANTI-MONEY LAUNDERING (AML) POLICY:`,
	'aml_text_4': `The Econobit AML Policy is designed to prevent money laundering by meeting the European standards on combating money laundering and terrorism financing, including the need to have adequate systems and controls in place to mitigate the risk of the firm being used to facilitate financial crime. This AML Policy sets out the minimum standards which must be complied with and includes:`,
	'aml_list_1': `Appointing a Money Laundering Reporting Officer (MLRO) who has a sufficient level of seniority and independence, and who has responsibility for oversight of compliance with the relevant legislation, regulations, rules and industry guidance;`,
	'aml_list_2': `Establishing and maintaining a Risk-Based Approach (RBA) to the assessment and management of money laundering and terrorist financing risks faced by the firm;`,
	'aml_list_3': `Establishing and maintaining risk-based Customer Due Diligence (CDD), identification, verification and Know Your Customer (KYC) procedures, including enhanced due diligence for customers presenting a higher risk, such as Politically Exposed Persons (PEPs);`,
	'aml_list_4': `Establishing and maintaining risk-based systems and procedures for the monitoring of on-going customer activity;`,
	'aml_list_5': `Establishing procedures for reporting suspicious activity internally and to the relevant law enforcement authorities as appropriate;`,
	'aml_list_6': `Maintaining appropriate records for the minimum prescribed periods;`,
	'aml_list_7': `Providing training for and raising awareness among all relevant employees.`,
	'aml_title_4': `SANCTIONS POLICY:`,
	'aml_text_5': `Econobit is prohibited from transacting with individuals, companies and countries that are on prescribed sanctions lists. Econobit will therefore screen against United Nations, European Union, UK Treasury and US Office of Foreign Assets Control (OFAC) sanctions lists in all jurisdictions in which we operate.`,
	'privacy_text_1': `This Privacy Policy explains how Econobit collects, uses, shares, and protects user information obtained through the Econobit.io website. The terms “we,” “us,” and “our” refer to Econobit. When we ask for certain personal information from users it is because we are required by law to collect this information or it is relevant for specified purposes. Any non-required information you provide to us is done so voluntarily. You decided whether to provide us with these non-required information; you may not be able to access or utilize all of our Services if you choose not to.`,
	'privacy_text_2': `By using the Site, you consent to the data practices described in this Privacy Policy. On occasion, Econobit may revise this Privacy Policy to reflect changes in law or our personal data collection and use practices. If material changes are made to this Privacy Policy, the changes will be announced by posting on the site. We will ask for your consent before using your information for any purpose that is not covered in this Privacy Policy.`,
	'privacy_text_3': `The latest privacy policy has incorporated elements from the General Data Protection Regulation (GDPR) as we act in accordance to its personal information processing rules within the European Economic Area (EEA).`,
	'privacy_title_1': `INFORMATION ECONOBIT COLLECTS`,
	'privacy_bold_1': `We want you to understand the types of information we collect when you register for and use Econobit’s services`,
	'privacy_bold_2': `Information you provide to us at registration`,
	'privacy_text_4': `When you create a Econobit Account, you provide us with personal information that includes your contact information (Email Address, name, and a password). You can also choose to add a Google Authenticator account to be used for 2FA verification for improved security.`,
	'privacy_bold_3': `Information we collect when authenticating user identity`,
	'privacy_text_5': `To comply with global industry regulatory standards including Anti-Money Laundering (AML), Know-Your-Customer (KYC), and Counter Terrorist Financing (CTF), Econobit requires user accounts to undergo user identity authentication for both Personal & Enterprise-level accounts. This entails collecting formal identification.`,
	'privacy_bold_4': `Information we collect as you use our services`,
	'privacy_bold_5': `Service Usage Information`,
	'privacy_text_6': `Through your use of the Econobit platform, we also monitor and collect tracking information related to usage such as access date & time, device identification, operating system, browser type and IP address. This information may be directly obtained by Econobit or through third party services. This service usage data helps us our systems to ensure that our interface is accessible for users across all platforms and can aid during criminal investigations.`,
	'privacy_bold_6': `Transaction Information`,
	'privacy_text_7': `For all personal and enterprise user accounts, we collect transaction information including deposit snapshots, account balances, trade history, withdrawals, order activity and distribution history. This transaction data is monitored for suspicious trading activity for user fraud protection, and legal case resolution.`,
	'privacy_title_2': `WHY DOES ECONOBIT COLLECT THIS INFORMATION`,
	'privacy_bold_7': `To provide and maintain our services`,
	'privacy_text_8': `We use the information collected to deliver our services and verify user identity.`,
	'privacy_text_9': `We use the IP address and unique identifiers stored in your device’s cookies to help us authenticate your identity and provide our service. Given our legal obligations and system requirements, we cannot provide you with services without data like identification, contact information and transaction-related information.`,
	'privacy_bold_8': `To protect our users`,
	'privacy_text_10': `We use the information collected to protect our platform, users’ accounts and archives.`,
	'privacy_text_11': `We use IP addresses and cookie data to protect against automated abuse such as spam, phishing and Distributed Denial of Service (DDoS) attacks.`,
	'privacy_text_12': `We analyse trading activity with the goal of detecting suspicious behavior early to prevent potential fraud and loss of funds to bad actors.`,
	'privacy_bold_9': `To comply with legal and regulatory requirements`,
	'privacy_text_13': `Respect for the privacy and security of data you store with Econobit informs our approach to complying with regulations, governmental requests and user-generated inquiries. We will not disclose or provide any personal information to third party sources without review from our legal case team and/or prior consent from the user.`,
	'privacy_bold_10': `To measure site performance`,
	'privacy_text_14': `We actively measure and analyse data to understand how our services are used. This review activity is conducted by our operations team to continually improve our platform’s performance and to resolve issues with the user experience.`,
	'privacy_text_15': `We continuously monitor our systems’ activity information and communications with users to look for and quickly fix problems.`,
	'privacy_bold_11': `To communicate with you`,
	'privacy_text_16': `We use personal information collected, like an email address to interact with users directly when providing customer support on a ticket or to keep you informed on log ins, transations, and security. Without processing your personal information for confirming each communication, we will not be able to respond to your submitted requests, questions and inquiries. All direct communications are kept confidential and reviewed internally for accuracy.`,
	'privacy_bold_12': `To enforce our Terms of Use and other agreements`,
	'privacy_text_17': `It is very important for us and our customers that we continually review, investigate and prevent any potentially prohibited or illegal activities that violate our Terms of Service. For the benefit of our entire user base, we carefully enforce our agreements with third parties and actively investigate violations of our posted Terms of Use. Econobit reserves the right to terminate the provision of service to any user found engaging in activities that violate our Terms of Use.`,
	'privacy_title_3': `HOW DOES ECONOBIT PROTECT USER DATA`,
	'privacy_text_18': `Econobit has implemented a number of security measures to ensure that your information is not lost, abused, or altered. Our data security measures include, but are not limited to: Secured Sockets Layered encryption technology, pseudonymisation, internal data access restrictions, and strict physical access controls to buildings & files. Please note that it is impossible to guarantee 100% secure transmission of data over the Internet nor method of electronic storage. As such, we request that you understand the responsibility to independently take safety precautions to protect your own personal information.`,
	'privacy_text_19': `If you suspect that your personal information has been compromised, especially account and/or password information, please contact Econobit customer service immediately for us to lock your account.`,
	'privacy_title_4': `CONTACT US`,
	'privacy_text_20': `We are committed to respecting the freedoms and rights of all our Econobit users who have placed their trust in our service. If you should have any questions or concerns regarding this Privacy Policy, or if you would like to file a Data Protection request, please refer to our FAQ and contact us on our Support page.`,
	'terms_text_1': `This agreement is between you (referenced herein as “you” or with “your”) and Econobit. By accessing, using or clicking “I agree” to any of the services made available by Econobit through the website (www.econobit.io), the API (api.econobit.io), our mobile applications, or any other related services provided by Econobit as further described in Section 4 below (collectively, the “Services”) you agree that you have read, understood and accepted all of the terms and conditions contained in this Terms of Use agreement (the or these “Terms”), as well as our Privacy Policy found at https://econobit.io/privacyPolicy. Additionally, when using certain features of the Services, you may be subject to additional terms and conditions applicable to such features.`,
	'terms_text_2': `PLEASE READ THESE TERMS CAREFULLY AS THEY GOVERN YOUR USE OF THE SERVICES. THESE TERMS CONTAINS IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION. THE TERMS OF THE ARBITRATION PROVISION ARE SET FORTH IN SECTION 14 BELOW ENTITLED “RESOLVING DISPUTES: FORUM, ARBITRATION, CLASS ACTION WAIVER.” AS WITH ANY ASSET, THE VALUE OF DIGITAL CURRENCIES CAN GO UP OR DOWN AND THERE CAN BE A SUBSTANTIAL RISK THAT YOU WILL LOSE MONEY BUYING, SELLING, HOLDING, OR INVESTING IN DIGITAL CURRENCIES. BY USING THE SERVICES YOU ACKNOWLEDGE AND AGREE THAT (1) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTING IN DIGITAL CURRENCIES (2) THAT YOU ASSUME ALL RISKS WITH RESPECT TO YOUR USE OF THE SERVICES AND TRADING IN DIGITAL CURRENCIES AND (3) ECONOBIT IS NOT RESPONSIBLE OR LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.`,
	'terms_text_3': `BY ACCESSING, USING OR ATTEMPTING TO USE THE SERVICES IN ANY CAPACITY, YOU ACKNOWLEDGE THAT YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, DO NOT ACCESS OR USE THE SERVICES.`,
	'terms_title_1': `1. Agreement Conditions`,
	'terms_s1_text_1': `Econobit reserves the right to modify or change the Terms at any time and at its sole discretion. Econobit will provide notice of these changes by updating the revised Terms on the webpage (https://econobit.io/termsOfUse) and changing the “[Last updated: ]” date on this page. Any and all modifications or changes to these Terms will be effective immediately upon being announced on the website or released to users. As such, your continued use of Econobit services acts as acceptance of the amended agreement and rules. If you do not agree to any modification to these Terms, you must stop using the Services. Econobit encourages you to frequently review the Terms to ensure you understand the terms and conditions that apply to your access to, and use of, the Services.`,
	'terms_title_2': `2. Eligibility`,
	'terms_s2_text_1': `By registering to use a Econobit Account (as defined in Section 5 below), you represent and warrant that you (a) are at least 18 years old or of legal age to form a binding contract under applicable law, (b) are an individual, legal person or other organization with full legal capacity and authority to enter into these Terms, (c) have not previously been suspended or removed from using our Services and (d) you do not currently have an existing Econobit Account, (e) you are not a U.S person. If you are entering into these Terms on behalf of a legal entity of which you are an employee or agent, you represent and warrant that you have all necessary rights and authority to bind such legal entity.`,
	'terms_title_3': `3. Prohibition of Use`,
	'terms_s3_text_1': `By accessing and using the Services, you represent and warrant that you are not on any trade or economic sanctions lists, such as the UN Security Council Sanctions list, designated as a “Specially Designated National” by OFAC (Office of Foreign Assets Control of the U.S. Treasury Department) or placed on the U.S. Commerce Department’s “Denied Persons List”. Econobit maintains the right to select its markets and jurisdictions to operate and may restrict or deny the Services in certain countries at its discretion.`,
	'terms_title_4': `4. Description of Services`,
	'terms_s4_text_1': `Econobit provides an online digital asset trading platform (crypto to crypto) for products commonly known as cryptographic tokens, digital tokens or cryptographic currency (collectively, “Digital Currency”). Econobit does not provide fiat trading capabilities on as part of the Services. Econobit functions as a trading platform provider and not as a buyer or seller in trades made between traders. Econobit is also not a market maker. Users must register and open an account with Econobit as further set forth in Section 5 below and deposit digital assets prior to commencement of trading. Traders may request the withdrawal of their digital assets, subject to the limitations as stated in these Terms.`,
	'terms_s4_text_2': `Econobit strives to maintain the accuracy of information posted on the Services however it cannot and does not guarantee the accuracy, suitability, reliability, completeness, performance or fitness for any purpose of the content made available through the Services, and will not be liable for any loss or damage that may arise directly or indirectly from your use of such content. Information on the Services can be subjected to change without notice and is provided for the primary purpose of facilitating users to arrive at independent decisions. Econobit does not provide investment or advisory advice and will have no liability for the use or interpretation of information as stated on the Services or other communication mediums. All users of the Services must understand that there are risks involved in trading in Digital Currencies. Econobit encourages all users to exercise prudence and trade responsibly within their own means.`,
	'terms_title_5': `5. Econobit Account Registration & Requirements`,
	'terms_s5_bold_a': `a. Registration`,
	'terms_s5a_text_1': `All users of the Services (each, a “User”) must register at (https://econobit.io/register) for a Econobit account (an “Account”) before using the Services. To register for an Account, you must provide your real name, email address and password, as well as accept the Terms of Use, Privacy Policy and Consent Form. Econobit may, in its sole discretion, refuse to open an account for you. You agree to provide complete and accurate information when opening an Account and agree to promptly update any information you provide to Econobit so that such information is complete and accurate at all times. Each registration is for a single user only and each User (including with respect to any User that is a business or legal entity) may only maintain one active Account with Econobit.`,
	'terms_s5_bold_b': `b. User Identity Verification`,
	'terms_s5b_text_1': `With registration of an account on Econobit, you agree to share personal information requested for the purposes of identity verification. This information is used specifically for the detection of money laundering, terrorist financing, fraud and other financial crimes on the Econobit platform. We will collect, use and share this information in accordance with our posted Privacy Policy. In addition to providing this information, to facilitate compliance with global industry standards for data retention, you agree to permit us to keep a record of such information for the lifetime of your account plus 5 years beyond account closing. You also authorise us to make inquiries, either directly or through third parties, that are deemed necessary to verify your identity or to protect you and/or us against financial crimes such as fraud.`,
	'terms_s5b_text_2': `The identity verification information we request may include, but is not limited to, your: Name, Email Address, Contact Information, Telephone Number, Username, Government Issued ID, Date of Birth and other information collected at the time of account registration. In providing this required information, you confirm that it is accurate and authentic. Post-registration, you must guarantee that the information is truthful, complete and updated in a timely manner with any changes. If there is any reasonable doubt that any information provided by you is wrong, untruthful, outdated or incomplete, Econobit shall have the right to send you a notice to demand corrections, remove relevant information directly and, as the case may be, terminate all or part of the Services to you. You shall be solely and fully responsible for any loss or expenses incurred during the use of Econobit Service if you cannot be reached through the contact information provided. You hereby acknowledge and agree that you have the obligation to keep all information provided up to date if there are any changes.`,
	'terms_s5b_text_3': `By signing up for account you hereby authorize Econobit to make inquiries, whether directly or through third parties, that Econobit considers necessary to verify your identity or protect you and/or Econobit against fraud or other financial crimes, and to take action Econobit reasonably deems necessary based on the results of such inquiries. You also acknowledge and agree that your personal information may be disclosed to credit reference and fraud prevention or financial crime agencies and that these agencies may respond to our inquiries in full.`,
	'terms_s5_bold_c': `c. Account Usage Requirements`,
	'terms_s5c_text_1': `Accounts can only be used by the person whose name they are registered under. Econobit reserves the right to suspend, freeze or cancel accounts that are used by persons other than the persons whose names they are registered under. You shall immediately notify Econobit if you suspect or become aware of unauthorized use of your user name and password. Econobit will not be liable for any loss or damage arising from any use of your Account by you or by any third party (whether or not authorized by you).`,
	'terms_s5_bold_d': `d. Account Security`,
	'terms_s5d_text_1': `Econobit strives to maintain the safety of those user funds entrusted to us and has implemented industry standard protections for the Services. However, there are risks that are created by individual User actions. You agree to consider your access credentials such as user name and password as confidential information and not to disclose such information to any third party. You also agree that you alone are responsible for taking necessary safety precautions to protect your own account and personal information.`,
	'terms_s5d_text_2': `You shall be solely responsible for the safekeeping of your Econobit account and password on your own, and you shall be responsible for all activities under Account and Econobit will not be responsible for any loss or consequences of authorized or unauthorized use of your Account credentials including but not limited to information disclosure, information posting, consent to or submission of various rules and agreements by clicking on the website, online renewal of agreement, etc.`,
	'terms_s5d_text_3': `By creating an Account, you hereby agree that:`,
	'terms_s5d_text_4': `(I) you will notify Econobit immediately if you are aware of any unauthorized use of your Econobit account and password by any person or any other violations to the security rules;`,
	'terms_s5d_text_5': `(II) you will strictly observe the security, authentication, dealing, charging, withdrawal mechanism or procedures of the website/service; and`,
	'terms_s5d_text_6': `(III) you will log out from the website by taking proper steps at the end of every visit.`,
	'terms_s5_bold_e': `e. Personal Data`,
	'terms_s5e_text_pre': `Your Personal Data will be protected and kept confidential, but subject to the provisions of any applicable law, your Personal Data may, depending on the products or services concerned, be disclosed to the following third parties:`,
	'terms_s5e_text_1': `(I) transaction counterparties;`,
	'terms_s5e_text_2': `(II) other divisions or entities within Econobit Ltd.;`,
	'terms_s5e_text_3': `(III) our joint venture/ alliance partners and business partners;`,
	'terms_s5e_text_4': `(IV) our agents, contractors, vendors, third party service providers and specialist advisers who have been contracted to provide us with administrative, financial, research, operational, IT or other services, such as telecommunications, information technology, payment, payroll, processing, training, market research, storage and archival;`,
	'terms_s5e_text_5': `(V) any third party business partners who offer goods and services or sponsor contests or other promotional programmes, whether in conjunction with us or not;`,
	'terms_s5e_text_6': `(VI) insurers or insurance investigators and credit providers;`,
	'terms_s5e_text_7': `(VII) the Credit Bureau, or in the event of default or disputes, any debt collection agencies or dispute resolution centres;`,
	'terms_s5e_text_8': `(VIII) any business partner, investor, assignee or transferee (actual or prospective) to facilitate business asset transactions (which may extend to any merger, acquisition or asset sale) involving the Econobit group;`,
	'terms_s5e_text_9': `(IX) our professional advisors such as our auditors and lawyers;`,
	'terms_s5e_text_10': `(X) relevant government regulators or authority or law enforcement agency to comply with any laws or rules and regulations imposed by any governmental authority;`,
	'terms_s5e_text_11': `(XI) anyone to whom we transfer or may transfer our rights and obligations;`,
	'terms_s5e_text_12': `(XII) banks, credit card companies and their respective service providers; and`,
	'terms_s5e_text_13': `(XIII) any other party as may be consented to by you, as specified by that individual or in the applicable contract.`,
	'terms_title_6': `6. Guidelines for Usage of the Services`,
	'terms_s6_bold_a': `a. License`,
	'terms_s6a_text_1': `Subject to your continued compliance with the express terms and conditions of these Terms, Econobit provides to you a revocable, limited, royalty-free, non-exclusive, non-transferable, and non-sublicensable license to access and use the Services on your computer or other internet compatible device for your personal, internal use only. You are not permitted to use the Services for any resale or commercial use including to place trades on behalf of another person or entity. All such use is expressly prohibited and shall constitute a material violation of these Terms. The content layout, formatting, and features of and access privileges for the Services shall be as specified by Econobit in its sole discretion. All rights not expressly granted under these Terms are hereby reserved. Accordingly, you are hereby prohibited from using the Services in any manner that is not expressly and unambiguously authorized by these Terms.`,
	'terms_s6a_text_2': `These Terms provide only a limited license to access and use the Services. Accordingly, you hereby agree that Econobit transfers no ownership or intellectual property interest or title in and to the Services or any Econobit intellectual property to you or anyone else in connection with your use of the Services. All text, graphics, user interfaces, visual interfaces, photographs, sounds, artwork, computer code (including html code), programs, software, products, information, and documentation as well as the design, structure, selection, coordination, expression, “look and feel,” and arrangement of any content contained on or available through the Services are exclusively owned, controlled, and/or licensed by Econobit or its members, parent(s), licensors, or affiliates.`,
	'terms_s6a_text_3': `Econobit will own any feedback, suggestions, ideas, or other information or materials regarding Econobit or the Services that you provide, whether by email, through the Services or otherwise (“Feedback”). You hereby assign to Econobit all right, title and interest to Feedback together with all associated intellectual property rights. You will not be entitled to, and hereby waive any claim for, acknowledgment or compensation based on any Feedback or any modifications made based on any Feedback.`,
	'terms_s6_bold_b': `b. Restrictions`,
	'terms_s6b_text_pre': `When you use the Services you agree and covenant to observe the following:`,
	'terms_s6b_text_1': `(I) All the activities that you carry out during the use of the Services will be in compliance with the requirements of applicable laws, regulations, as well as the various guidelines of Econobit;`,
	'terms_s6b_text_2': `(II) Your use of the Services will not be in violation of public interests, public ethics or other’s legitimate interests including taking any action that would interfere with, disrupt, negatively affect, or inhibit other Users from using the Services;`,
	'terms_s6b_text_3': `(III) You agree not to use the services for engaging in market manipulation (such as pump and dump schemes, wash trading, self-trading, front running, quote stuffing, and spoofing or layering regardless of whether prohibited by law);`,
	'terms_s6b_text_4': `(IV) The following commercial uses of Econobit data is prohibited unless written consent from Econobit is granted:`,
	'terms_s6b_text_4_1': `1) Exchange services that use quotes or order book information from Econobit.`,
	'terms_s6b_text_4_2': `2) Data feed or data stream services that make use of any market data from Econobit.`,
	'terms_s6b_text_4_3': `3) Any other websites/apps/services that charge for, or otherwise commercially monetize (including through advertising or referral fees), market data obtained from Econobit.`,
	'terms_s6b_text_5': `(V) You may not modify, reproduce, duplicate, copy, download, store, further transmit, disseminate, transfer, disassemble, broadcast, publish, remove or alter any proprietary notices or labels, license, sublicense, sell, mirror, frame, rent, lease, private label, grant a security interest in, create derivative works of, or otherwise exploit the Properties, or any portion of the Properties without Econobit’s prior written consent.;`,
	'terms_s6b_text_6': `(VI) You may not (I) use any "deep link," "page scrape," "robot," "spider," or other automatic device, program, script, algorithm, or methodology, or any similar or equivalent manual process, to access, acquire, copy, or monitor any portion of the Properties or in any way reproduce or circumvent the navigational structure or presentation of the Services to obtain or attempt to obtain any materials, documents, or information through any means not purposely made available through the Services, (II) attempt to gain unauthorized access to any portion or feature of the Properties or any other systems or networks connected to the Services or to any Econobit server or to any of the services offered on or through the Services, by hacking, password "mining," or any other illegitimate or prohibited means, (III) probe, scan, or test the vulnerability of the Services or any network connected to the Properties, nor breach the security or authentication measures on the Services or any network connected to the Services, (IV) reverse look-up, trace, or seek to trace any information on any other user of or visitor to the Services, (V) take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Services or Econobit’s systems or networks or any systems or networks connected to the Services, (VI) use any device, software, or routine to interfere with the proper working of the Services or any transaction conducted on the Services, or with any other person's use of the Services, (VII) forge headers, impersonate a person, or otherwise manipulate identifiers in order to disguise your identity or the origin of any message or transmittal you send to the Services, or (VIII) use the Services in an unlawful manner.`,
	'terms_s6b_text_7': `By accessing the Service, you agree that Econobit shall have the right to investigate any violation of these Terms, unilaterally determine whether you have violated these Terms, and take actions to apply relevant rules without receiving your consent or giving prior notice to you. Examples of such actions include, but are not limited to:`,
	'terms_s6b_text_7_1': `block and close order requests`,
	'terms_s6b_text_7_2': `freezing your account`,
	'terms_s6b_text_7_3': `reporting the incident to authorities`,
	'terms_s6b_text_7_4': `publishing the alleged violations and actions that have been taken`,
	'terms_s6b_text_7_5': `deleting any information you published that is in violation`,
	'terms_title_7': `7. Orders and Service Fees`,
	'terms_s7_bold_a': `a. Orders`,
	'terms_s7a_text_1': `Upon placing an instruction to effect a trade using the Services (an “Order”), your Account will be updated to reflect the open Order and your Order will be included in Econobit’s order book for matching with Orders from other Users. If all or a portion of your Order is matched with another User, the Services will execute an exchange (a “Trade”). Upon execution of a Trade, your Account will be updated to reflect that the Order has either been closed due to having been fully executed, or updated to reflect any partial fulfillment of the Order. Orders will remain open until fully executed or cancelled in accordance with subsection (b) below. For purposes of effectuating a Trade, you authorize Econobit to take temporary control of the Digital Currency that you are disposing of in the Trade.`,
	'terms_s7_bold_b': `b. Cancellations`,
	'terms_s7b_text_1': `You may only cancel an order initiated via the Services if such cancellation occurs before your Order has been matched with an Order from another user. Once your Order has been matched with an Order from another user, you may not change, withdraw, or cancel your authorization for Econobit to complete such Order. If any order has been partially matched, you may cancel the unmatched portion of the Order unless and until the unmatched portion has been matched. Econobit reserves the right to refuse any cancellation request associated with an Order after you have submitted such Order. If you have an insufficient amount of Digital Currency in your Account to fulfill an Order, Econobit may cancel the entire Order or may fulfill a partial Order that can be covered by the Digital Currency in your Account (in each case after deducting any fees payable to Econobit in connection with the Trade as described in subsection (c) below).`,
	'terms_s7_bold_c': `c. Fees`,
	'terms_s7c_text_1': `You agree to pay Econobit the fees set forth at  https://econobit.io/fees  which may be updated from time to time in Econobit’s sole discretion. Any such updated fees will apply prospectively to any trades or other transactions that take place following the effective date of such updated fees. You authorize Econobit to remove any amounts from your Account for any applicable fees owed by you under these Terms.`,
	'terms_s7_bold_d': `d. Fiat Payments`,
	'terms_s7d_text_1': `In order to provide adequate operational support for you in respect of refunds and cancelations (where applicable), the following Econobit entities shall be responsible for transactions conducted in the following currencies:`,
	'terms_s7d_text_2': `Real Brasileiro (BRL) - in respect of transactions available (unless otherwise specified) at www.econobit.io, ECONOBIT SERVICOS ONLINE LTDA. (registration number: 27.493.748/0001-20) and having its registered office at RUA VERGUEIRO, 1421, SALA 510, BAIRRO PARAISO, SAO PAULO/SP, 04101-100, BRASIL.`,
	'terms_title_8': `8. Liability`,
	'terms_s8_bold_a': `a. Disclaimer of Warranties`,
	'terms_s8a_text_1': `To the maximum extent permitted under applicable law, the services, the Econobit materials and any product, service or other item provided by or on behalf of Econobit are provided on an “as is” and “as available” basis and Econobit expressly disclaims, and you waive, any and all other warranties of any kind, whether express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title or non-infringement or warranties arising from course of performance, course of dealing or usage in trade. Without limiting the foregoing, Econobit does not represent or warrant that the site, the services or Econobit materials are accurate, complete, reliable, current, error-free, or free of viruses or other harmful components. Econobit does not guarantee that any order will be executed, accepted, recorded or remain open. Except for the express statements set forth in this agreement, you hereby acknowledge and agree that you have not relied upon any other statement or understanding, whether written or oral, with respect to your use and access of the services. Without limiting the foregoing, you hereby understand and agree that Econobit will not be liable for any losses or damages arising out of or relating to: (a) any inaccuracy, defect or omission of digital currency price data, (b) any error or delay in the transmission of such data, (c) interruption in any such data and (d) any damages incurred by another user’s actions, omissions or violation of this agreement.`,
	'terms_s8a_text_2': `The disclaimer of implied warranties contained herein may not apply if and to the extent it is prohibited by applicable law of the jurisdiction in which you reside.`,
	'terms_s8_bold_b': `b. Disclaimer of Damages and Limitation of Liability`,
	'terms_s8b_text_1': `To the maximum extent permitted by applicable law, in no event will Econobit, its affiliates and their respective shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers or contractors be liable for any incidental, indirect, special, punitive, consequential or similar damages or liabilities whatsoever (including, without limitation, damages for loss of data, information, revenue, profits or other business or financial benefit) arising out of or in connection with the services, any performance or non-performance of the services, or any other product, service or other item provided by or on behalf of Econobit and its affiliates, whether under contract, statute, strict liability or other theory even if Econobit has been advised of the possibility of such damages except to the extent of a final judicial determination that such damages were a result of Econobit’s gross negligence, fraud, willful misconduct or intentional violation of law. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation may not apply to you.`,
	'terms_s8b_text_2': `Notwithstanding the foregoing, in no event will the liability of Econobit, its affiliates and their respective shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers or contractors arising out of or in connection the services, any performance or non-performance of the services, or any other product, service or other item provided by or on behalf of Econobit or its affiliates whether under contract, statute, strict liability or other theory, exceed the amount of the fees paid by you to Econobit under this agreement in the twelve-month period immediately preceding the event giving rise to the claim for liability.`,
	'terms_s8_bold_c': `c. Indemnification`,
	'terms_s8c_text_1': `You agree to indemnify and hold harmless Econobit, its affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to (I) your use of, or conduct in connection with, the Services, (II) your breach or our enforcement of these Terms, or (III) your violation of any applicable law, regulation, or rights of any third party during your use of the Service. If you are obligated to indemnify Econobit, its affiliates, contractors, licensors, and their respective directors, officers, employees or agents pursuant to this clause, Econobit will have the right, in its sole discretion, to control any action or proceeding and to determine whether Econobit wishes to settle, and if so, on what terms.`,
	'terms_title_9': `9. Announcements`,
	'terms_s9_text_1': `Please be aware that all official announcements, news, promotions, competitions and airdrops will be listed on  https://support.econobit.io/category/announcements/  where we urge all users to refer to regularly. Econobit will not be held liable or responsible in any manner of compensation should users incur personal losses arising from ignorance or negligence of the announcements.`,
	'terms_title_10': `10. Termination of Agreement`,
	'terms_s10_text_1': `You agree that Econobit shall have the right to immediately suspend your account (and any accounts beneficially owned by related entities or affiliates), freeze or lock the funds in all such accounts, and suspend your access to Econobit for any reason including if it suspects any such accounts to be in violation of these Terms, our Privacy Policy, or any applicable laws and regulations. You agree that Econobit shall not be liable to you for any permanent or temporary modification, suspension or termination of your Account or access to all or any portion of the Services. Econobit shall have the right to keep and use the transaction data or other information related to such accounts. The above account controls may also be applied in the following cases:`,
	'terms_s10_text_1_1': `The account is subject to a governmental proceeding, criminal investigation or other pending litigation;`,
	'terms_s10_text_1_2': `We detect unusual activity in the account;`,
	'terms_s10_text_1_3': `We detect unauthorized access to the account;`,
	'terms_s10_text_1_4': `We are required to do so by a court order or command by a regulatory/government authority`,
	'terms_s10_text_2': `In case of any of the following events, Econobit shall have the right to directly terminate this agreement by cancelling your Account, and shall have the right to permanently freeze (cancel) the authorizations of your account on Econobit and withdraw the corresponding Econobit Account thereof:`,
	'terms_s10_text_2_1': `after Econobit terminates services to you;`,
	'terms_s10_text_2_2': `you allegedly register or register in any other person’s name as a new user, directly or indirectly;`,
	'terms_s10_text_2_3': `the information that you have provided is untruthful, inaccurate, outdated or incomplete;`,
	'terms_s10_text_2_4': `when these Terms are amended, you expressly state and notify Econobit of your unwillingness to accept the amended Terms;`,
	'terms_s10_text_2_5': `you request that the Services be terminated; and`,
	'terms_s10_text_2_6': `any other circumstances where Econobit deems it should terminate the services.`,
	'terms_s10_text_3': `Should your Account be terminated, the Account and transactional information required for meeting data retention standards will be securely stored for 5 years. In addition, if a transaction is unfinished during the Account termination process, Econobit shall have the right to notify your counterparty of the situation at that time. You acknowledge that a user initiated account exit (right to erasure under GDPR or other equivalent regulations) will also be subjected to the termination protocol stated above.`,
	'terms_s10_text_4': `If Econobit receives notice that any funds held in your Account are alleged to have been stolen or otherwise are not lawfully possessed by you, Econobit may, but has no obligation to, place an administrative hold on the affected funds and your Account. If Econobit does place an administrative hold on some or all of your funds or Account, Econobit may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Econobit has been provided to Econobit in a form acceptable to Econobit. Econobit will not involve itself in any such dispute or the resolution of the dispute. You agree that Econobit will have no liability or responsibility for any such hold, or for your inability to withdraw funds or execute trades during the period of any such hold.`,
	'terms_s10_bold_a': `a. Remaining funds after Account termination`,
	'terms_s10a_text_1': `Except as set forth in subsection (b) below, once the Account is closed/withdrawn, all remaining balance (which includes charges and liabilities owed to Econobit) on the account will be payable immediately to Econobit. Upon payment of all outstanding charges to Econobit (if any), the User will have 5 business days to withdraw all funds from the Account.`,
	'terms_s10_bold_b': `b. Remaining funds after Account termination due to fraud, violation of law, or violation of these terms`,
	'terms_s10b_text_1': `Econobit maintains full custody of the funds and User data/information which may be turned over to governmental authorities in the event of Account suspension/closure arising from fraud investigations, violation of law investigations or violation of these Terms.`,
	'terms_title_11': `11. No Financial Advice`,
	'terms_s11_text_1': `Econobit is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in connection with any trades or other decisions or activities effected by you using the Services. No communication or information provided to you by Econobit is intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice. All trades are executed automatically, based on the parameters of your order instructions and in accordance with posted trade execution procedures, and you are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance. You should consult your legal or tax professional regarding your specific situation. Econobit does not recommend that any Digital Currency should be bought, earned, sold, or held by you. Before making the decision to buy, sell or hold any Digital Currency, you should conduct your own due diligence and consult your financial advisors before making any investment decision. Econobit will not be held responsible for the decisions you make to buy, sell, or hold Digital Currency based on the information provided by Econobit.`,
	'terms_title_12': `12. Compliance with Local Laws`,
	'terms_s12_text_1': `It is the responsibility of the User to abide by local laws in relation to the legal usage of Econobit in their local jurisdiction. Users must also factor, to the extent of their local law all aspects of taxation, the withholding, collection, reporting and remittance to their appropriate tax authorities. All Users of Econobit and any of its services acknowledge and declare that the source of their funds come from a legitimate manner and are not derived from illegal activities. Econobit maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, terminate the account and funds of Users which are flagged out or investigated by legal mandate.`,
	'terms_title_13': `13. Privacy Policy`,
	'terms_s13_text_1': `Access to the Services will require the submission of certain personally identifiable information. Please review Econobit’s Privacy Policy found at https://econobit.io/privacyPolicy for a summary of Econobit’s practices related to the collection and use of personally identifiable information.`,
	'terms_title_14': `14. Resolving Disputes: Forum, Arbitration, Class Action Waiver`,
	'terms_s14_text_1': `Please read this section carefully, as it involves a waiver of certain rights to bring legal proceedings, including as a class action for residents of the U.S.`,
	'terms_s14_bold_a': `a. Notification of Dispute.`,
	'terms_s14a_text_1': `Please contact Econobit first! Econobit wants to address your concerns without resorting to formal legal proceedings. Before filing a claim, you agree to try to resolve the dispute informally by contacting Econobit first through https://support.econobit.io/request .`,
	'terms_s14_bold_b': `b. Agreement to Arbitrate.`,
	'terms_s14b_text_1': `You and Econobit agree to resolve any claims relating to this Agreement (including any question regarding its existence, validity, termination, or any services or products provided and any representations made by us) through final and binding arbitration, except as set forth under Exceptions to Agreement to Arbitrate below. You agree to first give us an opportunity to resolve any claims by contacting us as set forth in subsection (a) above. If we are not able to resolve your claims within 60 days of receiving the notice, you may seek relief through arbitration or in the Juizado Especial Cível Digital (“JECD”), as set forth below.`,
	'terms_s14_bold_c': `c. Arbitration Procedure.`,
	'terms_s14c_text_1': `Either you or Econobit may submit a dispute (after having made good faith efforts to resolve such dispute in accordance with subsections (a) and (b) above) for final, binding resolution by arbitration under the arbitration rules of the Câmara Empresarial de Mediação e Arbitragem da Associação Comercial (“CEMAAC”), which are deemed to be incorporated by reference. The arbitration tribunal shall consist of a sole arbitrator to be appointed by the President of CEMAAC. The language of the arbitration hearings shall be English and the seat, or legal place, of arbitration shall be Singapore. Judgment on any arbitral award may be entered in any court having jurisdiction over the party (or the assets of the party) due and owing such award.`,
	'terms_s14_bold_d': `d. Exceptions.`,
	'terms_s14d_text_1': `Either party may instead choose to assert the claims in the JECD if the claims fall within the jurisdiction of the JECD, and either party may seek injunctive relief or other urgent equitable relief in a court of competent jurisdiction. However, for the avoidance of doubt, where the claims fall outside of the jurisdiction of the JECD, the claims will be referred to and finally resolved by CEMAAC arbitration.`,
	'terms_s14_bold_e': `e. Notice.`,
	'terms_s14e_text_1': `To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claims to support@econobit.io . If we request arbitration against you, we will give you notice at the email address or street address you have provided. CEMAAC Rules and filing instructions are available at https://camara.acsp.com.br.`,
	'terms_title_15': `15. Miscellaneous`,
	'terms_s15_bold_a': `a. Independent Parties.`,
	'terms_s15a_text_1': `Econobit is an independent contractor and not an agent of you in the performance of these Terms. These Terms not to be interpreted as evidence of an association, joint venture, partnership, or franchise between the parties.`,
	'terms_s15_bold_b': `b. Entire Agreement.`,
	'terms_s15b_text_1': `These Terms constitute the entire agreement between the parties regarding use of the Services and will supersede all prior agreements between the parties whether, written or oral. No usage of trade or other regular practice or method of dealing between the parties will be used to modify, interpret, supplement, or alter the terms of these Terms.`,
	'terms_s15_bold_c': `c. Force Majeure.`,
	'terms_s15c_text_1': `Econobit will not be liable for any delay or failure to perform as required by these Terms because of any cause or condition beyond Econobit’s reasonable control.`,
	'terms_s15_bold_d': `d. Severability.`,
	'terms_s15d_text_1': `If any portion of these Terms are held invalid or unenforceable, such invalidity or enforceability will not affect the other provisions of these Terms, which will remain in full force and effect, and the invalid or unenforceable portion will be given effect to the greatest extent possible.`,
	'terms_s15_bold_e': `e. Assignment.`,
	'terms_s15e_text_1': `You may not assign or transfer any right to use the Services or any of your rights or obligations under these Terms without prior written consent from us, including by operation of law or in connection with any change of control. Econobit may assign or transfer any or all of its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval.`,
	'terms_s15_bold_f': `f. Waiver.`,
	'terms_s15f_text_1': `The failure of a party to require performance of any provision will not affect that party’s right to require performance at any time thereafter, nor will a waiver of any breach or default of these Terms or any provision of these Terms constitute a waiver of any subsequent breach or default or a waiver of the provision itself.`,
	'terms_s15_bold_g': `g. Third-Party Website Disclaimer.`,
	'terms_s15g_text_1': `Any links to third-party websites from the Services does not imply endorsement by Econobit of any products, services or information presented therein, nor does Econobit guarantee the accuracy of the information contained on them. In addition, since Econobit has no control over the terms of use or privacy practices of third-party websites, you should read and understand those policies carefully.`,
	'terms_s15_bold_h': `h. Contact Information.`,
	'terms_s15h_text_1': `For more information on Econobit, you can refer to the company and license information found on the website. If you have questions regarding this agreement, please feel free to contact Econobit for clarification via our Customer Support team at https://support.econobit.io/request.`,
	'delete_account': 'Delete Account',
	'account_deletion': 'Account Deletion',
	'request_account_deletion': 'Request Account Deletion',
	'confirm_account_deletion': 'Confirm Account Deletion',
	'delete_acc_warning': 'Please make sure you want to delete your account. We will handle the process with you by e-mail. You will not be able to use the same e-mail again to create another account.',
	'deposit_paused': 'Deposit temporarily unavailable.',
	'withdraw_paused': 'Withdrawal temporarily unavailable.',
	'withdraw_temporary_unavailable': 'Withdrawal temporarily unavailable.',

}