import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileFeesComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const brlLogo = require('../images/coins/BRL.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const usdcLogo = require('../images/coins/USDC.svg')

		return (
			<div className='feesMain'>
				<div className='homeTitle'>
					<h2>{translate.text('fees')}</h2>
				</div>
				<div className='aboutTitle'>
					<h4>{translate.text('account')}</h4>
				</div>
				<table>
					<tbody>
						<tr className='feesRow' >
							<td><b>{translate.text('account_opening')}</b></td>
							<td>{translate.text('free')}</td>
						</tr>
						<tr className='feesRow' >
							<td><b>{translate.text('custody')}</b></td>
							<td>{translate.text('free')}</td>
						</tr>
					</tbody>
				</table>
				<div className='aboutTitle'>
					<h4>{translate.text('trading')}</h4>
				</div>
				<table>
					<tbody>
						<tr>
							<td><b>{translate.text('maker_cap')}</b></td>
							<td>{translate.text('free')}</td>
						</tr>
						<tr>
							<td><b>{translate.text('taker_cap')}</b></td>
							<td>0.5%</td>
						</tr>
					</tbody>
				</table>
				<div className='aboutTitle'>
					<h4>{translate.text('deposits')} & {translate.text('withdrawals')}</h4>
				</div>
				<table>
					<tbody>
						<tr>
							<th>{translate.text('coin')}</th>
							<th>{translate.text('deposit')}</th>
							<th>{translate.text('withdraw')}</th>
						</tr>
						<tr>
							<td><img src={brlLogo} className='feesCoinLogo' alt='BRL' /> BRL</td>
							<td>{translate.text('free')}</td>
							<td>{translate.text('free')}</td>
						</tr>
						<tr>
							<td><img src={btcLogo} className='feesCoinLogo' alt='BTC' /> BTC</td>
							<td>{translate.text('free')}</td>
							<td>0.0005 BTC</td>
						</tr>
						<tr>
							<td><img src={ethLogo} className='feesCoinLogo' alt='ETH' /> ETH</td>
							<td>{translate.text('free')}</td>
							<td>0.01 ETH</td>
						</tr>
						<tr>
							<td><img src={ebitLogo} className='feesCoinLogo' alt='EBIT' /> EBIT</td>
							<td>{translate.text('free')}</td>
							<td>1 EBIT</td>
						</tr>
						<tr>
							<td><img src={ltcLogo} className='feesCoinLogo' alt='LTC' /> LTC</td>
							<td>{translate.text('free')}</td>
							<td>0.001 LTC</td>
						</tr>
						<tr>
							<td><img src={dogeLogo} className='feesCoinLogo' alt='DOGE' /> DOGE</td>
							<td>{translate.text('free')}</td>
							<td>5 DOGE</td>
						</tr>
						<tr>
							<td><img src={linkLogo} className='feesCoinLogo' alt='LINK' /> LINK</td>
							<td>{translate.text('free')}</td>
							<td>10 LINK</td>
						</tr>
						<tr>
							<td><img src={usdcLogo} className='feesCoinLogo' alt='USDC' /> USDC</td>
							<td>{translate.text('free')}</td>
							<td>5 USDC</td>
						</tr>
					</tbody>
				</table>
				<div className='aboutTitle'>
					<h4>{translate.text('fee_rounding')}</h4>
					<p>{translate.text('fee_rounding_msg')}</p>
				</div>
				<div className='aboutTitle'>
					<h4>{translate.text('minimum_trade_amount')}</h4>
				</div>
				<table>
					<tbody>
						<tr>
							<th>{translate.text('coin')}</th>
							<th>{translate.text('minimum_trade_amount')}</th>
						</tr>
						<tr>
							<td><img src={brlLogo} className='feesCoinLogo' alt='BRL' /> Real Brasileiro</td>
							<td>0.01 BRL</td>
						</tr>
						<tr>
							<td><img src={btcLogo} className='feesCoinLogo' alt='BTC' /> Bitcoin</td>
							<td>0.0001 BTC</td>
						</tr>
						<tr>
							<td><img src={ethLogo} className='feesCoinLogo' alt='ETH' /> Ethereum</td>
							<td>0.0001 ETH</td>
						</tr>
						<tr>
							<td><img src={ebitLogo} className='feesCoinLogo' alt='EBIT' /> Econobit</td>
							<td>0.0001 EBIT</td>
						</tr>
						<tr>
							<td><img src={ltcLogo} className='feesCoinLogo' alt='LTC' /> Litecoin</td>
							<td>0.0001 LTC</td>
						</tr>
						<tr>
							<td><img src={dogeLogo} className='feesCoinLogo' alt='DOGE' /> Dogecoin</td>
							<td>1 DOGE</td>
						</tr>
						<tr>
							<td><img src={linkLogo} className='feesCoinLogo' alt='LINK' /> Chainlink</td>
							<td>0.0001 LINK</td>
						</tr>
						<tr>
							<td><img src={usdcLogo} className='feesCoinLogo' alt='USDC' /> USD Coin</td>
							<td>0.01 USDC</td>
						</tr>
					</tbody>
				</table>
				<br />
				<br />
				<br />
			</div>
		)
	}

}