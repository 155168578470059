export class Cookies {

	static set(cName: string, cValue: string, expDays: number) {
		let date = new Date()
		date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
		const expires = "expires=" + date.toUTCString()
		document.cookie = cName + "=" + cValue + "; " + expires + "; path=/"
	}

	static get(cName: string) {
		const cDecoded = decodeURIComponent(document.cookie)
		const cookie = cDecoded.split('; ').find(f => f.startsWith(`${cName}=`))
		return cookie?.split('=')[1]
	}
}