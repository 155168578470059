import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class TermsOfUseComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		return (
			<div className='termsOfUse'>
				<div className='termsOfUseMain'>
					<span className='pageTitle'>{translate.text('terms_of_use')}</span>
					<b><i>[{translate.text('last_terms_updated_date')}]</i></b>
					<br /><br />
					<p>{translate.text('terms_text_1')}</p>

					<p>{translate.text('terms_text_2')}</p>

					<p>{translate.text('terms_text_3')}</p>

					<h3>{translate.text('terms_title_1')}</h3>
					<p>{translate.text('terms_s1_text_1')}</p>

					<h3>{translate.text('terms_title_2')}</h3>
					<p>{translate.text('terms_s2_text_1')}</p>

					<h3>{translate.text('terms_title_3')}</h3>
					<p>{translate.text('terms_s3_text_1')}</p>

					<h3>{translate.text('terms_title_4')}</h3>
					<p>{translate.text('terms_s4_text_1')}</p>

					<p>{translate.text('terms_s4_text_2')}</p>

					<h3>{translate.text('terms_title_5')}</h3>
					<b>{translate.text('terms_s5_bold_a')}</b>

					<p>{translate.text('terms_s5a_text_1')}</p>

					<b>{translate.text('terms_s5_bold_b')}</b>

					<p>{translate.text('terms_s5b_text_1')}</p>

					<p>{translate.text('terms_s5b_text_2')}</p>

					<p>{translate.text('terms_s5b_text_3')}</p>

					<b>{translate.text('terms_s5_bold_c')}</b>

					<p>{translate.text('terms_s5c_text_1')}</p>

					<b>{translate.text('terms_s5_bold_d')}</b>

					<p>{translate.text('terms_s5d_text_1')}</p>

					<p>{translate.text('terms_s5d_text_2')}</p>

					<p>{translate.text('terms_s5d_text_3')}<br />
						{translate.text('terms_s5d_text_4')}<br />
						{translate.text('terms_s5d_text_5')}<br />
						{translate.text('terms_s5d_text_6')}</p>

					<b>{translate.text('terms_s5_bold_e')}</b>

					<p>{translate.text('terms_s5e_text_pre')}</p>

					<p>{translate.text('terms_s5e_text_1')}<br />
						{translate.text('terms_s5e_text_2')}<br />
						{translate.text('terms_s5e_text_3')}<br />
						{translate.text('terms_s5e_text_4')}<br />
						{translate.text('terms_s5e_text_5')}<br />
						{translate.text('terms_s5e_text_6')}<br />
						{translate.text('terms_s5e_text_7')}<br />
						{translate.text('terms_s5e_text_8')}<br />
						{translate.text('terms_s5e_text_9')}<br />
						{translate.text('terms_s5e_text_10')}<br />
						{translate.text('terms_s5e_text_11')}<br />
						{translate.text('terms_s5e_text_12')}<br />
						{translate.text('terms_s5e_text_13')}</p>

					<h3>{translate.text('terms_title_6')}</h3>
					<b>{translate.text('terms_s6_bold_a')}</b>
					<p>{translate.text('terms_s6a_text_1')}</p>

					<p>{translate.text('terms_s6a_text_2')}</p>

					<p>{translate.text('terms_s6a_text_3')}</p>

					<b>{translate.text('terms_s6_bold_b')}</b>
					<p>{translate.text('terms_s6b_text_pre')}</p>
					<p>{translate.text('terms_s6b_text_1')}</p>
					<p>{translate.text('terms_s6b_text_2')}</p>
					<p>{translate.text('terms_s6b_text_3')}</p>
					<p>{translate.text('terms_s6b_text_4')}<br />
						{translate.text('terms_s6b_text_4_1')}<br />
						{translate.text('terms_s6b_text_4_2')}<br />
						{translate.text('terms_s6b_text_4_3')}</p>
					<p>{translate.text('terms_s6b_text_5')}</p>
					<p>{translate.text('terms_s6b_text_6')}</p>
					<p>{translate.text('terms_s6b_text_7')}</p>
					<ul>
						<li>{translate.text('terms_s6b_text_7_1')}</li>
						<li>{translate.text('terms_s6b_text_7_2')}</li>
						<li>{translate.text('terms_s6b_text_7_3')}</li>
						<li>{translate.text('terms_s6b_text_7_4')}</li>
						<li>{translate.text('terms_s6b_text_7_5')}</li>
					</ul>
					<h3>{translate.text('terms_title_7')}</h3>
					<b>{translate.text('terms_s7_bold_a')}</b>
					<p>{translate.text('terms_s7a_text_1')}</p>

					<b>{translate.text('terms_s7_bold_b')}</b>
					<p>{translate.text('terms_s7b_text_1')}</p>

					<b>{translate.text('terms_s7_bold_c')}</b>
					<p>{translate.text('terms_s7c_text_1')}</p>

					<b>{translate.text('terms_s7_bold_d')}</b>
					<p>{translate.text('terms_s7d_text_1')}</p>
					<ul>
						<li>{translate.text('terms_s7d_text_2')}</li>
					</ul>

					<h3>{translate.text('terms_title_8')}</h3>
					<b>{translate.text('terms_s8_bold_a')}</b>
					<p>{translate.text('terms_s8a_text_1')}</p>

					<p>{translate.text('terms_s8a_text_2')}</p>

					<b>{translate.text('terms_s8_bold_b')}</b>
					<p>{translate.text('terms_s8b_text_1')}</p>

					<p>{translate.text('terms_s8b_text_2')}</p>

					<b>{translate.text('terms_s8_bold_c')}</b>
					<p>{translate.text('terms_s8c_text_1')}</p>

					<h3>{translate.text('terms_title_9')}</h3>
					<p>{translate.text('terms_s9_text_1')}</p>

					<h3>{translate.text('terms_title_10')}</h3>
					<p>{translate.text('terms_s10_text_1')}</p>
					<ul>
						<li>{translate.text('terms_s10_text_1_1')}</li>
						<li>{translate.text('terms_s10_text_1_2')}</li>
						<li>{translate.text('terms_s10_text_1_3')}</li>
						<li>{translate.text('terms_s10_text_1_4')}</li>
					</ul>
					<p>{translate.text('terms_s10_text_2')}</p>
					<ul>
						<li>{translate.text('terms_s10_text_2_1')}</li>
						<li>{translate.text('terms_s10_text_2_2')}</li>
						<li>{translate.text('terms_s10_text_2_3')}</li>
						<li>{translate.text('terms_s10_text_2_4')}</li>
						<li>{translate.text('terms_s10_text_2_5')}</li>
						<li>{translate.text('terms_s10_text_2_6')}</li>
					</ul>
					<p>{translate.text('terms_s10_text_3')}</p>

					<p>{translate.text('terms_s10_text_4')}</p>

					<b>{translate.text('terms_s10_bold_a')}</b>
					<p>{translate.text('terms_s10a_text_1')}</p>

					<b>{translate.text('terms_s10_bold_b')}</b>
					<p>{translate.text('terms_s10b_text_1')}</p>

					<h3>{translate.text('terms_title_11')}</h3>
					<p>{translate.text('terms_s11_text_1')}</p>

					<h3>{translate.text('terms_title_12')}</h3>
					<p>{translate.text('terms_s12_text_1')}</p>

					<h3>{translate.text('terms_title_13')}</h3>
					<p>{translate.text('terms_s13_text_1')}</p>

					<h3>{translate.text('terms_title_14')}</h3>
					<p>{translate.text('terms_s14_text_1')}</p>

					<p><b>{translate.text('terms_s14_bold_a')}</b> {translate.text('terms_s14a_text_1')}</p>

					<p><b>{translate.text('terms_s14_bold_b')}</b> {translate.text('terms_s14b_text_1')}</p>

					<p><b>{translate.text('terms_s14_bold_c')}</b> {translate.text('terms_s14c_text_1')}</p>

					<p><b>{translate.text('terms_s14_bold_d')}</b> {translate.text('terms_s14d_text_1')}</p>

					<p><b>{translate.text('terms_s14_bold_e')}</b> {translate.text('terms_s14e_text_1')}</p>

					<h3>{translate.text('terms_title_15')}</h3>
					<p><b>{translate.text('terms_s15_bold_a')}</b> {translate.text('terms_s15a_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_b')}</b> {translate.text('terms_s15b_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_c')}</b> {translate.text('terms_s15c_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_d')}</b> {translate.text('terms_s15d_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_e')}</b> {translate.text('terms_s15e_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_f')}</b> {translate.text('terms_s15f_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_g')}</b> {translate.text('terms_s15g_text_1')}</p>

					<p><b>{translate.text('terms_s15_bold_h')}</b> {translate.text('terms_s15h_text_1')}</p>
					<br />
					<br />
				</div>
				<HomeFooterContainer />
			</div>

		)
	}

}