import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { DepthComponent } from '../components/depth'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		orderbook: state.orderbooks.list[state.markets.current],
		market: state.markets.list[state.markets.current],
		lang: state.lang,
		style: state.style,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {

	}
}

export const DepthContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DepthComponent))