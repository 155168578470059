import { BaseN } from '../models/baseN'
export class Utils {

	public static base256: BaseN = new BaseN()
	public static base62: BaseN = new BaseN('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
	public static base32: BaseN = new BaseN('0123456789ABCDEFGHKMNPQRSTUVWXYZ')

	public static timeSpan = (ms: string | number, rand?: string | number): number => {
		const sec = 1000
		const min = 60 * sec
		const hour = 60 * min
		const day = 24 * hour

		const toMsec = (ts?: string | number) => {
			if (!ts) {
				return 0
			}
			ts = ts.toString()
			let time = 0
			ts.split(/(\d+\D+)/).filter((f: string) => f !== '').forEach((e: string) => {
				const t = parseInt(e)
				switch (true) {
					case e.includes('day') || e.includes('d'):
						time += t * day
						break
					case e.includes('hour') || e.includes('h'):
						time += t * hour
						break
					case e.includes('min') || e.includes('m'):
						time += t * min
						break
					case e.includes('sec') || e.includes('s'):
						time += t * sec
						break
					default:
						break
				}
			})
			return time
		}

		return toMsec(ms) + toMsec(rand) * Math.random()
	}

	public static hash(str: string) {
		let i, l, hval = 0x811c9dc5

		for (i = 0, l = str.length; i < l; i++) {
			hval ^= str.charCodeAt(i)
			hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
		}

		return this.zip(hval >>> 0, 4)
	}

	public static pad(str: string, size: number) {
		return (new Array(size + 1).join('0') + str).slice(-size)
	}

	public static chunk(str: string, size: number) {
		let chunks = new Array(str.length / size + .5 | 0),
			nChunks = chunks.length

		let newo = 0
		for (let i = 0, o = 0; i < nChunks; ++i, o = newo) {
			newo += size
			chunks[i] = str.substr(o, size)
		}

		return chunks
	}

	public static zip(num: number, size: number) {
		return (size) ? this.pad(this.base256.encode(num), size) : this.base256.encode(num)
	}

	public static unzip(str: string) {
		return this.base256.decode(str)
	}

	public static round(value: number | string, decimals: number) {
		let pow = Math.pow(10, decimals)
		if (typeof(value) === 'string') {
					value = parseFloat(value)
		}
		return parseFloat((Math.round(value * pow) / pow) as any)
	}

	public static floor(value: number | string, decimals: number) {
		let pow = Math.pow(10, decimals)
		if (typeof (value) === 'string') {
			value = parseFloat(value)
		}
		return parseFloat((Math.floor(value * pow) / pow) as any)
	}

	public static ceil(value: number | string, decimals: number) {
		let pow = Math.pow(10, decimals)
		if (typeof (value) === 'string') {
			value = parseFloat(value)
		}
		return parseFloat((Math.ceil(value * pow) / pow) as any)
	}

	public static sleep = (time: number | string) => {
		// log(`sleep ${time}...`)
		return new Promise((resolve) => setTimeout(resolve, Utils.timeSpan(time)))
	}
}