import { IMarkets, IOrder, IOrderChange, IWallets } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { walletBalance } from '../functions/wallet'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	market: string
	wallets: IWallets
	markets: IMarkets
	changeOrder: (order: Partial<IOrder>) => IOrderChange
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class SideWalletsComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		translate.language = this.props.lang
		const changeOrder = this.props.changeOrder
		const amountDecimals = this.props.markets.list[this.props.market].amountDecimals

		const market: string = this.props.market
		const wallets = this.props.wallets.list
		
		const otherCoin: string = market.split('-')[0]
		const baseCoin: string = market.split('-')[1]
		if (!wallets[baseCoin] || !wallets[otherCoin]) {
			return <div></div>
		}

		return (
			<div className='sideWallets'>
				<div className='boxTitle'><span>{translate.text('available_balance')}</span></div>
				<div className='tableHeader'>
					<span className='asset'>{translate.text('coin')}</span>
					<span className='amount'>{translate.text('amount')}</span>
				</div>
				<div className='tableRow'>
					<span className='asset'>{baseCoin}</span>
					<span className='amount'>{walletBalance(wallets[baseCoin])}</span>
				</div>
				<div className='tableRow'>
					<span className='asset'>{otherCoin}</span>
					<span className='amount pointer' onClick={() => { changeOrder({ side: 'sell', amount: Utils.floor(walletBalance(wallets[otherCoin]), amountDecimals) }) }}>{walletBalance(wallets[otherCoin])}</span>
				</div>
				<div className='sideWalletsActions'>
					<Link className='sideDeposit' to='/deposit'><div className='arrowsSideWalletDown' />{translate.text('deposit')}</Link>
					<Link className='sideWithdraw' to='/withdraw'><div className='arrowsSideWalletUp' />{translate.text('withdraw')}</Link>
				</div>
			</div>
		)
	}
}