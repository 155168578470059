import { ActionCreators, IBankAccount, IBankAccounts, IBanks, ICurrencies, IDeposit, IDeposits, IWalletChange, IWallets } from 'exchange-common'
import { base32, pad, round } from 'libs-tools'
import * as React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast } from 'react-toastify'
import { Field, formValueSelector, reduxForm, reset } from 'redux-form'
import { store } from '..'
import config from '../config.json'
import { translate } from '../functions/lang'
import { walletBalance, walletInOrders } from '../functions/wallet'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { maxLength100, renderField, required } from './renderField'

interface IProps {
	current: string
	wallets: IWallets
	currencies: ICurrencies
	deposits: IDeposits
	walletChange: (current: string) => IWalletChange
	bankAccounts: IBankAccounts
	banks: IBanks
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

const resizeImg = async (imgDataUrl: string, maxWidth: number = 1280, maxHeight: number = 1280, quality: number = 0.8, imgType: string = 'jpeg') => {
	return new Promise<string>((resolve, reject) => {
		const canvas = document.createElement('canvas')
		const img = new Image() // document.createElement('img') as HTMLImageElement
		img.src = imgDataUrl
		img.onload = () => {
			let width = img.width
			let height = img.height
			// calculate the width and height, constraining the proportions
			if (width / maxWidth > height / maxHeight) {
				height = Math.round(height * maxWidth / width)
				width = maxWidth
			} else {
				width = Math.round(width * maxHeight / height)
				height = maxHeight
			}
			// resize the canvas and draw the image data into it
			canvas.width = width
			canvas.height = height
			const ctx = canvas.getContext('2d')
			if (ctx) { ctx.drawImage(img, 0, 0, width, height) }
			resolve(canvas.toDataURL(`image/${imgType}`, quality)) // get the data from canvas as 70% JPG (can be also PNG, etc.)
		}
	})
}

const submitted = false

let BrlDepositForm = (props: any) => {
	const { handleSubmit, bankAccounts, banks } = props
	const banksfilter: IBanks = banks

	return (
		<div>
			<form className='brlDepositForm' onSubmit={handleSubmit}>
				<div className='depositFormRow'>
					<label className='depositFormLabel'>{translate.text('bank_account')}</label>
					{bankAccounts.list.length > 0 ?
						<Field name='record' component='select' validate={[required]}>
							<option value='' />
							{bankAccounts.list.map((b: IBankAccount) =>
								<option key={b.bankAccountId} value={b.bankAccountId}> {banksfilter.list.filter((f) => f.bankId === b.bankId)[0].bankCode} – {b.branchNumber}{b.branchDigit !== undefined && b.branchDigit !== null && b.branchDigit !== '' ? '-' + b.branchDigit : ''} – {b.accountNumber}-{b.accountDigit}</option>
							)}
						</Field>
						:
						<div>
							<div className='noBankAccountsFormTag'><span>{translate.text('no_bank_accounts_found')}</span></div>
							<Link className='profileFormLabel verifyNow' to='/bankAccounts'><span>{translate.text('go_to_bank_accounts')}</span></Link>
							<Field name='record' component='input' type='hidden' value='' validate={[required]}></Field>
						</div>
					}
				</div>
				<div className='depositFormRow'>
					<label htmlFor='amount' className='depositFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='depositFormRow'>
					<label className='depositFormLabel'>{translate.text('deposit_receipt')}</label>
					<input type='file' name='deposit_receipt' required={true} id='deposit_receipt' accept='image/jpeg,image/png,application/pdf' />
					<label className='depositFormInfo'>{translate.text('deposit_receipt_msg')}</label>
				</div>
				<div className='depositSubmitButton'>
					<button type='submit' disabled={submitted} className='depositDisabledButton'>{translate.text('confirm')}</button>
					<span style={submitted ? {} : { display: 'none' }}>{translate.text('sending_do_not_leave')}</span>
				</div>
			</form>
		</div>
	)
}

BrlDepositForm = reduxForm({
	form: 'BrlDepositForm'
})(BrlDepositForm) as any

const selectorBrl = formValueSelector('BrlDepositForm')
BrlDepositForm = connect(
	(state) => {
		return {
			amount: selectorBrl(state, 'amount')
		}
	}
)(BrlDepositForm) as any

export class DepositMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { openReceiptModal: boolean, openConfirmModal: boolean, depositId: number, deposit: IDeposit, isLoading: boolean }> {

	// componentWillMount() {
	// 	if (!this.props.deposits.isLoaded) {
	// 	ws.send(ActionCreators.Server.Subscribe.Deposits())
	// 	}
	// }

	public componentDidMount() {
		// run after component is called.

	}

	public componentWillUnmount() {
		// run before component is unloaded.
		// ws.send(ActionCreators.Server.Unsubscribe.Deposits())
	}

	public render() {

		translate.language = this.props.lang

		const brlLogo = require('../images/coins/BRL.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const usdcLogo = require('../images/coins/USDC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')

		const pix = require('../images/qr-codes/qr-code-pix.png')

		const copy = require('../images/icons/copy.svg')
		const warning = require('../images/icons/warning.svg')

		const arrowDown = require('../images/icons/arrow-down-circle.svg')
		const checkCircle = require('../images/icons/check-circle.svg')

		const coin = this.props.current
		const currencies = this.props.currencies.list
		const wallets = this.props.wallets.list
		const deposits = this.props.deposits
		const isLoading = this.state && this.state.isLoading
		const openModal = (this.state && this.state.openReceiptModal !== undefined) ? this.state.openReceiptModal : false
		const openConfirmModal = (this.state && this.state.openConfirmModal !== undefined) ? this.state.openConfirmModal : false

		const getCurrency = (coin: string) => 
			Object.values(currencies).find(f => f.tag === coin)

		const bankAccounts = this.props.bankAccounts
		const banks = this.props.banks
		if (!isLoading) {
			if (!wallets[coin].fiat && !wallets[coin].address && getCurrency(coin)?.canDeposit) {
				ws.send(ActionCreators.Wallet.create(coin))
			}
			if (!deposits.isLoaded) {
				ws.send(ActionCreators.Deposit.getList())
			}

			if (!bankAccounts.isLoaded) {
				ws.send(ActionCreators.BankAccount.getList())
			}

			if (!banks.isLoaded) {
				ws.send(ActionCreators.Bank.getList())
			}
			this.setState({ isLoading: true })
		}

		let coinImg = btcLogo
		let coinName = 'Bitcoin'
		switch (coin) {

			case 'BRL':
				coinImg = brlLogo
				coinName = 'Real Brasileiro'
				break

			case 'LINK':
				coinImg = linkLogo
				coinName = 'Chainlink'
				break

			case 'BTC':
				coinImg = btcLogo
				coinName = 'Bitcoin'
				break

			case 'LTC':
				coinImg = ltcLogo
				coinName = 'Litecoin'
				break

			case 'ETH':
				coinImg = ethLogo
				coinName = 'Ethereum'
				break

			case 'EBIT':
				coinImg = ebitLogo
				coinName = 'Econobit'
				break
			
			case 'USDC':
				coinImg = usdcLogo
				coinName = 'USD Coin'
				break

			case 'DOGE':
				coinImg = dogeLogo
				coinName = 'Dogecoin'
				break

			default:
				break

		}

		const receiptModal = deposits.list.find(f => f.id === this.state?.depositId)?.receiptPic || ''
		const deposit = this.state && this.state.deposit

		const selectedBankAccount = (bankAccountId: number) => {
			const ba = bankAccounts.list.find((b: IBankAccount) => b.bankAccountId === bankAccountId)
			if (!ba) {
				return
			}
			return `${this.props.banks.list.filter((f) => f.bankId === ba.bankId)[0].bankCode} – ${ba.branchNumber}${ba.branchDigit !== undefined && ba.branchDigit !== null && ba.branchDigit !== '' ? '-' + ba.branchDigit : ''} – ${ba.accountNumber}-${ba.accountDigit}`
		}

		const pdfIcon = require('../images/icons/pdf.svg')

		return (
			<div className='depositMain' >
				<Modal
					open={openConfirmModal}
					onClose={this.onCloseConfirmModal}
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal',
					}}
					center={true}
				>
					<h2>{translate.text('confirm_your_deposit')}</h2>
					{
						(deposit)
							? <div className='modalForm'>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('coin')}</label>
									<div className='modalFormText'><img src={coinImg} className='walletsLogo' alt='' /><span>{coinName} ({deposit.coin})</span></div>
								</div>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('bank_account')}</label>
									<div className='modalFormText'><span>{selectedBankAccount(parseInt(deposit.record || ''))}</span></div>
								</div>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('amount')}</label>
									<div className='modalFormText'><span>{round(deposit.amount, 2).toFixed(2)} {deposit.coin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('deposit_receipt')}</label>
									<div className='modalFormThumb'><img src={deposit.receiptPic?.startsWith('data:image') ? deposit.receiptPic : pdfIcon} alt='deposit_receipt' /></div>
									<div className='modalFormText'><span>{!deposit.receiptPic?.startsWith('data:image') ? (document.getElementById('deposit_receipt') ? (document.getElementById('deposit_receipt') as HTMLInputElement).files![0].name : '') : ''}</span></div>
								</div>
							</div>
							: <div />
					}
					<div className='modalFormConfirm'>
						<button onClick={() => this.onBrlSubmit(deposit)}>{translate.text('confirm_deposit')}</button>
					</div>
				</Modal>
				<Modal
					open={openModal}
					onClose={this.onCloseReceiptModal}
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal',
					}}
					center={true}
				>
					<div className='modalForm'>
						<h2>{translate.text('deposit_receipt')}</h2>
						<div className='modalFormImg'>
							{receiptModal?.startsWith('data:image')
								?
								<img src={receiptModal} alt='deposit_receipt' />
								: receiptModal
									?
									<a href={receiptModal} download='receipt.pdf'>
										<img src={pdfIcon} alt='deposit_receipt' />
									</a>
									:
									<div />
							}
						</div>
						{/* <div className='modalFormText'><span>{translate.text('pdf_preview_not_available')}</span></div> */}
					</div>
				</Modal>
				<span className='pageTitle'>{translate.text('deposits')}</span>
				<Tabs>
					<TabList>
						<Tab><img className='iconsOrders' src={arrowDown} alt='' />{translate.text('deposit_funds')}</Tab>
						<Tab><img className='iconsOrders' src={checkCircle} alt='' />{translate.text('history')}</Tab>
					</TabList>
					<TabPanel>
						<div className='depositBox'>
							<div className='boxHeaderColumn3Left'>
								<div className='depositSelectCoin depositHeaderLabel'>
									<img src={coinImg} className='depositWithdrawLogo' alt={coin} />
									<span>{coin + ' - ' + coinName}</span>
								</div>
								<div className='depositCoinDropdown'><span className='coinSelectArrowDown' /></div>
								<div className='coinsDropdown'>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('BRL')}>
										<img src={brlLogo} className='depositWithdrawLogoList' alt='BRL' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('BTC')}>
										<img src={btcLogo} className='depositWithdrawLogoList' alt='BTC' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('ETH')}>
										<img src={ethLogo} className='depositWithdrawLogoList' alt='ETH' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('EBIT')}>
										<img src={ebitLogo} className='depositWithdrawLogoList' alt='EBIT' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('LTC')}>
										<img src={ltcLogo} className='depositWithdrawLogoList' alt='LTC' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('DOGE')}>
										<img src={dogeLogo} className='depositWithdrawLogoList' alt='DOGE' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('USDC')}>
										<img src={usdcLogo} className='depositWithdrawLogoList' alt='USDC' />
									</div>
									<div className='coinsDropdownItem' onClick={(e) => this.onSelectedCoin('LINK')}>
										<img src={linkLogo} className='depositWithdrawLogoList' alt='LINK' />
									</div>
								</div>
							</div>
							<div className='boxHeaderColumn3Middle'>
								<div className='depositHeaderLabel'><span>{coin}</span><span>{translate.text('available')}:</span><span className='depositHeaderBalance green'>{walletBalance(wallets[coin])}</span></div>
							</div>
							<div className='boxHeaderColumn3Right'>
								<div className='depositHeaderLabel'><span>{coin}</span><span>{translate.text('in_orders')}:</span><span className='depositHeaderBalance'>{walletInOrders(wallets[coin])}</span></div>
							</div>
							{coin === 'BRL' ?
								<>{ currencies[2].canDeposit ?
									<div className='depositBoxContent'>
										<BrlDepositForm onSubmit={(values: any) => this.onOpenConfirmModal(values)} bankAccounts={this.props.bankAccounts} banks={this.props.banks} fee={0} wallet={this.props.wallets.list.BRL} />
									</div>
									: <div>{translate.text('deposit_paused')}</div> }
								</> : ''}
							{coin === 'BTC' ?
								<>{ currencies[1].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_btc_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.BTC.address)} >{wallets.BTC.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.BTC.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'LTC' ?
								<>{ currencies[4].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_ltc_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.LTC.address)} >{wallets.LTC.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.LTC.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'ETH' ?
								<>{ currencies[3].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_eth_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.ETH.address)} >{wallets.ETH.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.ETH.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'EBIT' ?
								<>{ currencies[10].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_ebit_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.EBIT.address)} >{wallets.EBIT.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.EBIT.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'LINK' ?
								<>{ currencies[7].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_link_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.LINK.address)} >{wallets.LINK.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.LINK.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'USDC' ?
								<>{ currencies[8].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_usdc_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.USDC.address)} >{wallets.USDC.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.USDC.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'DOGE' ?
								<>{ currencies[9].canDeposit ?
									<div className='depositBoxContent centerText'>
										<div>{translate.text('your_doge_address')}:</div>
										<div className='walletAddress' onClick={(e) => this.copyAddress(wallets.DOGE.address)} >{wallets.DOGE.address} <img src={copy} className='copy' alt='copy' /></div>
										<div><img src={`https://chart.googleapis.com/chart?chs=262x262&cht=qr&chl=${wallets.DOGE.address}&choe=UTF-8`} alt='' /></div>
									</div>
									: <div className='depositPaused'>{translate.text('deposit_paused')}</div> }
									</> : ''}
								{coin === 'BRL' ?
								<div className='depositBankTop'>
									<div className='depositBankInfo centerText'>{translate.text('our_brl_bank_account')}:</div>
									<table className='depositBankAccount'>
										<tbody>
											<tr>
												<td>{translate.text('bank_code')}: </td>
												<td><b> 336 - BANCO C6 S.A.</b></td>
											</tr>
											<tr>
												<td>{translate.text('branch')}: </td>
												<td><b> 0001</b></td>
											</tr>
											<tr>
												<td>{translate.text('account')}: </td>
												<td><b> 19564354-2</b></td>
											</tr>
											<tr>
												<td>{translate.text('account')}: </td>
												<td><b> ECONOBIT SERVICOS ONLINE LTDA.</b></td>
											</tr>
											<tr>
												<td>CNPJ: </td>
												<td><b> 27.493.748/0001-20</b></td>
											</tr>
										</tbody>
									</table>
									<div className='depositBoxContent centerText'>
										<div>{translate.text('PIX QR-code')}:</div>
										<div><img src={pix} alt='pix' /></div>
									</div>
									<div className='depositInfoText'>
										<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
										<span><b>1.</b> {translate.text('brl_deposit_msg1')}</span><br /><br />
										<span><b>2.</b> {translate.text('brl_deposit_msg2')}</span><br /><br />
										<span><b>3.</b> {translate.text('brl_deposit_msg3')}</span>
									</div>
								</div>
								: ''}
							{coin === 'BTC' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('btc_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('btc_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('btc_deposit_msg3')}</span>
								</div>
								: ''}
							{coin === 'LTC' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('ltc_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('ltc_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('ltc_deposit_msg3')}</span>
								</div>
								: ''}
							{coin === 'ETH' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('eth_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('eth_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('eth_deposit_msg3')}</span>
								</div>
								: ''}
							{coin === 'EBIT' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('ebit_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('ebit_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('ebit_deposit_msg3')}</span>
								</div>
								: ''}
							{coin === 'LINK' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('link_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('link_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('link_deposit_msg3')}</span>
								</div>
								: ''}
							{coin === 'USDC' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('usdc_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('usdc_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('usdc_deposit_msg3')}</span>
								</div>
								: ''}
							{coin === 'DOGE' ?
								<div className='depositInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('doge_deposit_msg1')}</span><br /><br />
									<span><b>2.</b> {translate.text('doge_deposit_msg2')}</span><br /><br />
									<span><b>3.</b> {translate.text('doge_deposit_msg3')}</span>
								</div>
								: ''}
						</div>
					</TabPanel>
					<TabPanel>
						{/* <span className='pageBoxTitle'>Deposits History</span> */}
						<div>
							<table className='depositsTableMain'>
								<thead>
									<tr className='depositsTableHeaderRow'>
										<td className='depositsTableHeaderRowItem idColumn'>{translate.text('deposit_id')}</td>
										<td className='depositsTableHeaderRowItem dateColumn'>{translate.text('date')}</td>
										<td className='depositsTableHeaderRowItem'>{translate.text('coin')}</td>
										<td className='depositsTableHeaderRowItem'>{translate.text('amount')}</td>
										<td className='depositsTableHeaderRowItem'>{translate.text('status')}</td>
										<td className='depositsTableHeaderRowItem alignColumn'>{translate.text('record')}</td>
									</tr>
								</thead>
								<tbody>
									{deposits.list.length > 0 ?
										deposits.list.map((d) =>
											<tr key={d.id} className='depositsTableBodyRow'>
												<td className='depositsTableBodyRowItem'>{pad(base32.encode(d.id), 12)}</td>
												<td className='depositsTableBodyRowItem'>{new Date(d.date).toLocaleString('en-GB')}</td>
												<td className='depositsTableBodyRowItem'>{d.coin}</td>
												<td className='depositsTableBodyRowItem'>{round(d.amount, wallets[d.coin].fiat ? 2 : 8).toFixed(wallets[d.coin].fiat ? 2 : 8)}</td>
												<td className={'depositsTableBodyRowItem ' + ((d.status === 'Confirmed') ? 'green' : 'red')}>{translate.text(d.status.toLowerCase())}</td>
												<td className='depositsTableBodyRowItem alignColumn'>
													{wallets[d.coin].fiat
														?
														<button className='walletsTableButton' onClick={() => this.onOpenReceiptModal(d.id)}>{translate.text('receipt')}</button>
														:
														d.record === 'internal'
															?
															<span className='walletsTableButton internal'>{translate.text('internal')}</span>
															:
															<a className='walletsTableButton' href={`${this.blockExplorer(d.coin)}${d.record}`} target='_blank' rel='noopener noreferrer'>{translate.text('link')}</a>
													}
												</td>
											</tr>
										)
										:
										<tr><td colSpan={6} className='emptyTable'>{translate.text('no_results_found')}</td></tr>
									}
								</tbody>
							</table>
						</div>
					</TabPanel>
				</Tabs >
			</div>
		)
	}

	public blockExplorer(coin: string) {
		switch (coin) {
			case 'BTC':
				return `https://blockstream.info${config.environment === 'LIVE' ? '' : '/testnet'}/tx/`
			case 'LTC':
				return `https://chain.so/tx/${config.environment === 'LIVE' ? 'LTC' : 'LTCTEST'}/`
			case 'DOGE':
				return `https://chain.so/tx/${config.environment === 'LIVE' ? 'DOGE' : 'DOGETEST'}/`
			case 'EBIT':
				return `https://explorer${config.environment === 'LIVE' ? '' : '-test'}.econobit.io/tx/`
			default:
				return `https://${config.environment === 'LIVE' ? '' : 'goerli.'}etherscan.io/tx/`
		}
	}

	public onSelectedCoin(coin: string) {
		this.props.walletChange(coin)

	}

	public async onBrlSubmit(deposit: IDeposit) {
		try {
			ws.send(ActionCreators.Deposit.insert(deposit))
			store.dispatch(reset('BrlDepositForm'))
			this.setState({ openConfirmModal: false })
		} catch (err) {
			console.error(err)
		}
	}

	onOpenReceiptModal = (depositId: number) => {
		const deposit = this.props.deposits.list.find(f => f.id === depositId)
		console.log({ deposit })
		if (!deposit) {
			return
		}
		if (!deposit.receiptPic) {
			ws.send(ActionCreators.Deposit.getImg(depositId))
		}
		this.setState({ openReceiptModal: true, depositId })
	}

	onCloseReceiptModal = () => {
		console.log('onCloseModal')
		this.setState({ openReceiptModal: false })
	}

	onOpenConfirmModal = async (deposit: IDeposit) => {
		console.log('onOpenConfirmModal')
		deposit.coin = 'BRL'
		try {
			const maxSize = 5242880
			const depositReceiptPic = document.getElementById('deposit_receipt') as HTMLInputElement
			if (depositReceiptPic.files && !['jpeg', 'png', 'pdf'].includes(depositReceiptPic.files[0].type.split('/')[1])) {
				toast.error('file_type_unsupported')
				return
			}
			if (depositReceiptPic.files && depositReceiptPic.files[0].size > maxSize) {
				toast.error('file_too_big')
				return
			}
			deposit.receiptPic = depositReceiptPic.files ? await this.readFile(depositReceiptPic.files[0]) : ''
		} catch (err) {
			console.error(err)
			return
		}
		this.setState({ openConfirmModal: true, deposit })
	}

	onCloseConfirmModal = () => {
		console.log('onCloseConfirmModal')
		this.setState({ openConfirmModal: false })
	}

	public copyAddress(address: string | undefined) {
		if (!address) {
			return
		}

		const fallbackCopyTextToClipboard = (text: string) => {
			const textArea = document.createElement('textarea')
			textArea.value = text
			document.body.appendChild(textArea)
			textArea.focus()
			textArea.select()
			try {
				// var successful =
				document.execCommand('copy')

				// var msg = successful ? 'successful' : 'unsuccessful'
				// console.log('Fallback: Copying text command was ' + msg)
				toast.info(translate.text('address_copied'))
			} catch (err) {
				// toast.info('Address copy failed')
				// console.error('Fallback: Oops, unable to copy', err)
			}
			document.body.removeChild(textArea)
		}

		if (!(navigator as any).clipboard) {
			fallbackCopyTextToClipboard(address)
			return
		}
		(navigator as any).clipboard.writeText(address).then(() => {
			toast.info(translate.text('address_copied'))
		})
	}
	public async readFile(file: Blob) {
		// console.log(file.type)
		return new Promise<string>((resolve, reject) => {
			const fr = new FileReader()
			fr.readAsDataURL(file)
			fr.onloadend = () => {
				try {
					if (!file.type.startsWith('image')) {
						resolve(fr.result as string)
					}
					resolve(resizeImg(fr.result as string))
				} catch (err) {
					reject(err)
				}
			}
			fr.onerror = (error) => reject(error)
		})
	}

}