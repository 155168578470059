import { IConnection } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { store } from '..'
import { Cookies } from '../functions/cookies'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	connection: IConnection
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class FooterComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		const cookieColor = Cookies.get('color')
		if (cookieColor) {
			store.dispatch({ type: 'Style.Change', style: cookieColor })
		}
		const cookieLang = Cookies.get('lang')
		if (cookieLang) {
			store.dispatch({ type: 'Lang.Change', lang: cookieLang })
		}
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const pallete = require('../images/icons/pallete.svg')
		const globe = require('../images/icons/globe.svg')
		const isConnected = this.props.connection.status ? 'Connected' : 'Disconnected'
		const latency = this.props.connection.latency
		const lang = this.props.lang
		translate.language = lang

		const ptBr = require('../images/flags/pt-br.svg')
		const enUs = require('../images/flags/en-us.svg')
		const esEs = require('../images/flags/es-es.svg')

		const colorDefault = require('../images/colors/default.svg')
		const colorDark = require('../images/colors/dark.svg')
		const colorLight = require('../images/colors/light.svg')
		const colorCVD = require('../images/colors/cvd.svg')

		return (
			<div className='footer'>
				<span className={`systemStatusIcon${isConnected} ${(isConnected !== 'Connected') ? 'blink' : ''}`}>⦿</span><span className='systemStatusText'> {isConnected === 'Connected' ? translate.text('connected') + ' - ' + translate.text('latency') + ': ' + (latency ? `${latency}ms` : '0ms') : translate.text('disconnected')}</span>
				{/* <div className='langTheme' onClick={e => this.onLangClick()}> */}
				<div className='langTheme' >
					<img src={globe} className='colorThemeIcon' alt='' />
					<div className='langSelect' id='langMenu'>
						<div className='colorSelectTitle'>
							<span>{translate.text('language')}</span>
						</div>
						<div className='colorSelectItem'>
							<label><img src={enUs} className='colorItemIcon' alt='' /><input type='radio' name='lang' value='en-us' onChange={this.changeLang} /> English</label>
						</div>
						<div className='colorSelectItem'>
							<label><img src={esEs} className='colorItemIcon' alt='' /><input type='radio' name='lang' value='es-es' onChange={this.changeLang} /> Español</label>
						</div>
						<div className='colorSelectItem'>
							<label><img src={ptBr} className='colorItemIcon' alt='' /><input type='radio' name='lang' value='pt-br' onChange={this.changeLang} /> Português</label>
						</div>
					</div>
				</div>
				{/* <div className='colorTheme' onClick={e => this.onLangClick()}> */}
				<div className='colorTheme'>
					<img src={pallete} className='colorThemeIcon' alt='' />
					<div className='colorSelect' id='colorMenu'>
						<div className='colorSelectTitle'>
							<span>{translate.text('theme')}</span>
						</div>
						<div className='colorSelectItem'>
							<label><img src={colorDefault} className='colorItemIcon' alt='' /><input type='radio' name='color' value='default' onChange={this.changeStyle} /> {translate.text('default')}</label>
						</div>
						<div className='colorSelectItem'>
							<label><img src={colorDark} className='colorItemIcon' alt='' /><input type='radio' name='color' value='dark' onChange={this.changeStyle} /> {translate.text('dark')}</label>
						</div>
						<div className='colorSelectItem'>
							<label><img src={colorLight} className='colorItemIcon' alt='' /><input type='radio' name='color' value='light' onChange={this.changeStyle} /> {translate.text('light')}</label>
						</div>
						<div className='colorSelectItem'>
							<label><img src={colorCVD} className='colorItemIcon' alt='' /><input type='radio' name='color' value='cvd' onChange={this.changeStyle} /> {translate.text('cvd')}</label>
						</div>
					</div>
				</div>
			</div>
		)
	}

	changeStyle(event: React.ChangeEvent<HTMLInputElement>) {
		store.dispatch({ type: 'Style.Change', style: event.target.value })
		Cookies.set('color', event.target.value, 365)
	}
	changeLang(event: React.ChangeEvent<HTMLInputElement>) {
		store.dispatch({ type: 'Lang.Change', lang: event.target.value })
		Cookies.set('lang', event.target.value, 365)
	}

}
