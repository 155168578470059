import { Candlestick, ICandlestick, ICandlesticks, IMarket } from 'exchange-common'
import * as React from 'react'
import { Chart } from 'react-google-charts'
import { RouteComponentProps } from 'react-router'
import { Utils } from '../functions/utils'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	market: IMarket
	candlesticks: ICandlesticks
	style: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class CandlestickComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	private interval: any
	componentDidMount() {
		// run after component is called.
		const period = Utils.timeSpan(this.props.candlesticks.period)
		const lastPeriod = new Date(Math.floor(new Date().getTime() / period) * period)
		const nextPeriod = new Date(lastPeriod.getTime() + period)
		const timeSpan = nextPeriod.getTime() - new Date().getTime()

		this.interval = setInterval(() => this.forceUpdate(), timeSpan > 0 ? timeSpan : 0)
	}

	componentWillUnmount() {
		// run before component is unloaded.
		clearInterval(this.interval)
	}

	render() {
		const { candlesticks, market } = this.props
		const period = Utils.timeSpan(candlesticks.period)

		if (!candlesticks.list[market.symbol] || candlesticks.list[market.symbol].length === 0) {
			return <div className='candlestick' />
		}

		const candles = candlesticks.list[market.symbol]
			.map(m => {
				const periodDate = new Date(Math.floor(new Date(m.date).getTime() / period) * period)
				return new Candlestick({
					...m,
					date: periodDate
				})
			})

		const lastPeriod = new Date(Math.floor(new Date().getTime() / period) * period)

		const newCandles: ICandlestick[] = []

		for (let i = 0; i < 80; i++) {
			const periodDate = new Date(lastPeriod.getTime() - period * i)
			const periodCandles = candles.filter((f) => f.date.getTime() === periodDate.getTime())
			const lastCandles = candles.filter((f) => f.date.getTime() < periodDate.getTime())
			const lastClose = lastCandles.length > 0 ? lastCandles[lastCandles.length - 1].close : 0
			const emptyCandle = {
				volume: 0,
				open: lastClose,
				close: lastClose,
				high: lastClose,
				low: lastClose,
				date: periodDate
			}

			let nextCandle = periodCandles.shift()
			const candlestick = {
				...emptyCandle,
				...nextCandle,
			}
			nextCandle = periodCandles.shift()
			while (nextCandle) {
				candlestick.high = Math.max(candlestick.high, nextCandle.high)
				candlestick.low = Math.min(candlestick.low, nextCandle.low)
				candlestick.close = nextCandle.close
				nextCandle = periodCandles.shift()
			}

			if (candlestick.open) {
				newCandles.push(candlestick)
			}
		}

		const tooltip = (c: ICandlestick) =>
			`<div class='candleTooltip'>
				<div><b>${c.date.toLocaleString('en-gb', { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</b></div>
				<table>
					<tr>
						<td class='gray8'>O: </td>
						<td class='gray8'>${c.open.toFixed(market.priceDecimals)}</td>
					</tr>
					<tr>
						<td class='gray8'>H: </td>
						<td class='gray8'>${c.high.toFixed(market.priceDecimals)}</td>
					</tr>
					<tr>
						<td class='gray8'>L: </td>
						<td class='gray8'>${c.low.toFixed(market.priceDecimals)}</td>
					</tr>
					<tr>
						<td class='gray8'>C: </td>
						<td class='gray8'>${c.close.toFixed(market.priceDecimals)}</td>
					</tr>
					<tr>
						<td class='gray8'>V: </td>
						<td class='gray8'>${c.volume.toFixed(2)}</td>
					</tr>
				</table>
			</div>`

		const data = newCandles.map((m) => [
			m.date,
			Utils.round(m.low, market.priceDecimals),
			Utils.round(m.open, market.priceDecimals),
			Utils.round(m.close, market.priceDecimals),
			Utils.round(m.high, market.priceDecimals),
			tooltip(m),
			Utils.floor(m.volume, 2),
			tooltip(m),
		])



		const vAxes = [
			{
				minValue: newCandles.reduce((p, c) => Math.min(p, c.low), Number.MAX_SAFE_INTEGER) * 0.9,
				maxValue: newCandles.reduce((p, c) => Math.max(p, c.high), 0) * 1.1
			},
			{
				minValue: 0,
				maxValue: newCandles.reduce((p, c) => Math.max(p, c.volume), 0) * 3,
				textPosition: 'none',
			}]

		if (data.length === 0) {
			return <div />
		}
		// console.log({ data })

		const { style } = this.props
		var green = ''
		var red = ''
		var grey = ''
		var volGrey = ''
		if (style === 'dark') {
			green = '#2eae34'
			red = '#f94f2d'
			grey = '#222'
			volGrey = '#333'
		} else if (style === 'light') {
			green = '#2eae34'
			red = '#f94f2d'
			grey = '#eee'
			volGrey = '#ccc'
		} else if (style === 'cvd') {
			green = '#1f8df9'
			red = '#ec8d42'
			grey = '#222'
			volGrey = '#333'
		} else {
			green = '#2eae34'
			red = '#f94f2d'
			grey = '#222'
			volGrey = '#333'
		}

		return (
			<Chart
				chartType='ComboChart'
				data={[['Date', 'low', 'open', 'close', 'high', { role: 'tooltip', type: 'string', p: { html: true } }, 'vol', { role: 'tooltip', type: 'string', p: { html: true } }], ...data]}
				width='100%'
				height='100%'
				chartEvents={[
					{
						eventName: 'ready',
						callback: () => {
							// [...document.getElementsByTagName('rect') as any].filter(f => ['#2eae34', '#f94f2d'].includes(f.getAttribute('fill'))).forEach(el => {
							// 	if (el && el.previousSibling) {
							// 		el.previousSibling.setAttribute('fill', el.getAttribute('fill'))
							// 	}
							// })
						}
					},
					{
						eventName: 'hover' as any,
						callback: () => {
							console.warn('ALAAAAA')
						}
					},

				]}
				options={{
					legend: 'none',
					backgroundColor: 'transparent',
					bar: {
						groupWidth: '80%',
					}, // Remove space between bars.
					candlestick: {
						fallingColor: {
							fill: red, // red
							stroke: red, // red
							strokeWidth: 2,
						},
						risingColor: {
							fill: green, // or #eee for light theme or #121212 for dark theme
							stroke: green, // green
							strokeWidth: 2,
						},
						hollowIsRising: true,
					},
					bars: {
					},
					chartArea: {
						left: 0,
						right: 0,
						top: 5,
						bottom: 30,
						width: '100%',
						height: '100%'
					},
					fontName: 'Roboto',
					fontSize: 11,
					seriesType: 'candlesticks',
					series: {
						0: { color: '#888', targetAxisIndex: 0 }, // candle HL only one color for both up/down, open issue #1014 on github
						1: { type: 'bars', color: volGrey, targetAxisIndex: 1 }
					},
					vAxes,
					hAxis: {
						minorGridlines: {
							color: grey, // Grid lines color: #ccc for light theme, or #222 for dark and default
							count: 6
						},
						gridlines: {
							color: grey, // Grid lines color: #ccc for light theme, or #222 for dark and default
							count: 6
						},
						format: 'HH:mm'
					},
					vAxis: {
						textStyle: {
							color: '#888',
						},
						minorGridlines: {
							color: grey, // Grid lines color: #ccc for light theme, or #222 for dark and default
							count: 4
						},
						gridlines: {
							color: grey, // Grid lines color: #ccc for light theme, or #222 for dark and default
							count: 4
						},
						textPosition: 'in'
					},
					tooltip: {
						isHtml: true
					}

				}}
			/>
		)
	}
}