import { IMarkets, IOrder, IOrderbook, IOrderChange } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { formatAmount } from '../functions/formatAmount'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	orderbook: IOrderbook
	market: string
	markets: IMarkets
	changeOrder: (order: Partial<IOrder>) => IOrderChange
	lang: string
}

interface IMatchProps {
	// include any url parameters here.
	market: string
}

export class OrderbookComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.
	}

	render() {
		let asksum = 0, bidsum = 0
		const orderbook = this.props.orderbook
		const changeOrder = this.props.changeOrder
		// const market = this.props.market
		if (!orderbook) {
			return <div />
		}
		// const otherCoin = this.props.market.split('-')[0]
		const baseCoin = this.props.market.split('-')[1]
		const priceDecimals = this.props.markets.list[this.props.market].priceDecimals
		const amountDecimals = this.props.markets.list[this.props.market].amountDecimals

		const bids = orderbook.bids.reduce(
			(res: IOrder[], cur: IOrder) => {
				let order = res.find(f => f.price.toFixed(priceDecimals) === cur.price.toFixed(priceDecimals))
				if (order) {
					order.amount += cur.amount
					order.filled += cur.filled
				} else {
					res.push({ ...cur })
				}
				return res
			},
			[] as IOrder[])

		const asks = orderbook.asks.reduce(
			(res: IOrder[], cur: IOrder) => {
				let order = res.find(f => f.price.toFixed(priceDecimals) === cur.price.toFixed(priceDecimals))
				if (order) {
					order.amount += cur.amount
					order.filled += cur.filled
				} else {
					res.push({ ...cur })
				}
				return res
			},
			[] as IOrder[])

		let spread = (!asks[0] || !bids[0]) ? 0 : asks[0].price - bids[0].price
		let topBid = (!asks[0] || !bids[0]) ? 0 : bids[0].price
		let spreadPct = (spread / topBid * 100) || 0
		translate.language = this.props.lang

		return (
			<div className='orderbook'>
				<div className='boxTitle'><span>{translate.text('order_book')}{/*  ({market})*/}</span></div>
				<div className='orderbookHeader'>
					<div className='orderbookHeaderLabel'>{translate.text('market_size')}</div>
					<div className='orderbookHeaderPriceLabel'>{translate.text('price')} ({baseCoin})</div>
					<div className='orderbookHeaderLabel'>{translate.text('total')}</div>
				</div>
				<div className='orderbookMain'>
					<div className='orderbookAsks'>
						{asks.map((e, i) => {
							asksum += e.amount - e.filled
							return (
								<CSSTransition
									classNames='animateBook'
									timeout={500}
									enter={false}
									exit={false}
									appear={true}
									key={e.price.toFixed(priceDecimals)}
									component='div'
								>
									<div key={e.price.toFixed(priceDecimals)} className='orderbookRow borderRowDown'>
										<div className='orderbookRowSize white' onClick={() => { changeOrder({ side: 'buy', amount: Utils.round(e.amount - e.filled, amountDecimals) }) }}>{formatAmount(e.amount - e.filled, amountDecimals)}</div>
										<div className='orderbookRowPrice red' onClick={() => { changeOrder({ side: 'buy', price: Utils.round(e.price, priceDecimals) }) }} >{e.price.toFixed(priceDecimals)}</div>
										<div className='orderbookRowTotal gray8' onClick={(event) => { changeOrder({ side: 'buy', amount: Utils.round(event.currentTarget.innerText, amountDecimals) }) }} >{(asksum).toFixed(amountDecimals)}</div>
									</div>
								</CSSTransition>
							)
						}
						)}
					</div>
					<div className='orderbookSpread'>
						<div className='orderbookSpreadRow'>
							<div className='orderbookSpreadRowTitle gray8'>{baseCoin} Spread</div>
							<div className='orderbookSpreadRowValue white'>{spread.toFixed(priceDecimals)}</div>
							<div className='orderbookSpreadRowValue gray8'>{spreadPct.toFixed(2)}%</div>
						</div>
					</div>
					<div className='orderbookBids'>
						{bids.map((e, i) => {
							bidsum += e.amount - e.filled
							return (
								<CSSTransition
									classNames='animateBook'
									timeout={500}
									enter={false}
									exit={false}
									appear={true}
									key={e.price.toFixed(priceDecimals)}
									component='div'
								>
									<div key={e.price.toFixed(priceDecimals)} className='orderbookRow borderRowUp'>
										<div className='orderbookRowSize white' onClick={() => { changeOrder({ side: 'sell', amount: Utils.round(e.amount - e.filled, amountDecimals) }) }}>{formatAmount(e.amount - e.filled, amountDecimals)}</div>
										<div className='orderbookRowPrice green' onClick={() => { changeOrder({ side: 'sell', price: Utils.round(e.price, priceDecimals) }) }} >{e.price.toFixed(priceDecimals)}</div>
										<div className='orderbookRowTotal gray8' onClick={(event) => { changeOrder({ side: 'sell', amount: Utils.round(event.currentTarget.innerText, amountDecimals) }) }} >{(bidsum).toFixed(amountDecimals)}</div>
									</div>
								</CSSTransition>
							)
						}
						)}
					</div>
				</div>
				{/* <div className='orderbookFooter'> footer</div> */}
			</div>
		)
	}

}