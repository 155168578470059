import { ActionCreators, IBank, IBankAccount, IBankAccountDelete, IBankAccountInsert, IBankAccounts, IBanks, IUser } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Field, reduxForm, reset } from 'redux-form'
import { store } from '..'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { maxLength1, maxLength10, renderField, required } from './renderField'

interface IProps {
	// include component properties here.
	user: IUser
	bankAccounts: IBankAccounts
	banks: IBanks
	lang: string

}

interface IMatchProps {
	// include any url parameters here.

}

let BankAccountsForm = (props: any) => {
	const { handleSubmit, banks } = props

	return (
		<form className='bankAccountsForm' onSubmit={handleSubmit}>
			<div className='bankNameFormBox' >
				<label htmlFor='bankName' className='bankAccountsFormLabel'>{translate.text('bank_code')}</label>
				<Field name='bankId' component='select'>
					<option value='' />
					{banks.sort((a: any, b: any) => a.bankCode - b.bankCode).map((b: IBank) =>
						<option key={b.bankId} value={b.bankId}>{b.bankCode} - {b.bankName}</option>
					)}
				</Field>
			</div>
			<div className='bankAccountsFormBox' >
				<label htmlFor='branchNumber' className='bankAccountsFormLabel'>{translate.text('branch_number')}</label>
				<Field name='branchNumber' component={renderField} type='text' validate={[required, maxLength10]} />
			</div>
			<div className='bankAccountsFormBox' >
				<label htmlFor='branchDigit' className='bankAccountsFormLabel'>{translate.text('branch_digit')}</label>
				<Field name='branchDigit' component={renderField} type='text' validate={[maxLength1]} />
			</div>
			<div className='bankAccountsFormBox' >
				<label htmlFor='accountNumber' className='bankAccountsFormLabel'>{translate.text('account_number')}</label>
				<Field name='accountNumber' component={renderField} type='text' validate={[required, maxLength10]} />
			</div >
			<div className='bankAccountsFormBox' >
				<label htmlFor='accountDigit' className='bankAccountsFormLabel'>{translate.text('account_digit')}</label>
				<Field name='accountDigit' component={renderField} type='text' validate={[required, maxLength1]} />
			</div >
			<button type='submit'>{translate.text('add')}</button>
		</form>
	)
}

BankAccountsForm = reduxForm({
	form: 'bankAccountsForm'
})(BankAccountsForm) as any

export class BankAccountsMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		const banks = this.props.banks
		if (!banks.isLoaded) {
			ws.send(ActionCreators.Bank.getList())
		}

		const bankAccounts = this.props.bankAccounts
		if (!bankAccounts.isLoaded) {
			ws.send(ActionCreators.BankAccount.getList())
		}

		translate.language = this.props.lang

		return (
			<div className='bankAccountsMain'>
				<span className='pageTitle'>{translate.text('bank_accounts')}</span>
				<span className='pageBoxTitle'>{translate.text('add_new_bank_account')}</span>
				<div>
					<BankAccountsForm onSubmit={(values: any) => this.onSubmit((values))} banks={banks.list} />
				</div>
				<div className='bankAccountsInfoText'>
					<span>{translate.text('withdraw_3rd_start')}<span className='red'><b>{translate.text('prohibited')}</b></span>{translate.text('withdraw_3rd_end')}</span>
				</div>
				<span className='pageBoxTitle'>{translate.text('my_bank_accounts')}</span>
				<div>
					<table className='BankTableMain'>
						<thead>
							<tr className='BankTableHeaderRow'>
								<td className='BankTableHeaderRowItem bankCodeColumn'>{translate.text('bank_code')}</td>
								<td className='BankTableHeaderRowItem'>{translate.text('branch_number')}</td>
								<td className='BankTableHeaderRowItem'>{translate.text('branch_digit')}</td>
								<td className='BankTableHeaderRowItem'>{translate.text('account_number')}</td>
								<td className='BankTableHeaderRowItem'>{translate.text('account_digit')}</td>
								<td className='BankTableHeaderRowItem' />
							</tr>
						</thead>
						<tbody>
							{bankAccounts.list.length > 0 ?
								bankAccounts.list.map(b =>
									<tr key={b.bankAccountId} className='BankTableBodyRow'>
										<td className='BankTableBodyRowItem'>{banks.list.filter((f) => f.bankId === b.bankId)[0].bankCode || ''}</td>
										<td className='BankTableBodyRowItem'>{b.branchNumber}</td>
										<td className='BankTableBodyRowItem'>{b.branchDigit}</td>
										<td className='BankTableBodyRowItem'>{b.accountNumber}</td>
										<td className='BankTableBodyRowItem'>{b.accountDigit}</td>
										<td className='BankTableBodyRowItem'><button className='BankTableRemoveButton' onClick={e => this.onDelete(b.bankAccountId)}>{translate.text('X')}</button></td>
									</tr>
								)
								:
								<tr><td colSpan={6} className='emptyTable'>{translate.text('no_results_found')}</td></tr>
							}
						</tbody>
					</table>
				</div>
			</div>

		)
	}

	onSubmit(bankAccount: IBankAccount) {
		const action: IBankAccountInsert = ActionCreators.BankAccount.insert(bankAccount)
		ws.send(action)
		store.dispatch(reset('bankAccountsForm'))
		// ws.send(ActionCreators.Server.BankAccount.GetList())
	}

	onDelete(bankAccountId: number) {
		const action: IBankAccountDelete = ActionCreators.BankAccount.delete(bankAccountId)
		ws.send(action)
		// ws.send(ActionCreators.Server.BankAccount.GetList())
	}

}