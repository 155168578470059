import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { MobileHomeComponent } from '../components/mobileHome'
import { INews } from '../models/news'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		lang: state.lang,
		news: state.news,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {
		loadNews: (news: INews[]) => { dispatch({ type: 'News.Load', news } as any) }
	}
}

export const MobileHomeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHomeComponent))