import { IUserAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { SecurityMainComponent } from '../components/securityMain'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		user: state.user,
		lang: state.lang,
		loginHistory: state.loginHistory,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IUserAction>) {
	return {
	}
}

export const SecurityMainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityMainComponent))