import { ActionTypes, IKyc, IKycAction, Kyc } from 'exchange-common'

export const kyc = (state: IKyc = new Kyc(), action: IKycAction): IKyc => {
	switch (action.type) {
		case ActionTypes.Kyc.Load:
			return {
				...state,
				...action.kyc
			}
		case ActionTypes.Kyc.Unload:
			return new Kyc()
		default:
			return state
	}
}