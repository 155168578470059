import * as React from 'react'
import '../css/theme_light.css'
import { App } from '../App'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import config from '../config.json'

interface IProps {
	// include component properties here.
}

interface IMatchProps {
	// include any url parameters here.
}
export class ThemeLightComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	render() {
		return (
			<div className={`light${config.environment === 'LIVE' ? '' : ' test'}`}><App /></div>
		)
	}
}
