import { Markets } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	market: Markets
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class HeaderMenuComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		return (
			<div className='headerMenu'>
				<NavLink activeClassName='headerMenuSelected' to={'/exchange/' + this.props.market.current}>{translate.text('exchange')}</NavLink>
			</div>
		)
	}

}