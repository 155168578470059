import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { TickerContainer } from '../containers/ticker'
import { IState } from '../models/state'
import { MarketsComponent } from './markets'

interface IProps {
	// include component properties here.

}

interface IMatchProps {
	// include any url parameters here.

}

export class TradeHeaderComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		return (
			<div className='tradeHeader'>
				<MarketsComponent />
				<TickerContainer />
			</div>
		)
	}

}