import { ActionTypes, ITickerAction, ITickers, Tickers } from 'exchange-common'

export const tickers =
	(state: ITickers = new Tickers(), action: ITickerAction): ITickers => {
		switch (action.type) {
			case ActionTypes.Ticker.Load:
				// const newState = {
				// 	...state,
				// }
				// const tickers = action.tickers.list
				// Object.keys(tickers).forEach(symbol => {
				// 	if (newState.list[symbol]) {
				// 		newState.list[symbol] = {
				// 			...tickers[symbol]
				// 		}
				// 	}
				// })
				// return newState
				return {
					...state,
					...action.tickers
				}

			case ActionTypes.Ticker.Update:
				return {
					...state,
					list: {
						...state.list,
						[action.ticker.symbol]: {
							...state.list[action.ticker.symbol],
							...action.ticker,
						}
					}
				}

			default:
				return state
		}
	}