import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { FeesComponent } from '../components/fees'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		lang: state.lang,
		tickers: state.tickers,
		currencies: state.currencies,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {

	}
}

export const FeesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FeesComponent))