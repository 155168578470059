import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { OrdersMainComponent } from '../components/ordersMain'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		openOrders: state.openOrders,
		ordersHistory: state.ordersHistory,
		markets: state.markets,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {

	}
}

export const OrdersMainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersMainComponent))