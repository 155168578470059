import { IMarket, IOrder, IOrderbook } from 'exchange-common'
import * as React from 'react'
import { Chart } from 'react-google-charts'
import { RouteComponentProps } from 'react-router'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { IState } from '../models/state'

interface IProps {
	market: IMarket
	orderbook: IOrderbook
	lang: string
	style: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class DepthComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const { orderbook, market } = this.props
		if (!orderbook || orderbook.bids.length === 0 || orderbook.asks.length === 0) {
			return <div className='depth' />
		}

		translate.language = this.props.lang

		const decimals = market.priceDecimals

		let asksum = 0
		let bidsum = 0

		type orderLine = IOrder & { sum: number }

		let bids = orderbook.bids.reduce(
			(res: orderLine[], cur: IOrder) => {
				let order = res.find(f => f.price.toFixed(decimals) === cur.price.toFixed(decimals))
				bidsum += cur.amount - cur.filled
				if (order) {
					order.amount += cur.amount
					order.filled += cur.filled
					order.sum = bidsum
				} else {
					res.push({ ...cur, sum: bidsum })
				}
				return res
			},
			[]).map<[number, number, null]>(m => {
				return [m.price, m.sum, null]
			})

		let asks = orderbook.asks.reduce(
			(res: orderLine[], cur: IOrder) => {
				let order = res.find(f => f.price.toFixed(decimals) === cur.price.toFixed(decimals))
				asksum += cur.amount - cur.filled
				if (order) {
					order.amount += cur.amount
					order.filled += cur.filled
					order.sum = asksum
				} else {
					res.push({ ...cur, sum: asksum })
				}
				return res
			},
			[]).map<[number, null, number]>(m => {
				return [m.price, null, m.sum]
			})

		bids.reverse()
		const delta = {
			bids: Utils.round(bids[bids.length - 1][0] - bids[0][0], decimals),
			asks: Utils.round(asks[asks.length - 1][0] - asks[0][0], decimals),
			mid: Utils.round((bids[bids.length - 1][0] + asks[0][0]) / 2, decimals),
			get: () => Utils.round(Math.min(delta.bids, delta.asks) * 1.1, decimals),
			min: () => Utils.round(bids[bids.length - 1][0] - delta.get(), decimals),
			max: () => Utils.round(asks[0][0] + delta.get(), decimals),
		}

		const tooltipBid = (price: number, amount: number) =>
			`<div class='depthTooltipBid'>
				<div><b style='font-size: 15px;'>${price.toFixed(decimals)}</b></div>
				<table>
					<tr>
						<td><b class='red'>${translate.text('sell')}</b></td>
						<td class='gray8'> ${amount.toFixed(market.amountDecimals)}</td>
					</tr>
					<tr>
						<td><b class='red'>${translate.text('cost')}</b></td>
						<td class='gray8'>${(price * amount).toFixed(decimals)}</td>
					</tr>
				</table>
			</div>`
		const tooltipAsk = (price: number, amount: number) =>
			`<div class='depthTooltipAsk'>
				<div><b style='font-size: 15px;'>${price.toFixed(decimals)}</b></div>
				<table>
					<tr>
						<td><b class='green'>${translate.text('buy')}</b></td>
						<td class='gray8'> ${amount.toFixed(market.amountDecimals)}</td>
					</tr>
					<tr>
						<td><b class='green'>${translate.text('cost')}</b></td>
						<td class='gray8'>${(price * amount).toFixed(decimals)}</td>
					</tr>
				</table>
			</div>`

			bids = bids.filter(f => f[0] >= delta.min())
			asks = asks.filter(f => f[0] <= delta.max())

		const data: Array<any[]> = [
			[delta.min(), Utils.round(bids[0][1], market.amountDecimals), tooltipBid(delta.min(), bids[0][1]), null, ''],
			...bids.map(m => ([m[0], Utils.round(m[1], market.amountDecimals), tooltipBid(m[0], m[1]), null, ''])),
			[delta.mid, 0, tooltipBid(delta.mid, 0), null, ''],
			[asks[0][0], null, '', 0, tooltipAsk(delta.mid, 0)],
			...asks.map((m, i, a) => ([a[i+1] ? a[i+1][0] : delta.max(), null, '', m[2],tooltipAsk(m[0], m[2])]))
		]

		// console.log({ bids, asks, data, delta })

		const { style } = this.props
		var green = ''
		var red = ''
		if (style === 'dark') {
			green = '#2eae34'
			red = '#f94f2d'
		} else if (style === 'light') {
			green = '#2eae34'
			red = '#f94f2d'
		} else if (style === 'cvd') {
			green = '#1f8df9'
			red = '#ec8d42'
		} else {
			green = '#2eae34'
			red = '#f94f2d'
		}

		return (
			<Chart
				chartType='SteppedAreaChart'
				data={[['Price', 'Bids', { role: 'tooltip', type: 'string', p: { html: true } }, 'Asks', { role: 'tooltip', type: 'string', p: { html: true } }], ...data]}
				width='100%'
				height='180px'
				options={{
					legend: 'none',
					backgroundColor: 'transparent',
					chartArea: {
						left: 0,
						right: 0,
						top: 5,
						bottom: 30,
						width: '100%',
						height: '100%'
					},
					fontName: 'Roboto',
					fontSize: 11,
					series: {
						0: { color: green }, // green
						1: { color: red } //  red
					},
					hAxis: {
						textStyle: {
							color: '#888',
						},
						gridlines: {
							color: '',
							count: 0
						},
						viewWindow: {
							min: delta.min(),
							max: delta.max(),
						}
					},
					vAxis: {
						textStyle: {
							color: '#888',
						},
						gridlines: {
							color: '',
							count: 0
						},
						textPosition: 'in',
					},
					tooltip: {
						isHtml: true
					}
				}}
			/>
		)
	}
}