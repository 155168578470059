import { ActionCreators, IMarkets } from 'exchange-common'
import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { ChartContainer } from '../containers/chart'
import { ExchangeMenuContainer } from '../containers/exchangeMenu'
import { OrderbookContainer } from '../containers/orderbook'
import { SideAccountContainer } from '../containers/sideAccount'
import { TradeHeaderContainer } from '../containers/tradeHeader'
import { TradeHistoryContainer } from '../containers/tradeHistory'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	markets: IMarkets
}

interface IMatchProps {
	// include any url parameters here.
	market: string
}

export class ExchangeComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const { current } = this.props.markets
		const { market } = this.props.match.params
		if (Object.keys(this.props.markets.list).length === 0) {
			return <div />
		}

		if (market !== current) {
			ws.send(ActionCreators.Unsubscribe.orderbook(current))
			ws.send(ActionCreators.Unsubscribe.lastTrades(current))
			ws.send(ActionCreators.Unsubscribe.candlestick(current))
		} else {
			ws.send(ActionCreators.Subscribe.orderbook(current))
			ws.send(ActionCreators.Subscribe.lastTrades(current))
			ws.send(ActionCreators.Subscribe.candlestick(current))
		}

		if (!market || !this.props.markets.list[market]) {
			return <Redirect to='/exchange/BTC-BRL' />
		}

		if (!this.props.markets.list[market].visible) {
			return <Redirect to='/' />
		}

		return (
			<div className='exchange'>
				<TradeHeaderContainer />
				<div className='sideBar bg222'>
					<SideAccountContainer />
					<ExchangeMenuContainer />
				</div>
				<OrderbookContainer />
				<ChartContainer />
				<TradeHistoryContainer />
			</div>
		)
	}

}