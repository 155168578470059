export default {
	'invalid_login': 'E-mail ou senha inválidos',
	'login_successful': 'Login efetuado',
	'2fa_enabled': '2FA Ativado',
	'2fa_disabled': '2FA Desativado',
	'2fa_invalid': 'Token 2FA inválido',
	'2fa_already_used': 'Token 2FA já utilizado',
	'email_not_verified': 'E-mail não verificado',
	'email_already_exists': 'E-mail já utilizado',
	'order_book': 'Livro de Ofertas',
	'price_charts': 'Gráficos',
	'trade_history': 'Últimos Trades',
	'market_size': 'Quantia',
	'price': 'Preço',
	'total': 'Total',
	'mid_market_price': 'Preço Médio',
	'trade_size': 'Quantia',
	'time': 'Hora',
	'last_price': 'Último preço',
	'24h_price': '24h preço',
	'24h_high': '24h máxima',
	'24h_low': '24h mínima',
	'24h_volume': '24h volume',
	'open_orders': 'Ordens Abertas',
	'side': 'Lado',
	'size_abr': 'Qtd.',
	'filled_abr': 'Exec.',
	'size': 'Quantidade',
	'filled': 'Executado',
	'status': 'Estado',
	'actions': 'Ações',
	'cancel': 'Cancelar',
	'confirm': 'Confirmar',
	'buy': 'Compra',
	'sell': 'Venda',
	'partial': 'Parcial',
	'open': 'Aberta',
	'cancel_your_order': 'Cancele sua ordem',
	'confirm_your_order': 'Confirme sua ordem',
	'select_market': 'Escolha o par',
	'usdc_markets': 'Pares USDC',
	'btc_markets': 'Pares BTC',
	'brl_markets': 'Pares BRL',
	'order_form': 'Envio de Ordem',
	'market_order': 'Mercado',
	'limit_order': 'Limite',
	'amount': 'Quantia',
	'price_limit': 'Preço Limite',
	'fee': 'Taxa',
	'net': 'Líquido',
	'place_buy_order': 'Enviar Ordem de Compra',
	'place_sell_order': 'Enviar Ordem de Venda',
	'user_not_logged_in': 'Usuário não autenticado',
	'user_not_verified': 'Usuário não verificado.',
	'amount_invalid': 'Quantia inválida',
	'price_invalid': 'Preço inválido',
	'market': 'Par',
	'confirm_order': 'Confirmar Ordem',
	'login': 'Entrar',
	'register': 'Criar Conta',
	'connected': 'Conectado',
	'disconnected': 'Desconectado',
	'theme': 'Tema',
	'default': 'Padrão',
	'dark': 'Escuro',
	'light': 'Claro',
	'cvd': 'Daltonismo',
	'language': 'Idioma',
	'orders': 'Ordens',
	'wallets': 'Carteiras',
	'profile': 'Perfil',
	'id_verification': 'Verificação',
	'security': 'Segurança',
	'logout': 'Sair',
	'available_balance': 'Saldo Disponível',
	'coin': 'Moeda',
	'deposit': 'Depositar',
	'withdraw': 'Retirar',
	'exchange': 'Exchange',
	'beware_phishing': 'Cuidado com sites falsos',
	'always': 'Sempre',
	'phishing_msg': `verifique se você está no site correto`,
	'email': 'E-mail',
	'welcome_back': 'Bem vindo',
	'password': 'Senha',
	'2fa_token_if_enabled': 'Código 2FA (se ativo)',
	'lost_your_password': 'Esqueceu sua senha?',
	'email_us': 'envie um e-mail',
	'lost_2FA_box_msg_1': 'Se você perdeu o seu código 2FA, por favor',
	'lost_2FA_box_msg_2': `com o assunto "Perda de dispositivo 2FA".`,
	'lost_2FA_box_msg_3': 'Nosso suporte irá verificar sua identidade para sua segurança.',
	'request_password_reset': 'Redefinição de Senha',
	'insert_email_msg': 'Insira seu e-mail para receber as instruções para a redefinição da sua senha.',
	'reset_password': 'Redefinir senha',
	'reset_password_sent_msg': 'Confira no seu e-mail a caixa de entrada (ou lixo eletrônico) para seguir as intruções para redefinição da sua senha.',
	'go_back': 'Voltar',
	'password_reset': 'Redefina sua senha',
	'confirm_password': 'Confirme sua senha',
	'user_not_found': 'Usuário não encontrado',
	'password_reset_expired': 'Tempo expirado',
	'error_contact_support': 'Erro, fale com o suporte',
	'password_reseted': 'Senha redefinida',
	'create_free_account': 'Crie uma conta gratuita',
	'register_msg_1': 'Declaro ter lido e concordado com os',
	'terms_of_use': 'Termos de Uso',
	'privacy_policy': 'Política de Privacidade',
	'and': 'e',
	'aml_policy': 'Política de PLD',
	'register_successful': 'Conta criada com sucesso',
	'please_verify_your_email': 'Por favor verifique o seu e-mail',
	'home_banner_msg_1': `Bem vindo à melhor exchange de`,
	'home_banner_msg_2': 'Criptomoedas do Brasil',
	'view_more': 'Ver mais',
	'pair': 'Par',
	'experience_the_best_technology': 'Experimente a melhor tecnologia',
	'fast': 'Rápida',
	'easy': 'Fácil',
	'secure': 'Segura',
	'block1_msg_1': 'A Econobit oferece desempenho inabalável com nosso algoritmo de nível mundial, suportando até 1.000.000 ordens por segundo. Garantimos uma negociação de criptomoedas rápida e confiável, mesmo nos momentos de pico.',
	'block1_msg_2': 'Negociar criptomoedas na Econobit é fácil. Comece em minutos a negociar suas criptomoedas na Econobit.',
	'block1_msg_3': 'Protegemos seus ativos com uma arquitetura de sistema de múltiplas camadas e clusters. E com 100% de solvência, você pode ter certeza de que seus fundos estão seguros na Econobit.',
	'trade_anywhere': 'Negocie. Em qualquer lugar.',
	'block2_msg': 'Mantenha-se conectado com os últimos gráficos e cotações no seu computador usando nossa plataforma de negociação web especializada.',
	'register_now': 'Comece Agora',
	'view_exchange': 'Ver Exchange',
	'integration_with_ease': 'Integração fácil',
	'block3_msg': 'A API da Econobit foi desenvolvida para permitir uma forma fácil e eficiente de integrar o seu projeto ou aplicação em nossa plataforma.',
	'api_documentation': 'Documentação API',
	'block4_title': 'Entre em contato. Mantenha contato.',
	'247_support': 'Suporte 24 / 7',
	'econobit_blog': 'Blog Econobit',
	'community': 'Comunidade',
	'careers': 'Vagas',
	'got_a_problem': 'Está com dificuldades? Entre em contato. Nosso time de suporte está disponível 24/7.',
	'news_and_updates': 'Notícias e novidades da melhor exchange de criptomoedas do Brasil.',
	'econobit_is_global': 'A Econobit é internacional. Junte-se às discussões em nossas redes sociais.',
	'help_build_future': 'Ajude a construir o futuro da tecnologia. Comece sua carreira na Econobit.',
	'start_trading_now': 'Comece a negociar agora',
	'trade_many_cryptos': 'Negocie Bitcoin, Ethereum, e muitas outras criptomoedas com facilidade.',
	'address': 'Endereço',
	'company': 'Institucional',
	'help': 'Ajuda',
	'contact_us': 'Fale Conosco',
	'about_us': 'Sobre Nós',
	'fees': 'Taxas',
	'support': 'Suporte',
	'faq': 'Perguntas frequentes',
	'status_page': 'Status',
	'deposits': 'Depósitos',
	'deposit_funds': 'Depositar Fundos',
	'history': 'Histórico',
	'available': 'Disponível',
	'in_orders': 'Em Ordens',
	'your_btc_address': 'Endereço da sua carteira de Bitcoin',
	'your_ltc_address': 'Endereço da sua carteira de Litecoin',
	'your_doge_address': 'Endereço da sua carteira de Dogecoin',
	'your_eth_address': 'Endereço da sua carteira de Etheruem',
	'your_link_address': 'Endereço da sua carteira de Chainlink',
	'your_usdc_address': 'Endereço da sua carteira de USD Coin',
	'your_ebit_address': 'Endereço da sua carteira de Econobit',
	'our_brl_bank_account': 'Nossa conta bancária de Real Brasileiro',
	'instructions': 'Instruções',
	'brl_deposit_msg1': 'Esta é a única conta bancária que você pode usar para depositar Real Brasileiro na Econobit, e após a transação do depósito você precisará fazer o upload do recibo de depósito.',
	'brl_deposit_msg2': 'Esta conta bancária serve somente para depósitos de Real Brasileiro, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'brl_deposit_msg3': 'O depósito da sua transação será automático, a sua transferência de Real Brasileiro precisará ser confirmada por nosso banco, e seu Real Brasileiro será automaticamente depositado em sua conta após a confirmação.',
	'btc_deposit_msg1': 'Este endereço serve somente para depósitos de Bitcoin, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'btc_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de Bitcoin precisará ser confirmada por toda a rede Bitcoin, e seu Bitcoin será automaticamente depositado na sua conta após 1 confirmação.',
	'btc_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'ltc_deposit_msg1': 'Este endereço serve somente para depósitos de Litecoin, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'ltc_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de Litecoin precisará ser confirmada por toda a rede Litecoin, e seu Litecoin será automaticamente depositado na sua conta após 4 confirmações.',
	'ltc_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'doge_deposit_msg1': 'Este endereço serve somente para depósitos de Dogecoin, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'doge_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de Dogecoin precisará ser confirmada por toda a rede Dogecoin, e seu Dogecoin será automaticamente depositado na sua conta após 10 confirmações.',
	'doge_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'eth_deposit_msg1': 'Este endereço serve somente para depósitos de Ethereum, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'eth_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de Ethereum precisará ser confirmada por toda a rede Ethereum, e seu Ethereum será automaticamente depositado na sua conta após 12 confirmações.',
	'eth_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'link_deposit_msg1': 'Este endereço serve somente para depósitos de Chainlink, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'link_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de Chainlink precisará ser confirmada por toda a rede Ethereum, e seu Chainlink será automaticamente depositado na sua conta após 12 confirmações.',
	'link_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'usdc_deposit_msg1': 'Este endereço serve somente para depósitos de USD Coin, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'usdc_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de USD Coin precisará ser confirmada por toda a rede Ethereum, e seu USD Coin será automaticamente depositado na sua conta após 12 confirmações.',
	'usdc_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'ebit_deposit_msg1': 'Este endereço serve somente para depósitos de Econobit, e quaisquer outras moedas depositadas não poderão ser recuperadas.',
	'ebit_deposit_msg2': 'O depósito da sua transação será automático, a sua transferência de Econobit precisará ser confirmada por toda a rede Econobit, e seu Econobit será automaticamente depositado na sua conta após 1 confirmação.',
	'ebit_deposit_msg3': 'Este endereço é o único que você pode fazer uso, e você pode fazer múltiplos depósitos ao mesmo tempo.',
	'deposit_id': 'ID do Depósito',
	'date': 'Data',
	'record': 'Registro',
	'confirmed': 'Confirmado',
	'unconfirmed': 'Não confirmado',
	'link': 'Link',
	'no_results_found': 'Nenhum resultado encontrado.',
	'address_copied': 'Endereço copiado.',
	'insufficient_balance': 'Saldo insuficiente.',
	'withdraw_all_available_balance': 'Retirar todo saldo disponível.',
	'withdraw_id': 'ID da Retirada',
	'withdrawals': 'Retiradas',
	'withdraw_funds': 'Retirar Fundos',
	'withdraw_fee': 'Taxa de retirada',
	'will_receive': 'Irá receber',
	'confirm_your_withdraw': 'Confirme sua retirada',
	'withdraw_msg_1': 'Para aumentar a segurança de seus ativos, você deve aprovar o saque no link enviado para sua caixa de entrada de e-mail.',
	'withdraw_msg_2': 'Processaremos sua retirada instantaneamente, mas depende do blockchain quando os ativos serão finalmente recebidos em sua carteira externa.',
	'withdraw_msg_3': 'Por favor evite retirar diretamente para endereços de ICO, neste caso o reembolso NÃO vem para sua carteira da Econobit.',
	'withdraw_msg_fiat': 'Processaremos sua retirada em até 1 dia útil, mas depende da rede bancária quando os fundos serão finalmente recebidos em sua conta bancária.',
	'sent': 'Enviado',
	'expired': 'Expirado',
	'pending': 'Pendente',
	'confirm_withdraw': 'Confirmar Retirada',
	'order_id': 'ID da Ordem',
	'type': 'Tipo',
	'remain': 'Restante',
	'open_tab': 'Abertas',
	'executed': 'Executada',
	'cancelled': 'Cancelada',
	'balances': 'Saldos',
	'ledger': 'Extrato',
	'estimated_value': 'Valor Estimado',
	'name': 'Nome',
	'description': 'Descrição',
	'value': 'Valor',
	'balance_after': 'Saldo após',
	'internal': 'Interno',
	'deposit_from_external_wallet': 'Depósito de carteira externa',
	'deposit_from_internal_wallet': 'Depósito de carteira interna',
	'withdraw_to_external_wallet': 'Retirada para carteira externa',
	'withdraw_to_internal_wallet': 'Retirada para carteira interna',
	'deposit_from_bank_account': 'Depósito de conta bancária',
	'withdraw_to_bank_account': 'Retirada para conta bancária',
	'save_changes': 'Salvar',
	'verified': 'Verificado',
	'yes': 'Sim',
	'no': 'Não',
	'go_to_id_verification': 'Ir para Verificação',
	'nickname': 'Apelido',
	'mobile_phone': 'Telefone Celular',
	'personal_data': 'Dados Pessoais',
	'full_name': 'Nome Completo',
	'birth_date': 'Data de Nascimento',
	'document_id_type': 'Tipo de Conta',
	'personal': 'Individual',
	'corporate': 'Corporativa',
	'document_id_number': 'CPF / CNPJ',
	'address_info': 'Endereço de Residência',
	'street_address': 'Logradouro Completo',
	'city': 'Cidade',
	'country_state': 'Estado',
	'post_code': 'Código Postal',
	'country': 'País',
	'BR': 'Brasil',
	'ES': 'Espanha',
	'US': 'Estados Unidos',
	'verify': 'Verificar',
	'uploads': 'Envios',
	'upload': 'Enviar',
	'document_id_photo': 'Foto do Documento de ID',
	'document_id_photo_msg': 'Foto colorida da frente e verso do documento de ID, mostrando a foto do seu rosto 3x4, o nome completo, a data de nascimento e o número da ID. Todos os documentos devem estar dentro da data de validade.',
	'selfie_holding_id_photo': 'Foto Selfie segurando a ID',
	'selfie_holding_id_photo_msg': 'Uma foto sua segurando o documento de ID visivelmente. As selfies precisam ter alta resolução para que seu rosto, juntamente com os dados do documento, sejam legíveis.',
	'proof_of_residence': 'Comprovante de Residência',
	'proof_of_residence_msg': 'Foto de uma conta de serviço público que mostra o nome completo e o endereço completo, pode ser eletricidade, água, gás, telefone, ou extrato bancário, com até 3 meses de emissão.',
	'change_password': 'Alterar Senha',
	'two_factor_auth': 'Autenticação de 2 Passos',
	'enter_6_digit': 'Insira o código de validação de 6 dígitos de seu dispositivo',
	'secret': 'Segredo',
	'manual_activation': 'Para ativação manual use a chave abaixo',
	'enable': 'Ativar',
	'disable': 'Desativar',
	'save': 'Salvar',
	'2fa_token': 'Token 2FA',
	'old_password': 'Senha Antiga',
	'new_password': 'Senha Nova',
	'confirm_new_password': 'Confirmar Senha Nova',
	'activate_2fa_msg1': 'Instale o Google Authenticator no seu dispositivo',
	'activate_2fa_msg2': 'Escaneie o código com seu dispositivo',
	'trade_fee': 'Taxa de trade',
	'maker': 'passiva',
	'taker': 'ativa',
	'market_ledger': 'mercado',
	'limit_ledger': 'limite',
	'latency': 'Latência',
	'blog_posts_url': 'https://support.econobit.io/wp-json/wp/v2/posts?per_page=3&_fields=title,link,date_gmt&categories=29&lang=pt-br',
	'blog_cat_url': 'https://support.econobit.io/category/comunicados/?lang=pt-br',
	'support_url': 'https://support.econobit.io/?lang=pt-br',
	'support_faq_url': 'https://support.econobit.io/category/faq-pt-br/?lang=pt-br',
	'not_enough_buy_orders': 'Ordens de compra insuficientes',
	'not_enough_sell_orders': 'Ordens de venda insuficientes',
	'server_error': 'Erro no servidor. Tente novamente.',
	'invalid_password': 'Senha inválida',
	'invalid_order': 'Ordem inválida',
	'order_not_found': 'Ordem não encontrada',
	'invalid_market': 'Mercado inválido',
	'market_not_found': 'Mercado não encontrado',
	'withdraw_msg_confirmed': 'Retirada de {amount} {coin} confirmada',
	'deposit_msg_confirmed': 'Depósito de {amount} {coin} confirmado',
	'deposit_msg_cancelled': 'Depósito de {amount} {coin} cancelado',
	'deposit_msg_received': 'Depósito de {amount} {coin} recebido (não confirmado)',
	'order': 'Ordem',
	'changes_saved': 'Alterações salvas',
	'password_changed': 'Senha alterada',
	'field_email_empty': `Campo 'E-mail' deve ser preenchido`,
	'field_email_invalid': `Campo 'E-mail' deve ser um e-mail válido`,
	'field_password_empty': `Campo 'Senha' deve ser preenchido`,
	'field_password_invalid': `Campo 'Senha' deve ser maior que 6 caracteres`,
	'withdraw_success': 'Retirada efetuada com sucesso',
	'field_address_empty': `Campo 'Endereço' deve ser preenchido`,
	'field_amount_empty': `Campo 'Quantia' deve ser preenchido`,
	'field_coin_empty': `Campo 'Moeda' deve ser preenchido`,
	'field_amount_greater': `Campo 'Quantia' deve ser maior que {minAmount}`,
	'bank_account_added': 'Conta bancária adicionada',
	'bank_account_removed': 'Conta bancária removida',
	'2fa_not_enabled': '2FA não está habilitado',
	'order_cancelled': 'Ordem cancelada',
	'order_submitted': 'Ordem enviada',
	'field_market_empty': `Campo 'Par' deve ser preenchido`,
	'field_type_empty': `Campo 'Tipo' deve ser preenchido`,
	'field_side_empty': `Campo 'Lado' deve ser preenchido`,
	'invalid_amount_value': `Valor 'Quantia' inválido`,
	'invalid_price_value': `Valor 'Preço' inválido`,
	'field_old_password_empty': `Campo 'Senha Antiga' deve ser preenchido`,
	'field_old_password_invalid': `Campo 'Senha Antiga' deve ser maior que 6 caracteres`,
	'field_new_password_empty': `Campo 'Senha Nova' deve ser preenchido`,
	'field_new_password_invalid': `Campo 'Senha Nova' deve ser maior que 6 caracteres`,
	'required': 'Obrigatório',
	'same_old_password': 'Senha igual a última',
	'passwords_dont_match': `Senhas não coincidem`,
	'invalid_email_address': `Endereço de E-mail inválido`,
	'must_be': 'Deve ser',
	'characters_or_less': 'caracteres ou menos',
	'characters_or_more': 'caracteres ou mais',
	'exec_trade_msg': 'Ordem de {side} de {amount} {otherCoin} executada @ {price} {baseCoin}',
	'the_company': 'A Empresa',
	'the_company_msg_1': 'A Econobit iniciou como um pequeno projeto em 2013, começou a operar sua exchange em 2017 e agora é uma das exchanges de criptomoedas mais antigas e confiáveis ​​do mercado Brasileiro. Nossa plataforma oferece ferramentas avançadas para traders profissionais e desenvolvedores transacionarem suas criptomoedas, tais como Bitcoin, Litecoin, Ethereum, e tokens ERC-20. Nós continuamente melhoramos o código para entregar a melhor experiência para nossos clientes.',
	'the_company_msg_2': `Bitcoin é a criptomoeda mais utilizada no mundo, com um valor de mercado total de mais de US$1 trilhão. A rede bitcoin é composta por milhares de computadores executados por indivíduos em todo o mundo.`,
	'stats': 'Dados',
	'users': 'Usuários',
	'traded': 'Negociado',
	'investors_and_partners': 'Investidores & Parceiros',
	'follow_us_social': 'Siga-nos nas redes sociais',
	'no_positions_open': 'Nenhuma vaga aberta no momento.',
	'future_positions': 'Futuras vagas serão postadas aqui.',
	'account': 'Conta',
	'account_opening': 'Abertura de conta',
	'free': 'Gratuita',
	'custody': 'Custódia',
	'trading': 'Negociação',
	'deposit_fee': 'Taxa de depósito',
	'withdrawal_fee': 'Taxa de retirada',
	'minimum_withdrawal': 'Retirada mínima',
	'maker_cap': 'Passiva',
	'taker_cap': 'Ativa',
	'fee_rounding': 'Arredondamento de taxa',
	'minimum_trade_amount': 'Quantia mínima de negociação',
	'fee_rounding_msg': `Pedimos aos nossos usuários que tomem nota da política da Econobit em relação ao cálculo de taxas. Como nossas taxas são calculadas com duas casas decimais, todas as taxas que possam exceder essa limitação são arredondadas para cima. O arredondamento é executado de forma que o segundo dígito decimal seja sempre um valor de um dígito mais alto do que era antes do arredondamento. Por exemplo; uma taxa de 0.111 será cobrada como 0.12.`,
	'markets': 'Mercados',
	'whats_new': 'Novidades',
	'withdraw_created': 'Retirada solicitada. Confirme em seu e-mail.',
	'withdraw_confirmed': 'Solicitação de retirada confirmada.',
	'try_simulated_trading': 'Quer negociar no simulador?',
	'try_live_trading': 'Quer negociar de verdade?',
	'unverified': 'Não verificado',
	'unverified_msg': 'Por favor complete a sua verificação de ID.',
	'in_review': 'Em verificação',
	'in_review_msg': 'Por favor aguarde enquanto verificamos sua ID.',
	'verified_msg': 'Obrigado por verificar sua conta.',
	'login_history': 'Histórico de Login',
	'ip': 'IP',
	'browser': 'Navegador',
	'device': 'Dispositivo',
	'bank_accounts': 'Contas',
	'bank_account': 'Conta Bancária',
	'receipt': 'Recibo',
	'deposit_receipt': 'Recibo de Depósito',
	'go_to_bank_accounts': 'Ir para Contas Bancárias para adicionar',
	'deposit_receipt_msg': 'Foto do recibo de depósito mostrando seu nome completo, informações da conta bancária e o valor correto.',
	'confirm_your_deposit': 'Confirme seu depósito',
	'confirm_deposit': 'Confirmar depósito',
	'add': 'Adicionar',
	'remove': 'Remover',
	'sending_do_not_leave': 'Enviando... Não saia da página.',
	'no_bank_accounts_found': 'Nenhuma conta bancária encontrada.',
	'my_bank_accounts': 'Minhas Contas Bancárias',
	'bank_code': 'Código do banco',
	'branch': 'Agência',
	'branch_number': 'Número da agência',
	'branch_digit': 'Dígito da agência',
	'account_number': 'Número da conta',
	'account_digit': 'Dígito da conta',
	'add_new_bank_account': 'Adicionar nova Conta Bancària',
	'withdraw_3rd_start': 'Observe que retirar para contas bancárias de terceiros é ',
	'prohibited': 'Proíbido',
	'withdraw_3rd_end': ', adicione apenas contas bancárias em seu próprio nome.',
	'social_name': 'Razão social',
	'api_keys': 'Chaves de API',
	'permissions': 'Permissões',
	'api_key_created': 'Chave de API Criada',
	'api_key_caution': 'Certifique- se de anotar seu Segredo de API, pois ele não será exibido novamente.',
	'api_key': 'Chave de API',
	'api_secret': 'Segredo de API',
	'api_key_revoked': 'Chave de API Revogada',
	'revoked': 'Revogar',
	'field_invalid_address': 'Endereço Iiválido para a moeda selecionada',
	'field_bankAccountId_empty': 'Campo conta bancária vazio.',
	'cost': 'Custo',
	'read': 'Leitura',
	'trade': 'Câmbio',
	'limit_by_ip': 'Limite por IP',
	'custom_tag': 'Tag Personalizada',
	'tag': 'Tag',
	'key': 'Chave',
	'create': 'Criar',
	'created': 'Criada',
	'warning': 'Aviso',
	'never_disclose': 'Nunca revele suas chaves a ninguém. Use apenas se souber o que está fazendo.',
	'choose_permissions': 'Escolha abaixo quais permissões você deseja ativar.',
	'connect_their_projects': 'Usuários podem criar chaves de API para conectar seus projetos com a Econobit e automatizar suas estratégias.',
	'cancel_all_orders': 'Cancelar Todas Ordens',
	'all_open_orders': 'Todas Ordens Abertas',
	'cancel_warning': 'Isso irá cancelar todas suas ordens abertas. Tem certeza?',
	'create_wallet_error': 'Endereço indisponível no momento',
	'market_trade_disabled': 'Negociação desativada neste mercado',
	'cookie_consent_text': `Usamos cookies para melhorar a experiência do usuário e analisar o tráfego do site conforme descrito em nossa`,
	'cookie_accept_all': 'Aceitar Todos',
	'cookie_reject_all': 'Rejeitar',
	'last_aml_updated_date': 'Última atualização: 07 Jun 2022',
	'last_privacy_updated_date': 'Última atualização: 07 Jun 2022',
	'last_terms_updated_date': 'Última atualização: 07 Jun 2022',
	'aml_title_1': `POLÍTICA DA ECONOBIT ANTI LAVAGEM DE DINHEIRO("PLD")`,
	'aml_title_2': `E CONTRA O FINANCIAMENTO DO TERRORISMO ("CFT")`,
	'aml_text_1': `A lavagem de dinheiro é definida como o processo em que a identidade do produto do crime é tão disfarçada que dá uma impressão de renda legítima. Os criminosos visam especificamente as empresas de serviços financeiros através das quais tentam lavar os lucros criminais sem o conhecimento ou suspeita das empresas.`,
	'aml_text_2': `Em resposta à escala e efeito da lavagem de dinheiro, a União Europeia aprovou diretrizes destinadas a combater a lavagem de dinheiro e o terrorismo. Essas Diretivas, juntamente com regulamentos, regras e orientação do setor, formam a pedra angular de nossas obrigações PLD/CFT e delineiam as infrações e penalidades por não cumprirem.`,
	'aml_text_3': `Embora a Econobit Ltd não esteja atualmente regulamentada e não se enrespeita no âmbito das obrigações da PLD/CFT, a alta administração implementou sistemas e procedimentos que atendam às normas estabelecidas pela União Europeia. Essa decisão reflete o desejo da alta administração de prevenir a lavagem de dinheiro e não ser usada por criminosos para lavar o produto do crime.`,
	'aml_title_3': `POLÍTICA ANTILAVAGEM DE DINHEIRO (PLD):`,
	'aml_text_4': `A PLD da Econobit visa prevenir a lavagem de dinheiro, atendendo às normas europeias de combate à lavagem de dinheiro e ao financiamento do terrorismo, incluindo a necessidade de ter sistemas e controles adequados para mitigar o risco de a empresa ser usada para facilitar crimes financeiros. Esta Política PLD estabelece as normas mínimas que devem ser cumpridas e inclui:`,
	'aml_list_1': `Nomear um Oficial de Relatórios de Lavagem de Dinheiro (MLRO) que tenha um nível suficiente de antiguidade e independência, e que tenha a responsabilidade de fiscalizar o cumprimento da legislação, regulamentos, regras e orientação do setor relevantes;`,
	'aml_list_2': `Estabelecer e manter uma Abordagem Baseada em Riscos (RBA) para a avaliação e gestão dos riscos de lavagem de dinheiro e financiamento do terrorismo enfrentados pela empresa;`,
	'aml_list_3': `Estabelecendo e mantendo os procedimentos de diligência do cliente (CDD), identificação, verificação de identidade do cliente (KYC), incluindo uma diligência aprimorada para clientes que apresentem maior risco, como pessoas expostas politicamente (PEP);`,
	'aml_list_4': `Estabelecer e manter sistemas e procedimentos baseados em riscos para o monitoramento da atividade contínua do cliente;`,
	'aml_list_5': `Estabelecer procedimentos para denunciar atividades suspeitas internamente e às autoridades competentes, conforme apropriado;`,
	'aml_list_6': `Manutenção dos registros adequados para os períodos mínimos prescritos;`,
	'aml_list_7': `Capacitando e conscientizando todos os colaboradores relevantes.`,
	'aml_title_4': `POLÍTICA DE SANÇÕES:`,
	'aml_text_5': `A Econobit está proibida de fazer transações com indivíduos, empresas e países que estão em listas de sanções prescritas. A Econobit irá, portanto, analisar as listas de sanções das Nações Unidas, da União Europeia, do Tesouro do Reino Unido e do Escritório dos EUA de Controle de Ativos Estrangeiros (OFAC) em todas as jurisdições em que operamos.`,
	'privacy_text_1': `Esta Política de Privacidade explica como a Econobit coleta, usa, compartilha e protege as informações do usuário obtidas através do site Econobit.io. Os termos "nós", "nós" e "nosso" referem-se à Econobit. Quando pedimos certas informações pessoais dos usuários é porque somos obrigados por lei a coletar essas informações ou são relevantes para fins especificados. Qualquer informação não necessária que você nos forneça é feita voluntariamente. Você decide se nos fornecerá essas informações não necessárias; você pode não ser capaz de acessar ou utilizar todos os nossos Serviços se você optar por não fornecer.`,
	'privacy_text_2': `Ao usar o Site, você concorda com as práticas de dados descritas nesta Política de Privacidade. Na ocasião, a Econobit pode revisar esta Política de Privacidade para refletir mudanças na lei ou em nossas práticas de coleta e uso de dados pessoais. Se forem feitas alterações materiais nesta Política de Privacidade, as alterações serão anunciadas mediante postagem no site. Solicitamos seu consentimento antes de usar suas informações para qualquer finalidade que não esteja coberta nesta Política de Privacidade.`,
	'privacy_text_3': `A mais recente Política de Privacidade incorporou elementos do Lei Geral de Proteção de Dados (LGPD) à medida que atuamos de acordo com suas regras de processamento de informações pessoais dentro da Área Econômica Europeia (AEE).`,
	'privacy_title_1': `INFORMAÇÕES QUE A ECONOBIT COLETA`,
	'privacy_bold_1': `Queremos que você entenda os tipos de informações que coletamos quando você se cadastra e usa os serviços da Econobit`,
	'privacy_bold_2': `Informações que você nos fornece no momento do cadastro`,
	'privacy_text_4': `Quando você cria uma Conta Econobit, você nos fornece informações pessoais que incluem suas informações de contato (Endereço de e-mail, nome e uma senha). Você também pode optar por adicionar uma conta do Google Authenticator para ser usada para verificação 2FA para maior segurança.`,
	'privacy_bold_3': `Informações que coletamos ao autenticar a identidade do usuário`,
	'privacy_text_5': `Para cumprir as normas regulatórias globais do setor, incluindo Antilavagem de Dinheiro Laundering (PLD), Conheça Seu Cliente (KYC) e Contra o Financiamento do Terrorismo (CFT), a Econobit exige que as contas de usuário sejam submetidas à autenticação da identidade do usuário para ambas as contas de nível Pessoal & Corporativo. Isso implica a coleta de identificação formal.`,
	'privacy_bold_4': `Informações que coletamos à medida que você usa nossos serviços`,
	'privacy_bold_5': `Informações de uso do serviço`,
	'privacy_text_6': `Através do uso da plataforma Econobit, também monitoramos e coletamos informações de rastreamento relacionadas ao uso, como data e hora de acesso, identificação do dispositivo, sistema operacional, tipo de navegador e endereço IP. Essas informações podem ser obtidas diretamente pela Econobit ou por meio de serviços de terceiros. Esses dados de uso de serviços nos ajudam a garantir que nossa interface seja acessível para usuários em todas as plataformas e possam ajudar durante investigações criminais.`,
	'privacy_bold_6': `Informações de transação`,
	'privacy_text_7': `Para todas as contas de usuários pessoais e corporativas, coletamos informações de transações, incluindo instantâneos de depósito, saldos de contas, histórico comercial, saques, atividade de pedidos e histórico de distribuição. Esses dados de transação são monitorados para atividades de negociação suspeitas para proteção de fraudes de usuários e resolução de casos legais.`,
	'privacy_title_2': `POR QUE A ECONOBIT COLETA ESSAS INFORMAÇÕES`,
	'privacy_bold_7': `Para fornecer e manter nossos serviços`,
	'privacy_text_8': `Usamos as informações coletadas para entregar nossos serviços e verificar a identidade do usuário.`,
	'privacy_text_9': `Usamos o endereço IP e identificadores exclusivos armazenados nos cookies do seu dispositivo para nos ajudar a autenticar sua identidade e fornecer nosso serviço. Dadas as nossas obrigações legais e requisitos do sistema, não podemos fornecer serviços sem dados como identificação, informações de contato e informações relacionadas a transações.`,
	'privacy_bold_8': `Para proteger nossos usuários`,
	'privacy_text_10': `Usamos as informações coletadas para proteger nossa plataforma, contas e arquivos de usuários.`,
	'privacy_text_11': `Usamos endereços IP e dados de cookies para proteger contra abusos automatizados, como ataques de spam, phishing e Negação distribuída de serviço (DDoS).`,
	'privacy_text_12': `Analisamos a atividade comercial com o objetivo de detectar comportamentos suspeitos precocemente para evitar possíveis fraudes e perda de fundos para maus atores.`,
	'privacy_bold_9': `Para cumprir os requisitos legais e regulamentais`,
	'privacy_text_13': `O respeito pela privacidade e segurança dos dados que você armazena com a Econobit demonstra nossa abordagem para cumprir os regulamentos, solicitações governamentais e consultas geradas pelo usuário. Não divulgaremos ou forneceremos nenhuma informação pessoal a fontes de terceiros sem análise de nossa equipe jurídica e/ou consentimento prévio do usuário.`,
	'privacy_bold_10': `Para medir o desempenho do site`,
	'privacy_text_14': `Medimos e analisamos ativamente os dados para entender como nossos serviços são usados. Essa atividade de revisão é conduzida por nossa equipe de operações para melhorar continuamente o desempenho de nossa plataforma e resolver problemas com a experiência do usuário.`,
	'privacy_text_15': `Monitoramos continuamente as informações de atividade de nossos sistemas e as comunicações com os usuários para procurar e corrigir problemas rapidamente.`,
	'privacy_bold_11': `Para se comunicar com você`,
	'privacy_text_16': `Usamos as informações pessoais coletadas, como um endereço de e-mail, para interagir diretamente com os usuários ao fornecer suporte ao cliente em um ticket ou para mantê-lo informado sobre logins, transações e segurança. Sem processar suas informações pessoais para confirmar cada comunicação, não poderemos responder às suas solicitações, perguntas e consultas enviadas. Todas as comunicações diretas são mantidas em sigilo e analisadas internamente quanto à precisão.`,
	'privacy_bold_12': `Para fazer cumprir nossos Termos de Uso e outros acordos`,
	'privacy_text_17': `É muito importante para nós e nossos clientes que revisemos, investiguemos e evitemos continuamente quaisquer atividades potencialmente proibidas ou ilegais que violem nossos Termos de Uso. Para o benefício de toda a nossa base de usuários, aplicamos cuidadosamente nossos acordos com terceiros e investigamos ativamente violações de nossos Termos de Uso publicados. A Econobit se reserva o direito de encerrar a prestação de serviço a qualquer usuário encontrado envolvido em atividades que violem nossos Termos de Uso.`,
	'privacy_title_3': `COMO A ECONOBIT PROTEGE OS DADOS DO USUÁRIO`,
	'privacy_text_18': `A Econobit implementou uma série de medidas de segurança para garantir que suas informações não sejam perdidas, abusadas ou alteradas. Nossas medidas de segurança de dados incluem, mas não estão limitadas a: tecnologia de criptografia SSL, pseudoanonimisação, restrições internas de acesso a dados e controles rígidos de acesso físico a prédios e arquivos. Observe que é impossível garantir a transmissão 100% segura de dados pela Internet nem o método de armazenamento eletrônico. Como tal, solicitamos que você entenda a responsabilidade de tomar precauções de segurança de forma independente para proteger suas próprias informações pessoais.`,
	'privacy_text_19': `Se você suspeitar que suas informações pessoais foram comprometidas, especialmente informações de conta e/ou senha, entre imediatamente em contato com o atendimento ao cliente da Econobit para nós efetuarmos o bloqueio da sua conta.`,
	'privacy_title_4': `FALE CONOSCO`,
	'privacy_text_20': `Estamos comprometidos em respeitar as liberdades e direitos de todos os nossos usuários Econobit que confiaram em nosso serviço. Se você tiver alguma dúvida ou preocupação em relação a esta Política de Privacidade, ou se desejar registrar uma solicitação de proteção de dados, consulte nossas Perguntas Frequentes e entre em contato conosco em nossa página de Suporte.`,
	'terms_text_1': `Este acordo é entre você (aqui mencionado como "você" ou com "seu") e Econobit. Ao acessar, usando ou clicando em "Concordo" com qualquer um dos serviços disponibilizados pela Econobit através do site (www.econobit.io), a API (api.econobit.io), nossos aplicativos móveis ou quaisquer outros serviços relacionados fornecidos pela Econobit conforme descrito na Seção 4 abaixo (coletivamente, os "Serviços") você concorda que leu, entendeu e aceitou todos os termos e condições contidos neste contrato de Termos de Uso (o ou estes "Termos"), bem como nossa Política de Privacidade encontrada em https://econobit.io/privacyPolicy. Além disso, ao usar certos recursos dos Serviços, você pode estar sujeito a termos e condições adicionais aplicáveis a tais recursos.`,
	'terms_text_2': `LEIA ESTES TERMOS ATENTAMENTE, POIS ELES REGEM O USO DOS SERVIÇOS. ESTES TERMOS CONTÊM DISPOSIÇÕES IMPORTANTES, INCLUINDO UMA DISPOSIÇÃO ARBITRAL QUE EXIGE QUE TODAS AS REIVINDICAÇÕES SEJAM RESOLVIDAS POR MEIO DE ARBITRAGEM VINCULATIVA. OS TERMOS DA DISPOSIÇÃO ARBITRAL ESTÃO ESTABELECIDOS NA SEÇÃO 14 ABAIXO INTITULADA "RESOLUÇÃO DE DISPUTAS: FÓRUM, ARBITRAGEM, RENÚNCIA DE AÇÃO COLETIVA". COMO EM QUALQUER ATIVO, O VALOR DAS MOEDAS DIGITAIS PODE SUBIR OU DESCER E PODE HAVER UM RISCO SUBSTANCIAL DE QUE VOCÊ PERCA DINHEIRO COMPRANDO, VENDENDO, SEGURANDO OU INVESTINDO EM MOEDAS DIGITAIS. AO USAR OS SERVIÇOS, VOCÊ RECONHECE E CONCORDA QUE (1) VOCÊ ESTÁ CIENTE DOS RISCOS ASSOCIADOS À TRANSAÇÃO EM MOEDAS DIGITAIS (2) QUE VOCÊ ASSUME TODOS OS RISCOS EM RELAÇÃO AO USO DOS SERVIÇOS E NEGOCIAÇÃO EM MOEDAS DIGITAIS E (3) A ECONOBIT NÃO É RESPONSÁVEL OU RESPONSÁVEL POR TAIS RISCOS OU RESULTADOS ADVERSOS.`,
	'terms_text_3': `AO ACESSAR, USAR OU TENTAR USAR OS SERVIÇOS EM QUALQUER CAPACIDADE, VOCÊ RECONHECE QUE ACEITA E CONCORDA EM ESTAR VINCULADO A ESSES TERMOS. SE VOCÊ NÃO CONCORDAR, NÃO ACESSE OU USE OS SERVIÇOS.`,
	'terms_title_1': `1. Condições do Acordo`,
	'terms_s1_text_1': `A Econobit reserva-se o direito de modificar ou alterar os Termos a qualquer momento e a seu exclusivo critério. A Econobit fornecerá aviso dessas alterações atualizando os Termos revisados na página web (https://econobit.io/termsOfUse) e alterando a data "[Última atualização: ]" nesta página. Toda e qualquer modificação ou alteração nestes Termos entrará em vigor imediatamente após ser anunciado no site ou liberado aos usuários. Como tal, seu uso contínuo dos serviços Econobit atua como aceitação do acordo e regras alteradas. Se você não concordar com qualquer modificação nestes Termos, você deve parar de usar os Serviços. A Econobit incentiva você a revisar frequentemente os Termos para garantir que você entenda os termos e condições aplicáveis ao seu acesso e uso dos Serviços.`,
	'terms_title_2': `2. Elegibilidade`,
	'terms_s2_text_1': `Ao se registrar para usar uma Conta Econobit (conforme definido na Seção 5 abaixo), você representa e garante que você (a) tenha pelo menos 18 anos ou idade legal para formar um contrato vinculante sob a lei aplicável, (b) são uma pessoa física, jurídica ou outra organização com total capacidade jurídica e autoridade para entrar nestes Termos, c Não foram previamente suspensos ou removidos do uso de nossos Serviços e (d) você não tem atualmente uma Conta Econobit existente, (e) você não é uma pessoa dos EUA. Se você está entrando nestes Termos em nome de uma pessoa jurídica da qual você é um empregado ou agente, você representa e garante que tem todos os direitos e autoridade necessários para vincular tal pessoa jurídica.`,
	'terms_title_3': `3. Proibição de Uso`,
	'terms_s3_text_1': `Ao acessar e usar os Serviços, você representa e garante que não está em nenhuma lista de sanções comerciais ou econômicas, como a lista de sanções do Conselho de Segurança da ONU, designada como "Nacional Especialmente Designada" pelo OFAC (Escritório de Controle de Ativos Estrangeiros do Departamento do Tesouro dos EUA) ou colocada na "Lista de Pessoas Negadas" do Departamento de Comércio dos EUA. A Econobit mantém o direito de selecionar seus mercados e jurisdições para operar e pode restringir ou negar os Serviços em determinados países a seu critério.`,
	'terms_title_4': `4. Descrição dos Serviços`,
	'terms_s4_text_1': `A Econobit fornece uma plataforma de negociação de ativos digitais online (cripto para cripto) para produtos comumente conhecidos como tokens criptográficos, tokens digitais ou criptomoedas (coletivamente, "Moeda Digital"). A Econobit não fornece recursos de negociação fiat como parte dos Serviços. A Econobit funciona como um provedor de plataforma de negociação e não como comprador ou vendedor em negociações feitas entre comerciantes. A Econobit também não é um fabricante de mercado. Os usuários devem se cadastrar e abrir uma conta com a Econobit conforme estabelecido na Seção 5 abaixo e depositar ativos digitais antes do início da negociação. Os comerciantes podem solicitar a retirada de seus ativos digitais, sujeitos às limitações previstas nestes Termos.`,
	'terms_s4_text_2': `A Econobit se esforça para manter a exatidão das informações publicadas nos Serviços, no entanto, não pode e não garante a exatidão, adequação, confiabilidade, completude, desempenho ou adequação para qualquer finalidade do conteúdo disponibilizado através dos Serviços, e não será responsável por qualquer perda ou dano que possa surgir direta ou indiretamente do uso de tal conteúdo. As informações sobre os Serviços podem ser submetidas a alterações sem aviso prévio e são fornecidas com o objetivo principal de facilitar que os usuários cheguem a decisões independentes. A Econobit não fornece assessoria de investimento ou assessoria e não terá responsabilidade pelo uso ou interpretação das informações conforme indicado nos Serviços ou em outros meios de comunicação. Todos os usuários dos Serviços devem entender que existem riscos envolvidos na negociação de Moedas Digitais. A Econobit incentiva todos os usuários a exercer prudência e negociar de forma responsável dentro de seus próprios meios.`,
	'terms_title_5': `5. Registro e Requisitos da Conta Econobit`,
	'terms_s5_bold_a': `a. Registro`,
	'terms_s5a_text_1': `Todos os usuários dos Serviços (cada um, um "Usuário") devem se cadastrar (https://econobit.io/register) para uma conta Econobit (uma "Conta") antes de usar os Serviços. Para se cadastrar em uma Conta, você deve fornecer seu nome real, endereço de e-mail e senha, bem como aceitar os Termos de Uso, Política de Privacidade e Formulário de Consentimento. A Econobit pode, a seu exclusivo critério, recusar-se a abrir uma conta para você. Você concorda em fornecer informações completas e precisas ao abrir uma Conta e concorda em atualizar prontamente qualquer informação que você forneça à Econobit para que essas informações sejam completas e precisas o tempo todo. Cada cadastro é apenas para um único usuário e cada Usuário (inclusive com relação a qualquer Usuário que seja uma pessoa jurídica ou jurídica) só poderá manter uma Conta ativa com a Econobit.`,
	'terms_s5_bold_b': `b. Verificação de Identidade do Usuário`,
	'terms_s5b_text_1': `Com o registro de uma conta na Econobit, você concorda em compartilhar informações pessoais solicitadas para fins de verificação de identidade. Essas informações são usadas especificamente para a detecção de lavagem de dinheiro, financiamento do terrorismo, fraude e outros crimes financeiros na plataforma Econobit. Coletaremos, usaremos e compartilharemos essas informações de acordo com nossa Política de Privacidade publicada. Além de fornecer essas informações, para facilitar o cumprimento dos padrões globais do setor para retenção de dados, você concorda em nos permitir manter um registro dessas informações para a vida útil de sua conta mais 5 anos além do fechamento da conta. Você também nos autoriza a fazer perguntas, direta ou por meio de terceiros, que sejam consideradas necessárias para verificar sua identidade ou para protegê-lo e/ou contra crimes financeiros, como fraude.`,
	'terms_s5b_text_2': `As informações de verificação de identidade que solicitamos podem incluir, mas não se limitam a, seu nome, endereço de e-mail, informações de contato, número de telefone, nome de usuário, identificação emitida pelo governo, data de nascimento e outras informações coletadas no momento do registro da conta. Ao fornecer essas informações necessárias, você confirma que ela é precisa e autêntica. Após o registro, você deve garantir que as informações são verdadeiras, completas e atualizadas em tempo hábil com quaisquer alterações. Se houver qualquer dúvida razoável de que qualquer informação fornecida por você esteja errada, inverídica, desatualizada ou incompleta, a Econobit terá o direito de enviar-lhe um aviso para exigir correções, remover informações relevantes diretamente e, como o caso pode ser, encerrar todos ou parte dos Serviços para você. Você será único e totalmente responsável por qualquer perda ou despesa incorrida durante o uso do Serviço da Econobit se você não puder ser contatado através das informações de contato fornecidas. Você reconhece e concorda que tem a obrigação de manter todas as informações fornecidas em dia se houver alguma alteração.`,
	'terms_s5b_text_3': `Ao se inscrever para a conta, você autoriza a Econobit a fazer consultas, diretamente ou por terceiros, que a Econobit considera necessária para verificar sua identidade ou protegê-lo e/ou Econobit contra fraudes ou outros crimes financeiros, e tomar medidas que a Econobit considere razoavelmente necessária com base nos resultados de tais inquéritos. Você também reconhece e concorda que suas informações pessoais podem ser divulgadas para agências de referência de crédito e prevenção de fraudes ou crimes financeiros e que essas agencias podem responder às nossas perguntas na íntegra.`,
	'terms_s5_bold_c': `c. Requisitos de Uso de Contas`,
	'terms_s5c_text_1': `As contas só podem ser usadas pela pessoa cujo nome estão registrados. A Econobit reserva-se o direito de suspender, congelar ou cancelar contas que sejam usadas por pessoas que não sejam as pessoas cujos nomes estão registrados. Você deve notificar imediatamente a Econobit se suspeitar ou tomar conhecimento do uso não autorizado do seu nome de usuário e senha. A Econobit não será responsável por qualquer perda ou dano decorrente de qualquer uso de sua Conta por você ou por terceiros (autorizado ou não por você).`,
	'terms_s5_bold_d': `d. Segurança de Contas`,
	'terms_s5d_text_1': `A Econobit se esforça para manter a segurança desses fundos de usuário que nos foram confiados e implementou proteções padrão do setor para os Serviços. No entanto, existem riscos que são criados por ações individuais do Usuário. Você concorda em considerar suas credenciais de acesso, como nome de usuário e senha como informações confidenciais e não divulgar tais informações a terceiros. Você também concorda que sozinho você é responsável por tomar as precauções de segurança necessárias para proteger sua própria conta e informações pessoais.`,
	'terms_s5d_text_2': `Você será o único responsável pela segurança de sua conta Econobit e senha por conta própria, e você será responsável por todas as atividades sob Conta e Econobit não será responsável por qualquer perda ou consequências de uso autorizado ou não autorizado de suas credenciais de Conta, incluindo, mas não se limitando à divulgação de informações, postagem de informações, consentimento ou envio de várias regras e acordos clicando no site, renovação on-line do contrato, etc.`,
	'terms_s5d_text_3': `Ao criar uma Conta, você concorda que:`,
	'terms_s5d_text_4': `(I) você notificará a Econobit imediatamente se estiver ciente de qualquer uso não autorizado de sua conta Econobit e senha por qualquer pessoa ou quaisquer outras violações às regras de segurança;`,
	'terms_s5d_text_5': `(II) você observará estritamente a segurança, autenticação, negociação, cobrança, mecanismo de retirada ou procedimentos do site/serviço; e`,
	'terms_s5d_text_6': `(III) você vai sair do site tomando as medidas adequadas no final de cada visita.`,
	'terms_s5_bold_e': `e. Dados Pessoais`,
	'terms_s5e_text_pre': `Seus Dados Pessoais serão protegidos e mantidos em sigilo, mas sujeitos às disposições de qualquer lei aplicável, seus Dados Pessoais poderão, dependendo dos produtos ou serviços em causa, serem divulgados a terceiros:`,
	'terms_s5e_text_1': `(I) contrapartes de transação;`,
	'terms_s5e_text_2': `(II) outras divisões ou entidades dentro da Econobit Ltd.;`,
	'terms_s5e_text_3': `(III) nossos parceiros de joint venture/aliança e parceiros de negócios;`,
	'terms_s5e_text_4': `(IV) nossos agentes, empresas, fornecedores, prestadores de serviços terceirizados e consultores especializados que foram contratados para nos fornecer serviços administrativos, financeiros, de pesquisa, operacionais, de TI ou outros, como telecomunicações, tecnologia da informação, pagamento, folha de pagamento, processamento, treinamento, pesquisa de mercado, armazenamento e arquivamento;`,
	'terms_s5e_text_5': `(V) quaisquer parceiros comerciais de terceiros que ofereçam bens e serviços ou patrocinem concursos ou outros programas promocionais, em conjunto conosco ou não;`,
	'terms_s5e_text_6': `(VI) seguradoras ou investigadores de seguros e prestadores de serviços de crédito;`,
	'terms_s5e_text_7': `(VII) a Agência de Crédito, ou em caso de inadimplência ou disputas, quaisquer agências de cobrança de dívidas ou centros de resolução de litígios;`,
	'terms_s5e_text_8': `(VIII) qualquer parceiro de negócios, investidor, cessionário ou transferido (real ou prospectivo) para facilitar transações de ativos comerciais (que possam se estender a qualquer fusão, aquisição ou venda de ativos) envolvendo o grupo Econobit;`,
	'terms_s5e_text_9': `(IX) nossos conselheiros profissionais, como nossos auditores e advogados;`,
	'terms_s5e_text_10': `(X) reguladores governamentais relevantes ou autoridade ou agência de aplicação da lei para cumprir quaisquer leis ou regras e regulamentos impostos por qualquer autoridade governamental;`,
	'terms_s5e_text_11': `(XI) qualquer pessoa a quem transferir ou possa transferir nossos direitos e obrigações;`,
	'terms_s5e_text_12': `(XII) bancos, empresas de cartão de crédito e seus respectivos prestadores de serviços; e`,
	'terms_s5e_text_13': `(XIII) qualquer outra parte como pode ser consentido por você, conforme especificado por esse indivíduo ou no contrato aplicável.`,
	'terms_title_6': `6. Diretrizes para o Uso dos Serviços`,
	'terms_s6_bold_a': `a. Licença`,
	'terms_s6a_text_1': `Sujeito à sua contínua conformidade com os termos e condições expressos destes Termos, a Econobit fornece a você uma licença revogável, limitada, livre de royalties, não exclusiva, intransferível e não sublicável para acessar e usar os Serviços em seu computador ou outro dispositivo compatível com internet apenas para seu uso pessoal e interno. Você não tem permissão para usar os Serviços para qualquer revenda ou uso comercial, inclusive para fazer negócios em nome de outra pessoa ou entidade. Todo esse uso é expressamente proibido e constituirá uma violação material destes Termos. O layout de conteúdo, a formatação e os recursos e os privilégios de acesso para os Serviços serão especificados pela Econobit a seu exclusivo critério. Todos os direitos não expressamente concedidos nestes Termos são reservados. Portanto, você está proibido de utilizar os Serviços de qualquer maneira que não seja expressamente e inequivocamente autorizado por estes Termos.`,
	'terms_s6a_text_2': `Estes Termos fornecem apenas uma licença limitada para acessar e usar os Serviços. Portanto, você concorda que a Econobit não transfere nenhuma propriedade ou interesse de propriedade intelectual ou título para os Serviços ou qualquer propriedade intelectual Econobit para você ou qualquer outra pessoa relacionada com o seu uso dos Serviços. Todo texto, gráficos, interfaces de usuário, interfaces visuais, fotografias, sons, obras de arte, código de computador (incluindo código html), programas, software, produtos, informações e documentação, bem como o design, estrutura, seleção, coordenação, expressão, "olhar e sentir", e arranjo de qualquer conteúdo contido ou disponível através dos Serviços são exclusivamente de propriedade, controlado e/ou licenciado pela Econobit ou seus membros, parceiros, licenciadores ou afiliados.`,
	'terms_s6a_text_3': `A Econobit será dona de qualquer comentário, sugestões, ideias ou outras informações ou materiais sobre a Econobit ou os Serviços que você fornecer, seja por e-mail, através dos Serviços ou de outra forma ("Feedback"). Você atribui à Econobit tudo bem, título e interesse ao Feedback juntamente com todos os direitos de propriedade intelectual associados. Você não terá direito, e renuncia a qualquer pedido, reconhecimento ou compensação com base em qualquer Comentário ou quaisquer modificações feitas com base em qualquer Feedback.`,
	'terms_s6_bold_b': `b. Restrições`,
	'terms_s6b_text_pre': `Quando você usa os Serviços, você concorda e concorda em observar o seguinte:`,
	'terms_s6b_text_1': `(I) Todas as atividades que você realizar durante a utilização dos Serviços estarão em conformidade com os requisitos das leis, regulamentos aplicáveis, bem como as várias diretrizes da Econobit;`,
	'terms_s6b_text_2': `(II) O uso dos Serviços não violará interesses públicos, éticos públicos ou interesses legítimos de outros, incluindo tomar qualquer ação que interfira, interrompa, afete negativamente ou iniba outros Usuários a usar os Serviços;`,
	'terms_s6b_text_3': `(III) Você concorda em não usar os serviços para se envolver em manipulação de mercado (como esquemas de bomba e despejo, negociação de lavagem, auto-negociação, execução frontal, recheio de cotações e falsificação ou camadas, independentemente de proibido por lei);`,
	'terms_s6b_text_4': `(IV) Os seguintes usos comerciais de dados econobit são proibidos a menos que o consentimento por escrito da Econobit seja concedido:`,
	'terms_s6b_text_4_1': `1) Serviços de troca que usam cotações ou informações de livro de pedidos da Econobit.`,
	'terms_s6b_text_4_2': `2) Serviços de alimentação de dados ou fluxo de dados que fazem uso de quaisquer dados de mercado da Econobit.`,
	'terms_s6b_text_4_3': `3) Quaisquer outros sites/aplicativos/serviços que cobrem ou monetizem comercialmente (inclusive através de taxas de publicidade ou de referência), dados de mercado obtidos da Econobit.`,
	'terms_s6b_text_5': `(V) Você não pode modificar, reproduzir, duplicar, copiar, baixar, armazenar, transmitir, divulgar, transferir, desmontar, transmitir, publicar, remover ou alterar quaisquer avisos ou rótulos proprietários, licença, sublicencia, vender, espelhar, enquadrar, alugar, alugar, alugar, rotular privada, conceder um interesse de segurança, criar obras derivadas ou explorar as Propriedades, ou qualquer parte das Propriedades sem o consentimento prévio por escrito da Econobit.;`,
	'terms_s6b_text_6': `(VI) Você não pode (I) usar qualquer "link profundo", "raspagem de página", "robô", "aranha" ou outro dispositivo automático, programa, script, algoritmo ou metodologia, ou qualquer processo manual semelhante ou equivalente, para acessar, adquirir, copiar ou monitorar qualquer parte das Propriedades ou de qualquer forma reproduzir ou contornar a estrutura de navegação ou apresentação dos Serviços para obter ou tentar obter quaisquer materiais, documentos, ou informações através de quaisquer meios não disponibilizados propositalmente através dos Serviços, (II) tentar obter acesso não autorizado a qualquer parte ou recurso das Propriedades ou quaisquer outros sistemas ou redes conectadas aos Serviços ou a qualquer servidor Econobit ou a qualquer um dos serviços oferecidos ou através dos Serviços, por meio de hacking, senha "mineração", ou qualquer outro meio ilegítimo ou proibido, (III) sonda, digitalização ou teste a vulnerabilidade dos Serviços ou qualquer rede conectada às Propriedades, nem viole as medidas de segurança ou autenticação nos Serviços ou qualquer rede conectada aos Serviços, (IV) busca reversa, rastreamento ou procure rastrear qualquer informação sobre qualquer outro usuário ou visitante dos Serviços, (V) tomar qualquer ação que imponha uma carga irracional ou desproporcionalmente grande na infraestrutura dos Serviços ou sistemas ou redes da Econobit ou quaisquer sistemas ou redes conectados aos Serviços, (VI) usar qualquer dispositivo, software ou rotina para interferir no bom funcionamento dos Serviços ou qualquer transação realizada nos Serviços, ou com o uso dos Serviços por qualquer outra pessoa, (VII) forjar cabeçalhos, personificar uma pessoa ou manipular identificadores de outra forma, a fim de disfarçar sua identidade ou a origem de qualquer mensagem ou transmissão que você enviar para os Serviços, ou (VIII) usar os Serviços de forma ilegal.`,
	'terms_s6b_text_7': `Ao acessar o Serviço, você concorda que a Econobit terá o direito de investigar qualquer violação destes Termos, determinar unilateralmente se você violou estes Termos e tomar medidas para aplicar regras relevantes sem receber seu consentimento ou dar aviso prévio a você. Exemplos de tais ações incluem, mas não se limitam a:`,
	'terms_s6b_text_7_1': `bloquear e cancelar solicitações de ordens`,
	'terms_s6b_text_7_2': `congelando sua conta`,
	'terms_s6b_text_7_3': `relatando o incidente às autoridades`,
	'terms_s6b_text_7_4': `publicando as supostas violações e ações que foram tomadas`,
	'terms_s6b_text_7_5': `excluindo qualquer informação que você publicou que está em violação`,
	'terms_title_7': `7. Ordens e Taxas de Serviço`,
	'terms_s7_bold_a': `a. Ordens`,
	'terms_s7a_text_1': `Ao colocar uma instrução para efetuar uma negociação usando os Serviços (uma "Ordem"), sua Conta será atualizada para refletir a Ordem aberta e sua Ordem será incluída no livro de ordens da Econobit para combinar com ordens de outros Usuários. Se toda ou uma parte da sua Ordem for combinada com outro Usuário, os Serviços executarão uma troca (uma "Negociação"). Após a execução de uma Negociação, sua Conta será atualizada para refletir que a Ordem foi encerrada devido à execução total ou atualizada para refletir qualquer cumprimento parcial da Ordem. As ordens permanecerão abertas até que sejam executadas ou canceladas totalmente, de acordo com a subseção (b) abaixo. Para efeitos de efetivação de uma Negociação, você autoriza a Econobit a assumir o controle temporário da Moeda Digital que você está colocando na Negociação.`,
	'terms_s7_bold_b': `b. Cancelamentos`,
	'terms_s7b_text_1': `Você só pode cancelar um pedido iniciado através dos Serviços se esse cancelamento ocorrer antes que seu Pedido tenha sido combinado com uma ordem de outro usuário. Uma vez que seu Pedido tenha sido combinado com uma ordem de outro usuário, você não pode alterar, retirar ou cancelar sua autorização para a Econobit para completar tal Ordem. Se qualquer ordem tiver sido parcialmente compatível, você pode cancelar a parte incomparável da Ordem a menos e até que a parte incomparável tenha sido combinada. A Econobit reserva-se o direito de recusar qualquer solicitação de cancelamento associada a uma Ordem depois de ter enviado tal Ordem. Se você tiver uma quantidade insuficiente de Moeda Digital em sua Conta para cumprir uma Ordem, a Econobit pode cancelar toda a Ordem ou pode cumprir uma Ordem parcial que pode ser coberta pela Moeda Digital em sua Conta (em cada caso, após deduzir quaisquer taxas a pagar à Econobit em relação com a Negociação conforme descrito na subseção (c) abaixo).`,
	'terms_s7_bold_c': `c. Taxas`,
	'terms_s7c_text_1': `Você concorda em pagar à Econobit as taxas estabelecidas em https://econobit.io/fees que podem ser atualizadas de tempos em tempos a critério exclusivo da Econobit. Tais taxas atualizadas serão aplicadas prospectivamente a quaisquer negociações ou outras transações que ocorram após a data efetiva de tais taxas atualizadas. Você autoriza a Econobit a remover quaisquer valores de sua Conta para quaisquer taxas aplicáveis devidos por você nestes Termos.`,
	'terms_s7_bold_d': `d. Pagamentos Fiduciários`,
	'terms_s7d_text_1': `A fim de fornecer suporte operacional adequado para você em relação a reembolsos e cancelamentos (quando aplicável), as seguintes entidades Econobit serão responsáveis pelas transações realizadas nas seguintes moedas:`,
	'terms_s7d_text_2': `Real Brasileiro (BRL) - em relação às transações disponíveis (salvo especificação em contrário) em www.econobit.io, ECONOBIT SERVICOS ONLINE LTDA. (número de inscrição: 27.493.748/0001-20) e ter sua sede na RUA VERGUEIRO, 1421, SALA 510, BAIRRO PARAISO, SÃO PAULO/SP, 04101-100, BRASIL.`,
	'terms_title_8': `8. Responsabilidade`,
	'terms_s8_bold_a': `a. Isenção de Responsabilidade de Garantias`,
	'terms_s8a_text_1': `Na medida do permitido pela lei aplicável, os serviços, os materiais Econobit e qualquer produto, serviço ou outro item fornecido por ou em nome da Econobit são fornecidos em uma base "Como está" e "Conforme disponível" e a Econobit expressamente se isenta, e você renuncia, toda e qualquer outra garantia de qualquer tipo, expressa ou implícita, incluindo, sem limitação, garantias implícitas de comercialização, adequação para um propósito específico, título ou não-infração ou garantias decorrentes de curso de desempenho, curso de negociação ou uso no comércio. Sem limitar o anterior, a Econobit não representa ou garante que o site, os serviços ou materiais Econobit sejam precisos, completos, confiáveis, atuais, livres de erros ou livres de vírus ou outros componentes nocivos. A Econobit não garante que qualquer ordem será executada, aceita, gravada ou permanece aberta. Exceto pelas declarações expressas estabelecidas neste contrato, você reconhece e concorda que não se baseou em nenhuma outra declaração ou entendimento, seja escrito ou oral, no que diz respeito ao seu uso e acesso aos serviços. Sem limitar o precedente, você entende e concorda que a Econobit não será responsável por quaisquer perdas ou danos decorrentes ou relacionados a: (a) qualquer imprecisão, defeito ou omissão de dados de preço da moeda digital, (b) qualquer erro ou atraso na transmissão de tais dados, (c) interrupção em tais dados e (d) quaisquer danos incorridos pelas ações de outros usuários, omissões ou violação deste acordo.`,
	'terms_s8a_text_2': `A isenção de responsabilidade das garantias implícita contidas aqui não pode ser aplicada se e na medida em que for proibida pela lei aplicável da jurisdição em que você reside.`,
	'terms_s8_bold_b': `b. Isenção de Danos e Limitação de Responsabilidade`,
	'terms_s8b_text_1': `Na medida do tempo permitido pela lei aplicável, em nenhum caso a Econobit, suas afiliadas e seus respectivos acionistas, membros, diretores, diretores, funcionários, advogados, agentes, representantes, fornecedores ou contratados serão responsáveis por quaisquer danos ou passivos incidentais, indiretos, especiais, punitivos, consequenciais ou similares (incluindo, sem limitação, danos por perda de dados, informações, receitas, lucros ou outros negócios ou benefícios financeiros) decorrentes ou em qualquer conexão com os serviços, qualquer desempenho ou não desempenho dos serviços, ou qualquer outro produto, serviço ou outro item fornecido por ou em nome da Econobit e suas afiliadas, seja sob contrato, estatuto, responsabilidade estrita ou outra teoria, mesmo que a Econobit tenha sido avisada da possibilidade de tais danos, exceto na extensão de uma determinação judicial final de que tais danos foram resultado da negligência grosseira da Econobit, fraude, má conduta intencional ou violação intencional da lei. Algumas jurisdições não permitem a exclusão ou limitação de danos incidentais ou consequentes, portanto, a limitação acima pode não se aplicar a você.`,
	'terms_s8b_text_2': `Não obstante o precedente, em nenhum caso será a responsabilidade da Econobit, de suas afiliadas e de seus respectivos acionistas, membros, diretores, diretores, funcionários, advogados, agentes, representantes, fornecedores ou contratados decorrentes ou em conexão dos serviços, qualquer atuação ou não dos serviços, ou qualquer outro produto, serviço ou outro item fornecido por ou em nome da Econobit ou de suas afiliadas, seja sob contrato, estatuto, responsabilidade estrita ou outra teoria, exceda o valor das taxas pagas por você à Econobit sob este contrato no período de doze meses imediatamente anterior ao evento que dá origem ao pedido de responsabilidade.`,
	'terms_s8_bold_c': `c. Indenização`,
	'terms_s8c_text_1': `Você concorda em indenizar e isentar a Econobit, suas afiliadas, empreiteiros, licenciadores e seus respectivos diretores, diretores, funcionários e agentes de e contra quaisquer reivindicações, ações, processos, investigações, demandas, processos, custos, despesas e danos (incluindo honorários advocatícios, multas ou penalidades impostas por qualquer autoridade reguladora) decorrentes ou relacionadas a (I) seu uso ou conduta em conexão com, os Serviços, (II) sua violação ou nossa aplicação destes Termos, ou (III) sua violação de qualquer lei, regulamento ou direitos aplicáveis de qualquer terceiro durante o uso do Serviço. Se você for obrigado a indenizar a Econobit, suas afiliadas, empreiteiros, licenciadores e seus respectivos diretores, diretores, funcionários ou agentes de acordo com esta cláusula, a Econobit terá o direito, a seu exclusivo critério, de controlar qualquer ação ou processo e determinar se a Econobit deseja resolver, e se for o caso, em que termos.`,
	'terms_title_9': `9. Anúncios`,
	'terms_s9_text_1': `Por favor, esteja ciente de que todos os anúncios oficiais, notícias, promoções, competições e airdrops serão listados em https://support.econobit.io/category/announcements/ onde pedimos a todos os usuários que se refira regularmente. A Econobit não será responsabilizado ou responsável de qualquer forma de compensação caso os usuários incorram em perdas pessoais decorrentes de ignorância ou negligência dos anúncios.`,
	'terms_title_10': `10. Rescisão de Contrato`,
	'terms_s10_text_1': `Você concorda que a Econobit terá o direito de suspender imediatamente sua conta (e quaisquer contas benéficas de entidades ou afiliados), congelar ou bloquear os fundos em todas essas contas e suspender seu acesso à Econobit por qualquer motivo, incluindo se suspeitar que tais contas estejam violando estes Termos, nossa Política de Privacidade, ou quaisquer leis e regulamentos aplicáveis. Você concorda que a Econobit não será responsável por qualquer modificação permanente ou temporária, suspensão ou rescisão de sua Conta ou acesso a toda ou qualquer parte dos Serviços. A Econobit terá o direito de manter e usar os dados da transação ou outras informações relacionadas a tais contas. Os controles de conta acima também podem ser aplicados nos seguintes casos:`,
	'terms_s10_text_1_1': `A conta está sujeita a um processo governamental, investigação criminal ou outro litígio pendente;`,
	'terms_s10_text_1_2': `Detectamos atividade incomum na conta;`,
	'terms_s10_text_1_3': `Detectamos acesso não autorizado à conta;`,
	'terms_s10_text_1_4': `Somos obrigados a fazê-lo por uma ordem judicial ou comando por uma autoridade reguladora/governamental`,
	'terms_s10_text_2': `Em caso de qualquer um dos seguintes eventos, a Econobit terá o direito de rescindir diretamente este contrato cancelando sua Conta, e terá o direito de congelar permanentemente (cancelar) as autorizações de sua conta na Econobit e retirar a conta econobit correspondente:`,
	'terms_s10_text_2_1': `após a Econobit encerrar os serviços para você;`,
	'terms_s10_text_2_2': `você supostamente registra ou registra em nome de qualquer outra pessoa como novo usuário, direta ou indiretamente;`,
	'terms_s10_text_2_3': `as informações que você forneceu são inverídicas, imprecisas, desatualizadas ou incompletas;`,
	'terms_s10_text_2_4': `quando estes Termos são alterados, você expressamente declara e notifica Econobit de sua relutância em aceitar os Termos alterados;`,
	'terms_s10_text_2_5': `você solicita que os Serviços sejam encerrados; e`,
	'terms_s10_text_2_6': `quaisquer outras circunstâncias em que a Econobit considere que deve encerrar os serviços.`,
	'terms_s10_text_3': `Caso sua Conta seja encerrada, a conta e as informações transacionais necessárias para atender aos padrões de retenção de dados serão armazenadas com segurança por 5 anos. Além disso, se uma transação estiver inacabada durante o processo de rescisão da Conta, a Econobit terá o direito de notificar sua contraparte da situação naquele momento. Você reconhece que uma saída de conta iniciada pelo usuário (direito de eliminação sob GDPR ou outras regulamentações equivalentes) também será submetida ao protocolo de rescisão indicado acima.`,
	'terms_s10_text_4': `Se a Econobit receber a notificação de que quaisquer fundos mantidos em sua Conta são acusados de terem sido roubados ou não são legalmente possuídos por você, a Econobit pode, mas não tem obrigação de, colocar um controle administrativo sobre os fundos afetados e sua Conta. Se a Econobit colocar um controle administrativo sobre alguns ou todos os seus fundos ou conta, a Econobit pode continuar com essa espera até que a disputa tenha sido resolvida e evidências da resolução aceitável para a Econobit foram fornecidas à Econobit de forma aceitável para a Econobit. A Econobit não se envolverá em tal disputa ou na resolução da disputa. Você concorda que a Econobit não terá responsabilidade ou responsabilidade por tal porção, ou por sua incapacidade de retirar fundos ou executar negociações durante o período de tal espera.`,
	'terms_s10_bold_a': `a. Fundos restantes após a rescisão da conta`,
	'terms_s10a_text_1': `Exceto conforme estabelecido na subseção (b) abaixo, uma vez que a Conta seja fechada/retirada, todo o saldo restante (que inclui encargos e passivos devidos à Econobit) na conta será pago imediatamente à Econobit. Após o pagamento de todos os encargos pendentes à Econobit (se houver), o Usuário terá 5 dias úteis para sacar todos os recursos da Conta.`,
	'terms_s10_bold_b': `b. Fundos restantes após a rescisão da conta por fraude, violação da lei ou violação desses termos`,
	'terms_s10b_text_1': `A Econobit mantém a custódia total dos fundos e dados/informações do Usuário que podem ser entregues às autoridades governamentais em caso de suspensão/encerramento de contas decorrentes de investigações de fraude, violação de investigações policiais ou violação destes Termos.`,
	'terms_title_11': `11. Sem Conselhos Financeiros`,
	'terms_s11_text_1': `A Econobit não é seu corretor, intermediário, agente ou conselheiro e não tem nenhuma relação fiduciária ou obrigação com você em relação a quaisquer negociações ou outras decisões ou atividades efetivadas por você usando os Serviços. Nenhuma comunicação ou informação fornecida a você pela Econobit destina-se como, ou deve ser considerado ou interpretado como, conselhos de investimento, conselhos financeiros, conselhos comerciais ou qualquer outro tipo de conselho. Todas as negociações são executadas automaticamente, com base nos parâmetros de suas instruções de ordem e de acordo com os procedimentos de execução comercial postados, e você é o único responsável por determinar se qualquer investimento, estratégia de investimento ou transação relacionada é apropriada para você com base em seus objetivos de investimento pessoal, circunstâncias financeiras e tolerância ao risco. Você deve consultar seu profissional legal ou tributário sobre sua situação específica. A Econobit não recomenda que qualquer Moeda Digital seja comprada, ganha, vendida ou detida por você. Antes de tomar a decisão de comprar, vender ou manter qualquer Moeda Digital, você deve realizar sua própria due diligence e consultar seus consultores financeiros antes de tomar qualquer decisão de investimento. A Econobit não será responsabilizado pelas decisões que você tomar para comprar, vender ou manter a Moeda Digital com base nas informações fornecidas pela Econobit.`,
	'terms_title_12': `12. Cumprimento das Leis Locais`,
	'terms_s12_text_1': `É responsabilidade do Usuário cumprir as leis locais em relação ao uso legal da Econobit em sua jurisdição local. Os usuários também devem considerar, na medida de sua lei local, todos os aspectos da tributação, a retenção, cobrança, emissão de remessas às suas autoridades fiscais competentes. Todos os Usuários da Econobit e de qualquer de seus serviços reconhecem e declaram que a fonte de seus fundos vem de uma forma legítima e não são derivados de atividades ilegais. A Econobit mantém uma postura de cooperação com as autoridades policiais globalmente e não hesitará em apreender, congelar, encerrar a conta e os fundos dos Usuários que são sinalizados ou investigados por mandato legal.`,
	'terms_title_13': `13. Política de Privacidade`,
	'terms_s13_text_1': `O acesso aos Serviços exigirá a apresentação de certas informações pessoalmente identificáveis. Por favor, revise a Política de Privacidade da Econobit encontrada em https://econobit.io/privacyPolicy para um resumo das práticas da Econobit relacionadas à coleta e uso de informações pessoalmente identificáveis.`,
	'terms_title_14': `14. Resolução De Disputas: Fórum, Arbitragem, Renúncia De Ação Coletiva`,
	'terms_s14_text_1': `Leia esta seção com atenção, pois envolve uma renúncia de certos direitos para entrar com processos legais, inclusive como uma ação coletiva para os residentes dos EUA.`,
	'terms_s14_bold_a': `a. Notificação de Disputa.`,
	'terms_s14a_text_1': `Entre em contato com a Econobit primeiro! A Econobit quer resolver suas preocupações sem recorrer a processos legais formais. Antes de entrar com uma reclamação, você concorda em tentar resolver a disputa informalmente entrando em contato com a Econobit primeiro através de https://support.econobit.io/request.`,
	'terms_s14_bold_b': `b. Acordo com a Arbitragem.`,
	'terms_s14b_text_1': `Você e a Econobit concordam em resolver quaisquer reivindicações relativas a este Contrato (incluindo qualquer questão relativa à sua existência, validade, rescisão ou quaisquer serviços ou produtos fornecidos e quaisquer representações feitas por nós) através de arbitragem final e vinculativa, exceto conforme estabelecido sob exceções ao acordo de arbitragem abaixo. Você concorda em primeiro nos dar uma oportunidade de resolver quaisquer reivindicações entrando em contato conosco conforme estabelecido na subseção (a) acima. Se não conseguirmos resolver suas reivindicações no prazo de 60 dias após o recebimento do aviso prévio, você poderá buscar alívio por meio de arbitragem ou no Juizado Especial Cível Digital ("JECD"), conforme estabelecido abaixo.`,
	'terms_s14_bold_c': `c. Procedimento arbitral.`,
	'terms_s14c_text_1': `Ou você ou a Econobit podem apresentar uma disputa (depois de ter feito esforços de boa fé para resolver tal disputa de acordo com as subseções (a) e (b) acima) para resolução final, vinculante por arbitragem sob as regras arbitrais da Câmara Empresarial de Mediação e Arbitragem da Associação Comercial ("CEMAAC"), que são consideradas incorporadas por referência. O tribunal arbitral será composto por um árbitro único a ser nomeado pelo Presidente do CEMAAC. A língua das audiências de arbitragem será inglês e a sede, ou lugar legal, de arbitragem será Cingapura. O julgamento sobre qualquer sentença arbitral pode ser inscrito em qualquer tribunal com jurisdição sobre a parte (ou os bens da parte) devidos e devido a tal sentença.`,
	'terms_s14_bold_d': `d. Exceções.`,
	'terms_s14d_text_1': `Qualquer das partes pode, em vez disso, optar por afirmar as reivindicações na JECD se as reivindicações se enquadrarem na jurisdição do JECD, e qualquer das partes pode buscar auxílio cautelar ou outro alívio equitário urgente em um tribunal de jurisdição competente. No entanto, para evitar dúvidas, quando as reivindicações estão fora da jurisdição da JECD, as reivindicações serão referidas e finalmente resolvidas pela arbitragem do CEMAAC.`,
	'terms_s14_bold_e': `e. Observe.`,
	'terms_s14e_text_1': `Para iniciar um processo de arbitragem, você deve enviar uma carta solicitando arbitragem e descrevendo suas reivindicações para support@econobit.io . Se solicitarmos arbitragem contra você, lhe daremos aviso prévio no endereço de e-mail ou endereço de rua que você forneceu. As regras do CEMAAC e as instruções de arquivamento estão disponíveis em https://camara.acsp.com.br.`,
	'terms_title_15': `15. Diversos`,
	'terms_s15_bold_a': `a. Partidos Independentes.`,
	'terms_s15a_text_1': `A Econobit é uma empresa independente e não um agente seu na realização destes Termos. Estes Termos não devem ser interpretados como evidência de associação, joint venture, parceria ou franquia entre as partes.`,
	'terms_s15_bold_b': `b. Acordo total.`,
	'terms_s15b_text_1': `Estes Termos constituem todo o acordo entre as partes relativas ao uso dos Serviços e substituirão todos os acordos prévios entre as partes, seja, escritos ou orais. Não será utilizado o uso de comércio ou outra prática ou método regular de negociação entre as partes para modificar, interpretar, complementar ou alterar os termos destes Termos.`,
	'terms_s15_bold_c': `c. Força Maior.`,
	'terms_s15c_text_1': `A Econobit não será responsável por qualquer atraso ou falha no desempenho conforme exigido por estes Termos devido a qualquer causa ou condição além do controle razoável da Econobit.`,
	'terms_s15_bold_d': `d. Severidade.`,
	'terms_s15d_text_1': `Se qualquer parte destes Termos for considerada inválida ou inexequível, tal invalidade ou aplicabilidade não afetará as outras disposições destes Termos, que permanecerão em pleno vigor e efeito, e a parcela inválida ou inexequível terá efeito na maior medida possível.`,
	'terms_s15_bold_e': `e. Atribuição.`,
	'terms_s15e_text_1': `Você não pode atribuir ou transferir qualquer direito de usar os Serviços ou quaisquer de seus direitos ou obrigações sob estes Termos sem consentimento prévio por escrito de nós, inclusive por operação da lei ou em conexão com qualquer mudança de controle. A Econobit pode atribuir ou transferir qualquer ou todos os seus direitos ou obrigações nos termos ou em parte destes Termos, total ou parcialmente, sem aviso prévio ou obtenção de seu consentimento ou aprovação.`,
	'terms_s15_bold_f': `f. Renúncia. `,
	'terms_s15f_text_1': `A falha de uma parte em exigir o desempenho de qualquer disposição não afetará o direito dessa parte de exigir desempenho em qualquer momento posterior, nem uma renúncia de qualquer violação ou inadimplência destes Termos ou qualquer disposição destes Termos constituirá uma renúncia de qualquer violação ou inadimplência subsequente ou uma renúncia da própria disposição.`,
	'terms_s15_bold_g': `g. Aviso de isenção de responsabilidade do site de terceiros.`,
	'terms_s15g_text_1': `Quaisquer links para sites de terceiros dos Serviços não implicam o endosso pela Econobit de quaisquer produtos, serviços ou informações nele apresentados, nem a Econobit garante a exatidão das informações contidas neles. Além disso, como a Econobit não tem controle sobre os termos de uso ou práticas de privacidade de sites de terceiros, você deve ler e entender essas políticas cuidadosamente.`,
	'terms_s15_bold_h': `h. Informações de contato.`,
	'terms_s15h_text_1': `Para obter mais informações sobre a Econobit, você pode consultar a empresa e licenciar informações encontradas no site. Se você tiver dúvidas sobre este contrato, por favor, fique à vontade para entrar em contato com a Econobit para esclarecimentos através de nossa equipe de Suporte ao Cliente em https://support.econobit.io/request.`,
	'delete_account': 'Excluir Conta',
	'account_deletion': 'Exclusão de Conta',
	'request_account_deletion': 'Solicitar Exclusão de Conta',
	'confirm_account_deletion': 'Confirmar Exclusão de Conta',
	'delete_acc_warning': 'Certifique-se de que deseja excluir sua conta. Trataremos do processo com você por e-mail. Você não poderá usar o mesmo e-mail novamente para criar outra conta.',
	'deposit_paused': 'Depósito temporariamente indisponível.',
	'withdraw_paused': 'Retirada temporariamente indisponível.',
	'withdraw_temporary_unavailable': 'Retirada temporariamente indisponível.',

}