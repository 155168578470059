import { ActionCreators, IMarkets, IOrder } from 'exchange-common'
import * as React from 'react'
import Modal from 'react-responsive-modal'
import { RouteComponentProps } from 'react-router'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	openOrders: IOrder[]
	market: string

	markets: IMarkets
	isLogged: boolean
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class TradingComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { openModal: boolean, cancelOrder: IOrder }> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const { openOrders, markets } = this.props
		const priceDecimals = markets.list[markets.current].priceDecimals
		const amountDecimals = markets.list[markets.current].amountDecimals
		const otherCoin = this.props.market.split('-')[0]
		const baseCoin = this.props.market.split('-')[1]
		const openModal = (this.state && this.state.openModal !== undefined) ? this.state.openModal : false
		const cancelOrder = (this.state && this.state.cancelOrder !== undefined) ? this.state.cancelOrder : null
		translate.language = this.props.lang

		return (
			<div className='trading'>
				<Modal open={openModal} onClose={this.onCloseModal} center={true}>
					<h2>{translate.text('cancel_your_order')}</h2>
					{
						(cancelOrder && cancelOrder.orderId)
							? <div className='modalForm'>
								<div className='modalFormRow'>
									<label htmlFor='side' className='modalFormLabel'>{translate.text('side')}</label>
									<div className='modalFormText'><span className={(cancelOrder.side === 'buy') ? 'caps green' : 'caps red'}>{cancelOrder.side === 'buy' ? translate.text('buy') : translate.text('sell')}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='size' className='modalFormLabel'>{translate.text('size')}</label>
									<div className='modalFormText'><span>{cancelOrder.amount.toFixed(amountDecimals)} {otherCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='filled' className='modalFormLabel'>{translate.text('filled')}</label>
									<div className='modalFormText'><span>{cancelOrder.filled.toFixed(amountDecimals)} {otherCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('price')}</label>
									<div className='modalFormText'><span>{cancelOrder.type === 'market' ? '-' : cancelOrder.price.toFixed(priceDecimals)} {cancelOrder.type === 'market' ? '' : baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='status' className='modalFormLabel'>{translate.text('status')}</label>
									<div className='modalFormText'><span>{cancelOrder.filled > 0 ? translate.text('partial') : translate.text('open')}</span></div>
								</div>
							</div>
							: <div />
					}
					<div className='modalFormConfirm'>
						<button onClick={() => { this.onCloseModal(); this.cancelOrder(cancelOrder) }}>{translate.text('confirm')}</button>
					</div>
				</Modal>
				<div className='tradingBoxTitle'><span>{translate.text('open_orders')}{/* ({markets.current})*/}</span></div>
				<div className='tradingHeader'>
					<div className='tradingHeaderLabel sideColumn'>{translate.text('side')}</div>
					<div className='tradingHeaderLabel'>{translate.text('size_abr')} ({otherCoin})</div>
					<div className='tradingHeaderLabel'>{translate.text('filled_abr')} ({otherCoin})</div>
					<div className='tradingHeaderLabel'>{translate.text('price')} ({baseCoin})</div>
					<div className='tradingHeaderLabel'>{translate.text('status')}</div>
					<div className='tradingHeaderLabel'>{translate.text('actions')}</div>
				</div>
				{this.props.isLogged
					?
					<div className='tradingList'>
						{openOrders.filter(f => f.market === markets.current).map(m =>
							<div className='tradingBody' key={m.orderId}>
								<div className={'tradingBodyItem sideColumn ' + ((m.side === 'buy') ? 'green' : 'red')}><b>{m.side === 'buy' ? translate.text('buy') : translate.text('sell')}</b></div>
								<div className='tradingBodyItem'>{m.amount.toFixed(amountDecimals)}</div>
								<div className='tradingBodyItem'>{m.filled.toFixed(amountDecimals)}</div>
								<div className='tradingBodyItem'>{m.price.toFixed(priceDecimals)}</div>
								<div className='tradingBodyItem'>{m.filled > 0 ? translate.text('partial') : translate.text('open')}</div>
								{/* <div className='cancelOrder' onClick={() => this.cancelOrder({ orderId: m.orderId, market: m.market, side: m.side })}>Cancel</div> */}
								<div className='cancelOrder' onClick={() => this.onOpenModal(m)}>{translate.text('cancel')}</div>
							</div>
						)}
					</div>
					:
					<div className='tradingList' />
				}
			</div>
		)
	}

	onOpenModal = (order: IOrder) => {
		this.setState({ openModal: true, cancelOrder: order })
	}

	onCloseModal = () => {
		this.setState({ openModal: false })
	}
	cancelOrder(order: IOrder | null) {
		// if (confirm('Are you sure?')) { 
		ws.send(ActionCreators.Order.delete(order as IOrder))
		// }
	}
}