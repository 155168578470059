import { ActionTypes, IWithdrawAction, IWithdrawals, Withdrawals } from 'exchange-common'

export const withdrawals = (state: IWithdrawals = new Withdrawals(), action: IWithdrawAction): IWithdrawals => {
	switch (action.type) {
		case ActionTypes.Withdraw.List:
			return {
				...state,
				list: action.withdrawals.list,
				isLoaded: action.withdrawals.isLoaded,
			}
		case ActionTypes.Withdraw.Add:
			return {
				...state,
				list: [
					action.withdraw,
					...state.list,
				]
			}
		case ActionTypes.Withdraw.Update:
			const i = state.list.findIndex(f => f.id === action.withdraw.id)
			return {
				...state,
				list: [
					...state.list.slice(0, i),
					{
						...state.list[i],
						...action.withdraw,
					},
					...state.list.slice(i + 1),
				]
			}
		default:
			return state
	}
}