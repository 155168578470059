import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { Field, reduxForm } from 'redux-form'
import { renderField, isEmail, required, maxLength100 } from './renderField'
import { Api } from '../functions/api'
import { translate } from '../functions/lang'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

let LostPasswordForm = (props: any) => {
	const { handleSubmit, pristine, submitting } = props
	// console.log(props)

	return (
		<div className='lostPasswordBox' >
			<div className='lost2FABox' >
				<span className='lost2FABoxText' >{translate.text('lost_2FA_box_msg_1')} <a href='mailto:support@econobit.io'>{translate.text('email_us')}</a> {translate.text('lost_2FA_box_msg_2')}<br /><br />{translate.text('lost_2FA_box_msg_3')}</span>
			</div>
			<form className='lostPasswordForm' onSubmit={handleSubmit}>
				<h2>{translate.text('request_password_reset')}</h2>
				<p>{translate.text('insert_email_msg')}</p>
				<label htmlFor='email'>{translate.text('email')}</label>
				<Field name='email' component={renderField} type='email' placeholder='your@email.com' validate={[isEmail, required, maxLength100]} />
				<button type='submit' disabled={submitting || pristine}>{translate.text('reset_password')}</button>
			</form>
		</div>
	)
}

LostPasswordForm = reduxForm({
	form: 'lostPassword'
})(LostPasswordForm) as any

export class LostPasswordComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	render() {
		translate.language = this.props.lang

		return (
			<div className='lostPassword'>
				<LostPasswordForm onSubmit={(values: any) => this.onSubmit(values)} />
			</div>

		)
	}

	onSubmit(values: any) {
		Api.post('/security/lostpassword', values)
		this.props.history.push('/sentPassword')
	}
}