import { ActionTypes, IOpenOrdersAction, IOrder } from 'exchange-common'

export const openOrders =
	(state: IOrder[] = [], action: IOpenOrdersAction): IOrder[] => {
		switch (action.type) {
			case ActionTypes.OpenOrders.Load:
				return [
					...action.list,
				]

			case ActionTypes.OpenOrders.Add:
				return [
					action.order,
					...state,
				]

			case ActionTypes.OpenOrders.Update:
				const i = state.findIndex(f => f.orderId === action.order.orderId)

				return [
					...state.slice(0, i),
					action.order,
					...state.slice(i + 1)
				]

			case ActionTypes.OpenOrders.Delete:
				return [
					...state.filter(f => f.orderId !== action.orderId),
				]

			default:
				return state
		}
	}