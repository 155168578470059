import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { TradeHeaderContainer } from '../containers/tradeHeader'
import { BankAccountsMainContainer } from '../containers/bankAccountsMain'
import { SideWalletsContainer } from '../containers/sideWallets'

interface IProps {
	// include component properties here.
}

interface IMatchProps {
	// include any url parameters here.
	
}

export class BankAccountsComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		
	}

	componentWillUnmount() {
		// run before component is unloaded.
		
	}

	render() {
		
		return (
			<div className='bankAccounts'>
				<TradeHeaderContainer />
				<div className='sideBar'>
					<SideWalletsContainer />
				</div>
				<BankAccountsMainContainer />
			</div>
		)
	}
	
}