import { BankAccounts, Banks, Candlesticks, Connection, Currencies, Deposits, Kyc, Markets, Order, Orderbooks, Tickers, Trades, User, Wallets, Withdrawals } from 'exchange-common'
import { createBrowserHistory as createHistory } from 'history'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import { applyMiddleware, compose, createStore, Store } from 'redux'
import { ThemeContainer } from './containers/theme'
import { showGreeting } from './functions/greeting'
import { IState } from './models/state'
import { reducers } from './reducers'
import * as serviceWorker from './serviceWorker'

export const history = createHistory()
const middleware = routerMiddleware(history)

const initialState: IState = {
	user: new User(),
	order: new Order(),
	markets: new Markets(),
	wallets: new Wallets(),
	deposits: new Deposits(),
	withdrawals: new Withdrawals(),
	bankAccounts: new BankAccounts(),
	banks: new Banks(),
	kyc: new Kyc(),
	openOrders: [],
	ordersHistory: [],
	orderbooks: new Orderbooks(),
	tickers: new Tickers(),
	currencies: new Currencies(),
	lastTrades: new Trades(),
	connection: new Connection(),
	trades: [],
	candlesticks: new Candlesticks(),
	style: 'default',
	lang: 'en-us',
	news: [],
	loginHistory: [],
	apiKeys: [],
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store: Store<IState> = createStore(
	reducers,
	initialState,
	composeEnhancers(
		applyMiddleware(middleware)
	)
)

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<ThemeContainer />
		</Router>
	</Provider>,
	document.getElementById('root')
)

showGreeting()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
