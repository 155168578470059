import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { translate } from '../functions/lang'
import { NavLink } from 'react-router-dom'
import { SecurityMainContainer } from '../containers/securityMain'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileSecurityComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		return (
			<div className='profileMain'>
				<div className='profileTabList'>
					<NavLink className='profileTabFirst' activeClassName='profileTabSelected' to='/profile'>{translate.text('profile')}</NavLink>
					<NavLink className='profileTab' activeClassName='profileTabSelected' to='/kyc'>{translate.text('id_verification')}</NavLink>
					<NavLink className='profileTab' activeClassName='profileTabSelected' to='/bankAccounts'>{translate.text('bank_accounts')}</NavLink>
					<NavLink className='profileTab' activeClassName='profileTabSelected' to='/security'>{translate.text('security')}</NavLink>
				</div>
				<SecurityMainContainer />
			</div>
		)
	}

}