import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
// import { NavLink } from 'react-router-dom'

interface IProps {
	// include component properties here.

}

interface IMatchProps {
	// include any url parameters here.

}

export class AccountMenuComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		// const wallets = require('../images/icons/wallets.svg')
		// const orders = require('../images/icons/orders.svg')
		// const ledger = require('../images/icons/ledger.svg')
		// const deposit = require('../images/icons/deposit.svg')
		// const withdraw = require('../images/icons/withdraw.svg')

		return (
			<div className='accountMenu'>
				{/* <div className='sideMenu'>
					<NavLink activeClassName='leftMenuSelected' to='/wallets'><img src={wallets} className='icon' alt='wallet' /><span>Wallets</span></NavLink>
					<NavLink activeClassName='leftMenuSelected' to='/orders'><img src={orders} className='icon' alt='orders' /><span>Orders</span></NavLink>
					<NavLink activeClassName='leftMenuSelected' to='/deposit'><img src={deposit} className='icon' alt='deposit' /><span>Deposits</span></NavLink>
					<NavLink activeClassName='leftMenuSelected' to='/withdraw'><img src={withdraw} className='icon' alt='withdraw' /><span>Withdrawals</span></NavLink>
					<NavLink activeClassName='leftMenuSelected' to='/ledger'><img src={ledger} className='icon' alt='ledger' /><span>Ledger</span></NavLink>
				</div> */}
			</div>
		)
	}

}