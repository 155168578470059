import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { TradeHeaderContainer } from '../containers/tradeHeader'
// import { ProfileMenuContainer } from '../containers/profileMenu'
import { KycMainContainer } from '../containers/kycMain'
import { SideWalletsContainer } from '../containers/sideWallets'

interface IProps {
	// include component properties here.
	
}

interface IMatchProps {
	// include any url parameters here.
	
}

export class KycComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		
	}

	componentWillUnmount() {
		// run before component is unloaded.
		
	}

	render() {
		
		return (
			<div className='kyc'>
				<TradeHeaderContainer />
				<div className='sideBar'>
					<SideWalletsContainer />
					{/* <ProfileMenuContainer /> */}
				</div>
				<KycMainContainer />
			</div>
		)
	}
	
}