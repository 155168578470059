import { IMarketAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { MarketsMainComponent } from '../components/marketsMain'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		markets: state.markets,
		tickers: state.tickers,
		isLogged: state.user.isLogged,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IMarketAction>) {
	return {
	}
}

export const MarketsMainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketsMainComponent))