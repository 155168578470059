import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { SideLoginContainer } from '../containers/sideLogin'
import { SideWalletsContainer } from '../containers/sideWallets'

interface IProps {
	// include component properties here.
	isLogged: boolean
}

interface IMatchProps {
	// include any url parameters here.

}

export class SideAccountComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		return (
			<div className='sideAccount'>
				{this.props.isLogged ? <SideWalletsContainer /> : <SideLoginContainer />}
			</div>
		)
	}

}