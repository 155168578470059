import { toast } from 'react-toastify'

export class Api {
	// static host = (process.env.NODE_ENV === 'development') ? 'http://localhost' : ''

	static fetchResponseHandler = (res?: Response) => {
		if (!res) { 
			return
		}
		return res.json().then(json => {
			// if (!json) {
			// 	toast.error('Server not found')
			// } else
			if (json.errors) {
				json.errors.forEach((err: string) => {
					toast.error(err)
					return false
				})
			} else {
				return json
			}
		})

		// if (res.ok) {
		// 	return res.json()
		// } else {
		// 	throw res.json()
		// }
	}

	// const fetchErrorHandler = (res: any) => {
	// 	res.then((err: any) => {
	// 		toast.error(`'${err.param}': ${err.msg}`)
	// 	})
	// }
	static post = (url: string, data: any) => {
		return fetch('/api' + url, {
			body: JSON.stringify(data),
			method: 'POST',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				credentials: 'include',
				mode: 'cors',
			},
		})
			// .catch(err => console.info('POST ERROR:', err))
			.then(Api.fetchResponseHandler)
		// .catch(fetchErrorHandler)
	}

	static get = (url: string) => {
		return fetch('/api' + url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				credentials: 'include',
				mode: 'cors',
			},
		})
			// .catch(err => console.warn(err))
			.then(Api.fetchResponseHandler)
		// .catch(fetchErrorHandler)
	}

	static validate = (url: string) => {
		return fetch('/api' + url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				credentials: 'include',
				mode: 'cors',
			},
		})
			// .catch(err => console.warn(err))
			.then(Api.fetchResponseHandler)
		// .catch(fetchErrorHandler)
	}
}