import { ActionTypes, ILoginHistory, ILoginHistoryAction } from 'exchange-common'

export const loginHistory = (state: ILoginHistory[] = [], action: ILoginHistoryAction) => {
	switch (action.type) {
		case ActionTypes.LoginHistory.List:
			return [
				...state,
				...action.loginHistory
			]
		case ActionTypes.LoginHistory.Clear:
			return []
		default:
			return state
	}
}