import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { Link } from 'react-router-dom'
import { translate } from '../functions/lang'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.
	
}

export class SideLoginComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		
	}

	componentWillUnmount() {
		// run before component is unloaded.
		
	}

	render() {
		translate.language = this.props.lang

		return (
			<div className='sideLogin'>
				<Link to='/login'>{translate.text('login')}</Link>
				<Link to='/register'>{translate.text('register')}</Link>
			</div>
		)
	}
	
}