import en_us from '../languages/en-us'
import pt_br from '../languages/pt-br'
import es_es from '../languages/es-es'
export class Translate {

	public language = 'en-us'
	public files: any = {}

	public add = (language: string, file: any) => {
		this.files[language] = file
	}

	public text = (tag: string, ...args: any[]) => this.format(this.find(tag), ...args)
	private find = (tag: string) => this.files[this.language][tag] || tag
	private format = (msg: string, ...args: any[]) => {
		if (!args.length) {
			return msg
		}
		const t = typeof args[0]
		const params = 'string' === t || 'number' === t ? Array.prototype.slice.call(args) : args[0]
		// tslint:disable-next-line: forin
		for (const param in params) {
			msg = msg.replace(new RegExp('\\{' + param + '\\}', 'gi'), this.find(params[param]))
		}
		return msg
	}
}

export const translate = new Translate()

translate.add('en-us', en_us)
translate.add('es-es', es_es)
translate.add('pt-br', pt_br)