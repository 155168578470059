import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { Field, reduxForm } from 'redux-form'
import { renderField, required, minLength6, passwordsMatch } from './renderField'
import { Api } from '../functions/api'
import { toast } from 'react-toastify'
import { translate } from '../functions/lang'

interface IProps {
	// include component properties here.
lang: string
}

interface IMatchProps {
	// include any url parameters here.
	reset: string
}

let ResetPasswordForm = (props: any) => {
	const { handleSubmit, submitting, pristine } = props

	return (
		<form className='resetPasswordForm' onSubmit={handleSubmit}>
			<h2>{translate.text('password_reset')}</h2>
			<label htmlFor='password'>{translate.text('password')}</label>
			<Field name='password' component={renderField} type='password' validate={[required, minLength6]} />
			<label htmlFor='confirmPassword'>{translate.text('confirm_password')}</label>
			<Field name='confirmPassword' component={renderField} type='password' validate={[required, passwordsMatch]} />
			<button type='submit' disabled={submitting || pristine}>{translate.text('reset_password')}</button>
		</form>
	)
}

ResetPasswordForm = reduxForm({
	form: 'resetPassword'
})(ResetPasswordForm) as any

export class ResetPasswordComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {
	
	render() {
		// let hash = this.props.match.params.reset
		// console.log(hash)

		translate.language = this.props.lang

		return (
			<div className='resetPassword'>
				<ResetPasswordForm onSubmit={(values: any) => this.onSubmit(values)} />
			</div>
		)
	}

	onSubmit(values: any) {
		let reset = {
			newpassword: values.password,
			passwordconfirmation: values.confirmPassword,
			hash: this.props.match.params.reset
		}

		Api.post('/security/changepassword', reset)
			.then((data: any) => {
				// console.log(data)
				if (data === 'Password reseted') {
					toast.success(translate.text('password_reseted'))
					this.props.history.push('/login')
				}
				
				if (data === 'User not found') {
					toast.error(translate.text('user_not_found'))
				}	
				
				if (data === 'Error') {
					toast.error(translate.text('error_contact_support'))
				}

				if (data === 'Password reset time expired') {
					toast.error(translate.text('password_reset_expired'))
				}
			})
		}
}