import { ActionTypes, Deposits, IDepositAction, IDeposits } from 'exchange-common'

export const deposits = (state: IDeposits = new Deposits(), action: IDepositAction): IDeposits => {
	switch (action.type) {
		case ActionTypes.Deposit.List:
			return {
				...action.deposits,
			}
		case ActionTypes.Deposit.Add:
			return {
				...state,
				list: [
					action.deposit,
					...state.list,
				]
			}
		case ActionTypes.Deposit.Update:
			const i = state.list.findIndex((f) => f.id === action.deposit.id)
			return {
				...state,
				list: [
					...state.list.slice(0, i),
					{
						...state.list[i],
						...action.deposit,
					},
					...state.list.slice(i + 1),
				]
			}
		default:
			return state
	}
}