import { IDeposits, ILedger, ITrade, IUser, IWallets, IWithdrawals, Ledger, Ledgers } from 'exchange-common'
import { ceil, multipliedBy } from 'libs-tools'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	trades: ITrade[]
	deposits: IDeposits
	withdrawals: IWithdrawals
	user: IUser
	wallets: IWallets
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class LedgerMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const { deposits, withdrawals, trades, user, wallets } = this.props

		const getDecimals = (coin: string) => wallets.list[coin].fiat ? 2 : 8

		const ledgers = new Ledgers()
		const ledgerWallets: { [coin: string]: number } = {}
		Object.keys(wallets.list).forEach(coin => {
			ledgerWallets[coin] = wallets.list[coin].amount
		})
		const depositsLedger = deposits.list.filter(f => f.status === 'Confirmed').map(m => new Ledger({
			id: `D${m.id}`,
			date: m.date,
			coin: m.coin,
			description: translate.text(wallets.list[m.coin].fiat ? 'deposit_from_bank_account' : m.record === 'internal' ? 'deposit_from_internal_wallet' : 'deposit_from_external_wallet'),
			value: m.amount,
			balance: 0,
		} as ILedger))
		ledgers.list.push(...depositsLedger)

		const withdrawsLedger: ILedger[] = []
		withdrawals.list.filter(f => f.status === 'sent').reduce(
			(res, cur) => {
				res.push(
					new Ledger({
						id: `W${cur.id}`,
						date: cur.date,
						coin: cur.coin,
						description: translate.text(wallets.list[cur.coin].fiat ? 'withdraw_to_bank_account' : cur.txid === 'internal' ? 'withdraw_to_internal_wallet' : 'withdraw_to_external_wallet'),
						value: -(cur.amount - cur.fee),
						balance: 0,
					} as ILedger))
				res.push(
					new Ledger({
						id: `WF${cur.id}`,
						date: cur.date,
						coin: cur.coin,
						description: translate.text('withdraw_fee'),
						value: -(cur.fee),
						balance: 0,
					} as ILedger))
				return res
			},
			withdrawsLedger)
		ledgers.list.push(...withdrawsLedger)

		const tradesLedger: ILedger[] = []
		trades.reduce(
			(res, cur) => {
				const otherCoin: string = cur.market.split('-')[0]
				const baseCoin: string = cur.market.split('-')[1]
				if (cur.buyerUserId === user.id) {
					res.push(
						new Ledger({
							id: `TB${cur.tradeId}`,
							date: cur.date,
							coin: baseCoin,
							description: `${translate.text('buy')} (${cur.side === 'buy' ? translate.text('market_ledger') : translate.text('limit_ledger')}) @ ${cur.price} ${baseCoin}`,
							value: -(ceil(multipliedBy(cur.amount, cur.price), getDecimals(baseCoin))),
							balance: 0,
						} as ILedger))
					res.push(
						new Ledger({
							id: `TS${cur.tradeId}`,
							date: cur.date,
							coin: otherCoin,
							description: `${translate.text('buy')} (${cur.side === 'buy' ? translate.text('market_ledger') : translate.text('limit_ledger')}) @ ${cur.price} ${baseCoin}`,
							value: cur.amount,
							balance: 0,
						} as ILedger))
					res.push(
						new Ledger({
							id: `TF${cur.tradeId}`,
							date: cur.date,
							coin: otherCoin,
							description: `${translate.text('trade_fee')} (${cur.side === 'buy' ? translate.text('taker') : translate.text('maker')})`,
							value: -cur.buyerFee,
							balance: 0,
						} as ILedger))
				}
				if (cur.sellerUserId === user.id) {
					res.push(
						new Ledger({
							id: `TS${cur.tradeId}`,
							date: cur.date,
							coin: otherCoin,
							description: `${translate.text('sell')} (${cur.side === 'sell' ? translate.text('market_ledger') : translate.text('limit_ledger')}) @ ${cur.price} ${baseCoin}`,
							value: -cur.amount,
							balance: 0,
						} as ILedger))
					res.push(
						new Ledger({
							id: `TB${cur.tradeId}`,
							date: cur.date,
							coin: baseCoin,
							description: `${translate.text('sell')} (${cur.side === 'sell' ? translate.text('market_ledger') : translate.text('limit_ledger')}) @ ${cur.price} ${baseCoin}`,
							value: ceil(multipliedBy(cur.amount, cur.price), getDecimals(baseCoin)),
							balance: 0,
						} as ILedger))
					res.push(
						new Ledger({
							id: `TF${cur.tradeId}`,
							date: cur.date,
							coin: baseCoin,
							description: `${translate.text('trade_fee')} (${cur.side === 'sell' ? translate.text('taker') : translate.text('maker')})`,
							value: -cur.sellerFee,
							balance: 0,
						} as ILedger))
				}
				return res
			},
			tradesLedger)
		ledgers.list.push(...tradesLedger)

		ledgers.list.sort((a, b) => a.date < b.date ? 1 : -1)
		ledgers.list.forEach(ledger => {
			ledger.balance = ledgerWallets[ledger.coin]
			ledgerWallets[ledger.coin] -= ledger.value
		})

		return (
			<div>
				<table className='ledgerTableMain'>
					<thead>
						<tr className='ledgerTableHeaderRow'>
							<td className='ledgerTableHeaderRowItem dateColumn'>{translate.text('date')}</td>
							<td className='ledgerTableHeaderRowItem descColumn'>{translate.text('description')}</td>
							<td className='ledgerTableHeaderRowItem coinColumn'>{translate.text('coin')}</td>
							<td className='ledgerTableHeaderRowItem'>{translate.text('value')}</td>
							<td className='ledgerTableHeaderRowItem'>{translate.text('balance_after')}</td>
						</tr>
					</thead>
					<tbody>
						{ledgers.list.length > 0 ?
							ledgers.list.map(ledger =>
								<tr key={ledger.id} className='ledgerTableBodyRow'>
									<td className='ledgerTableBodyRowItem'>{new Date(ledger.date).toLocaleString('en-GB')}</td>
									<td className='ledgerTableBodyRowItem'>{ledger.description}</td>
									<td className='ledgerTableBodyRowItem'>{ledger.coin}</td>
									<td className={`ledgerTableBodyRowItem ${ledger.value >= 0 ? `green` : `red`}`}>{ledger.value.toFixed(8)}</td>
									<td className='ledgerTableBodyRowItem'>{ledger.balance.toFixed(8)}</td>
								</tr>
							)
							:
							<tr><td colSpan={5} className='emptyTable'>{translate.text('no_results_found')}</td></tr>
						}
					</tbody>
				</table>
			</div>
		)
	}

}