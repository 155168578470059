import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class AmlPolicyComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		return (
			<div className='amlPolicy'>
				<div className='amlPolicyMain'>
					<span className='pageTitle'>{translate.text('aml_policy')}</span>
					<b><i>[{translate.text('last_aml_updated_date')}]</i></b>
					<br /><br />
					<h3>{translate.text('aml_title_1')}<br />
						{translate.text('aml_title_2')}</h3>
					<p>{translate.text('aml_text_1')}</p>

					<p>{translate.text('aml_text_2')}</p>

					<p>{translate.text('aml_text_3')}</p>

					<h3>{translate.text('aml_title_3')}</h3>
					<p>{translate.text('aml_text_4')}</p>

					<ul>
						<li>{translate.text('aml_list_1')}</li>
						<li>{translate.text('aml_list_2')}</li>
						<li>{translate.text('aml_list_3')}</li>
						<li>{translate.text('aml_list_4')}</li>
						<li>{translate.text('aml_list_5')}</li >
						<li>{translate.text('aml_list_6')}</li>
						<li>{translate.text('aml_list_7')}</li>
					</ul>

					<h3>{translate.text('aml_title_4')}</h3>
					<p>{translate.text('aml_text_5')}</p>
					<br />
					<br />
				</div>
				<HomeFooterContainer />
			</div>

		)
	}

}