import { ActionTypes, IApiKey, IApiKeyAction } from 'exchange-common'

export const apiKeys = (state: IApiKey[] = [], action: IApiKeyAction): IApiKey[] => {
	switch (action.type) {
		case ActionTypes.ApiKey.Load:
			return [
				...action.apiKeys
			]
		case ActionTypes.ApiKey.Add:
			return [
				action.create,
				...state,
			]
		case ActionTypes.ApiKey.Remove:
			return [
				...state.filter(f => f.key !== action.key)
			]
		case ActionTypes.ApiKey.Update:
			return [
				...state.map(m => (m.key === action.update.key) ? { ...m, ...action.update } : m)
			]
		default:
			return state
	}
}