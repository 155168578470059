import { ICandlestickPeriod, IMarket, IOrder, IOrderbook, ITickers } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { CandlestickContainer } from '../containers/candlestick'
import { DepthContainer } from '../containers/depth'
import { TradingContainer } from '../containers/trading'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	market: IMarket
	tickers: ITickers
	orderbook: IOrderbook
	period: (period: string) => ICandlestickPeriod
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class ChartComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { clockTime: string, clockTimeout: NodeJS.Timeout }> {

	componentDidMount() {
		// run after component is called.
		this.setState({
			clockTime: new Date().toLocaleTimeString('en-GB'),
		})
		this.updateClock()
	}
	componentWillUnmount() {
		// run before component is unloaded.
		clearTimeout(this.state.clockTimeout)
	}

	updateClock() {
		const clockTimeout = setTimeout(() => {
			this.setState({ clockTime: new Date().toLocaleTimeString('en-GB') })
			this.updateClock()
		}, Utils.timeSpan('1s'))
		this.setState({
			...this.state,
			clockTimeout
		})
	}

	render() {

		const { market, tickers, orderbook, period } = this.props
		const ticker = tickers.list[market.symbol]
		if (!market || !ticker || !orderbook) {
			return <div />
		}
		const decimals = market.priceDecimals
		const bids = orderbook.bids.reduce(
			(res: IOrder[], cur: IOrder) => {
				let order = res.find(f => f.price.toFixed(decimals) === cur.price.toFixed(decimals))
				if (order) {
					order.amount += cur.amount
				} else {
					res.push({ ...cur })
				}
				return res
			},
			[] as IOrder[])
		const asks = orderbook.asks.reduce(
			(res: IOrder[], cur: IOrder) => {
				let order = res.find(f => f.price.toFixed(decimals) === cur.price.toFixed(decimals))
				if (order) {
					order.amount += cur.amount
				} else {
					res.push({ ...cur })
				}
				return res
			},
			[] as IOrder[])

		const midPrice = Utils.round((asks[0] && bids[0]) ? (asks[0].price + bids[0].price) / 2 : asks[0] ? asks[0].price : bids[0] ? bids[0].price : 0, decimals)
		translate.language = this.props.lang


		const clockTime = this.state?.clockTime || ''
		// let clockDate = new Date().toLocaleString('en-GB')

		return (
			<div className='chart'>
				<div className='boxTitle'>
					<span>{translate.text('price_charts')}{/*  ({market.symbol})*/}</span>
				</div>
				<div className='chartHeader'>
					<div className='chartHeaderLabel'><span onClick={e => period('1m')}>1m</span></div>
					<div className='chartHeaderLabel'><span onClick={e => period('15m')}>15m</span></div>
					<div className='chartHeaderLabel'><span onClick={e => period('30m')}>30m</span></div>
					<div className='chartHeaderLabel'><span onClick={e => period('1h')}>1h</span></div>
					<div className='chartHeaderLabel'><span onClick={e => period('6h')}>6h</span></div>
					<div className='chartHeaderLabel'><span onClick={e => period('1d')}>1d</span></div>
					<div className='clock'><span>{clockTime}</span></div>
				</div>
				<div className='candlestick'>
					<CandlestickContainer />
				</div>
				<div className='chartPrice'>
					<h3>{midPrice.toFixed(market.priceDecimals) + ' ' + market.symbol.split('-')[1]}</h3>
					<p>{translate.text('mid_market_price')}</p>
				</div>
				<div className='depth'>
					<DepthContainer />
				</div>
				<TradingContainer />
			</div>
		)
	}

}