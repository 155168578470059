import { IUserAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { LoginComponent } from '../components/login'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		market: state.markets.current,
		isLogged: state.user.isLogged,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IUserAction>) {
	return {

	}
}

export const LoginContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginComponent))