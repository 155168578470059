import * as React from 'react'
import { translate } from '../functions/lang'

export const maxLength = (max: number) => (value: string) =>
	value && value.length > max ? `${translate.text('must_be')} ${max} ${translate.text('characters_or_less')}` : undefined
export const minLength = (min: number) => (value: string) =>
	value && value.length < min ? `${translate.text('must_be')} ${min} ${translate.text('characters_or_more')}` : undefined
export const required = (value: string) => value ? undefined : translate.text('required')
export const requiredBox = (value: string) => value ? undefined : '*'
export const maxLength1 = maxLength(1)
export const maxLength10 = maxLength(10)
export const maxLength14 = maxLength(14)
export const maxLength100 = maxLength(100)
export const minLength6 = minLength(6)
export const minLength7 = minLength(7)

export const passwordsDiff = (value: string, allValues: any) =>
	value === allValues.oldPassword ? translate.text('same_old_password') : undefined
export const passwordsMatch = (value: string, allValues: any) =>
	value !== allValues.password ? translate.text('passwords_dont_match') : undefined
export const newPasswordsMatch = (value: string, allValues: any) =>
	value !== allValues.newPassword ? translate.text('passwords_dont_match') : undefined
export const isEmail = (value: string) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
		translate.text('invalid_email_address') : undefined

export const renderField = (params: any) => {
	let { input, label, type, autoComplete, id } = params
	let { asyncValidating, touched, error } = params.meta

	return (
		<div className={asyncValidating ? 'async-validating' : ''}>
			{id && <input {...input} placeholder={label} type={type} autoComplete={autoComplete} id={id} />}
			{!id && <input {...input} placeholder={label} type={type} autoComplete={autoComplete} />}
			{touched && error && <span className='inputError'>{error}</span>}
			{
				(!touched || !error) && <span className='inputError'>&nbsp;</span>
			}
		</div>
	)
}
export const renderSelect = (params: any) => {
	let { values, input } = params
	let { asyncValidating, touched, error } = params.meta

	return (
		<div className={asyncValidating ? 'async-validating' : ''}>
			<select {...input}>
				<option />
				{
					values.map((value: string) =>
						<option key={value} value={value} >{translate.text(value)}</option>
					)
				}
			</select>
			{touched && error && <span className='inputError'>{error}</span>}
			{
				(!touched || !error) && <span className='inputError'>&nbsp;</span>
			}
		</div>
	)
}
export const renderHiddenField = ({ input, val }: any) => {
	input.onChange(val)
	return (
		<span />
	)
}