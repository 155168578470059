import { ActionCreators, IUserAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { SecurityComponent } from '../components/security'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		user: state.user,

	}
}

function mapDispatchToProps(dispatch: Dispatch<IUserAction>) {
	return {
		updateAuth: (secret: string) => dispatch(ActionCreators.User.updateAuth(secret))

	}
}

export const SecurityContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SecurityComponent))