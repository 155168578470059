import { ActionTypes, ITrade, ITradesAction } from 'exchange-common'

export const trades =
	(state: ITrade[] = [], action: ITradesAction): ITrade[] => {
		switch (action.type) {
			case ActionTypes.Trades.List:
				return [
					...action.trades,
				]

			case ActionTypes.Trades.Add:
				return [
					action.trade,
					...state,
				]

			default:
				return state
		}
	}