import { ActionCreators, ILoginHistory, ISecurityAuthDisable, ISecurityAuthEnable, ISecurityChangePassword, IUser } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Field, reduxForm, reset } from 'redux-form'
import { store } from '..'
import config from '../config.json'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IChangePassword } from '../models/changePassword'
import { IState } from '../models/state'
import { maxLength100, minLength6, newPasswordsMatch, passwordsDiff, renderField, required } from './renderField'

interface IProps {
	// include component properties here.
	user: IUser
	lang: string
	loginHistory: ILoginHistory[]
}

interface IMatchProps {
	// include any url parameters here.

}

let ChangePasswordForm = (props: any) => {
	const { handleSubmit } = props

	return (
		<form className='changePasswordForm' onSubmit={handleSubmit}>
			<div className='changePasswordFormBox' >
				<label htmlFor='oldPassword' className='changePasswordFormLabel'>{translate.text('old_password')}</label>
				<Field name='oldPassword' component={renderField} type='password' validate={[required, minLength6, maxLength100]} />
			</div>
			<div className='changePasswordFormBox' >
				<label htmlFor='newPassword' className='changePasswordFormLabel'>{translate.text('new_password')}</label>
				<Field name='newPassword' component={renderField} type='password' validate={[required, passwordsDiff, minLength6, maxLength100]} />
			</div>
			<div className='changePasswordFormBox' >
				<label htmlFor='confirmNewPassword' className='changePasswordFormLabel'>{translate.text('confirm_new_password')}</label>
				<Field name='confirmNewPassword' component={renderField} type='password' validate={[required, minLength6, maxLength100, newPasswordsMatch]} />
			</div >
			<button type='submit'>{translate.text('save')}</button>
		</form>
	)
}

ChangePasswordForm = reduxForm({
	form: 'changePasswordForm'
})(ChangePasswordForm) as any

let AuthEnableForm = (props: any) => {
	const { handleSubmit } = props

	return (
		<form className='authEnableForm' onSubmit={handleSubmit}>
			<div className='authEnableFormBox' >
				<label htmlFor='auth'>{translate.text('2fa_token')}</label>
				<Field name='auth' component={renderField} type='text' validate={[required, minLength6]} autoComplete='off' />
			</div>
			<button type='submit'>{translate.text('enable')}</button>
		</form>
	)
}

AuthEnableForm = reduxForm({
	form: 'authEnable'
})(AuthEnableForm) as any

let AuthDisableForm = (props: any) => {
	const { handleSubmit } = props

	return (
		<form className='authDisableForm' onSubmit={handleSubmit}>
			<div className='authDisableFormBox' >
				<label htmlFor='auth'>{translate.text('2fa_token')}</label>
				<Field name='auth' component={renderField} type='text' validate={[required, minLength6]} autoComplete='off' /></div>
			<button type='submit'>{translate.text('disable')}</button>
		</form>
	)
}

AuthDisableForm = reduxForm({
	form: 'authDisable'
})(AuthDisableForm) as any

export class SecurityMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {
	private loginHistoryLength: number = 0

	render() {

		translate.language = this.props.lang

		const appStore = require('../images/logos/appstore.svg')
		const googlePlay = require('../images/logos/googleplay.svg')
		const { authSecret, email } = this.props.user
		const qrCodeUrlBase = 'https://chart.googleapis.com/chart?chs=200x200&chld=M%7C0&cht=qr&chl='
		const otpauth = `otpauth://totp/${email}?secret=${authSecret}&issuer=${config.url}`
		const qrCodeUrl = qrCodeUrlBase + encodeURIComponent(otpauth)

		const loginHistory = this.props.loginHistory

		return (
			<div className='securityMain'>
				<span className='pageTitle'>{translate.text('security')}</span>
				<h2 className='pageBoxTitle'>{translate.text('change_password')}</h2>
				<div>
					<ChangePasswordForm onSubmit={(changePassword: IChangePassword) => this.onChangePasswordSubmit(changePassword)} />
				</div>
				<h2 className='pageBoxTitle'>{translate.text('two_factor_auth')}</h2>
				{this.props.user.authEnabled ?
					<div className='twoFactorBox'>
						<div className='pageColumn3'>
							<span><p>{translate.text('enter_6_digit')}</p></span>
							<AuthDisableForm onSubmit={(values: any) => this.onAuthDisableSubmit(values)} />
						</div>
					</div>
					:
					<div className='twoFactorBox'>
						{navigator.userAgent.match(/android/i) ?
							<div className='pageColumn3'>
								<span><p><b className='steps2fa'>1</b> {translate.text('activate_2fa_msg1')}</p></span>
								<a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target='_blank' rel='noopener noreferrer'><img src={googlePlay} className='googlePlay' alt='googlePlay' /></a>
							</div> :
							navigator.userAgent.match(/iPhone/i) ?
								<div className='pageColumn3'>
									<span><p><b className='steps2fa'>1</b> {translate.text('activate_2fa_msg1')}</p></span>
									<a href='https://apps.apple.com/us/app/google-authenticator/id388497605' target='_blank' rel='noopener noreferrer'><img src={appStore} className='appStore' alt='appStore' /></a>
								</div> :
								<div className='pageColumn3'>
									<span><p><b className='steps2fa'>1</b> {translate.text('activate_2fa_msg1')}</p></span>
									<a href='https://apps.apple.com/us/app/google-authenticator/id388497605' target='_blank' rel='noopener noreferrer'><img src={appStore} className='appStore' alt='appStore' /></a>
									<a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target='_blank' rel='noopener noreferrer'><img src={googlePlay} className='googlePlay' alt='googlePlay' /></a>
								</div>}
						<div className='pageColumn3'>
							<span><p><b className='steps2fa'>2</b> {translate.text('activate_2fa_msg2')}</p></span>
							<span><img className='qrCode2fa' src={qrCodeUrl} alt='' /></span>
							<span><p>{translate.text('manual_activation')}</p></span>
							<span><p><b>{translate.text('secret')}:</b> {authSecret}</p></span>
						</div>
						<div className='pageColumn3'>
							<span><p><b className='steps2fa'>3</b> {translate.text('enter_6_digit')}</p></span>
							<AuthEnableForm onSubmit={(values: any) => this.onAuthEnableSubmit(values)} />
						</div>
					</div>
				}
				<h2 className='pageBoxTitle'>{translate.text('login_history')}</h2>
				<div>
					<table className='BankTableMain'>
						<thead>
							<tr className='BankTableHeaderRow'>
								<td className='BankTableHeaderRowItem'>{translate.text('date')}</td>
								<td className='BankTableHeaderRowItem'>{translate.text('ip')}</td>
								<td className='BankTableHeaderRowItem'>{translate.text('browser')} / {translate.text('device')}</td>
							</tr>
						</thead>
						<tbody>
							{loginHistory.map(m =>
								<tr key={m.id} className='BankTableBodyRow'>
									<td className='BankTableBodyRowItem'>{new Date(m.date).toLocaleString('en-GB')}</td>
									<td className='BankTableBodyRowItem'>{m.ip}</td>
									<td className='BankTableBodyRowItem'>{m.browser}</td>
								</tr>
							)}
						</tbody>
					</table>
					{this.loginHistoryLength !== this.props.loginHistory.length
						?
						<p className='loadMore' onClick={() => this.loadMore()}>{translate.text('view_more')}</p>
						:
						<></>}
				</div>
			</div>
		)
	}

	onChangePasswordSubmit(changePassword: IChangePassword) {
		const action: ISecurityChangePassword = ActionCreators.Security.changePassword(changePassword)
		ws.send(action)
		store.dispatch(reset('changePasswordForm'))
	}

	onAuthEnableSubmit(values: any) {
		const { authSecret } = this.props.user
		if (!authSecret) { return }

		const action: ISecurityAuthEnable = ActionCreators.Security.authEnable(values.auth, authSecret)
		ws.send(action)

		// console.log('values', values, this)
		// let data = {
		// 	token: values.auth,
		// 	email: this.props.user.email,
		// 	secret: this.props.user.authSecret
		// }
		// console.log(data)	

		// api.post('/security/authenable', data).then(auth => {
		// 	if (auth === 'Invalid token') {
		// 		toast.error('Invalid token')
		// 		return
		// 	}

		// 	toast.success('2FA Enabled')

		// }).catch(err => {
		// 	toast.error('Invalid token')
		// })
	}

	onAuthDisableSubmit(values: any) {
		const action: ISecurityAuthDisable = ActionCreators.Security.authDisable(values.auth)
		ws.send(action)

		// let data = {
		// 	token: values.auth,
		// 	email: this.props.user.email			
		// }

		// api.post('/security/authdisable', data).then(auth => {
		// 	if (auth === 'Invalid token') {
		// 		toast.error('Invalid token')
		// 		return
		// 	}

		// 	if (auth === '2FA token already used') {
		// 		toast.error('2FA token already used')
		// 		return
		// 	}

		// 	toast.success('2FA Disabled')
		// }).catch(err => {
		// 	toast.error('Invalid token')
		// })
	}

	loadMore() {
		this.loginHistoryLength = this.props.loginHistory.length
		const lastId = this.props.loginHistory.reduce((prev, curr) => {
			if (prev === 0 || prev > curr.id) {
				return curr.id
			}
			return prev
		}, 0)
		ws.send(ActionCreators.Security.getLoginHistory(lastId))
	}

}