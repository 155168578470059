import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { TradeHeaderContainer } from '../containers/tradeHeader'
import { AccountMenuContainer } from '../containers/accountMenu'
import { WithdrawMainContainer } from '../containers/withdrawMain'
import { SideWalletsContainer } from '../containers/sideWallets'

interface IProps {
	// include component properties here.
	
}

interface IMatchProps {
	// include any url parameters here.
	
}

export class WithdrawComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		
	}

	componentWillUnmount() {
		// run before component is unloaded.
		
	}

	render() {
		
		return (
			<div className='withdraw'>
				<TradeHeaderContainer />
				<div className='sideBar'>
					<SideWalletsContainer />
					<AccountMenuContainer />
				</div>
				<WithdrawMainContainer />
			</div>
		)
	}
	
}