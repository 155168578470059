import { ActionTypes, IOrder, IOrdersHistoryAction } from 'exchange-common'

export const ordersHistory =
	(state: IOrder[] = [], action: IOrdersHistoryAction): IOrder[] => {
		switch (action.type) {
			case ActionTypes.OrdersHistory.Load:
				return [
					...action.list,
				]

			case ActionTypes.OrdersHistory.Add:
				return [
					action.order,
					...state,
				]

			case ActionTypes.OrdersHistory.Page:
				return [
					...state,
					...action.list,
				]

			default:
				return state
		}
	}