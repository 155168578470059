import { ActionCreators, IAction, IOrder } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { SideWalletsComponent } from '../components/sideWallets'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		market: state.markets.current,
		markets: state.markets,
		wallets: state.wallets,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {
		changeOrder: (order: Partial<IOrder>) => dispatch(ActionCreators.Order.change(order))

	}
}

export const SideWalletsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SideWalletsComponent))