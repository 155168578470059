import { IMarkets, ITickers } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	markets: IMarkets
	tickers: ITickers
	lang: string

}

interface IMatchProps {
	// include any url parameters here.

}

export class TickerComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const market = this.props.markets.list[this.props.markets.current]
		const ticker = this.props.tickers.list[this.props.markets.current]

		if (!market || !ticker) {
			return <div />
		}

		window.document.title = `${ticker.lastPrice.toFixed(market.priceDecimals)} | ${market.symbol} | Econobit`

		const color = (delta: number) => {
			switch (true) {
				case delta > 0:
					return ' green'
				case delta < 0:
					return ' red'
				default:
					return ' white'
			}
		}
		const format = (delta: number, decimals: number) => {
			return delta < 0 ? delta.toFixed(decimals) : '+' + delta.toFixed(decimals)
		}
		translate.language = this.props.lang

		return (
			<div className='ticker'>
				<div className='tickerLast'>
					<span className='tickerValue'>{ticker.lastPrice.toFixed(market.priceDecimals) + ' ' + market.symbol.split('-')[1]}</span>
					<span className='tickerLabel'>{translate.text('last_price')}</span>
				</div>
				<div className='tickerDelta'>
					<span className={'tickerValue' + color(ticker.delta24h)}>{format(ticker.delta24h * 100, 2)}%</span>
					<span className='tickerLabel'>{translate.text('24h_price')}</span>
				</div>
				<div className='tickerLow'>
					<span className='tickerValue'>{ticker.low24h.toFixed(market.priceDecimals) + ' ' + market.symbol.split('-')[1]}</span>
					<span className='tickerLabel'>{translate.text('24h_low')}</span>
				</div>
				<div className='tickerHigh'>
					<span className='tickerValue'>{ticker.high24h.toFixed(market.priceDecimals) + ' ' + market.symbol.split('-')[1]}</span>
					<span className='tickerLabel'>{translate.text('24h_high')}</span>
				</div>
				<div className='tickerVolume'>
					<span className='tickerValue'>{ticker.volume24h.toFixed(2) + ' ' + market.symbol.split('-')[0]}</span>
					<span className='tickerLabel'>{translate.text('24h_volume')}</span>
				</div>
			</div>
		)
	}

}