import { IMarkets, ITickers, IWalletChange, IWallets } from 'exchange-common'
import { dividedBy, multipliedBy } from 'libs-tools'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { DepositMainContainer } from '../containers/depositMain'
import { LedgerMainContainer } from '../containers/ledgerMain'
import { WithdrawMainContainer } from '../containers/withdrawMain'
import { translate } from '../functions/lang'
import { walletAmount, walletBalance, walletInOrders } from '../functions/wallet'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	wallets: IWallets
	walletChange: (current: string) => IWalletChange
	tickers: ITickers
	markets: IMarkets
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileWalletsComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const brlLogo = require('../images/coins/BRL.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const usdcLogo = require('../images/coins/USDC.svg')

		const wallets = this.props.wallets.list

		const { list: tickers } = this.props.tickers

		const estValue = (wallets.BTC.amount || 0)
			+ (tickers['BTC-BRL'].lastPrice ? dividedBy(wallets.BRL.amount, tickers['BTC-BRL'].lastPrice) : 0)
			+ multipliedBy(wallets.ETH.amount, tickers['ETH-BTC'].lastPrice)
			+ multipliedBy(wallets.EBIT.amount, tickers['EBIT-BTC'].lastPrice)
			+ multipliedBy(wallets.LTC.amount, tickers['LTC-BTC'].lastPrice)
			+ multipliedBy(wallets.DOGE.amount, tickers['DOGE-BTC'].lastPrice)
			+ multipliedBy(wallets.LINK.amount, tickers['LINK-BTC'].lastPrice)
			+ (tickers['BTC-USDC'].lastPrice ? dividedBy(wallets.USDC.amount, tickers['BTC-USDC'].lastPrice) : 0)

		const estValueBRL = multipliedBy(estValue, tickers['BTC-BRL'].lastPrice)

		return (
			<div className='walletsMain'>
				<Tabs>
					<TabList>
						<Tab>{translate.text('balances')}</Tab>
						<Tab>{translate.text('deposit')}</Tab>
						<Tab>{translate.text('withdraw')}</Tab>
						<Tab>{translate.text('ledger')}</Tab>
					</TabList>
					<TabPanel>
						<div>
							<div className='walletsValueBox'>
								<div className='walletsValueBoxRow'><span className='walletsValueBoxRowGray'>{translate.text('estimated_value')} ≈ </span>{estValue.toFixed(8)} BTC <span className='walletsValueBoxRowGray'>({estValueBRL.toFixed(2)} BRL)</span></div>
							</div>
							<table className='walletsTableMain'>
								<thead>
									<tr className='walletsTableHeaderRow'>
										<td className='walletsTableHeaderRowItem coinColumn'>{translate.text('coin')}</td>
										<td className='walletsTableHeaderRowItem balanceColumn'>{translate.text('total')}</td>
										<td className='walletsTableHeaderRowItem balanceColumn'>{translate.text('available')}</td>
										<td className='walletsTableHeaderRowItem balanceColumn'>{translate.text('in_orders')}</td>
									</tr>
								</thead>
								<tbody>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={brlLogo} className='walletsLogo' alt='BRL' /> BRL</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.BRL)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.BRL)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.BRL)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={btcLogo} className='walletsLogo' alt='BTC' /> BTC</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.BTC)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.BTC)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.BTC)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={ethLogo} className='walletsLogo' alt='ETH' /> ETH</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.ETH)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.ETH)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.ETH)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={ebitLogo} className='walletsLogo' alt='EBIT' /> EBIT</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.EBIT)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.EBIT)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.EBIT)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={ltcLogo} className='walletsLogo' alt='LTC' /> LTC</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.LTC)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.LTC)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.LTC)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={dogeLogo} className='walletsLogo' alt='DOGE' /> DOGE</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.DOGE)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.DOGE)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.DOGE)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={linkLogo} className='walletsLogo' alt='LINK' /> LINK</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.LINK)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.LINK)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.LINK)}</td>
									</tr>
									<tr className='walletsTableBodyRow'>
										<td className='walletsTableBodyRowItem'><img src={usdcLogo} className='walletsLogo' alt='USDC' /> USDC</td>
										<td className='walletsTableBodyRowItem'>{walletAmount(wallets.USDC)}</td>
										<td className='walletsTableBodyRowItem green'>{walletBalance(wallets.USDC)}</td>
										<td className='walletsTableBodyRowItem gray8'>{walletInOrders(wallets.USDC)}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</TabPanel>
					<TabPanel>
						<DepositMainContainer />
					</TabPanel>
					<TabPanel>
						<WithdrawMainContainer />
					</TabPanel>
					<TabPanel>
						<LedgerMainContainer />
					</TabPanel>
				</Tabs>
			</div>
		)
	}
	onSelectedCoin(coin: string) {
		this.props.walletChange(coin)
	}
}