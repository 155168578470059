import { ActionTypes, Currencies, ICurrencies, ICurrencyAction } from 'exchange-common'

export const currencies =
	(state: ICurrencies = new Currencies(), action: ICurrencyAction): ICurrencies => {

		switch (action.type) {
			case ActionTypes.Currency.Load:
				return {
					...action.currencies,
				}
			case ActionTypes.Currency.Update:
				return {
					...state,
					list: {
						...state.list,
						[action.currency.id]: action.currency
					}
				}
			default:
				return state
		}
	}