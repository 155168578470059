import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { PrivacyPolicyComponent } from '../components/privacyPolicy'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {

	}
}

export const PrivacyPolicyContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyComponent))