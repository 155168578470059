import { IMarkets, IUser } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	user: IUser
	markets: IMarkets

	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileHeaderComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		const user = this.props.user

		translate.language = this.props.lang

		const profile = require('../images/icons/profile.svg')
		const plus = require('../images/icons/plus.svg')
		const enter = require('../images/icons/enter.svg')
		const exit = require('../images/icons/exit.svg')

		const btcBrlLogo = require('../images/pairs/BTC-BRL.svg')
		const ltcBrlLogo = require('../images/pairs/LTC-BRL.svg')
		const dogeBrlLogo = require('../images/pairs/DOGE-BRL.svg')
		const ethBrlLogo = require('../images/pairs/ETH-BRL.svg')
		const ebitBrlLogo = require('../images/pairs/EBIT-BRL.svg')
		const linkBrlLogo = require('../images/pairs/LINK-BRL.svg')

		const btcUsdcLogo = require('../images/pairs/BTC-USDC.svg')
		const ltcUsdcLogo = require('../images/pairs/LTC-USDC.svg')
		const dogeUsdcLogo = require('../images/pairs/DOGE-USDC.svg')
		const ethUsdcLogo = require('../images/pairs/ETH-USDC.svg')
		const ebitUsdcLogo = require('../images/pairs/EBIT-USDC.svg')
		const linkUsdcLogo = require('../images/pairs/LINK-USDC.svg')

		const ltcBtcLogo = require('../images/pairs/LTC-BTC.svg')
		const dogeBtcLogo = require('../images/pairs/DOGE-BTC.svg')
		const ethBtcLogo = require('../images/pairs/ETH-BTC.svg')
		const ebitBtcLogo = require('../images/pairs/EBIT-BTC.svg')
		const linkBtcLogo = require('../images/pairs/LINK-BTC.svg')

		const current = this.props.markets.current
		const markets = this.props.markets.list
		if (!Object.keys(markets).length) {
			return <div />
		}

		const logo: any = {
			'BTC-BRL': {
				src: btcBrlLogo,
				alt: 'BTC-BRL',
			},
			'LTC-BRL': {
				src: ltcBrlLogo,
				alt: 'LTC-BRL',
			},
			'DOGE-BRL': {
				src: dogeBrlLogo,
				alt: 'DOGE-BRL',
			},
			'ETH-BRL': {
				src: ethBrlLogo,
				alt: 'ETH-BRL',
			},
			'EBIT-BRL': {
				src: ebitBrlLogo,
				alt: 'EBIT-BRL',
			},
			'LINK-BRL': {
				src: linkBrlLogo,
				alt: 'LINK-BRL',
			},
			'BTC-USDC': {
				src: btcUsdcLogo,
				alt: 'BTC-USDC',
			},
			'LTC-USDC': {
				src: ltcUsdcLogo,
				alt: 'LTC-USDC',
			},
			'DOGE-USDC': {
				src: dogeUsdcLogo,
				alt: 'DOGE-USDC',
			},
			'ETH-USDC': {
				src: ethUsdcLogo,
				alt: 'ETH-USDC',
			},
			'EBIT-USDC': {
				src: ebitUsdcLogo,
				alt: 'EBIT-USDC',
			},
			'LINK-USDC': {
				src: linkUsdcLogo,
				alt: 'LINK-USDC',
			},
			'LTC-BTC': {
				src: ltcBtcLogo,
				alt: 'LTC-BTC',
			},
			'DOGE-BTC': {
				src: dogeBtcLogo,
				alt: 'DOGE-BTC',
			},
			'ETH-BTC': {
				src: ethBtcLogo,
				alt: 'ETH-BTC',
			},
			'EBIT-BTC': {
				src: ebitBtcLogo,
				alt: 'EBIT-BTC',
			},
			'LINK-BTC': {
				src: linkBtcLogo,
				alt: 'LINK-BTC',
			},
		}

		return user.isLogged ? (
			<div className='mobileHeader' >
				<Link to='/' className='mobileHeaderLogo' ><div className='header-logo' /></Link>
				<Link to='/markets' className='mobileHeaderMkt'><img src={logo[current].src} className='marketsLogo' alt={logo[current].alt} /><span>{this.props.markets.current}</span></Link>
				<Link to='/profile' className='mobileHeaderLink'><img src={profile} className='mobileHeaderMenuIcon' alt='profile' /><span>{translate.text('profile')}</span></Link>
				<Link to='/logout' className='mobileHeaderLink'><img src={exit} className='mobileHeaderMenuIcon' alt='logout' /><span>{translate.text('logout')}</span></Link>
			</div>
		) : (
			<div className='mobileHeader' >
				<Link to='/' className='mobileHeaderLogo' ><div className='header-logo' /></Link>
				<Link to='/markets' className='mobileHeaderMkt'><img src={logo[current].src} className='marketsLogo' alt={logo[current].alt} /><span>{this.props.markets.current}</span></Link>
				<Link to='/login' className='mobileHeaderLink'><img src={enter} className='mobileHeaderMenuIcon' alt='login' /><span>{translate.text('login')}</span></Link>
				<Link to='/register' className='mobileHeaderLink'><img src={plus} className='mobileHeaderMenuIcon' alt='register' /><span>{translate.text('register')}</span></Link>
			</div>
		)
	}

}