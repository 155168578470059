import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { apiKeys } from './apiKey'
import { banks } from './bank'
import { bankAccounts } from './bankAccount'
import { candlesticks } from './candlesticks'
import { connection } from './connection'
import { currencies } from './currencies'
import { deposits } from './deposit'
import { kyc } from './kyc'
import { lang } from './lang'
import { lastTrades } from './lastTrades'
import { loginHistory } from './loginHistory'
import { markets } from './markets'
import { news } from './news'
import { openOrders } from './openOrders'
import { order } from './order'
import { orderbooks } from './orderbooks'
import { ordersHistory } from './ordersHistory'
import { style } from './style'
import { tickers } from './tickers'
import { trades } from './trades'
import { user } from './user'
import { wallets } from './wallets'
import { withdrawals } from './withdraw'

export const reducers = combineReducers({
	form,
	user,
	order,
	markets,
	wallets,
	deposits,
	withdrawals,
	bankAccounts,
	banks,
	kyc,
	trades,
	openOrders,
	lastTrades,
	ordersHistory,
	orderbooks,
	tickers,
	currencies,
	connection,
	candlesticks,
	style,
	lang,
	news,
	loginHistory,
	apiKeys,
})
