import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { PrivateRouteComponent } from '../components/privateRoute'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		isLogged: state.user.isLogged
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {

	}
}

export const PrivateRoute = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRouteComponent))