import * as React from 'react'
import { RouteComponentProps, Route, Redirect } from 'react-router'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	path: string
	component: any
	isLogged: boolean
}

interface IMatchProps {
	// include any url parameters here.

}

export class PrivateRouteComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		return (
			this.props.isLogged ?
				<Route path={this.props.path} component={this.props.component} />
				:
				<Redirect to='/login' />
		)
	}

}