import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'
import config from '../config.json'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class HomeFooterComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const wordpress = require('../images/logos/wordpress.svg')
		const facebook = require('../images/logos/facebook.svg')
		const instagram = require('../images/logos/instagram.svg')
		const linkedin = require('../images/logos/linkedin.svg')
		const twitter = require('../images/logos/twitter.svg')
		const youtube = require('../images/logos/youtube.svg')
		const econobitLive = require('../images/branding/econobit-text-dark.svg')
		const econobitTest = require('../images/branding/econobit-text-dark-testnet.svg')

		return (
			<div className='homeFooter'>
				<div className='homeFooterColumn'>
					<span className='homeFooterTitle'><img src={config.environment === 'LIVE' ? econobitLive : econobitTest} alt='Econobit' width="100px" /></span>
					<span className='homeFooterText'>©‎ 2023 Econobit Ltd.</span>
					<div className='homeFooterSocialColumn'>
						<a href='https://blog.econobit.io' target='_blank' rel='noopener noreferrer'><img src={wordpress} className='socialLogo' alt='blog' /></a>
						<a href='https://facebook.com/econobit' target='_blank' rel='noopener noreferrer'><img src={facebook} className='socialLogo' alt='facebook' /></a>
						<a href='https://twitter.com/econobit' target='_blank' rel='noopener noreferrer'><img src={twitter} className='socialLogo' alt='twitter' /></a >
						<a href='https://instagram.com/econobit' target='_blank' rel='noopener noreferrer'><img src={instagram} className='socialLogo' alt='instagram' /></a>
						<a href='https://linkedin.com/company/econobit' target='_blank' rel='noopener noreferrer'><img src={linkedin} className='socialLogo' alt='linkedin' /></a>
						<a href='https://youtube.com/user/econobit' target='_blank' rel='noopener noreferrer'><img src={youtube} className='socialLogo' alt='youtube' /></a >
					</div>
				</div>
				<div className='homeFooterColumn'>
					<span className='homeFooterTitle'>{translate.text('company')}</span>
					<Link to='/aboutUs' className='homeFooterItem'><span>{translate.text('about_us')}</span></Link>
					<Link to='/fees' className='homeFooterItem'><span>{translate.text('fees')}</span></Link>
					<Link to='/amlPolicy' className='homeFooterItem'><span>{translate.text('aml_policy')}</span></Link>
					<Link to='/privacyPolicy' className='homeFooterItem'><span>{translate.text('privacy_policy')}</span></Link>
					<Link to='/termsOfUse' className='homeFooterItem'><span>{translate.text('terms_of_use')}</span></Link>
				</div >
				<div className='homeFooterColumn'>
					<span className='homeFooterTitle'>{translate.text('help')}</span>
					<span className='homeFooterItem'><a href='https://docs.econobit.io/' target='_blank' rel='noopener noreferrer'>{translate.text('api_documentation')}</a></span>
					<span className='homeFooterItem'><a href={translate.text('support_faq_url')} target='_blank' rel='noopener noreferrer'>{translate.text('faq')}</a></span>
					<span className='homeFooterItem'><a href='https://stats.uptimerobot.com/49nrKuPnJ' target='_blank' rel='noopener noreferrer'>{translate.text('status_page')}</a></span>
					<span className='homeFooterItem'><a href={translate.text('support_url')} target='_blank' rel='noopener noreferrer'>{translate.text('support')}</a></span>
				</div >
				<div className='homeFooterColumn'>
					<span className='homeFooterTitle'>{translate.text('contact_us')}</span>
					<span className='homeFooterItem'><a href='mailto:contact@econobit.io'>contact@econobit.io</a></span>
					<span className='homeFooterItem'><a href='mailto:support@econobit.io'>support@econobit.io</a></span>
				</div>

			</div>
		)
	}

}