import { ActionCreators, IUser, IUserUpdateProfile } from 'exchange-common'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Field, reduxForm } from 'redux-form'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { maxLength14, renderField } from './renderField'

interface IProps {
	// include component properties here.
	user: IUser
	lang: string
	verified: boolean | undefined

}

interface IMatchProps {
	// include any url parameters here.

}

let ProfileForm = (props: any) => {
	const { handleSubmit, userId, email, verified } = props
	// const profileAvatar = require('../images/avatar.svg')

	return (
		<form className='profileForm' onSubmit={handleSubmit}>
			<div className='pageColumn3'>
				<div className='profileFormRow'>
					<label htmlFor='id' className='profileFormLabel'>ID</label>
					<div className='profileFormID'>{Utils.pad(Utils.base32.encode(userId), 6)}</div>
				</div>
				<div className='profileFormRow'>
					<label htmlFor='email' className='profileFormLabel'>{translate.text('email')}</label>
					<div className='profileFormID'>{email}</div>
				</div>
				<div className='profileFormRow'>
					<label className='profileFormLabel'>{translate.text('verified')}</label>
					{verified
						? <div className='profileFormTag tagGreen'>{translate.text('yes')}</div>
						: <div className='profileFormTag tagRed'>{translate.text('no')}</div>
					}
				</div>
			</div>
			<div className='pageColumn3'>
				<div className='profileFormRow'>
					<label htmlFor='nickname' className='profileFormLabel'>{translate.text('nickname')}</label>
					<Field name='nickname' component={renderField} type='text' />
				</div>
				<div className='profileFormRow'>
					<label htmlFor='mobilePhone' className='profileFormLabel'>{translate.text('mobile_phone')}</label>
					<Field name='mobilePhone' component={renderField} type='text' validate={[maxLength14]} />
				</div>
				<div>
					<button type='submit'>{translate.text('save_changes')}</button>
				</div>
			</div>
			<div className='pageColumn3'>
				{/* <div className='avatarBox'>
					<img src={profileAvatar} className='profileAvatar' alt='profileAvatar' />
					<button className='ChangePhoto'>Change avatar</button>
				</div> */}
			</div>
		</form>
	)
}

ProfileForm = reduxForm({
	form: 'profileForm'
})(ProfileForm) as any

ProfileForm = connect((state: IState) => ({
	initialValues: {
		nickname: state.user.nickname,
		mobilePhone: state.user.mobilePhone,
		// language: state.user.language,
	}
}))(ProfileForm) as any

export class ProfileMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const { id, email, nickname, mobilePhone } = this.props.user

		return (
			<div className='profileMain'>
				<span className='pageTitle'>{translate.text('profile')}</span>
				<div className='profileForm'>
					<div className='profileFormBox'>
						<ProfileForm onSubmit={(values: any) => this.onSubmit(values)} userId={id} email={email} nickname={nickname} mobilePhone={mobilePhone} verified={this.props.verified} />
					</div>
				</div>
			</div>
		)
	}

	onSubmit(userPartial: Partial<IUser>) {
		userPartial.id = this.props.user.id
		const action: IUserUpdateProfile = ActionCreators.User.updateProfile(userPartial)
		ws.send(action)
	}

}