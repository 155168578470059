import { ActionCreators, IMarkets, IOrder, IOrderbooks, IOrderChange, ITickers } from 'exchange-common'
import * as React from 'react'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	order: IOrder
	symbol: string
	markets: IMarkets
	isLogged: boolean
	tickers: ITickers
	orderbooks: IOrderbooks
	changeOrder: (order: Partial<IOrder>) => IOrderChange
	lang: string
	verified: boolean | undefined
}

interface IMatchProps {
	// include any url parameters here.
	// market: string
}

export class ExchangeMenuComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { openModal: boolean }> {
	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const openModal = (this.state && this.state.openModal !== undefined) ? this.state.openModal : false
		const symbol: string = this.props.symbol
		const otherCoin: string = symbol.split('-')[0]
		const baseCoin: string = symbol.split('-')[1]
		const order = this.props.order
		const changeOrder = this.props.changeOrder
		const priceDecimals = this.props.markets.list[symbol].priceDecimals
		const amountDecimals = this.props.markets.list[symbol].amountDecimals
		const ticker = this.props.tickers.list[symbol]
		translate.language = this.props.lang
		const orderbook = this.props.orderbooks.list[symbol]
		const getTotal = (amount: number, side: 'buy' | 'sell') => {
			let total = 0
			let amountLeft = amount
			for (const row of orderbook[side === 'buy' ? 'asks' : 'bids']) {
				const rowAmount = Math.min(amountLeft, row.amount - row.filled)
				amountLeft -= rowAmount
				total += rowAmount * row.price
				if (!amountLeft) {
					break
				}
			}
			return total
		}
		if (!ticker) {
			return <div />
		}

		return (
			<div className='exchangeMenu'>
				<Modal
					open={openModal}
					onClose={this.onCloseModal}
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal',
					}}
					center={true}
				>
					<h2>{translate.text('confirm_your_order')}</h2>
					{
						(order.type === 'market')
							? <div className='modalForm'>
								<div className='modalFormRow'>
									<label htmlFor='side' className='modalFormLabel'>{translate.text('market')}</label>
									<div className='modalFormText'><span>{otherCoin}-{baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='side' className='modalFormLabel'>{translate.text('side')}</label>
									<div className='modalFormText'><span className={(order.side === 'buy') ? 'caps green' : 'caps red'}>{order.side === 'buy' ? translate.text('buy') : translate.text('sell')}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='amount' className='modalFormLabel'>{translate.text('amount')}</label>
									<div className='modalFormText'><span>{order.amount.toFixed(amountDecimals)} {otherCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('price')}</label>
									<div className='modalFormText'><span className='caps'>{order.type} Price ( ≈ {(order.side === 'buy') ? ticker.askPrice.toFixed(priceDecimals) : ticker.bidPrice.toFixed(priceDecimals)} {baseCoin})</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('total')}</label>
									<div className='modalFormText'><span>≈ {(order.side === 'buy') ? (order.amount * ticker.askPrice).toFixed(8) : (order.amount * ticker.bidPrice).toFixed(8)} {baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('fee')}</label>
									<div className='modalFormText'><span>
										≈ {(order.side === 'buy') ? (order.amount * ticker.takerFee).toFixed(8) : (order.amount * ticker.bidPrice * ticker.takerFee).toFixed(8)} {(order.side === 'buy') ? otherCoin : baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('net')}</label>
									<div className='modalFormText'><span>≈ {(order.side === 'buy') ? (order.amount * (1 - ticker.takerFee)).toFixed(8) : (order.amount * ticker.bidPrice * (1 - ticker.takerFee)).toFixed(8)} {(order.side === 'buy') ? otherCoin : baseCoin}</span></div>

								</div>
							</div>
							: <div className='modalForm'>
								<div className='modalFormRow'>
									<label htmlFor='side' className='modalFormLabel'>{translate.text('market')}</label>
									<div className='modalFormText'><span>{otherCoin}-{baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='side' className='modalFormLabel'>{translate.text('side')}</label>
									<div className='modalFormText'><span className={(order.side === 'buy') ? 'caps green' : 'caps red'}>{order.side === 'buy' ? translate.text('buy') : translate.text('sell')}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='amount' className='modalFormLabel'>{translate.text('amount')}</label>
									<div className='modalFormText'><span>{order.amount.toFixed(amountDecimals)} {otherCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('price')}</label>
									<div className='modalFormText'><span>{order.price.toFixed(priceDecimals)} {baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('total')}</label>
									<div className='modalFormText'><span>{(order.amount * order.price).toFixed(8)} {baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('fee')}</label>
									<div className='modalFormText'><span>{(order.side === 'buy') ? (order.amount * ticker.takerFee).toFixed(8) : (order.amount * order.price * ticker.makerFee).toFixed(8)} {(order.side === 'buy') ? otherCoin : baseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('net')}</label>
									<div className='modalFormText'><span>{(order.side === 'buy') ? (order.amount * (1 - ticker.takerFee)).toFixed(8) : (order.amount * order.price * (1 - ticker.makerFee)).toFixed(8)} {(order.side === 'buy') ? otherCoin : baseCoin}</span></div>
								</div>
							</div>
					}
					<div className='modalFormConfirm'>
						<button onClick={() => { this.onCloseModal(); this.sendOrder(order, symbol) }}>{translate.text('confirm_order')}</button>
					</div>
				</Modal>
				<div className='boxTitle'><span>{translate.text('order_form')}{/*  ({symbol})*/}</span></div>
				<div className='exchangeOrderForm'>
					<form id='orderForm'>
						<div className='orderSide'>
							<div className={'orderBuy ' + (order.side === 'buy' ? 'bgGreen' : '')} onClick={e => { changeOrder({ side: 'buy' }) }}>{translate.text('buy')}</div>
							<div className={'orderSell ' + (order.side === 'sell' ? 'bgRed' : '')} onClick={e => { changeOrder({ side: 'sell' }) }}>{translate.text('sell')}</div>
						</div>
						<div className='orderType'>
							<div className={'orderMarket ' + (order.type === 'market' ? 'orderTypeSelected' : '')} onClick={e => { changeOrder({ type: 'market' }) }}>{translate.text('market_order')}</div>
							<div className={'orderLimit ' + (order.type === 'limit' ? 'orderTypeSelected' : '')} onClick={e => { changeOrder({ type: 'limit' }) }}>{translate.text('limit_order')}</div>
						</div>
						{order.type === 'limit' ?
							<div>
								<div className='orderAmount'>
									<span>{translate.text('amount')}</span>
									<div className='orderAmountField'>
										<input type='number' id='amount' name='amount' autoComplete='off' min='0' placeholder={amountDecimals > 0 ? '0.' + Utils.pad('', amountDecimals) : '0'} step={amountDecimals > 0 ? '0.' + Utils.pad('1', Math.min(amountDecimals, 6)) : '1'} onChange={e => changeOrder({ amount: Utils.round(e.target.value, amountDecimals) })} value={order.amount || undefined} />
										<div className='orderAmountCurrency'>{otherCoin}</div>
									</div>
								</div>
								<div className='orderPriceLimit'>
									<span>{translate.text('price_limit')}</span>
									<div className='orderPriceLimitField'>
										<input type='number' id='price' name='price' autoComplete='off' min='0' placeholder={priceDecimals > 0 ? '0.' + Utils.pad('', priceDecimals) : '0'} step={priceDecimals > 0 ? '0.' + Utils.pad('1', Math.min(priceDecimals, 6)) : '1'} onChange={e => changeOrder({ price: Utils.round(e.target.value, priceDecimals) })} value={order.price || undefined} />
										<div className='orderPriceLimitCurrency'>{baseCoin}</div>
									</div>
								</div>
							</div>
							:
							<div className='orderAmount'>
								<span>{translate.text('amount')}</span>
								<div className='orderAmountField'>
									<input type='number' id='amount' name='amount' autoComplete='off' min='0' placeholder={amountDecimals > 0 ? '0.' + Utils.pad('', amountDecimals) : '0'} step={amountDecimals > 0 ? '0.' + Utils.pad('1', Math.min(amountDecimals, 6)) : '1'} onChange={e => changeOrder({ amount: Utils.round(e.target.value, amountDecimals) })} value={order.amount || undefined} />
									<div className='orderAmountCurrency'>{otherCoin} </div>
								</div>
							</div>
						}
						<div className='orderDivider' />
						{(order.side === 'buy')
							?
							<div className='orderDetails'>
								<div className='orderDetailsTotal'>
									<div className='orderDetailsTotalLabel'>{translate.text('total')} ≈</div>
									<div className='orderDetailsTotalAmount'>{((order.type === 'market' ? getTotal(order.amount, order.side) : order.amount * order.price) || 0).toFixed(8)} {baseCoin}</div>
								</div>
								<div className='orderDetailsFee'>
									<div className='orderDetailsFeeLabel'>{translate.text('fee')} ≈</div>
									<div className='orderDetailsFeeAmount'>{(order.amount * ticker.takerFee || 0).toFixed(8)} {otherCoin}</div>
								</div>
								<div className='orderDetailsTotal'>
									<div className='orderDetailsTotalLabel'>{translate.text('net')} ≈</div>
									<div className='orderDetailsTotalAmount'>{(order.amount * (1 - ticker.takerFee) || 0).toFixed(8)} {otherCoin}</div>
								</div>
							</div>
							:
							<div className='orderDetails'>
								<div className='orderDetailsTotal'>
									<div className='orderDetailsTotalLabel'>{translate.text('total')} ≈</div>
									<div className='orderDetailsTotalAmount'>{((order.type === 'market' ? getTotal(order.amount, order.side) : order.amount * order.price) || 0).toFixed(8)} {baseCoin}</div>
								</div>
								<div className='orderDetailsFee'>
									<div className='orderDetailsFeeLabel'>{translate.text('fee')} ≈</div>
									<div className='orderDetailsFeeAmount'>{((order.type === 'market' ? getTotal(order.amount, order.side) : order.amount * order.price) * ticker.takerFee || 0).toFixed(8)} {baseCoin}</div>
								</div>
								<div className='orderDetailsTotal'>
									<div className='orderDetailsTotalLabel'>{translate.text('net')} ≈</div>
									<div className='orderDetailsTotalAmount'>{((order.type === 'market' ? getTotal(order.amount, order.side) : order.amount * order.price) * (1 - ticker.takerFee) || 0).toFixed(8)} {baseCoin}</div>
								</div>
							</div>
						}
						{order.side === 'buy'
							? <div className='orderSubmit bgGreen' onClick={() => this.onOpenModal(order)}>{translate.text('place_buy_order')}</div>
							: <div className='orderSubmit bgRed' onClick={() => this.onOpenModal(order)}>{translate.text('place_sell_order')}</div>
						}
					</form>
				</div>
			</div>
		)
	}

	onOpenModal = (order: IOrder) => {
		if (!this.props.isLogged) {
			toast.error(translate.text('user_not_logged_in'))
			return
		}
		let error = false
		if (order.amount <= 0) {
			toast.error(translate.text('amount_invalid'))
			error = true
		}
		if (order.price <= 0 && order.type !== 'market') {
			toast.error(translate.text('price_invalid'))
			error = true
		}
		if (error) {
			return
		}
		this.setState({ openModal: true })
	}

	onCloseModal = () => {
		this.setState({ openModal: false })
	}

	reset() {
		let orderForm = document.getElementById('orderForm') as HTMLFormElement
		let amount = document.getElementById('amount') as HTMLInputElement
		let price = document.getElementById('price') as HTMLInputElement
		if (orderForm) {
			orderForm.reset()
		}
		if (amount) {
			amount.value = ''
		}
		if (price) {
			price.value = ''
		}
		this.props.changeOrder({ price: 0, amount: 0 })
	}

	sendOrder(order: Partial<IOrder>, symbol: string) {
		if (!this.props.isLogged) {
			toast.error(translate.text('user_not_logged_in'))
			return
		}
		const verified = this.props.verified
		if (!verified) {
			toast.error(translate.text('user_not_verified'))
			return
		}
		let error = false
		if (!order.amount || isNaN(order.amount) || order.amount <= 0) {
			toast.error(translate.text('amount_invalid'))
			error = true
		}
		if (order.type !== 'market' && (!order.price || isNaN(order.price) || order.price <= 0)) {
			toast.error(translate.text('price_invalid'))
			error = true
		}
		if (error) {
			return
		}

		// const otherCoin: string = symbol.split('-')[0]
		// const baseCoin: string = symbol.split('-')[1]
		// if (order.type === 'market') {
		// 	if (!confirm(`${order.side} ${order.amount} ${otherCoin} @ ${order.type}`)) {
		// 		return
		// 	}
		// } else {
		// 	if (!confirm(`${order.side} ${order.amount} ${otherCoin} @ ${order.price} ${baseCoin}`)) {
		// 		return
		// 	}
		// }
		delete order.orderId
		delete order.userId
		delete order.filled
		delete order.date

		ws.send(ActionCreators.Order.insert({ ...order, market: symbol } as IOrder))
		this.reset()
	}
}