import { ActionCreators, IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { ChartComponent } from '../components/chart'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		market: state.markets.list[state.markets.current],
		tickers: state.tickers,
		orderbook: state.orderbooks.list[state.markets.current],
		lang: state.lang
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {
		period: (period: string) => dispatch(ActionCreators.Candlestick.period(period))
	}
}

export const ChartContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartComponent))