import { IMarkets, ITickers } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import config from '../config.json'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { INews } from '../models/news'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	markets: IMarkets
	tickers: ITickers
	lang: string
	news: INews[]
	loadNews: (news: INews[]) => void
}

interface IMatchProps {
	// include any url parameters here.

}

export class HomeComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	public isLoadingNews: boolean = false
	loadNews() {

		if (this.isLoadingNews || this.props.news.some(s => s.lang === this.props.lang)) {
			return
		}
		this.isLoadingNews = true
		const blogUrl = translate.text('blog_posts_url')
		fetch(blogUrl).then(res => res.json()).then(json => {
			this.props.loadNews(json.map((m: any) => {
				return {
					date: new Date(m.date_gmt),
					link: m.link,
					title: m.title.rendered,
					lang: this.props.lang,
				} as INews
			})
			)
			this.isLoadingNews = false
		})
	}

	render() {

		translate.language = this.props.lang
		this.loadNews()

		const brlLogo = require('../images/coins/BRL.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const usdcLogo = require('../images/coins/USDC.svg')

		const fast = require('../images/icons/fast.svg')
		const easy = require('../images/icons/easy.svg')
		const secure = require('../images/icons/secure.svg')

		const desktop = require('../images/branding/devices.png')
		const desktopNpm = require('../images/branding/desktop-npm.png')
		const npmBtn = require('../images/branding/npm-econobit.png')
		const macBtn = require('../images/branding/mac-os.svg')
		const iosBtn = require('../images/logos/appstore.svg')
		const iosQR = require('../images/qr-codes/qrcode_apps.apple.com.png')

		const winBtn = require('../images/branding/windows.svg')
		const andBtn = require('../images/logos/googleplay.svg')
		const androidQR = require('../images/qr-codes/qrcode_play.google.com.png')

		const support = require('../images/icons/support.svg')
		const newspaper = require('../images/icons/newspaper.svg')
		const community = require('../images/icons/community.svg')
		const career = require('../images/icons/career.svg')

		const { list: markets } = this.props.markets
		const { list: tickers } = this.props.tickers

		const format = (delta: number, decimals: number) => {
			return delta < 0 ? delta.toFixed(decimals) : '+' + delta.toFixed(decimals)
		}

		const color = (delta: number) => {
			switch (true) {
				case delta > 0:
					return ' green'
				case delta < 0:
					return ' red'
				default:
					return ' grayZero'
			}
		}

		const news = (this.props.news || []).filter(f => f.lang === this.props.lang)

		if (!Object.keys(markets).length || !Object.keys(tickers).length) {
			return <div />
		}

		interface MarketRowProps {
			symbol: string
			logo: any
			deltaDecimals?: number
			volumeDecimals?: number
		}

		const MarketRow = ({ symbol, logo, deltaDecimals = 2, volumeDecimals = 2 }: MarketRowProps) => {
			if (!markets[symbol].visible) { return null }
			return (
				<Link className='homeMarketsTableBodyRowLink' to={`/exchange/${symbol}/`}>
					<div className='homeMarketsTableBodyRow'>
						<div className='homeMarketsTableBodyRowItem'><img src={logo} className='homeMarketsLogo' alt={symbol.split('-')[0]} /><span>{symbol}</span></div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].lastPrice.toFixed(markets[symbol].priceDecimals)}</div>
						<div className={'homeMarketsTableBodyRowItem' + color(tickers[symbol].delta24h)}><b>{format(tickers[symbol].delta24h * 100, deltaDecimals)}%</b></div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].high24h.toFixed(markets[symbol].priceDecimals)}</div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].low24h.toFixed(markets[symbol].priceDecimals)}</div>
						<div className='homeMarketsTableBodyRowItem'>{tickers[symbol].volume24h.toFixed(volumeDecimals)}</div>
					</div>
				</Link>
			)
		}

		const MarketRowHeader = () =>
			<div className='homeMarketsTableHeaderRow'>
				<div className='homeMarketsTableHeaderRowItem pairColumn'>{translate.text('pair')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('last_price')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_price')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_high')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_low')}</div>
				<div className='homeMarketsTableHeaderRowItem'>{translate.text('24h_volume')}</div>
			</div>

		const haveMarkets = (coin: string) => Object.entries(markets).some(([name, market]) => name.endsWith(coin) && market.visible)

		return (
			<div className='home'>
				<div className='homeBanner'>
					<h2>{translate.text('home_banner_msg_1')}</h2>
					<p>{translate.text('home_banner_msg_2')}</p>
					<Link className='homeBannerBtn' to='/register'>{translate.text('create_free_account')}</Link>
					<div className='homeBannerLink'><a href={config.environment === 'LIVE' ? 'https://testnet.econobit.io' : 'https://econobit.io'} target='_self'>{config.environment === 'LIVE' ? translate.text('try_simulated_trading') : translate.text('try_live_trading')}</a></div>
				</div>
				<div className='homeBannerFooter'>
					<div className='homeBannerFooterColumnIcon'><div className='iconAnnouncements' /></div>
					{news.map(m =>
						<div className='homeBannerFooterColumn' key={m.title}>
							<a href={m.link} target='_blank' rel='noopener noreferrer'>{m.title}<span>{Intl.DateTimeFormat(undefined, { day: '2-digit', month: 'numeric' }).format(m.date)}</span></a>
						</div>
					)}
					<div className='homeBannerFooterColumnMore'><a href={translate.text('blog_cat_url')} target='_blank' rel='noopener noreferrer'>{translate.text('view_more')}</a></div>
				</div>
				<div className='homeMarketsBox'>
					<Tabs>
						<TabList>
							{haveMarkets('BRL') &&
								<Tab><img src={brlLogo} className='homeSelectMarketsLogo' alt='BRL' /> {translate.text('brl_markets')}</Tab>
							}
							{haveMarkets('USDC') &&
								<Tab><img src={usdcLogo} className='homeSelectMarketsLogo' alt='USDC' /> {translate.text('usdc_markets')}</Tab>
							}
							{haveMarkets('BTC') &&
								<Tab><img src={btcLogo} className='homeSelectMarketsLogo' alt='BTC' /> {translate.text('btc_markets')}</Tab>
							}
						</TabList>
						{haveMarkets('BRL') &&
							<TabPanel>
								<div className='homeMarketsTableMain'>
									<MarketRowHeader />
									<MarketRow symbol='BTC-BRL' logo={btcLogo} />
									<MarketRow symbol='ETH-BRL' logo={ethLogo} />
									<MarketRow symbol='EBIT-BRL' logo={ebitLogo} />
									<MarketRow symbol='LTC-BRL' logo={ltcLogo} />
									<MarketRow symbol='DOGE-BRL' logo={dogeLogo} />
									<MarketRow symbol='LINK-BRL' logo={linkLogo} />
								</div>
							</TabPanel>
						}
						{haveMarkets('USDC') &&
							<TabPanel>
								<div className='homeMarketsTableMain'>
									<MarketRowHeader />
									<MarketRow symbol='BTC-USDC' logo={btcLogo} />
									<MarketRow symbol='ETH-USDC' logo={ethLogo} />
									<MarketRow symbol='EBIT-USDC' logo={ebitLogo} />
									<MarketRow symbol='LTC-USDC' logo={ltcLogo} />
									<MarketRow symbol='DOGE-USDC' logo={dogeLogo} />
									<MarketRow symbol='LINK-USDC' logo={linkLogo} />
								</div>
							</TabPanel>
						}
						{haveMarkets('BTC') &&
							<TabPanel>
								<div className='homeMarketsTableMain'>
									<MarketRowHeader />
									<MarketRow symbol='ETH-BTC' logo={ethLogo} />
									<MarketRow symbol='EBIT-BTC' logo={ebitLogo} />
									<MarketRow symbol='LTC-BTC' logo={ltcLogo} />
									<MarketRow symbol='DOGE-BTC' logo={dogeLogo} />
									<MarketRow symbol='LINK-BTC' logo={linkLogo} />
								</div>
							</TabPanel>
						}
					</Tabs>
				</div>
				<div className='homeBlocks1'>
					<div className='homeBlocks1Title'>
						{translate.text('experience_the_best_technology')}
					</div>
					<div className='homeBlocks1Columns'>
						<div className='homeBlocks1Column3'>
							<img src={fast} className='homeBlocks1Column3Icon' alt='Fast' />
							<div className='homeBlocks1Column3Title'>{translate.text('fast')}</div>
							<div className='homeBlocks1Column3Text'>{translate.text('block1_msg_1')}</div>
						</div>
						<div className='homeBlocks1Column3'>
							<img src={easy} className='homeBlocks1Column3Icon' alt='Easy' />
							<div className='homeBlocks1Column3Title'>{translate.text('easy')}</div>
							<div className='homeBlocks1Column3Text'>{translate.text('block1_msg_2')}</div>
						</div>
						<div className='homeBlocks1Column3'>
							<img src={secure} className='homeBlocks1Column3Icon' alt='Secure' />
							<div className='homeBlocks1Column3Title'>{translate.text('secure')}</div>
							<div className='homeBlocks1Column3Text'>{translate.text('block1_msg_3')}</div>
						</div>
					</div>
				</div>
				<div className='homeBlocks2'>
					<div className='homeBlocks2Column'>
						<div className='homeBlocks2Title'>
							{translate.text('trade_anywhere')}
						</div>
						<div className='homeBlocks2Text'>
							{translate.text('block2_msg')}
						</div>
						<div className='homeBlocks2BtnsTop'>
							<a className='homeBlocks2BtnLeft tooltipIos' href='https://apps.apple.com/us/app/econobit/id1498110940' target='_blank' rel='noopener noreferrer' >
								<img className='homeBlocks2Btn' src={iosBtn} alt='ios' />
								<img className='tooltipIosQR' src={iosQR} alt='App Store' />
							</a>
							<a className='homeBlocks2BtnRight' href='https://files.econobit.io/desktop/econobit-1.0.1-mac.dmg' target='_self' >
								<img className='homeBlocks2Btn' src={macBtn} alt='macos' />
							</a>
						</div>
						<div className='homeBlocks2BtnsLow'>
							<a className='homeBlocks2BtnLeft tooltipAndroid' href='https://play.google.com/store/apps/details?id=com.econobit.app' target='_blank' rel='noopener noreferrer'>
								<img className='homeBlocks2Btn' src={andBtn} alt='android' />
								<img className='tooltipAndroidQR' src={androidQR} alt='App Store' />
							</a>
							<a className='homeBlocks2BtnRight' href='https://files.econobit.io/desktop/econobit-1.0.1-win.exe' target='_self' >
								<img className='homeBlocks2Btn' src={winBtn} alt='windows' />
							</a>
						</div>
					</div>
					<div className='homeBlocks2Column'>
						<img src={desktop} className='homeBlocks2ColumnImage' alt='Econobit' />
					</div>
				</div>
				<div className='homeBlocks3'>
					<div className='homeBlocks3Column'>
						<div className='homeBlocks3Title'>
							{translate.text('integration_with_ease')}
						</div>
						<div className='homeBlocks3Text'>
							{translate.text('block3_msg')}
						</div>
						<div className='homeBlocks3Btns'>
							<a className='homeBlocks3BtnsLink' href='https://docs.econobit.io' target='_blank' rel='noopener noreferrer'>
								<div className='homeBlocks3ApiBtn'>{translate.text('api_documentation')}</div>
							</a>
							<a className='homeBlocks3BtnsLink' href='https://www.npmjs.com/package/econobit' target='_blank' rel='noopener noreferrer'>
								<img src={npmBtn} className='homeBlocks3NpmBtn' alt='npm' />
							</a>
						</div>
					</div>
					<div className='homeBlocks3Column'>
						<img src={desktopNpm} className='homeBlocks3ColumnImage' alt='Econobit' />
					</div>
				</div>
				<div className='homeBlocks4'>
					<div className='homeBlocks4Title'>
						{translate.text('block4_title')}
					</div>
					<div className='homeBlocks1Columns'>
						<div className='homeBlocks4Column4'>
							<a className='homeBlocks4Column4Link' href={translate.text('support_url')} target='_blank' rel='noopener noreferrer'>
								<img src={support} className='homeBlocks1Column3Icon' alt='Support' />
								<div className='homeBlocks4Column4Title'>{translate.text('247_support')}</div>
							</a>
							<div className='homeBlocks4Column4Text'>{translate.text('got_a_problem')}</div>
						</div>
						<div className='homeBlocks4Column4'>
							<a className='homeBlocks4Column4Link' href='https://blog.econobit.io/' target='_blank' rel='noopener noreferrer'>
								<img src={newspaper} className='homeBlocks1Column3Icon' alt='Blog' />
								<div className='homeBlocks4Column4Title'>{translate.text('econobit_blog')}</div>
							</a>
							<div className='homeBlocks4Column4Text'>{translate.text('news_and_updates')}</div>
						</div>
						<div className='homeBlocks4Column4'>
							<a className='homeBlocks4Column4Link' href='/aboutUs/#community' target='_self'>
								<img src={community} className='homeBlocks1Column3Icon' alt='Community' />
								<div className='homeBlocks4Column4Title'>{translate.text('community')}</div>
							</a>
							<div className='homeBlocks4Column4Text'>{translate.text('econobit_is_global')}</div>
						</div>
						<div className='homeBlocks4Column4'>
							<a className='homeBlocks4Column4Link' href='/aboutUs/#careers' target='_self'>
								<img src={career} className='homeBlocks1Column3Icon' alt='Career' />
								<div className='homeBlocks4Column4Title'>{translate.text('careers')}</div>
							</a>
							<div className='homeBlocks4Column4Text'>{translate.text('help_build_future')}</div>
						</div>
					</div>
				</div>
				<div className='homeBlocks5'>
					<div className='homeBlocks5Title'>
						{translate.text('start_trading_now')}
					</div>
					<div className='homeBlocks5Text'>
						{translate.text('trade_many_cryptos')}
					</div>
					<div className='homeBlocks5Buttons'>
						<Link to='/register' className='homeCallButton1'>{translate.text('register_now')}</Link>
						<Link to='/exchange' className='homeCallButton2'>{translate.text('view_exchange')}</Link>
					</div>
				</div>
				<HomeFooterContainer />
			</div >

		)
	}

}