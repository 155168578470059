import { IMarkets, ITickers } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	markets: IMarkets
	tickers: ITickers
	isLogged: boolean
	lang: string
}

interface IMatchProps {
	// include any url parameters here.
	market: string
}

export class MobileMarketsComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {
	componentWillMount() {
		const { market } = this.props.match.params
		// const { current } = this.props.markets

		if (!market) {
			return
		}
		// if (market !== current) {
		// 	ws.send(ActionCreators.Server.Unsubscribe.Orderbook(current))
		// 	ws.send(ActionCreators.Server.Subscribe.Orderbook(market))
		// 	ws.send(ActionCreators.Server.Unsubscribe.LastTrades(current))
		// 	ws.send(ActionCreators.Server.Subscribe.LastTrades(market))
		// 	ws.send(ActionCreators.Server.Unsubscribe.Candlestick(current))
		// 	ws.send(ActionCreators.Server.Subscribe.Candlestick(market))
		// 	this.props.marketsChange(market)
		// } else {
		// 	ws.send(ActionCreators.Server.Subscribe.Orderbook(current))
		// 	ws.send(ActionCreators.Server.Subscribe.LastTrades(current))
		// 	ws.send(ActionCreators.Server.Subscribe.Candlestick(current))
		// }
	}

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		translate.language = this.props.lang

		const btcBrlLogo = require('../images/pairs/BTC-BRL.svg')
		const ltcBrlLogo = require('../images/pairs/LTC-BRL.svg')
		const dogeBrlLogo = require('../images/pairs/DOGE-BRL.svg')
		const ethBrlLogo = require('../images/pairs/ETH-BRL.svg')
		const ebitBrlLogo = require('../images/pairs/EBIT-BRL.svg')
		const linkBrlLogo = require('../images/pairs/LINK-BRL.svg')

		const btcUsdcLogo = require('../images/pairs/BTC-USDC.svg')
		const ltcUsdcLogo = require('../images/pairs/LTC-USDC.svg')
		const dogeUsdcLogo = require('../images/pairs/DOGE-USDC.svg')
		const ethUsdcLogo = require('../images/pairs/ETH-USDC.svg')
		const ebitUsdcLogo = require('../images/pairs/EBIT-USDC.svg')
		const linkUsdcLogo = require('../images/pairs/LINK-USDC.svg')

		const ltcBtcLogo = require('../images/pairs/LTC-BTC.svg')
		const dogeBtcLogo = require('../images/pairs/DOGE-BTC.svg')
		const ethBtcLogo = require('../images/pairs/ETH-BTC.svg')
		const ebitBtcLogo = require('../images/pairs/EBIT-BTC.svg')
		const linkBtcLogo = require('../images/pairs/LINK-BTC.svg')

		const markets = this.props.markets.list
		const tickers = this.props.tickers.list
		if (!Object.keys(markets).length || !Object.keys(tickers).length) {
			return <div />
		}

		const color = (delta: number) => {
			switch (true) {
				case delta > 0:
					return ' green'
				case delta < 0:
					return ' red'
				default:
					return ' white'
			}
		}

		const format = (delta: number, decimals: number) => {
			return delta < 0 ? delta.toFixed(decimals) : '+' + delta.toFixed(decimals)
		}

		interface MarketsDropdownRowProps {
			symbol: string
			logo: any
			deltaDecimals?: number
		}

		const MarketsDropdownRow = ({ symbol, logo, deltaDecimals = 2 }: MarketsDropdownRowProps) => {
			if (!markets[symbol].visible) { return null }
			return (
				<Link className='marketsDropdownItem' to={`/exchange/${symbol}/`}>
					<div className='marketsDropdownThird' >
						<img src={logo} className='marketsDropdownLogo' alt={symbol} />
						<div className='marketsDropdownLabel'>{symbol}</div>
					</div>
					<div className='marketsDropdownTickerValue marketsDropdownThird'><span>{tickers[symbol].lastPrice.toFixed(markets[symbol].priceDecimals)}</span></div>
					<div className={'marketsDropdownTickerDelta marketsDropdownThird' + color(tickers[symbol].delta24h)}><span>{format(tickers[symbol].delta24h * 100, deltaDecimals)}%</span></div>
				</Link>

			)
		}

		const haveMarkets = (coin: string) => Object.entries(markets).some(([name, market]) => name.endsWith(coin) && market.visible)

		const MarketsDropdownHeader = ({ coin }: { coin: string }) => {
			if (!haveMarkets(coin)) { return null }
			return (
				<div className='marketsDropdownTitle gray'>
					<div className='marketsTitleLabel'>{translate.text(`${coin.toLowerCase()}_markets`)}</div>
					<div className='marketsTitleLabely'>{translate.text('last_price')}</div>
					<div className='marketsTitleLabely'>{translate.text('24h_price')}</div>
				</div>
			)
		}

		return (
			<div>
				<MarketsDropdownHeader coin='BRL' />
				<MarketsDropdownRow symbol='BTC-BRL' logo={btcBrlLogo} />
				<MarketsDropdownRow symbol='ETH-BRL' logo={ethBrlLogo} />
				<MarketsDropdownRow symbol='EBIT-BRL' logo={ebitBrlLogo} />
				<MarketsDropdownRow symbol='LTC-BRL' logo={ltcBrlLogo} />
				<MarketsDropdownRow symbol='DOGE-BRL' logo={dogeBrlLogo} />
				<MarketsDropdownRow symbol='LINK-BRL' logo={linkBrlLogo} />

				{haveMarkets('BRL') && haveMarkets('USDC') &&
					<div className='homeSpacer' />
				}

				<MarketsDropdownHeader coin='USDC' />
				<MarketsDropdownRow symbol='BTC-USDC' logo={btcUsdcLogo} />
				<MarketsDropdownRow symbol='ETH-USDC' logo={ethUsdcLogo} />
				<MarketsDropdownRow symbol='EBIT-USDC' logo={ebitUsdcLogo} />
				<MarketsDropdownRow symbol='LTC-USDC' logo={ltcUsdcLogo} />
				<MarketsDropdownRow symbol='DOGE-USDC' logo={dogeUsdcLogo} />
				<MarketsDropdownRow symbol='LINK-USDC' logo={linkUsdcLogo} />

				{(haveMarkets('BRL') || haveMarkets('USDC')) && haveMarkets('BTC') &&
					<div className='homeSpacer' />
				}

				<MarketsDropdownHeader coin='BTC' />
				<MarketsDropdownRow symbol='ETH-BTC' logo={ethBtcLogo} />
				<MarketsDropdownRow symbol='EBIT-BTC' logo={ebitBtcLogo} />
				<MarketsDropdownRow symbol='LTC-BTC' logo={ltcBtcLogo} />
				<MarketsDropdownRow symbol='DOGE-BTC' logo={dogeBtcLogo} />
				<MarketsDropdownRow symbol='LINK-BTC' logo={linkBtcLogo} />

				<br />
				<br />
			</div>
		)
	}
 
}