import { ActionTypes, IOrder, IOrderAction, Order } from 'exchange-common'

export const order =
	(state: IOrder = new Order(), action: IOrderAction): IOrder => {
		switch (action.type) {
			case ActionTypes.Order.Change:
				return {
					...state,
					...action.order,
				}
			default:
				return state
		}
	}