import { ActionTypes, Connection, IConnection, IConnectionChange } from 'exchange-common'

export const connection = (state: IConnection = new Connection(), action: IConnectionChange): IConnection => {
	switch (action.type) {
		case ActionTypes.Connection.Change:
			return {
				...state,
				...action.connection,
			}
		default:
			return state
	}
}