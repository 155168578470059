import { ActionCreators, ActionTypes } from 'exchange-common'
import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.
	hash: string
}

export class ConfirmWithdrawComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { sent: boolean }> {

	componentDidMount() {
	}
	render() {
		const sendWithdrawConfirm = () => {
			console.log('sending confirm withdraw...')
			ws.send(ActionCreators.Withdraw.confirm(this.props.match.params.hash))
			this.setState({ ...this.state, sent: true })
		}
		ws.off(ActionTypes.WS.Open, sendWithdrawConfirm)
		ws.once(ActionTypes.WS.Open, sendWithdrawConfirm)
		translate.language = this.props.lang
		return <Redirect to='/' />
	}
}