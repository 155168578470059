import { IMarkets, ITrades } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { formatAmount } from '../functions/formatAmount'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lastTrades: ITrades
	market: string

	markets: IMarkets
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class TradeHistoryComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const symbol = this.props.markets.current
		const { lastTrades } = this.props
		const baseCoin = this.props.market.split('-')[1]
		const priceDecimals = this.props.markets.list[symbol].priceDecimals
		const amountDecimals = this.props.markets.list[symbol].amountDecimals
		translate.language = this.props.lang

		if (!lastTrades.list[symbol]) {
			return <div />
		}

		return (
			<div className='tradeHistory'>
				<div className='boxTitle'><span>{translate.text('trade_history')}{/* ({symbol})*/}</span></div>
				<div className='tradeHistoryHeader'>
					<div className='tradeHistoryHeaderLabel'>{translate.text('trade_size')}</div>
					<div className='tradeHistoryHeaderPriceLabel'>{translate.text('price')} ({baseCoin})</div>
					<div className='tradeHistoryHeaderLabel'>{translate.text('time')}</div>
				</div>
				<div className='tradeHistoryMain'>
					{
						lastTrades.list[symbol].map((trade) => {
							const lastArrow = trade.side === 'buy' ? '↗' : '↘'
							const lastDirection = trade.side === 'buy' ? 'Up' : 'Down'
							return (
								<CSSTransition
									classNames='animateTrade'
									appear={true}
									timeout={500}
									enter={false}
									exit={false}
									key={trade.tradeId}
									component='div'
								>
									<div key={trade.tradeId} className={'tradeHistoryRow borderRow' + lastDirection}>
										<div className='tradeHistoryRowSize'>{formatAmount(trade.amount, amountDecimals)}</div>
										<div className={'tradeHistoryRowPrice' + lastDirection}>{trade.price.toFixed(priceDecimals)}
											<span className='arrowWidth'>
												{lastArrow}
											</span>
										</div>
										<div className='tradeHistoryRowTime' title={new Date(trade.date).toLocaleString('en-GB')}>{new Date(trade.date).toTimeString().split(' ')[0]}</div>
									</div>
								</CSSTransition>
							)
						})
					}
				</div>
			</div >
		)
	}

}