import { ActionCreators, ILogin, IUserLogin } from 'exchange-common'
import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { isEmail, maxLength100, minLength6, renderField, required } from './renderField'

interface IProps {
	market: string
	isLogged: boolean
	lang: string
}

interface IMatchProps {
	redirect: string
}

let LoginForm = (props: any) => {
	const { handleSubmit, pristine, submitting } = props

	return (
		<div className='loginBox' >
			<div className='phishingBox' >
				<span className='phishingBoxTitle' >{translate.text('beware_phishing')}</span>
				<span className='phishingBoxText' ><b>{translate.text('always')}</b> {translate.text('phishing_msg')}</span>
				<div className='URLimg' />
			</div>
			<form className='loginForm' onSubmit={handleSubmit}>
				<h2>{translate.text('welcome_back')}</h2>
				<label htmlFor='email'>{translate.text('email')}</label>
				<Field name='email' component={renderField} type='email' placeholder='your@email.com' validate={[isEmail, required, maxLength100]} />
				<label htmlFor='password'>{translate.text('password')}</label>
				<Field name='password' component={renderField} type='password' validate={[required, minLength6]} />
				<label htmlFor='auth'>{translate.text('2fa_token_if_enabled')}</label>
				<Field name='auth' component={renderField} type='text' autoComplete='off' />
				<button type='submit' disabled={submitting || pristine}>{translate.text('login')}</button>
				<Link className='lostPasswordLink' to='/lostPassword'>{translate.text('lost_your_password')}</Link>
			</form>
		</div>
	)
}

LoginForm = reduxForm({
	form: 'login'
})(LoginForm) as any

export class LoginComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	render() {
		if (this.props.isLogged) {
			ws.send(ActionCreators.Subscribe.openOrders())
			ws.send(ActionCreators.Subscribe.ordersHistory())
			ws.send(ActionCreators.Subscribe.deposits())
			ws.send(ActionCreators.Subscribe.withdrawals())
			ws.send(ActionCreators.Subscribe.trades())
			ws.send(ActionCreators.Subscribe.balances())
			const redirect = this.props.match.params.redirect
			if (!redirect) {
				return <Redirect to={`/exchange/${this.props.market}`} />
			}
			return <Redirect to={`/${redirect}`} />
		}
		translate.language = this.props.lang

		return (
			<div className='login'>
				<LoginForm onSubmit={(login: ILogin) => this.onSubmit(login)} />
			</div>
		)
	}

	onSubmit(login: ILogin) {
		// console.log(login)
		const action: IUserLogin = ActionCreators.User.login(login)
		ws.send(action)
	}
}