import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { ThemeDarkContainer } from '../containers/themeDark'
import { ThemeCVDContainer } from '../containers/themeCVD'
import { ThemeLightContainer } from '../containers/themeLight'
import { ThemeDefaultContainer } from '../containers/themeDefault'
import { translate } from '../functions/lang'

interface IProps {
	// include component properties here.
	style: string
	lang: string
}

interface IMatchProps {
	// include any url parameters here.
}
export class ThemeComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {
	render() {
		const { style, lang } = this.props
		translate.language = lang
		switch (style) {
			case 'dark':
				return <ThemeDarkContainer />
			case 'cvd':
				return <ThemeCVDContainer />
			case 'light':
				return <ThemeLightContainer />
			default:
				return <ThemeDefaultContainer />
		}
	}
}