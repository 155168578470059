import { Markets } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	market: Markets

	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileFooterComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const markets = require('../images/icons/graphic.svg')
		const exchange = require('../images/icons/orders.svg')
		const orders = require('../images/icons/list.svg')
		const wallets = require('../images/icons/wallets.svg')

		return (
			<div className='mobileFooter' >
				<NavLink activeClassName='mobileFooterSelected' className='mobileFooterColumn mobileFooterLink' to='/markets'><img src={markets} className='mobileFooterIcon' alt='markets' /><span>{translate.text('markets')}</span></NavLink>
				<NavLink activeClassName='mobileFooterSelected' className='mobileFooterColumn mobileFooterLink' to={'/exchange/' + this.props.market.current}><img src={exchange} className='mobileFooterIcon' alt='exchange' /><span>{translate.text('exchange')}</span></NavLink>
				<NavLink activeClassName='mobileFooterSelected' className='mobileFooterColumn mobileFooterLink' to='/orders'><img src={orders} className='mobileFooterIcon' alt='orders' /><span>{translate.text('orders')}</span></NavLink>
				<NavLink activeClassName='mobileFooterSelected' className='mobileFooterColumnLast mobileFooterLink' to='/wallets'><img src={wallets} className='mobileFooterIcon' alt='wallets' /><span>{translate.text('wallets')}</span></NavLink>
			</div>
		)
	}

}