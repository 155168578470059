export const showGreeting = () => {
	console.log(
		'\n' +
		'                       *,*%                     \n' +
		'                  #*,,,,,,,,,*%                 \n' +
		'              %*,,,,,,,,,,,,,,,,,*%             \n' +
		'          & /,,,,,,,,,,,,,,,,,,,,,,,/           \n' +
		'        /,,,,,,,,,,,*///,...,,,,,,,,,,,,/       \n' +
		'    &/*,,,,,,,,,,**/////,......,,,,,,,,,,,*/    \n' +
		' #*,,,,,,,,,,**/////////,.........,,,,,,,,,,,,*%\n' +
		'.,,,,,,,**//////////*     .,......,,,,,,,,,,,,**\n' +
		'....,,*///////////            ,,,,,,,,,,,,,*///*\n' +
		'.....,////////.           #/,,,,,,,,,,,*///////*\n' +
		'.....,//////          &/,,,,,,,,,,,**////////// \n' +
		'.....,//////       &/,,,,,,,,,,,**//////////*   \n' +
		'.....,//////      ,,,,,,,,,,,*//////////*       \n' +
		'.....,//////      ,...,,,**//////////,          \n' +
		'.....,//////      ,.....*/////////              \n' +
		'.....,//////       ,....*/////.          &%&    \n' +
		'.....,//////         ,..*//*           %*,,,,/& \n' +
		'.....,////**/&                     %*,,,,,,,,,,*\n' +
		'.....,**,,,,,,,* /             %,,,,,,,,,,,,*//*\n' +
		'......,,,,,,,,,,,,*%        %*,,,,,,,,,,**/////*\n' +
		'..........,,,,,,,,,,,, /&/,,,,,,,,,,**/////////*\n' +
		'  ............,,,,,,,,,,,,,,,,,,,,*///////////* \n' +
		'      ............,,,,,,,,,,,,*///////////*     \n' +
		'         , ..........,,,,,**///////////         \n' +
		'            , ..........,///////////            \n' +
		'               ., ......,///////                \n' +
		'                    ....,///,                   \n' +
		'\n' +
		'\n' +
		'Hello visitor! Welcome to the Econobit exchange.\n' +
		'\n' +
		'If you are looking for our API documentation,\n' +
		'check out this link:\n' +
		'\n' +
		'https://docs.econobit.io\n' +
		'\n' +
		'If you are looking for ou support channel,\n' +
		'check out this link:\n' +
		'\n' +
		'https://support.econobit.io' +
		'\n' +
		'\n'
	)
}