import { IUser } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	user: IUser
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class HeaderAccountComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		const headerAvatar = require('../images/icons/avatar.svg')
		const profile = require('../images/icons/profile.svg')
		const kyc = require('../images/icons/kyc.svg')
		const bankAccounts = require('../images/icons/banks.svg')
		const keys = require('../images/icons/api.svg')
		const security = require('../images/icons/security.svg')
		const logout = require('../images/icons/logout.svg')

		let user = this.props.user
		translate.language = this.props.lang

		return user.isLogged ? (
			<div className='headerAccount'>
				<div>
					<NavLink activeClassName='headerMenuSelected' to='/orders'>{translate.text('orders')}</NavLink>
					<NavLink activeClassName='headerMenuSelected' to='/wallets'>{translate.text('wallets')}</NavLink>
				</div>
				<div className='headerProfile' onClick={e => this.onProfileClick()}>
					<div className='headerProfileBox'>
						<span className='headerProfileName'>{user.nickname || user.email}</span>
						<img src={headerAvatar} className='headerAvatar' alt='headerAvatar' />
						<span className='profileArrowDown' id='profileArrowDown' />
					</div>
					<div className='profileDropdown' id='profileDropdown'>
						<Link className='profileDropdownItem' to='/profile'><img src={profile} className='icon' alt='profile' /><span>{translate.text('profile')}</span></Link>
						<Link className='profileDropdownItem' to='/kyc'><img src={kyc} className='icon' alt='kyc' /><span>{translate.text('id_verification')}</span></Link>
						<Link className='profileDropdownItem' to='/bankAccounts'><img src={bankAccounts} className='icon' alt='bankAccounts' /><span>{translate.text('bank_accounts')}</span></Link>
						<Link className='profileDropdownItem' to='/keys'><img src={keys} className='icon' alt='keys' /><span>{translate.text('api_keys')}</span></Link>
						<Link className='profileDropdownItem' to='/security'><img src={security} className='icon' alt='security' /><span>{translate.text('security')}</span></Link>
						<Link className='profileDropdownItem' to='/logout'><img src={logout} className='icon' alt='logout' /><span>{translate.text('logout')}</span></Link>
					</div >
				</div>
			</div>
		) : (
			<div className='headerLogin'>
				<Link to='/login'>{translate.text('login')}</Link>
				<Link to='/register'>{translate.text('register')}</Link>
			</div>
		)
	}
	onProfileClick() {
		const x = document.getElementById('profileDropdown')!
		if (x.style.top !== '50px') {
			x.style.top = '50px'
			x.style.transition = 'top 150ms ease'
		} else {
			x.style.top = '-300px'
		}
		const y = document.getElementById('profileArrowDown')!
		if (y.style.transform !== 'rotate(-135deg)') {
			y.style.transform = 'rotate(-135deg)'
			y.style.transition = 'transform 150ms ease'

		} else {
			y.style.transform = 'rotate(45deg)'
			y.style.margin = '13px 0 11px 7px'
		}
	}

}