import * as events from 'events'
import { ActionCreators, ActionTypes, IAction } from 'exchange-common'
import { toast } from 'react-toastify'
import { store } from '..'
import config from '../config.json'
import { history } from './history'
import { translate } from './lang'
import { Utils } from './utils'

export class ClientWS extends events.EventEmitter {
	public ws!: WebSocket

	// private actionsQueue: IAction[] = []

	/** connect to websocket */
	public connect() {
		try {
			if (window.location.hostname === 'localhost') {
				this.ws = new WebSocket('ws://localhost:8000/ws')
			} else {
				this.ws = new WebSocket('wss://' + window.location.host + '/ws')
			}

			this.ws.onmessage = this.onMessage.bind(this)
			this.ws.onopen = this.onOpen.bind(this)
			this.ws.onclose = this.onClose.bind(this)
			this.ws.onerror = this.onError.bind(this)
		} catch (err) {
			this.reconnect()
		}
	}

	public send(action: IAction) {
		if (this.ws && this.ws.readyState === this.ws.OPEN) {
			if (config.environment !== 'LIVE') {
				console.log('sending:', action.type)
			}
			this.ws.send(JSON.stringify(action))
		}
		// else {
		// this.actionsQueue.push(action)
		// console.warn('ws is not OPEN!')
		// }
	}

	// constructor() {
	// 	super()
	// 	this.on(ActionTypes.WS.Info, this.onInfo.bind(this))
	// }

	private onMessage(msg: any) {
		const data = JSON.parse(msg.data)
		const actions: IAction[] = Array.isArray(data) ? data : [data]
		if (config.environment !== 'LIVE') {
			console.log('received:', ...actions.map(m => m.type))
		}
		actions.forEach(action => {
			this.emit(ActionTypes.WS.Action, action)
		})
	}

	private onOpen() {
		// console.log('ws connected!')
		// while (this.actionsQueue.length > 0) {
		// 	const action = this.actionsQueue.shift()
		// 	if (action) {
		// 		this.send(action)
		// 	}
		// }
		store.dispatch(ActionCreators.Connection.change({ status: true }))
		this.emit(ActionTypes.WS.Open)
	}

	private onClose() {
		// console.log('ws closed!')
		store.dispatch(ActionCreators.Connection.change({ status: false }))
		this.emit(ActionTypes.WS.Close)
		this.reconnect()
	}

	private reconnect() {
		setTimeout(this.connect.bind(this), Utils.timeSpan('5s'))
	}

	private onError(err: Event) {
		// console.error('ws error:', err)
		this.emit(ActionTypes.WS.Error, err)
	}

	// private onInfo(data: string) {
	// 	// console.info('INFO', data)
	// 	this.emit(ActionTypes.WS.Info, data)
	// }
}

export class WS extends ClientWS {
	public init() {
		this.connect()
		this.route()
	}

	private route() {
		this.on(ActionTypes.WS.Action, this.action)
	}

	private action(action: IAction) {

		switch (action.type) {

			case ActionTypes.Message.Errors:
				action.errors.forEach(err => {
					toast.error(translate.text(err, action.params))
				})
				break

			case ActionTypes.Message.Warn:
				toast.warn(translate.text(action.message, action.params))
				break

			case ActionTypes.Message.Info:
				toast.info(translate.text(action.message, action.params))
				break

			case ActionTypes.Message.Success:
				toast.success(translate.text(action.message, action.params))
				if (action.sound) {
					const sound = document.getElementById(`${action.sound}Sound`) as HTMLAudioElement
					sound.play()
				}
				break

			case ActionTypes.Message.Redirect:
				history.push(action.path)
				break

			default:
				store.dispatch(action)
				break
		}

	}
}

export const ws = new WS()