import { ActionCreators, IRegister, IUserRegister } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { Api } from '../functions/api'
import { translate } from '../functions/lang'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { isEmail, maxLength100, minLength6, passwordsMatch, renderField, required, requiredBox } from './renderField'

interface IProps {
	// include component properties here.
	registered: boolean
	register: (email: string, password: string) => IUserRegister
	isLogged: boolean
	lang: string

}

interface IMatchProps {
	// include any url parameters here.

}

const asyncValidate = (values: any) => {
	return Api.validate('/register/emailexists/' + values.email)
		.then(emailExists => {
			if (emailExists) {
				// eslint-disable-next-line
				throw { email: 'Email already exists' }
			}
		})
}

let RegisterForm = (props: any) => {
	const { handleSubmit, submitting, pristine } = props

	return (
		<div className='registerBox'>
			<form className='registerForm' onSubmit={handleSubmit}>
				<h2>{translate.text('create_free_account')}</h2>
				<label htmlFor='email'>{translate.text('email')}</label>
				<Field name='email' component={renderField} type='email' label='your@email.com' validate={[isEmail, required, maxLength100]} />
				<label htmlFor='password'>{translate.text('password')}</label>
				<Field name='password' component={renderField} type='password' validate={[required, minLength6]} />
				<label htmlFor='confirmPassword'>{translate.text('confirm_password')}</label>
				<Field name='confirmPassword' component={renderField} type='password' validate={[required, passwordsMatch]} />
				<div className='registerTermsDiv'>
					<Field name='registerTerms' component={renderField} type='checkbox' validate={[requiredBox]} />
					<label htmlFor='accept_tregisterTermserms' className='registerTerms'>
						{translate.text('register_msg_1')} <Link to='/termsOfUse' target='_blank'>{translate.text('terms_of_use')}</Link>, <Link to='/privacyPolicy' target='_blank'>{translate.text('privacy_policy')}</Link> {translate.text('and')} <Link to='/amlPolicy' target='_blank'>{translate.text('aml_policy')}</Link>.
				</label>
				</div>
				<button type='submit' disabled={submitting || pristine}>{translate.text('register')}</button>
			</form>
		</div>
	)
}

RegisterForm = reduxForm({
	form: 'register',
	asyncValidate,
	asyncBlurFields: ['email']
})(RegisterForm) as any

export class MobileRegisterComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	render() {
		translate.language = this.props.lang

		// if (this.props.isLogged) {
		// 	return <Redirect to={'/profile'} />
		// }
		// if (this.props.registered) {
		// 	toast.success(translate.text('register_successful'))
		// 	toast(translate.text('please_verify_your_email'))
		// 	return <Redirect to='/login' />
		// }
		return (
			<div className='register'>
				<RegisterForm onSubmit={(values: IRegister) => this.onSubmit(values)} />
			</div>
		)
	}

	onSubmit(register: IRegister) {
		const action: IUserRegister = ActionCreators.User.register(register)
		ws.send(action)
	}

}