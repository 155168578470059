import { ActionCreators, IWalletAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { WalletsMainComponent } from '../components/walletsMain'
import { IState } from '../models/state'


function mapStateToProps(state: IState) {
	return {
		wallets: state.wallets,
		markets: state.markets,
		tickers: state.tickers,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IWalletAction>) {
	return {
		walletChange: (current: string) => dispatch(ActionCreators.Wallet.change(current))
	}
}

export const WalletsMainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletsMainComponent))