export class BaseN {
	private alphabet: string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſƀƁƂƃ'
	private base: number
	constructor(alpha?: string) {
		this.alphabet = alpha || this.alphabet
		this.base = this.alphabet.length
	}
	public encode(enc: number) {
		if (typeof enc !== 'number') { return '' }
		let encoded = ''
		while (enc) {
			let remainder = enc % this.base
			enc = Math.floor(enc / this.base)
			encoded = this.alphabet[remainder].toString() + encoded
		}
		return encoded
	}

	public decode(dec: string) {
		let decoded = 0
		while (dec) {
			let alphabetPosition = this.alphabet.indexOf(dec[0])
			if (alphabetPosition < 0) {
				throw new Error('decode can\'t find ' + dec[0] + ' in the alphabet: ' + this.alphabet)
			}
			let powerOf = dec.length - 1
			decoded += alphabetPosition * (Math.pow(this.base, powerOf))
			dec = dec.substring(1)
		}
		return decoded
	}

}