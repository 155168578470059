import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { TradeHeaderContainer } from '../containers/tradeHeader'
import { KeysMainContainer } from '../containers/keysMain'
import { SideWalletsContainer } from '../containers/sideWallets'
import { AccountMenuContainer } from '../containers/accountMenu'

interface IProps {
	// include component properties here.
	
}

interface IMatchProps {
	// include any url parameters here.
	
}

export class KeysComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		
	}

	componentWillUnmount() {
		// run before component is unloaded.
		
	}

	render() {
		
		return (
			<div className='keys'>
				<TradeHeaderContainer />
				<div className='sideBar'>
					<SideWalletsContainer />
					<AccountMenuContainer />
				</div>
				<KeysMainContainer />
			</div>
		)
	}
	
}