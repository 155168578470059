import { ActionCreators, IMarkets, IOrder } from 'exchange-common'
import * as React from 'react'
import Modal from 'react-responsive-modal'
import { RouteComponentProps } from 'react-router'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { translate } from '../functions/lang'
import { Utils } from '../functions/utils'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	openOrders: IOrder[]
	ordersHistory: IOrder[]

	markets: IMarkets
	lang: string

}

interface IMatchProps {
	// include any url parameters here.

}

export class OrdersMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { openModal: boolean, cancelOrder: IOrder, openCancelAllModal: boolean }> {
	private ordersHistoryLength: number = 0

	render() {

		translate.language = this.props.lang

		const clock = require('../images/icons/clock.svg')
		const checkCircle = require('../images/icons/check-circle.svg')

		const { ordersHistory, openOrders, markets } = this.props
		const openModal = (this.state && this.state.openModal !== undefined) ? this.state.openModal : false
		const openCancelAllModal = (this.state && this.state.openCancelAllModal !== undefined) ? this.state.openCancelAllModal : false
		const cancelOrder = (this.state && this.state.cancelOrder !== undefined) ? this.state.cancelOrder : null
		const cancelPriceDecimals = (cancelOrder) ? markets.list[cancelOrder.market].priceDecimals : 0
		const cancelAmountDecimals = (cancelOrder) ? markets.list[cancelOrder.market].amountDecimals : 0
		const cancelOtherCoin = (cancelOrder) ? cancelOrder.market.split('-')[0] : ''
		const cancelBaseCoin = (cancelOrder) ? cancelOrder.market.split('-')[1] : ''

		return (
			<div className='ordersMain'>
				<Modal open={openModal} onClose={this.onCloseModal} center={true}>
					<h2>{translate.text('cancel_your_order')}</h2>
					{
						(cancelOrder && cancelOrder.orderId)
							? <div className='modalForm'>
								<div className='modalFormRow'>
									<label htmlFor='orderId' className='modalFormLabel'>{translate.text('order_id')}</label>
									<div className='modalFormText'><span>{Utils.pad(Utils.base32.encode(cancelOrder.orderId), 6)}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='side' className='modalFormLabel'>{translate.text('side')}</label>
									<div className='modalFormText'><span className={(cancelOrder.side === 'buy') ? 'caps green' : 'caps red'}>{cancelOrder.side === 'buy' ? translate.text('buy') : translate.text('sell')}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='market' className='modalFormLabel'>{translate.text('market')}</label>
									<div className='modalFormText'><span>{cancelOrder.market}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='price' className='modalFormLabel'>{translate.text('price')}</label>
									<div className='modalFormText'><span>{cancelOrder.type === 'market' ? '-' : cancelOrder.price.toFixed(cancelPriceDecimals)} {cancelOrder.type === 'market' ? '' : cancelBaseCoin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label htmlFor='quantity' className='modalFormLabel'>{translate.text('amount')}</label>
									<div className='modalFormText'><span>{cancelOrder.amount.toFixed(cancelAmountDecimals)} {cancelOtherCoin}</span></div>
								</div>
							</div>
							: <div />
					}
					<div className='modalFormConfirm'>
						<button onClick={() => { this.onCloseModal(); this.cancelOrder(cancelOrder) }}>{translate.text('confirm')}</button>
					</div>
				</Modal>
				<Modal open={openCancelAllModal} onClose={this.onCloseModalCancelAll} center={true}>
					<h2>{translate.text('cancel_all_orders')}</h2>
					<div className='modalForm'>
						<div className='modalFormRow'>
							<label htmlFor='orderId' className='modalFormLabel'>{translate.text('order_id')}</label>
							<div className='modalFormText'><span>{translate.text('all_open_orders')}</span></div>
						</div>
						<div className='modalFormRow'>
							<label htmlFor='orderId' className='modalFormLabel'>{translate.text('warning')}</label>
							<div className='modalFormText'><span>{translate.text('cancel_warning')}</span></div>
						</div>
					</div>
					<div className='modalFormConfirm'>
						<button onClick={() => { this.onCloseModalCancelAll(); this.cancelAllOrders() }}>{translate.text('confirm')}</button>
					</div>
				</Modal>
				<span className='pageTitle'>{translate.text('orders')}</span>
				<div className='walletsValueBox'>
					<div className='walletsValueBoxRow'><span className='ordersCancelAll' onClick={() => this.onOpenModalCancelAll()}>{translate.text('cancel_all_orders')}</span></div>
				</div>
				<Tabs>
					<TabList>
						<Tab><img className='iconsOrders' src={clock} alt='' />{translate.text('open_tab')}</Tab>
						<Tab><img className='iconsOrders' src={checkCircle} alt='' />{translate.text('history')}</Tab>
					</TabList>
					<TabPanel>
						<div>
							<table className='ordersTableMain'>
								<thead>
									<tr className='ordersTableHeaderRow'>
										<td className='ordersTableHeaderRowItem idColumn'>{translate.text('order_id')}</td>
										<td className='ordersTableHeaderRowItem dateColumn'>{translate.text('date')}</td>
										<td className='ordersTableHeaderRowItem sideColumn'>{translate.text('side')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('market')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('price')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('amount')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('filled')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('remain')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('status')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('actions')}</td>
									</tr>
								</thead>
								<tbody>
									{openOrders.length > 0 ?
										openOrders.map(openOrder => {
											const priceDecimals = markets.list[openOrder.market].priceDecimals
											const amountDecimals = markets.list[openOrder.market].amountDecimals
											const otherCoin = openOrder.market.split('-')[0]
											const baseCoin = openOrder.market.split('-')[1]
											return <tr key={openOrder.orderId} className='ordersTableBodyRow'>
												<td className='ordersTableBodyRowItem'>{Utils.pad(Utils.base32.encode(openOrder.orderId), 6)}</td>
												<td className='ordersTableBodyRowItem'>{new Date(openOrder.date).toLocaleString('en-GB')}</td>
												<td className={'ordersTableBodyRowItem sideColumn ' + ((openOrder.side === 'buy') ? 'green' : 'red')}>{openOrder.side === 'buy' ? translate.text('buy') : translate.text('sell')}</td>
												<td className='ordersTableBodyRowItem'>{openOrder.market}</td>
												<td className='ordersTableBodyRowItem'>{openOrder.type === 'market' ? '-' : openOrder.price.toFixed(priceDecimals)} {openOrder.type === 'market' ? '' : baseCoin}</td>
												<td className='ordersTableBodyRowItem'>{openOrder.amount.toFixed(amountDecimals)} {otherCoin}</td>
												<td className='ordersTableBodyRowItem'>{openOrder.filled.toFixed(amountDecimals)}  {otherCoin}</td>
												<td className='ordersTableBodyRowItem'>{(openOrder.amount - openOrder.filled).toFixed(amountDecimals)} {otherCoin}</td>
												<td className='ordersTableBodyRowItem'>{openOrder.filled > 0 ? translate.text('partial') : translate.text('open')}</td>
												<td className='ordersCancelOrder' onClick={() => this.onOpenModal(openOrder)}>{translate.text('cancel')}</td>
											</tr>
										})
										:
										<tr><td colSpan={10} className='emptyTable'>{translate.text('no_results_found')}</td></tr>
									}
								</tbody>
							</table>
						</div>
					</TabPanel>
					<TabPanel>
						<div>
							<table className='ordersTableMain'>
								<thead>
									<tr className='ordersTableHeaderRow'>
										<td className='ordersTableHeaderRowItem idColumn'>{translate.text('order_id')}</td>
										<td className='ordersTableHeaderRowItem dateColumn'>{translate.text('date')}</td>
										<td className='ordersTableHeaderRowItem sideColumn'>{translate.text('side')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('type')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('market')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('price')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('amount')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('filled')}</td>
										<td className='ordersTableHeaderRowItem'>{translate.text('remain')}</td>
										<td className='ordersTableHeaderRowItem statusColumn'>{translate.text('status')}</td>
									</tr>
								</thead>
								<tbody>
									{ordersHistory.length > 0 ?
										ordersHistory.map(orderHistory => {
											const priceDecimals = markets.list[orderHistory.market].priceDecimals
											const amountDecimals = markets.list[orderHistory.market].amountDecimals

											const otherCoin = orderHistory.market.split('-')[0]
											const baseCoin = orderHistory.market.split('-')[1]
											return <tr key={orderHistory.orderId} className='ordersTableBodyRow'>
												<td className='ordersTableBodyRowItem'>{Utils.pad(Utils.base32.encode(orderHistory.orderId), 6)}</td>
												<td className='ordersTableBodyRowItem'>{new Date(orderHistory.date).toLocaleString('en-GB')}</td>
												<td className={'ordersTableBodyRowItem sideColumn ' + ((orderHistory.side === 'buy') ? 'green' : 'red')}>{orderHistory.side === 'buy' ? translate.text('buy') : translate.text('sell')}</td>
												<td className='ordersTableBodyRowItem typeColumn'>{orderHistory.type === 'market' ? translate.text('market_order') : translate.text('limit_order')}</td>
												<td className='ordersTableBodyRowItem'>{orderHistory.market}</td>
												<td className='ordersTableBodyRowItem'>{orderHistory.type === 'market' ? '-' : orderHistory.price.toFixed(priceDecimals)} {orderHistory.type === 'market' ? '' : baseCoin}</td>
												<td className='ordersTableBodyRowItem'>{orderHistory.amount.toFixed(amountDecimals)} {otherCoin}</td>
												<td className='ordersTableBodyRowItem'>{orderHistory.filled.toFixed(amountDecimals)} {otherCoin}</td>
												<td className='ordersTableBodyRowItem'>{(orderHistory.amount - orderHistory.filled).toFixed(amountDecimals)} {otherCoin}</td>
												<td className='ordersTableBodyRowItem'>{orderHistory.filled === orderHistory.amount ? translate.text('executed') : orderHistory.filled > 0 ? translate.text('partial') : translate.text('cancelled')}</td>
											</tr>
										})
										:
										<tr><td colSpan={10} className='emptyTable'>{translate.text('no_results_found')}</td></tr>
									}
								</tbody>
							</table>
							{this.ordersHistoryLength !== this.props.ordersHistory.length
								? <p className='loadMore' onClick={() => this.loadMoreOrdersHistory()}>{translate.text('view_more')}</p>
								: <></>
							}
						</div>
					</TabPanel>
				</Tabs>
			</div>
		)
	}

	onOpenModal = (order: IOrder) => {
		this.setState({ openModal: true, cancelOrder: order })
	}

	onCloseModal = () => {
		this.setState({ openModal: false })
	}

	onOpenModalCancelAll = () => {
		this.setState({ openCancelAllModal: true })
	}

	onCloseModalCancelAll = () => {
		this.setState({ openCancelAllModal: false })
	}

	cancelOrder(order: IOrder | null) {
		ws.send(ActionCreators.Order.delete(order as IOrder))
	}
	cancelAllOrders() {
		ws.send(ActionCreators.BulkOrders.delete({ all: true }))
	}

	loadMoreOrdersHistory() {
		this.ordersHistoryLength = this.props.ordersHistory.length
		const lastId = this.props.ordersHistory.reduce((prev, curr) => {
			if (prev === 0 || prev > curr.orderId) {
				return curr.orderId
			}
			return prev
		}, 0)
		ws.send(ActionCreators.OrdersHistory.getPage(lastId))
	}
}