import * as React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { AmlPolicyContainer } from '../containers/amlPolicy'
import { LostPasswordContainer } from '../containers/lostPassword'
import { MobileAboutUsContainer } from '../containers/mobileAboutUs'
import { MobileBankAccountsContainer } from '../containers/mobileBankAccounts'
import { MobileExchangeContainer } from '../containers/mobileExchange'
import { MobileFeesContainer } from '../containers/mobileFees'
import { MobileHomeContainer } from '../containers/mobileHome'
import { MobileKeysContainer } from '../containers/mobileKeys'
import { MobileKycContainer } from '../containers/mobileKyc'
import { MobileLoginContainer } from '../containers/mobileLogin'
import { MobileMarketsContainer } from '../containers/mobileMarkets'
import { MobileOrdersContainer } from '../containers/mobileOrders'
import { MobileProfileContainer } from '../containers/mobileProfile'
import { MobileRegisterContainer } from '../containers/mobileRegister'
import { MobileSecurityContainer } from '../containers/mobileSecurity'
import { MobileWalletsContainer } from '../containers/mobileWallets'
import { PrivacyPolicyContainer } from '../containers/privacyPolicy'
import { PrivateRoute } from '../containers/privateRoute'
import { ResetPasswordContainer } from '../containers/resetPassword'
import { SentPasswordContainer } from '../containers/sentPassword'
import { TermsOfUseContainer } from '../containers/termsOfUse'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.

}

interface IMatchProps {
	// include any url parameters here.

}

export class MobileMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		// translate.language = this.props.lang

		return (
			<div className='mobileMain' >
				<Switch>
					<Route path='/exchange/:market?' component={MobileExchangeContainer} />

					<PrivateRoute path='/wallets' component={MobileWalletsContainer} />
					<PrivateRoute path='/orders' component={MobileOrdersContainer} />
					<PrivateRoute path='/deposit' component={MobileWalletsContainer} />
					<PrivateRoute path='/withdraw' component={MobileWalletsContainer} />

					<PrivateRoute path='/profile' component={MobileProfileContainer} />
					<PrivateRoute path='/kyc' component={MobileKycContainer} />
					<PrivateRoute path='/bankAccounts' component={MobileBankAccountsContainer} />
					<PrivateRoute path='/security' component={MobileSecurityContainer} />
					<PrivateRoute path='/keys' component={MobileKeysContainer} />

					<Route path='/markets' component={MobileMarketsContainer} />

					<Route path='/aboutUs' component={MobileAboutUsContainer} />
					<Route path='/fees' component={MobileFeesContainer} />
					<Route path='/termsOfUse' component={TermsOfUseContainer} />
					<Route path='/privacyPolicy' component={PrivacyPolicyContainer} />
					<Route path='/amlPolicy' component={AmlPolicyContainer} />

					<Route path='/register' component={MobileRegisterContainer} />
					<Route path='/login' component={MobileLoginContainer} />
					<Route path='/lostPassword' component={LostPasswordContainer} />
					<Route path='/sentPassword' component={SentPasswordContainer} />
					<Route path='/resetPassword/:reset' component={ResetPasswordContainer} />
					{/* <Route path='/logout' component={LogoutContainer} /> */}
					<Route path='/' exact={true} component={MobileHomeContainer} />
				</Switch>
			</div>

		)
	}

}