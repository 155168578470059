import { IMarkets } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { ChartContainer } from '../containers/chart'
import { ExchangeMenuContainer } from '../containers/exchangeMenu'
import { OrderbookContainer } from '../containers/orderbook'
import { SideWalletsContainer } from '../containers/sideWallets'
import { TradeHistoryContainer } from '../containers/tradeHistory'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	markets: IMarkets
	isLogged: boolean
	lang: string

}

interface IMatchProps {
	// include any url parameters here.
	market: string
}

export class MobileExchangeComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		// const { market } = this.props.match.params
		if (Object.keys(this.props.markets.list).length === 0) {
			return <div />
		}

		// if (!market || !this.props.markets.list[market]) {
		// 	return <Redirect to='/exchange/BTC-BRL' />
		// }

		return (
			<div className='exchange'>
				<Tabs>
					<TabList>
						<Tab>{translate.text('order_book')}</Tab>
						<Tab>{translate.text('order_form')}</Tab>
						<Tab>{translate.text('price_charts')}</Tab>
						<Tab>{translate.text('trade_history')}</Tab>
					</TabList>
					<TabPanel>
						<OrderbookContainer />
					</TabPanel>
					<TabPanel>
						<div className='exchangeMobilePanel'>
							{this.props.isLogged ? <SideWalletsContainer /> : <div />}
							<ExchangeMenuContainer />
						</div>
					</TabPanel>
					<TabPanel>
						<ChartContainer />
					</TabPanel>
					<TabPanel>
						<TradeHistoryContainer />
					</TabPanel>
				</Tabs>
			</div>
		)
	}

}