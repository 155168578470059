import { ActionCreators, IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { KeysMainComponent } from '../components/keysMain'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		apiKeys: state.apiKeys,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {
		clearSecret: (key: string) => dispatch(ActionCreators.ApiKey.update({ key, secret: undefined }))
	}
}

export const KeysMainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(KeysMainComponent))