import { ActionCreators, IBankAccount, IBankAccounts, IBanks, ICurrencies, IWalletChange, IWallets, IWithdraw, IWithdrawals } from 'exchange-common'
import { base32, pad, round } from 'libs-tools'
import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast } from 'react-toastify'
import { Field, change, formValueSelector, reduxForm, reset } from 'redux-form'
import { store } from '../'
import config from '../config.json'
import { Api } from '../functions/api'
import { translate } from '../functions/lang'
import { walletBalance, walletInOrders } from '../functions/wallet'
import { ws } from '../functions/ws'
import { IState } from '../models/state'
import { maxLength100, renderField, renderHiddenField, required } from './renderField'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const WAValidator = require('multicoin-address-validator')
// ;(window as any).WAValidator = WAValidator

interface IProps {
	// include component properties here.
	current: string
	wallets: IWallets
	withdrawals: IWithdrawals
	currencies: ICurrencies
	walletChange: (current: string) => IWalletChange
	bankAccounts: IBankAccounts
	banks: IBanks
	lang: string
	verified: boolean | undefined
}

interface IMatchProps {
	// include any url parameters here.

}

const internalWallet = (coin: string, setIsInternal: React.Dispatch<React.SetStateAction<boolean>>) =>
	(obj: any) => {
		const value = obj?.target?.value

		if (value && WAValidator.validate(value, ['BTC', 'LTC', 'DOGE'].includes(coin) ? coin : 'ETH', config.environment === 'LIVE' ? 'prod' : 'testnet')) {
			console.log({ value, coin })
			Api.validate(`/withdraw/internal/${coin}/${value}`).then((res: boolean) => {
				setIsInternal(res)
			})
			return
		}
		setIsInternal(false)
	}



let BrlWithdrawForm = (props: any) => {
	const { handleSubmit, bankAccounts, banks, fee, feePct, amount } = props
	const banksfilter: IBanks = banks

	return (
		<div>
			<form className='brlWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label className='withdrawFormLabel'>{translate.text('bank_account')}</label>
					{bankAccounts.list.length > 0 ?
						<Field name='bankAccountId' component='select' validate={[required]}>
							<option value='' />
							{bankAccounts.list.map((b: IBankAccount) =>
								<option key={b.bankAccountId} value={b.bankAccountId}> {banksfilter.list.filter((f) => f.bankId === b.bankId)[0].bankCode} – {b.branchNumber}{b.branchDigit !== undefined && b.branchDigit !== null && b.branchDigit !== '' ? '-' + b.branchDigit : ''} – {b.accountNumber}-{b.accountDigit}</option>
							)}
						</Field>
						:
						<div>
							<div className='noBankAccountsFormTag'><span>{translate.text('no_bank_accounts_found')}</span></div>
							<Link className='profileFormLabel verifyNow' to='/bankAccounts'><span>{translate.text('go_to_bank_accounts')}</span></Link>
						</div>
					}

				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='text' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{(amount && amount > (fee + (amount * feePct)) ? fee + (amount * feePct) : 0).toFixed(2)}</span> <span>BRL</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > (fee + (amount * feePct)) ? (amount - (fee + (amount * feePct))) : 0).toFixed(2)}</span> <span>BRL</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

BrlWithdrawForm = reduxForm({
	form: 'brlWithdrawForm'
})(BrlWithdrawForm) as any

const selectorBrl = formValueSelector('brlWithdrawForm')
BrlWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorBrl(state, 'amount')
		}
	}
)(BrlWithdrawForm) as any

let BtcWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='btcWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('BTC', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span> <span>BTC</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>BTC</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

BtcWithdrawForm = reduxForm({
	form: 'btcWithdrawForm'
})(BtcWithdrawForm) as any

const selectorBtc = formValueSelector('btcWithdrawForm')
BtcWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorBtc(state, 'amount')
		}
	}
)(BtcWithdrawForm) as any

let LtcWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='ltcWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('LTC', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span> <span>LTC</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>LTC</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

LtcWithdrawForm = reduxForm({
	form: 'ltcWithdrawForm'
})(LtcWithdrawForm) as any

const selectorLtc = formValueSelector('ltcWithdrawForm')
LtcWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorLtc(state, 'amount')
		}
	}
)(LtcWithdrawForm) as any

let EthWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='ethWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('ETH', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span> <span>ETH</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>ETH</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

EthWithdrawForm = reduxForm({
	form: 'ethWithdrawForm'
})(EthWithdrawForm) as any

const selectorEth = formValueSelector('ethWithdrawForm')
EthWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorEth(state, 'amount')
		}
	}
)(EthWithdrawForm) as any

let EbitWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='ebitWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('EBIT', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span> <span>EBIT</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>EBIT</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

EbitWithdrawForm = reduxForm({
	form: 'ebitWithdrawForm'
})(EbitWithdrawForm) as any

const selectorEbit = formValueSelector('ebitWithdrawForm')
EbitWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorEbit(state, 'amount')
		}
	}
)(EbitWithdrawForm) as any

let LinkWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='linkWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('LINK', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span> <span>LINK</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>LINK</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

LinkWithdrawForm = reduxForm({
	form: 'linkWithdrawForm'
})(LinkWithdrawForm) as any

const selectorLink = formValueSelector('linkWithdrawForm')
LinkWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorLink(state, 'amount')
		}
	}
)(LinkWithdrawForm) as any

let UsdcWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='usdcWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('USDC', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span>  <span>USDC</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>USDC</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

UsdcWithdrawForm = reduxForm({
	form: 'usdcWithdrawForm'
})(UsdcWithdrawForm) as any

const selectorUsdc = formValueSelector('usdcWithdrawForm')
UsdcWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorUsdc(state, 'amount')
		}
	}
)(UsdcWithdrawForm) as any


let DogeWithdrawForm = (props: any) => {
	const { handleSubmit, fee, amount } = props
	const [isInternal, setIsInternal] = React.useState(false)
	const addrFee = isInternal ? 0 : fee

	return (
		<div>
			<form className='ltcWithdrawForm' onSubmit={handleSubmit} noValidate={true} >
				<div className='withdrawFormRow'>
					<label htmlFor='address' className='withdrawFormLabel'>{translate.text('address')}:</label>
					<Field name='address' component={renderField} type='text' validate={[required, maxLength100]} onChange={internalWallet('DOGE', setIsInternal)} />
				</div>
				<div className='withdrawFormRow'>
					<label htmlFor='amount' className='withdrawFormLabel'>{translate.text('amount')}:</label>
					<Field name='amount' component={renderField} type='number' validate={[required, maxLength100]} />
				</div>
				<div className='withdrawFormRow'>
					<Field name='fee' component={renderHiddenField} val={addrFee} />
					<div className='withdrawFormFeeInfo'><span>{translate.text('withdraw_fee')}:</span> <span>{addrFee}</span> <span>DOGE</span></div>
				</div><br />
				<div className='withdrawFormRow'>
					<div className=''><span>{translate.text('will_receive')}:</span> <b><span>{(amount && amount > addrFee ? (amount - addrFee) : 0).toFixed(8)}</span> <span>DOGE</span></b></div>
				</div>
				<div>
					<button type='submit'>{translate.text('confirm')}</button>
				</div>
			</form>
		</div>
	)
}

DogeWithdrawForm = reduxForm({
	form: 'dogeWithdrawForm'
})(DogeWithdrawForm) as any

const selectorDoge = formValueSelector('dogeWithdrawForm')
DogeWithdrawForm = connect(
	(state) => {
		return {
			amount: selectorDoge(state, 'amount')
		}
	}
)(DogeWithdrawForm) as any

export class WithdrawMainComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState & { openModal: boolean, withdraw: IWithdraw }> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const brlLogo = require('../images/coins/BRL.svg')
		const btcLogo = require('../images/coins/BTC.svg')
		const ltcLogo = require('../images/coins/LTC.svg')
		const dogeLogo = require('../images/coins/DOGE.svg')
		const ethLogo = require('../images/coins/ETH.svg')
		const ebitLogo = require('../images/coins/EBIT.svg')
		const linkLogo = require('../images/coins/LINK.svg')
		const usdcLogo = require('../images/coins/USDC.svg')

		const copy = require('../images/icons/copy.svg')
		const warning = require('../images/icons/warning.svg')

		const arrowUp = require('../images/icons/arrow-up-circle.svg')
		const checkCircle = require('../images/icons/check-circle.svg')

		const wallets = this.props.wallets.list

		const withdrawals = this.props.withdrawals

		const openModal = (this.state && this.state.openModal !== undefined) ? this.state.openModal : false
		const withdraw = (this.state && this.state.withdraw !== undefined) ? this.state.withdraw : null

		if (!withdrawals.isLoaded) {
			ws.send(ActionCreators.Withdraw.getList())
		}

		const bankAccounts = this.props.bankAccounts
		if (!bankAccounts.isLoaded) {
			ws.send(ActionCreators.BankAccount.getList())
		}

		const banks = this.props.banks
		if (!banks.isLoaded) {
			ws.send(ActionCreators.Bank.getList())
		}

		const coin = this.props.current
		const currencies = this.props.currencies.list
		let coinImg = btcLogo
		let coinName = 'Bitcoin'
		switch (coin) {

			case 'BRL':
				coinImg = brlLogo
				coinName = 'Real Brasileiro'
				break

			case 'LINK':
				coinImg = linkLogo
				coinName = 'Chainlink'
				break

			case 'BTC':
				coinImg = btcLogo
				coinName = 'Bitcoin'
				break

			case 'LTC':
				coinImg = ltcLogo
				coinName = 'Litecoin'
				break

			case 'ETH':
				coinImg = ethLogo
				coinName = 'Ethereum'
				break

			case 'EBIT':
				coinImg = ebitLogo
				coinName = 'Econobit'
				break

			case 'USDC':
				coinImg = usdcLogo
				coinName = 'USD Coin'
				break

			case 'DOGE':
				coinImg = dogeLogo
				coinName = 'Dogecoin'
				break

			default:
				break
		}

		const amountDecimals = this.props.wallets.list[coin].fiat ? 2 : 8

		const selectedBankAccount = (bankAccountId?: number) => {
			const ba = bankAccounts.list.find((b: IBankAccount) => b.bankAccountId === bankAccountId)
			if (!ba) {
				return
			}
			return `${this.props.banks.list.filter((f) => f.bankId === ba.bankId)[0].bankCode} – ${ba.branchNumber}${ba.branchDigit !== undefined && ba.branchDigit !== null && ba.branchDigit !== '' ? '-' + ba.branchDigit : ''
				} – ${ba.accountNumber}-${ba.accountDigit}`
		}

		return (
			<div className='withdrawMain'>
				<Modal
					open={openModal}
					onClose={this.onCloseModal}
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal',
					}}
					center={true}
				>
					<h2>{translate.text('confirm_your_withdraw')}</h2>
					{
						(withdraw)
							? <div className='modalForm'>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('coin')}</label>
									<div className='modalFormText'><img src={coinImg} className='walletsLogo' alt='' /><span>{coinName} ({withdraw.coin})</span></div>
								</div>
								{withdraw.address ?
									<div className='modalFormRow'>
										<label className='modalFormLabel'>{translate.text('address')}</label>
										<div className='modalFormText'><span>{withdraw.address}</span></div>
									</div>
									:
									<div className='modalFormRow'>
										<label className='modalFormLabel'>{translate.text('bank_account')}</label>
										<div className='modalFormText'><span>{selectedBankAccount(withdraw.bankAccountId)}</span></div>
									</div>
								}
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('amount')}</label>
									<div className='modalFormText'><span>{round(withdraw.amount, amountDecimals).toFixed(amountDecimals)} {withdraw.coin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('withdraw_fee')}</label>
									<div className='modalFormText'><span>{withdraw.fee.toFixed(amountDecimals)} {withdraw.coin}</span></div>
								</div>
								<div className='modalFormRow'>
									<label className='modalFormLabel'>{translate.text('will_receive')}</label>
									<div className='modalFormText'><span>{(withdraw.amount && withdraw.amount > withdraw.fee ? (withdraw.amount - withdraw.fee) : 0).toFixed(amountDecimals)} {withdraw.coin}</span></div>
								</div>
								<div className='authEnableForm'>
									<div className='authEnableFormBox' >
										<label htmlFor='auth'>{translate.text('2fa_token_if_enabled')}</label>
										<input name='auth' id='auth' type='text' autoComplete='off' />
									</div>
								</div>
							</div>
							: <div />
					}
					<div className='modalFormConfirm'>
						<button onClick={() => { if (!withdraw) { return } const auth = document.getElementById('auth') as HTMLInputElement; withdraw.auth = auth ? auth.value : ''; this.onCloseModal(); this.onSubmit(withdraw, wallets[coin].amount) }}>{translate.text('confirm_withdraw')}</button>
					</div>
				</Modal>
				<span className='pageTitle'>{translate.text('withdrawals')}</span>
				<Tabs>
					<TabList>
						<Tab><img className='iconsOrders' src={arrowUp} alt='' />{translate.text('withdraw_funds')}</Tab>
						<Tab><img className='iconsOrders' src={checkCircle} alt='' />{translate.text('history')}</Tab>
					</TabList>
					<TabPanel>
						<div className='withdrawBox'>
							<div className='boxHeaderColumn3Left'>
								<div className='withdrawSelectCoin withdrawHeaderLabel'>
									<img src={coinImg} className='depositWithdrawLogo' alt={coin} />
									<span>{coin + ' - ' + coinName}</span>
								</div>
								<div className='withdrawCoinDropdown'><span className='coinSelectArrowDown' /></div>
								<div className='coinsDropdown'>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('BRL')}>
										<img src={brlLogo} className='depositWithdrawLogoList' alt='BRL' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('BTC')}>
										<img src={btcLogo} className='depositWithdrawLogoList' alt='BTC' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('ETH')}>
										<img src={ethLogo} className='depositWithdrawLogoList' alt='ETH' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('EBIT')}>
										<img src={ebitLogo} className='depositWithdrawLogoList' alt='EBIT' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('LTC')}>
										<img src={ltcLogo} className='depositWithdrawLogoList' alt='LTC' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('DOGE')}>
										<img src={dogeLogo} className='depositWithdrawLogoList' alt='DOGE' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('USDC')}>
										<img src={usdcLogo} className='depositWithdrawLogoList' alt='USDC' />
									</div>
									<div className='coinsDropdownItem' onClick={e => this.onSelectedCoin('LINK')}>
										<img src={linkLogo} className='depositWithdrawLogoList' alt='LINK' />
									</div>
								</div>
							</div>
							<div className='boxHeaderColumn3Middle'>
								<div className='withdrawHeaderLabel'><span>{coin}</span><span>{translate.text('available')}:</span><span className='withdrawHeaderBalance green cursor' data-amount={walletBalance(wallets[coin])} onClick={this.handleClickBtc}>{walletBalance(wallets[coin])}</span><img src={copy} className='withdrawCopy' alt='' data-amount={walletBalance(wallets[coin])} onClick={this.handleClickBtc} /></div>
							</div>
							<div className='boxHeaderColumn3Right'>
								<div className='withdrawHeaderLabel'><span>{coin}</span><span>{translate.text('in_orders')}:</span><span className='withdrawHeaderBalance'>{walletInOrders(wallets[coin])}</span></div>
							</div>
							<div className='withdrawBoxContent'>
								{coin === 'BRL' ?
									<>{currencies[2].canWithdraw ?
										<BrlWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, fee: currencies[2].fee + (currencies[2].feePct * values.amount), coin })} bankAccounts={this.props.bankAccounts} banks={this.props.banks} fee={currencies[2].fee} feePct={currencies[2].feePct} wallet={this.props.wallets.list.BRL} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'BTC' ?
									<>{currencies[1].canWithdraw ?
										<BtcWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[1].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'LTC' ?
									<>{currencies[4].canWithdraw ?
										<LtcWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[4].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'ETH' ?
									<>{currencies[3].canWithdraw ?
										<EthWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[3].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'EBIT' ?
									<>{currencies[10].canWithdraw ?
										<EbitWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[10].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'LINK' ?
									<>{currencies[7].canWithdraw ?
										<LinkWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[7].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'USDC' ?
									<>{currencies[8].canWithdraw ?
										<UsdcWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[8].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
								{coin === 'DOGE' ?
									<>{currencies[9].canWithdraw ?
										<DogeWithdrawForm onSubmit={(values: IWithdraw) => this.onOpenModal({ ...values, coin })} fee={currencies[9].fee} />
										: <div className='depositPaused'>{translate.text('withdraw_paused')}</div>}
									</> : ''}
							</div>
							{coin === 'BRL' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_fiat')}</span><br /><br />
								</div>
								: ''}
							{coin === 'BTC' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
							{coin === 'LTC' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
							{coin === 'ETH' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
							{coin === 'EBIT' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
							{coin === 'LINK' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
							{coin === 'USDC' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
							{coin === 'DOGE' ?
								<div className='withdrawInfoText'>
									<span><img src={warning} className='depositWithdrawLogoList depositWarning' alt='/!\' /><b>{translate.text('instructions')}:</b></span><br /><br />
									<span><b>1.</b> {translate.text('withdraw_msg_1')}</span><br /><br />
									<span><b>2.</b> {translate.text('withdraw_msg_2')}</span><br /><br />
									<span><b>3.</b> {translate.text('withdraw_msg_3')}</span><br /><br />
								</div>
								: ''}
						</div>
					</TabPanel>
					<TabPanel>
						<div>
							<table className='withdrawsTableMain'>
								<thead>
									<tr className='withdrawsTableHeaderRow'>
										<td className='withdrawsTableHeaderRowItem idColumn'>{translate.text('withdraw_id')}</td>
										<td className='withdrawsTableHeaderRowItem dateColumn'>{translate.text('date')}</td>
										<td className='withdrawsTableHeaderRowItem'>{translate.text('coin')}</td>
										<td className='withdrawsTableHeaderRowItem'>{translate.text('amount')}</td>
										<td className='withdrawsTableHeaderRowItem'>{translate.text('status')}</td>
										<td className='withdrawsTableHeaderRowItem alignColumn'>{translate.text('record')}</td>
									</tr>
								</thead>
								<tbody>
									{withdrawals.list.length > 0 ?
										withdrawals.list.map(w =>
											<tr key={w.id} className='withdrawsTableBodyRow'>
												<td className='withdrawsTableBodyRowItem'>{pad(base32.encode(w.id), 12)}</td>
												<td className='withdrawsTableBodyRowItem'>{new Date(w.date).toLocaleString('en-GB')}</td>
												<td className='withdrawsTableBodyRowItem'>{w.coin}</td>
												<td className='withdrawsTableBodyRowItem'>{w.amount.toFixed(wallets[w.coin].fiat ? 2 : 8)}</td>
												<td className={'withdrawsTableBodyRowItem caps ' + ((w.status === 'sent') ? 'green' : (w.status === 'expired') ? 'red' : '')}>{translate.text(w.status)}</td>
												<td className='depositsTableBodyRowItem alignColumn'>
													{!w.txid ? '' :
														w.txid === 'internal'
															?
															<span className='walletsTableButton internal'>{translate.text('internal')}</span>
															:
															<a className='walletsTableButton' href={`${this.blockExplorer(w.coin)}${w.txid}`} target='_blank' rel='noopener noreferrer'>{translate.text('link')}</a>
													}
												</td>
											</tr>
										)
										:
										<tr><td colSpan={6} className='emptyTable'>{translate.text('no_results_found')}</td></tr>
									}
								</tbody>
							</table>
						</div>
					</TabPanel>
				</Tabs >
			</div>
		)
	}

	onBrlSubmit = () => {
		//
	}

	onOpenModal = (withdraw: IWithdraw) => {
		withdraw.bankAccountId = parseInt(withdraw.bankAccountId as any)
		this.setState({ openModal: true, withdraw })
	}

	onCloseModal = () => {
		this.setState({ openModal: false })
	}
	blockExplorer(coin: string) {
		switch (coin) {
			case 'BTC':
				return `https://blockstream.info${config.environment === 'LIVE' ? '' : '/testnet'}/tx/`
			case 'LTC':
				return `https://chain.so/tx/${config.environment === 'LIVE' ? 'LTC' : 'LTCTEST'}/`
			case 'DOGE':
				return `https://chain.so/tx/${config.environment === 'LIVE' ? 'DOGE' : 'DOGETEST'}/`
			case 'EBIT':
				return `https://explorer${config.environment === 'LIVE' ? '' : '-test'}.econobit.io/tx/`
			default:
				return `https://${config.environment === 'LIVE' ? '' : 'goerli.'}etherscan.io/tx/`
		}
	}
	handleClickBtc = (e: any) => {
		e.preventDefault()
		const amount = e.target.getAttribute('data-amount')
		// console.log(amount)
		store.dispatch(change('brlWithdrawForm', 'amount', amount))
		store.dispatch(change('btcWithdrawForm', 'amount', amount))
		store.dispatch(change('ltcWithdrawForm', 'amount', amount))
		store.dispatch(change('ethWithdrawForm', 'amount', amount))
		store.dispatch(change('linkWithdrawForm', 'amount', amount))
		store.dispatch(change('usdcWithdrawForm', 'amount', amount))
		store.dispatch(change('dogeWithdrawForm', 'amount', amount))

		toast.info(translate.text('withdraw_all_available_balance'))
	}
	onSelectedCoin(coin: string) {
		this.props.walletChange(coin)
		const amount = ''
		store.dispatch(change('brlWithdrawForm', 'amount', amount))
		store.dispatch(change('btcWithdrawForm', 'amount', amount))
		store.dispatch(change('ltcWithdrawForm', 'amount', amount))
		store.dispatch(change('ethWithdrawForm', 'amount', amount))
		store.dispatch(change('linkWithdrawForm', 'amount', amount))
		store.dispatch(change('usdcWithdrawForm', 'amount', amount))
		store.dispatch(change('dogeWithdrawForm', 'amount', amount))

	}
	onSubmit(values: IWithdraw | null, amountWallet: number) {
		const verified = this.props.verified
		if (!verified) {
			toast.error(translate.text('user_not_verified'))
			return
		}
		if (!values) {
			return
		}
		if (values.amount > amountWallet) {
			toast.error(translate.text('insufficient_balance'))
			return
		}
		values.coin = this.props.current
		ws.send(ActionCreators.Withdraw.insert(values))
		store.dispatch(reset(`${values.coin.toLowerCase()}WithdrawForm`))
	}

}
