import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { toast } from 'react-toastify'

interface IProps {
	// include component properties here.
}

interface IMatchProps {
	// include any url parameters here.
	email: string
	hash: string
}

export class VerifyEmailComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {
	render() {
		const { email, hash } = this.props.match.params
		fetch(`/api/register/verifyEmail/${email}/${hash}`)
			.then(res => res.json())
			.then(res => {
				if (res) {
					toast.success('E-mail verified.')
					this.props.history.push('/login')
				} else { 
					toast.error('404 - Page not found')
					this.props.history.push('/')
				}
			})
		
		return (
			<div />
		)
	}
}