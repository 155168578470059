import { ActionCreators, ActionTypes, IAction } from 'exchange-common'
import * as React from 'react'
import CookieConsent from "react-cookie-consent"
 import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import config from './config.json'
import { FooterContainer } from './containers/footer'
import { HeaderContainer } from './containers/header'
import { MainContainer } from './containers/main'
import { MobileFooterContainer } from './containers/mobileFooter'
import { MobileHeaderContainer } from './containers/mobileHeader'
import { MobileMainContainer } from './containers/mobileMain'
import { translate } from './functions/lang'
import { ws } from './functions/ws'

ws.init()
ws.on(ActionTypes.WS.Open, () => {
	ws.send(ActionCreators.Market.getList())
	ws.send(ActionCreators.Subscribe.connection())
})
ws.on(ActionTypes.WS.Action, (action: IAction) => {
	switch (action.type) {
		case ActionTypes.Market.List: {
			ws.send(ActionCreators.Subscribe.tickers())
			ws.send(ActionCreators.Subscribe.currencies())
			break
		}
	}
})

export class App extends React.Component {
	componentDidMount() {

	}

	render() {
		return (
			<div className='App'>
				<div className='mobile-class'>
					<MobileHeaderContainer />
					<MobileMainContainer />
					<MobileFooterContainer />
				</div>
				<HeaderContainer />
				<MainContainer />
				<FooterContainer />
				<CookieConsent
					location="bottom"
					buttonText={translate.text('cookie_accept_all')}
					declineButtonText={translate.text('cookie_reject_all')}
					cookieName="econobit-cookie"
					style={{ background: "#12121299", fontSize: "11px" }}
					buttonStyle={{ color: "#FFF", fontSize: "11px", fontWeight: "600", background: "#2eae34", borderRadius: "2px", letterSpacing: "1px", textTransform: "uppercase", border: "1px solid #2eae34" }}
					declineButtonStyle={{ color: "#FFF", fontSize: "11px", fontWeight: "600", background: "transparent", borderRadius: "2px", letterSpacing: "1px", textTransform: "uppercase", border: "1px solid #fff" }}
					expires={365}
					onAccept={() => {
						const tagManagerArgs = {
							gtmId: config.gtmcode
						}
						TagManager.initialize(tagManagerArgs)
						hotjar.initialize(config.hotjarcode, 6)
					}}
					enableDeclineButton
					flipButtons
					onDecline={() => { }}
				>
					{translate.text('cookie_consent_text')} <Link to='/privacyPolicy'>{translate.text('privacy_policy')}</Link>.
				</CookieConsent>
				<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={10000} newestOnTop={true} />
				<audio id='coinSound'>
					<source src='/sounds/beep.mp3' type='audio/mpeg' />
				</audio>
				<audio id='sonicSound'>
					<source src='/sounds/sonic.mp3' type='audio/mpeg' />
				</audio>
			</div>
		)
	}



}