import { ActionCreators, IUser, IUserUpdateAuth } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { store } from '..'
import { AccountMenuContainer } from '../containers/accountMenu'
import { SecurityMainContainer } from '../containers/securityMain'
import { SideWalletsContainer } from '../containers/sideWallets'
import { TradeHeaderContainer } from '../containers/tradeHeader'
import { Api } from '../functions/api'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	user: IUser

	updateAuth: (secret: string) => IUserUpdateAuth

}

interface IMatchProps {
	// include any url parameters here.

}

export class SecurityComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
		Api.get(`/security/newauth/${this.props.user.email}`).then(auth => {
			if (!auth) {
				return
			}
			this.props.updateAuth(auth)
		})
		store.dispatch(ActionCreators.LoginHistory.clear())
		ws.send(ActionCreators.Security.getLoginHistory(0))
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		return (
			<div className='security'>
				<TradeHeaderContainer />
				<div className='sideBar'>
					<SideWalletsContainer />
					<AccountMenuContainer />
				</div>
				<SecurityMainContainer />
			</div>
		)
	}

}