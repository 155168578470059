import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class PrivacyPolicyComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		return (
			<div className='privacyPolicy'>
				<div className='privacyPolicyMain'>
					<span className='pageTitle'>{translate.text('privacy_policy')}</span>
					<b><i>[{translate.text('last_privacy_updated_date')}]</i></b>
					<br /><br />
					<p>{translate.text('privacy_text_1')}</p>

					<p>{translate.text('privacy_text_2')}</p>

					<p>{translate.text('privacy_text_3')}</p>

					<h3>{translate.text('privacy_title_1')}</h3>

					<b>{translate.text('privacy_bold_1')}</b>
					<br /><br />
					<b>{translate.text('privacy_bold_2')}</b>

					<p>{translate.text('privacy_text_4')}</p>

					<b>{translate.text('privacy_bold_3')}</b>

					<p>{translate.text('privacy_text_5')}</p>

					<b>{translate.text('privacy_bold_4')}</b>
					<br /><br />
					<b>{translate.text('privacy_bold_5')}</b>

					<p>{translate.text('privacy_text_6')}</p>

					<b>{translate.text('privacy_bold_6')}</b>

					<p>{translate.text('privacy_text_7')}</p>

					<h3>{translate.text('privacy_title_2')}</h3>

					<b>{translate.text('privacy_bold_7')}</b>

					<p>{translate.text('privacy_text_8')}</p>

					<p>{translate.text('privacy_text_9')}</p>

					<b>{translate.text('privacy_bold_8')}</b>

					<p>{translate.text('privacy_text_10')}</p>

					<p>{translate.text('privacy_text_11')}</p>

					<p>{translate.text('privacy_text_12')}</p>

					<b>{translate.text('privacy_bold_9')}</b>

					<p>{translate.text('privacy_text_13')}</p>

					<b>{translate.text('privacy_bold_10')}</b>

					<p>{translate.text('privacy_text_14')}</p>

					<p>{translate.text('privacy_text_15')}</p>

					<b>{translate.text('privacy_bold_11')}</b>

					<p>{translate.text('privacy_text_16')}</p>

					<b>{translate.text('privacy_bold_12')}</b>

					<p>{translate.text('privacy_text_17')}</p>

					<h3>{translate.text('privacy_title_3')}</h3>

					<p>{translate.text('privacy_text_18')}</p>

					<p>{translate.text('privacy_text_19')}</p>

					<h3>{translate.text('privacy_title_4')}</h3>

					<p>{translate.text('privacy_text_20')}</p>
					<br />
					<br />
				</div>
				<HomeFooterContainer />
			</div>

		)
	}

}