import { ActionTypes, IMarketAction, IMarkets, Markets } from 'exchange-common'

export const markets =
	(state: IMarkets = new Markets(), action: IMarketAction): IMarkets => {
		switch (action.type) {
			case ActionTypes.Market.Change:
				return {
					...state,
					current: action.current
				}

			case ActionTypes.Market.List:
				return {
					...action.markets
				}

			default:
				return state
		}
	}