import { IWallet } from 'exchange-common'
import { round } from 'libs-tools'

export const walletAmount = (wallet: IWallet) => {
    const dec = wallet.fiat ? 2 : 8
    const num = wallet.amount || 0
    return round(num, dec).toFixed(dec)
}
export const walletInOrders = (wallet: IWallet) => {
    const dec = wallet.fiat ? 2 : 8
    const inOrders = wallet.inOrders || 0
    return round(inOrders, dec).toFixed(dec)
}

export const walletBalance = (wallet: IWallet) => {
    const dec = wallet.fiat ? 2 : 8
    const amount = wallet.amount || 0
    const inOrders = wallet.inOrders || 0
    return round(amount - inOrders, dec).toFixed(dec)
}