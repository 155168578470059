import { ActionTypes, BankAccounts, IBankAccountAction, IBankAccounts } from 'exchange-common'

export const bankAccounts = (state: IBankAccounts = new BankAccounts(), action: IBankAccountAction): IBankAccounts => {
	switch (action.type) {
		case ActionTypes.BankAccount.List:
			return {
				...state,
				list: action.bankAccounts.list,
				isLoaded: action.bankAccounts.isLoaded,
			}
		default:
			return state
	}
}