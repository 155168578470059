import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { HomeFooterContainer } from '../containers/homeFooter'
import { translate } from '../functions/lang'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
	lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class AboutUsComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.

	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {

		translate.language = this.props.lang

		const partnerLogo1 = require('../images/logos/accurate-advice.png')
		const partnerLogo2 = require('../images/logos/ignitions-lab.png')

		const wordpress = require('../images/logos/wordpress.svg')
		const facebook = require('../images/logos/facebook.svg')
		const twitter = require('../images/logos/twitter.svg')
		const instagram = require('../images/logos/instagram.svg')
		const linkedin = require('../images/logos/linkedin.svg')
		const youtube = require('../images/logos/youtube.svg')

		return (
			<div className='aboutUs'>
				<div className='aboutUsMain'>
					<span className='pageTitle'>{translate.text('about_us')}</span>
					<div className='aboutHalfs'>
						<div className='aboutHalfLeft'>
							<h4>{translate.text('the_company')}</h4>
							<p>{translate.text('the_company_msg_1')}</p>
							<p>{translate.text('the_company_msg_2')}</p>
						</div>
						<div className='aboutHalfRight'>
							<h4>{translate.text('stats')}</h4>
							<div className='aboutStats'>
								<span>1K+</span>
								<p>{translate.text('users')}</p>
							</div>
							<div className='aboutStats'>
								<span>$1M+</span>
								<p>{translate.text('traded')}</p>
							</div>
						</div>
					</div>
					<br />
					<div className='aboutInvestors'>
						<h4>{translate.text('investors_and_partners')}</h4>
						<div className='aboutLogos'>
							<img src={partnerLogo1} alt='' />
							<img src={partnerLogo2} alt='' />
						</div>
					</div>
					<div className='aboutHalfs'>
						<div className='aboutHalfLeft' id='community'>
							<h4>{translate.text('community')}</h4>
							<p>{translate.text('follow_us_social')}:</p>
							<div>
								<a href='https://blog.econobit.io' target='_blank' rel='noopener noreferrer'><img src={wordpress} className='communityLogo' alt='blog' /></a>
								<a href='https://facebook.com/econobit' target='_blank' rel='noopener noreferrer'><img src={facebook} className='communityLogo' alt='facebook' /></a>
								<a href='https://twitter.com/econobit' target='_blank' rel='noopener noreferrer'><img src={twitter} className='communityLogo' alt='twitter' /></a >
								<a href='https://instagram.com/econobit' target='_blank' rel='noopener noreferrer'><img src={instagram} className='communityLogo' alt='instagram' /></a>
								<a href='https://linkedin.com/company/econobit' target='_blank' rel='noopener noreferrer'><img src={linkedin} className='communityLogo' alt='linkedin' /></a>
								<a href='https://youtube.com/user/econobit' target='_blank' rel='noopener noreferrer'><img src={youtube} className='communityLogo' alt='youtube' /></a >
							</div>
						</div>
						<div className='aboutHalfRight' id='careers'>
							<h4>{translate.text('careers')}</h4>
							<p><i>{translate.text('no_positions_open')}</i></p>
							<p><i>{translate.text('future_positions')}</i></p>
						</div>
					</div>
					<br />
					<br />
				</div>
				<HomeFooterContainer />
			</div>

		)
	}

}