import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { IState } from '../models/state'
import { Link } from 'react-router-dom'
import { translate } from '../functions/lang'

interface IProps {
	// include component properties here.
lang: string
}

interface IMatchProps {
	// include any url parameters here.

}

export class SentPasswordComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	render() {
		translate.language = this.props.lang

		return (
			<div className='sentPassword'>
				<div className='sentPasswordBox'>
					<div className='sentPasswordForm'>
						<h2>{translate.text('request_password_reset')}</h2>
						<p>{translate.text('reset_password_sent_msg')}</p>
						<Link className='sentPasswordLink' to='/login'>{translate.text('go_back')}</Link>
					</div>
				</div>
			</div>
		)
	}

}