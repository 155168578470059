import { ActionCreators } from 'exchange-common'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { ws } from '../functions/ws'
import { IState } from '../models/state'

interface IProps {
	// include component properties here.
}

interface IMatchProps {
	// include any url parameters here.

}

export class LogoutComponent extends React.Component<IProps & RouteComponentProps<IMatchProps>, IState> {

	componentDidMount() {
		// run after component is called.
	}

	componentWillUnmount() {
		// run before component is unloaded.

	}

	render() {
		ws.send(ActionCreators.User.logout())
		this.props.history.goBack()

		return (
			<div>
				Logout
			</div>
		)
	}
}