import { IAction } from 'exchange-common'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { MobileHeaderComponent } from '../components/mobileHeader'
import { IState } from '../models/state'

function mapStateToProps(state: IState) {
	return {
		markets: state.markets,
		user: state.user,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch: Dispatch<IAction>) {
	return {

	}
}

export const MobileHeaderContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeaderComponent))