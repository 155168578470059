import { ActionTypes, IOrderbookAction, IOrderbooks, Orderbooks } from 'exchange-common'

export const orderbooks =
	(state: IOrderbooks = new Orderbooks(), action: IOrderbookAction): IOrderbooks => {
		switch (action.type) {
			case ActionTypes.Orderbook.Load:
				return {
					...state,
					list: {
						...state.list,
						[action.symbol]: {
							...action.orderbook,
							isLoaded: true,
						}
					}
				}

			case ActionTypes.Orderbook.Add:
				const side = action.order.side === 'buy' ? 'bids' : 'asks'
				const orders = state.list[action.order.market][side]
				const i = (side === 'bids')
					? orders.findIndex(f => f.price < action.order.price)
					: orders.findIndex(f => f.price > action.order.price)
				let newOrders = []
				if (i < 0) {
					newOrders = [...orders, action.order]
				} else {
					newOrders = [...orders.slice(0, i), action.order, ...orders.slice(i)]
				}

				return {
					...state,
					list: {
						...state.list,
						[action.order.market]: {
							...state.list[action.order.market],
							[side]: newOrders
						}
					}
				}

			case ActionTypes.Orderbook.Update:
				const s = action.order.side === 'buy' ? 'bids' : 'asks'
				const o = state.list[action.order.market][s]
				const j = o.findIndex(f => f.orderId === action.order.orderId)

				return {
					...state,
					list: {
						...state.list,
						[action.order.market]: {
							...state.list[action.order.market],
							[s]: [
								...o.slice(0, j),
								{
									...o[j],
									...action.order,
								},
								...o.slice(j + 1)
							]
						}
					}
				}

			case ActionTypes.Orderbook.Delete:
				const sd = action.order.side === 'buy' ? 'bids' : 'asks'
				const mkt = action.order.market || ''

				return {
					...state,
					list: {
						...state.list,
						[mkt]: {
							...state.list[mkt],
							[sd]: [
								...state.list[mkt][sd].filter(f => f.orderId !== action.order.orderId)
							],
						}
					}
				}

			default:
				return state
		}
	}